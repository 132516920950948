import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';
import Page from 'arui-private/page';

import ExternalPageFooter from './footer';
import ExternalPageHeader from './header';

import './external-page.css';

type ExternalPfageProps = {
    children: React.ReactNode;
    title?: string;
};

const cn = createCn('external-page');

export function ExternalPage({ children, title }: ExternalPfageProps) {
    return (
        <Page
            className={cn()}
            hasOffset={false}
            header={<ExternalPageHeader />}
            footer={<ExternalPageFooter />}
        >
            {title && (
                <Typography.Title tag='div' view='xlarge' defaultMargins={true} weight='bold'>
                    {title}
                </Typography.Title>
            )}
            {children}
        </Page>
    );
}
