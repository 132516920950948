import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CalendarInputProps } from '@alfalab/core-components/calendar-input';
import { CalendarRange, CalendarRangeProps } from '@alfalab/core-components/calendar-range';

import { updateSelectedFilters } from '#/src/store/registers/registers-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';

const useHandleChangeFilterDate = (key: 'dateFrom' | 'dateTo') => {
    const dispatch = useDispatch();

    return useCallback<NonNullable<CalendarRangeProps['onDateFromChange']>>(
        (payload) => {
            dispatch(updateSelectedFilters({ [key]: payload?.value || '' }));
        },
        [dispatch, key],
    );
};

export const inputDateToProps: CalendarInputProps = {
    label: 'Период до',
    preventFlip: true,
    popoverPosition: 'bottom-start',
    dataTestId: 'registers-filter-field-date-to',
};

export const inputDateFromProps: CalendarInputProps = {
    label: 'Период с',
    preventFlip: true,
    popoverPosition: 'bottom-start',
    dataTestId: 'registers-filter-field-date-from',
};

export function DatesFilter() {
    const { dateFrom, dateTo } = useSelector(selectedFiltersSelector);

    const handleChangeDateFrom = useHandleChangeFilterDate('dateFrom');
    const handleChangeDateTo = useHandleChangeFilterDate('dateTo');

    return (
        <CalendarRange
            calendarPosition='popover'
            valueFrom={dateFrom}
            inputFromProps={inputDateFromProps}
            valueTo={dateTo}
            inputToProps={inputDateToProps}
            onDateFromChange={handleChangeDateFrom as CalendarRangeProps['onDateFromChange']}
            onDateToChange={handleChangeDateTo as CalendarRangeProps['onDateFromChange']}
        />
    );
}
