import { Certificate } from '@alfa-bank/corp-sign-ui-react/types';

import { formatDateTimeToUnixTime } from '#/src/utils/date-converter';

export function filterCertificates(certificates: Certificate[]) {
    return certificates.filter((item) => {
        const dateFrom = item.ValidFromDate
            ? formatDateTimeToUnixTime(item.ValidFromDate, '00:00:00')
            : null;
        const dateTo = item.ValidToDate
            ? formatDateTimeToUnixTime(item.ValidToDate, '23:59:59')
            : null;
        const dateFromIsValid = dateFrom ? dateFrom <= Date.now() : true;
        const dateToIsValid = dateTo ? dateTo >= Date.now() : true;

        return dateFromIsValid && dateToIsValid;
    });
}
