import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';

import { CalendarRangeFilter } from './calendar-range-filter';
import { CorrectionFilter } from './correction-filter';
import { StatusesFilter } from './statuses-filter';
import { SupplySourceTinFilter } from './supply-source-filter';

export function MainFiltersSection() {
    return (
        <React.Fragment>
            <Grid.Row>
                <Grid.Col width='6'>
                    <CalendarRangeFilter />
                </Grid.Col>
                <Grid.Col width='6'>
                    <SupplySourceTinFilter />
                </Grid.Col>
            </Grid.Row>
            <Gap size='xl' />
            <Grid.Row>
                <Grid.Col width='6'>
                    <StatusesFilter />
                </Grid.Col>
                <Grid.Col width='6'>
                    <CorrectionFilter />
                </Grid.Col>
            </Grid.Row>
            <Gap size='xl' />
        </React.Fragment>
    );
}
