import React, { Fragment, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Space } from '@alfalab/core-components/space';

import { ReportLinks } from '#/src/components/report-links';
import { LOADING_STATE } from '#/src/constants/loading-state';
import CachedReportsSection from '#/src/containers/pages/reports-xls-page/cached-reports-section';
import {
    currentOrganizationIdSelector,
    currentOrganizationIsDebtorSelector,
} from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { fetchCachedReports } from '#/src/store/reports-xls/cached-reports/actions';
import { cachedReportsSelector } from '#/src/store/reports-xls/cached-reports/selectors';
import { fetchDebtors } from '#/src/store/reports-xls/debtors/actions';
import { debtorsLoadingStateSelector } from '#/src/store/reports-xls/debtors/selectors';
import { fetchGeneralAgreements } from '#/src/store/reports-xls/general-agreements/actions';
import { generalAgreementsLoadingStateSelector } from '#/src/store/reports-xls/general-agreements/selectors';
import { resetReportFilters } from '#/src/store/reports-xls/report-filters/action-creators';
import { fetchReportNames } from '#/src/store/reports-xls/report-names/actions';
import { reportNamesLoadingStateSelector } from '#/src/store/reports-xls/report-names/selectors';

import { GenerateReportButton } from './generate-report-button';
import OrganizationTypeRadioGroup from './organization-type-radio-group';
import ReportsXlsFilters from './reports-xls-filters';
import ReportsXlsNames from './reports-xls-names';
import ReportXlsSkeleton from './reports-xls-skeleton';

const loadingStatePriority = {
    [LOADING_STATE.SUCCESS]: 0,
    [LOADING_STATE.UNKNOWN]: 1,
    [LOADING_STATE.ERROR]: 2,
    [LOADING_STATE.LOADING]: 3,
};

export default function ReportsXlsPage() {
    const dispatch = useDispatch();
    const organizationId = useSelector(currentOrganizationIdSelector);
    const isDebtor = useSelector(currentOrganizationIsDebtorSelector);
    const cachedReports = useSelector(cachedReportsSelector);
    const commonLoadingState = useSelector<ApplicationState>((state) =>
        [
            debtorsLoadingStateSelector(state),
            generalAgreementsLoadingStateSelector(state),
            reportNamesLoadingStateSelector(state),
        ].reduce((res, currentValue) => {
            if (loadingStatePriority[currentValue] > loadingStatePriority[res]) {
                return currentValue;
            }

            return res;
        }, LOADING_STATE.SUCCESS),
    );

    useEffect(() => {
        batch(() => {
            if (organizationId) {
                dispatch(fetchReportNames(isDebtor));
                dispatch(fetchDebtors(organizationId, isDebtor));
                dispatch(fetchGeneralAgreements(organizationId, isDebtor));
                dispatch(fetchCachedReports());
                dispatch(resetReportFilters());
            }
        });
    }, [dispatch, organizationId, isDebtor]);

    return (
        <Fragment>
            <ReportLinks />
            {commonLoadingState === LOADING_STATE.LOADING && <ReportXlsSkeleton />}
            {commonLoadingState === LOADING_STATE.SUCCESS && (
                <Fragment>
                    <Space size='m' direction='vertical' fullWidth={true}>
                        <OrganizationTypeRadioGroup />
                        <ReportsXlsNames />
                        <ReportsXlsFilters />
                    </Space>
                    <GenerateReportButton />
                    {cachedReports && !!cachedReports.length && <CachedReportsSection />}
                </Fragment>
            )}
        </Fragment>
    );
}
