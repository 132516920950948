import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Amount } from '@alfalab/core-components/amount';
import { Button } from '@alfalab/core-components/button';
import { Divider } from '@alfalab/core-components/divider';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { SidePanel } from '@alfalab/core-components/side-panel';
import { Status } from '@alfalab/core-components/status';
import { Typography } from '@alfalab/core-components/typography';

import { currencyCodes } from '#/src/dictionaries/currency';
import { statusColors, statusDictionary } from '#/src/dictionaries/register-row-status';
import { setSelectedRowId } from '#/src/store/registers/register-info/action-creators';
import {
    selectedRowIdSelector,
    selectedRowSelector,
} from '#/src/store/registers/register-info/selectors';
import {
    repaymentsIsSuccessSelector,
    repaymentsSelector,
    rowIdSelector,
} from '#/src/store/registers/repayments/selectors';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import { RegisterRowErrors } from './register-row-errors';

import './register-info-side-panel.css';

const cn = createCn('register-info-side-panel');

export function RegisterInfoSidePanel() {
    const dispatch = useDispatch();
    const selectedRowId = useSelector(selectedRowIdSelector);
    const selectedRow = useSelector(selectedRowSelector);

    const repaymentsIsSuccess = useSelector(repaymentsIsSuccessSelector);
    const repayments = useSelector(repaymentsSelector);
    const rowId = useSelector(rowIdSelector);

    const handleCloseSidePanel = useCallback(() => {
        dispatch(setSelectedRowId(null));
    }, [dispatch]);

    return (
        <div>
            <SidePanel
                className={cn()}
                open={Boolean(selectedRowId)}
                onClose={handleCloseSidePanel}
                dataTestId='register-info-side-panel'
            >
                <SidePanel.Header hasCloser={true} />
                <SidePanel.Content dataTestId='register-info-side-panel-content'>
                    {selectedRow && (
                        <React.Fragment>
                            <Grid.Row justify='between' align='top'>
                                <Grid.Col width='9'>
                                    <Typography.Title tag='div' view='small' weight='bold'>
                                        № {selectedRow.documentNumber || 'Отсутствует'}
                                    </Typography.Title>
                                </Grid.Col>
                                <Grid.Col width='3'>
                                    {selectedRow.supplyDate
                                        ? formatDateForCalendar(selectedRow.supplyDate)
                                        : 'Отсутствует'}
                                </Grid.Col>
                            </Grid.Row>
                            <Gap size='s' />
                            <div>
                                {statusDictionary[selectedRow.status] ? (
                                    <Status color={statusColors[selectedRow.status] || 'grey'}>
                                        {statusDictionary[selectedRow.status]}
                                    </Status>
                                ) : (
                                    'Отсутствует'
                                )}
                            </div>
                            <Gap size='s' />
                            <Grid.Row justify='between' align='top' className={cn('row-custom')}>
                                <Grid.Col width='3'>Сумма</Grid.Col>
                                <Grid.Col width='9' className={cn('col-right')}>
                                    {selectedRow.supplyAmount ? (
                                        <Typography.Title tag='div' view='xsmall'>
                                            <Amount
                                                view='withZeroMinorPart'
                                                value={selectedRow.supplyAmount * 100}
                                                currency={currencyCodes[selectedRow.currencyNumber]}
                                                minority={100}
                                            />
                                        </Typography.Title>
                                    ) : (
                                        'Отсутствует'
                                    )}
                                </Grid.Col>
                            </Grid.Row>
                            <Divider />
                            <Grid.Row justify='between' align='top' className={cn('row')}>
                                <Grid.Col width='3'>Контракт</Grid.Col>
                                <Grid.Col width='9' className={cn('col-right')}>
                                    {selectedRow.agreementNumber || 'Отсутствует'}
                                </Grid.Col>
                            </Grid.Row>
                            <Divider />
                            <Typography.Title
                                tag='div'
                                view='xsmall'
                                weight='bold'
                                defaultMargins={true}
                            >
                                Поставщик
                            </Typography.Title>
                            <Grid.Row justify='between' align='top' className={cn('row')}>
                                <Grid.Col width='3'>ИНН</Grid.Col>
                                <Grid.Col width='9' className={cn('col-right')}>
                                    {selectedRow.sellerTin || 'Отсутствует'}
                                </Grid.Col>
                            </Grid.Row>
                            <Divider />
                            <Typography.Title
                                tag='div'
                                view='xsmall'
                                weight='bold'
                                defaultMargins={true}
                            >
                                Дебитор
                            </Typography.Title>
                            <Grid.Row justify='between' align='top' className={cn('row')}>
                                <Grid.Col width='3'>ИНН</Grid.Col>
                                <Grid.Col width='9' className={cn('col-right')}>
                                    {selectedRow.debtorTin || 'Отсутствует'}
                                </Grid.Col>
                            </Grid.Row>
                            <Divider />
                            {rowId === selectedRowId &&
                                repaymentsIsSuccess &&
                                Boolean(repayments.length) && (
                                    <React.Fragment>
                                        <Typography.Title
                                            tag='div'
                                            view='xsmall'
                                            weight='bold'
                                            defaultMargins={true}
                                        >
                                            Плановое погашение
                                        </Typography.Title>
                                        {repayments.map((item) => (
                                            <React.Fragment>
                                                <Grid.Row
                                                    justify='between'
                                                    align='top'
                                                    className={cn('row')}
                                                >
                                                    <Grid.Col width='3'>
                                                        {formatDateForCalendar(item.repaymentDate)}
                                                    </Grid.Col>
                                                    <Grid.Col width='9' className={cn('col-right')}>
                                                        <Amount
                                                            view='withZeroMinorPart'
                                                            value={item.repaymentAmount * 100}
                                                            currency={
                                                                currencyCodes[
                                                                    selectedRow.currencyNumber
                                                                ]
                                                            }
                                                            minority={100}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Row>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                )}
                            <RegisterRowErrors registerRow={selectedRow} />
                            <Gap size='m' />
                        </React.Fragment>
                    )}
                </SidePanel.Content>
                <SidePanel.Footer>
                    <Button
                        dataTestId='close-sidebar-button'
                        view='primary'
                        size='s'
                        block={true}
                        onClick={handleCloseSidePanel}
                    >
                        Закрыть
                    </Button>
                </SidePanel.Footer>
            </SidePanel>
        </div>
    );
}
