import React from 'react';

import { Amount } from '@alfalab/core-components/amount';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { SupplyEventInfo } from '#/src/types/supply';
import {
    formatDateForList,
    getHoursFromTimeStamp,
    getMinutesFromTimeStamp,
} from '#/src/utils/date-converter';

type SupplyEventRowProps = {
    eventInfo: SupplyEventInfo;
};

export function SupplyEventRow({ eventInfo }: SupplyEventRowProps) {
    return (
        <React.Fragment>
            <Gap size='s' />
            <Grid.Row>
                <Grid.Col width='5'>
                    <Typography.Text view='secondary-large'>
                        {eventInfo.eventDescription}
                    </Typography.Text>
                </Grid.Col>
                <Grid.Col width='3'>
                    <Typography.Text view='secondary-large'>
                        {!!eventInfo.sum && (
                            <Amount
                                view='withZeroMinorPart'
                                minority={100}
                                value={eventInfo.sum * 100}
                                currency='RUR'
                                bold='none'
                            />
                        )}
                    </Typography.Text>
                </Grid.Col>
                <Grid.Col width='4'>
                    <Typography.Text color='secondary' view='secondary-large'>
                        {formatDateForList(eventInfo.eventTimestamp, true)} (
                        {getHoursFromTimeStamp(eventInfo.eventTimestamp)}:
                        {getMinutesFromTimeStamp(eventInfo.eventTimestamp)})
                    </Typography.Text>
                </Grid.Col>
            </Grid.Row>
        </React.Fragment>
    );
}
