import { ThunkAction } from 'redux-thunk';

import { reportSelector } from '#/src/store/documents/selectors';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { appliedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';
import { FileType } from '#/src/types/documents';
import { fetchers } from '#/src/utils/client-api';
import { downloadFile } from '#/src/utils/download-file';
import { parseErrorMessage } from '#/src/utils/parse-error-message';

import * as actionCreators from './action-creators';
import { DocumentsActionsType } from './types';

export function downloadDocuments(
    ids: number[],
    fileTypes: FileType[] = [],
): ThunkAction<void, ApplicationState, void, DocumentsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.downloadStart());

        try {
            const { successDocuments, report } = await fetchers.fetchCheckDocuments({
                ids,
                fileTypes,
            });

            if (successDocuments.length) {
                const file = await fetchers.fetchDownloadDocuments({
                    ids: successDocuments,
                    fileTypes,
                });

                await downloadFile(file, 'application/zip', 'архив документов.zip');
            }

            dispatch(
                actionCreators.downloadSuccess({
                    successDocuments,
                    report,
                    ids,
                    resultModalIsOpen: !!report,
                }),
            );
        } catch (e) {
            const error = parseErrorMessage(e);

            dispatch(actionCreators.downloadError(error));
            dispatch(dispatchErrorNotification(e));
        }
    };
}

export function downloadReport(): ThunkAction<void, ApplicationState, void, DocumentsActionsType> {
    return async (dispatch, getState) => {
        const state = getState();
        const report = reportSelector(state) as string;

        try {
            const byteCharacters = atob(report);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            await downloadFile(byteArray, 'application/vnd.ms-excel', 'отчёт.xlsx');
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
        }
    };
}

export function downloadDocumentsForRegisters(
    registerIds: number[],
    fileTypes: FileType[] = [],
): ThunkAction<void, ApplicationState, void, DocumentsActionsType> {
    return async (dispatch, getState) => {
        const state = getState();
        const partyTin = currentOrganizationIdSelector(state) as string;

        dispatch(actionCreators.downloadStart());

        try {
            const { compressedInfoList } = await fetchers.fetchRegisters({
                needFields: ['documentId'],
                needCompress: true,
                ids: registerIds,
                partyTin,
            });

            dispatch(downloadDocuments(compressedInfoList?.map((id) => +id) || [], fileTypes));
        } catch (e) {
            const error = parseErrorMessage(e);

            dispatch(actionCreators.downloadError(error));
            dispatch(dispatchErrorNotification(e));
        }
    };
}

export function downloadReportByRegisters(
    ids: number[],
): ThunkAction<void, ApplicationState, void, DocumentsActionsType> {
    return async (dispatch, getState) => {
        const state = getState();
        const filters = appliedFiltersSelector(state);

        dispatch(actionCreators.downloadStart());

        try {
            const file = (await fetchers.fetchDownloadReportByRegisters({
                ids,
                registerRowFilter: {
                    documentNumber: filters.documentNumber,
                    sellersTin: filters.role === 'debtor' ? filters.sellersTin : [],
                    debtorsTin: filters.role === 'debtor' ? [] : filters.debtorsTin,
                },
            })) as never;

            await downloadFile(file, 'application/vnd.ms-excel', 'Отчёт.xlsx');

            dispatch(actionCreators.downloadFinish());
        } catch (e) {
            const error = parseErrorMessage(e);

            dispatch(actionCreators.downloadError(error));
            dispatch(dispatchErrorNotification(e));
        }
    };
}
