import React from 'react';

import { ReportLinks } from '#/src/components/report-links';

import { DashboardContent } from './dashboard-content';

export function DashboardPage() {
    return (
        <React.Fragment>
            <ReportLinks />
            <DashboardContent />
        </React.Fragment>
    );
}
