import generateCSRFName from 'corporate-services/lib/csrf-name-generator';

import { create as createFetchers } from '#/src/server/services/client';

export let fetchers: ReturnType<typeof createFetchers>; // eslint-disable-line import/no-mutable-exports

/**
 * Инициализирует переменную fetchers c правильным contextRoot.
 * Должна быть вызвана ДО использования fetchers.
 *
 * @param  {String} contextRoot contextRoot проекта
 */
export function initClientApi(contextRoot = '') {
    if (!fetchers) {
        const csrfCookieName = generateCSRFName(contextRoot);

        fetchers = createFetchers(contextRoot, csrfCookieName);
    }

    return fetchers;
}
