import React, { useCallback, useState } from 'react';

import { IconButton } from '@alfalab/core-components/icon-button';
import { Input, InputProps } from '@alfalab/core-components/input';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';
import { EyeLineMIcon } from '@alfalab/icons-glyph/EyeLineMIcon';
import { EyeOffLineMIcon } from '@alfalab/icons-glyph/EyeOffLineMIcon';

import { FormType } from '#/src/components/external-page-form/types';

type FormFieldInputProps = {
    value: string;
    onChange: InputProps['onChange'];
    error: string;
    name: string;
    label: string;
    hasHiddenValue?: boolean;
    form: FormType;
};

export default function FormFieldInput({
    value,
    error,
    onChange,
    name,
    label,
    hasHiddenValue = false,
    form,
}: FormFieldInputProps) {
    const [valueIsHidden, setHiddenStatePassword] = useState(hasHiddenValue);
    const handleToggleHiddenStatePassword = useCallback(() => {
        setHiddenStatePassword((prevState) => !prevState);
    }, []);

    return (
        <Input
            dataTestId={`${name}-${form}-form-field`}
            type={valueIsHidden ? 'password' : 'text'}
            name={name}
            size='m'
            label={label}
            value={value}
            onChange={onChange}
            block={true}
            rightAddons={
                error ? (
                    <IconButton view='negative' icon={ExclamationCircleMIcon} />
                ) : (
                    hasHiddenValue && (
                        <IconButton
                            view='primary'
                            onClick={handleToggleHiddenStatePassword}
                            icon={valueIsHidden ? EyeOffLineMIcon : EyeLineMIcon}
                        />
                    )
                )
            }
            error={error}
            autoComplete='off'
        />
    );
}
