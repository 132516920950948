import React from 'react';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';
import { CallMBlackIcon } from '@alfalab/icons-classic/CallMBlackIcon';

import './external-page-phones-info.css';

const cn = createCn('external-page-phones-info');

type ExternalPagePhonesProps = {
    view: 'rows' | 'columns';
    hasIcon: boolean;
};
type Phone = {
    phone: string;
    text: string;
};

const phones: Phone[] = [
    {
        phone: '+7 495 755-58-58',
        text: 'в Москве и за границей',
    },
    {
        phone: '8 800 100-77-33',
        text: 'в любом городе России',
    },
];

export default function ExternalPagePhonesInfo({ view, hasIcon }: ExternalPagePhonesProps) {
    return (
        <div className={cn('container', { view })}>
            {hasIcon && (
                <div className={cn('icon')}>
                    <CallMBlackIcon />
                </div>
            )}
            <div className={cn('list', { view })}>
                {phones.map((item) => (
                    <div key={item.phone} className={cn('list-item', { view })}>
                        <Link
                            dataTestId='phone-link'
                            href={`tel:${item.phone.replace(/[^\d]/g, '')}`}
                        >
                            {item.phone}
                        </Link>
                        <div className={cn('phone-text')}>{item.text}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
