import { combineReducers } from 'redux';

import { dashboardChartsReducer, DashboardChartsState } from './dashboard-charts/reducer';
import { dashboardFiltersReducer, DashboardFiltersState } from './dashboard-filters/reducer';
import { dashboardLimitsReducer, DashboardLimitsState } from './dashboard-limits/reducer';

export type DashboardState = {
    dashboardFilters: DashboardFiltersState;
    dashboardCharts: DashboardChartsState;
    dashboardLimits: DashboardLimitsState;
};

export const dashboardReducer = combineReducers<DashboardState>({
    dashboardFilters: dashboardFiltersReducer,
    dashboardCharts: dashboardChartsReducer,
    dashboardLimits: dashboardLimitsReducer,
});
