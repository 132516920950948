import { LOADING_STATE } from '#/src/constants/loading-state';
import { CheckDocumentResponse } from '#/src/types/documents';
import createAction from '#/src/utils/create-action';

export const downloadStart = createAction('DOCUMENTS / downloadStart', () => ({
    loadingState: LOADING_STATE.LOADING,
}));

export const downloadSuccess = createAction(
    'DOCUMENTS / downloadSuccess',
    (data: CheckDocumentResponse & { ids: number[]; resultModalIsOpen: boolean }) => ({
        loadingState: LOADING_STATE.SUCCESS,
        data,
    }),
);

export const downloadFinish = createAction('DOCUMENTS / downloadFinish', () => ({
    loadingState: LOADING_STATE.SUCCESS,
}));

export const downloadError = createAction('DOCUMENTS / downloadError', (error: string) => ({
    error,
    loadingState: LOADING_STATE.ERROR,
}));

export const clearState = createAction('DOCUMENTS / clearState', () => ({}));
