import React from 'react';
import { createCn } from 'bem-react-classname';

import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import FatalErrorSvg from 'arui-private/icons/error.svg';

import './register-error-section.css';

const cn = createCn('register-error-section');

export function RegisterErrorSection() {
    return (
        <div className={cn()}>
            <img src={FatalErrorSvg} alt='Сервис временно недоступен' />
            <Typography.Title tag='div' view='small' weight='bold'>
                Похоже, что-то пошло не так
            </Typography.Title>
            <Typography.Text tag='div' view='primary-medium'>
                Сервис временно недоступен, повторите операцию позднее.
            </Typography.Text>
            <Gap size='6xl' />
        </div>
    );
}
