import React from 'react';
import { useSelector } from 'react-redux';

import { invoiceRegistryEdiSoftIframeUrlSelector } from '#/src/store/navigations/selectors';

const styles = { minHeight: 'calc(100vh - 64px)' };

export default function SuppliesEdiSoftPage() {
    const iframeSrc = useSelector(invoiceRegistryEdiSoftIframeUrlSelector);

    return (
        <iframe
            title='Реестры СФ'
            src={iframeSrc}
            width='100%'
            height='100%'
            frameBorder='0'
            style={styles}
        />
    );
}
