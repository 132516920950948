import { LoadingState } from '#/src/constants/loading-state';
import { SortField, SortOrder } from '#/src/types/sort';
import { SupplyDto, SupplySource } from '#/src/types/supply';

import * as actionCreators from './action-creators';
import { SuppliesListActionsType } from './types';

export type SuppliesListState = {
    ids: number[];
    entities: SupplyDto[];
    loadingState: LoadingState;
    pagination: {
        page: number;
        pageSize: number;
    };
    selectedIds: number[];
    sort: {
        field: SortField;
        order: SortOrder;
    };
    serverRequestTime: string | null;
    needUpdate: boolean;
    availableSupplies: {
        available: Record<number, number>;
        source: Record<number, SupplySource[]>;
        amountCertificationDocumentDate: Record<number, number>;
    };
    totalPrice: number;
    allTotalPrice: Record<number, number>;
};

const initialState: SuppliesListState = {
    ids: [],
    entities: [],
    pagination: {
        page: 1,
        pageSize: 20,
    },
    selectedIds: [],
    sort: {
        field: SortField.DATE,
        order: SortOrder.DESC,
    },
    loadingState: 'unknown',
    serverRequestTime: null,
    needUpdate: false,
    availableSupplies: {
        available: {},
        source: {},
        amountCertificationDocumentDate: {},
    },
    totalPrice: 0,
    allTotalPrice: {},
};

export function suppliesListReducer(
    state: SuppliesListState = initialState,
    action: SuppliesListActionsType,
): SuppliesListState {
    switch (action.type) {
        case actionCreators.changeSuppliesListLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.setSupplies.type:
            return {
                ...state,
                ...action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.setSuppliesList.type:
            return {
                ...state,
                ...action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.clearSupplies.type:
            return initialState;
        case actionCreators.toggleSelectedSupplies.type:
            return {
                ...state,
                selectedIds: state.ids.length === state.selectedIds.length ? [] : state.ids,
            };
        case actionCreators.setSelectedSupplies.type:
            return {
                ...state,
                selectedIds: action.selectedIds,
            };
        case actionCreators.setSort.type:
            return {
                ...state,
                sort: {
                    ...action.data,
                },
            };
        case actionCreators.setNeedUpdate.type:
            return {
                ...state,
                needUpdate: action.needUpdate,
            };
        default:
            return state;
    }
}
