export type UserState = {
    profile: {
        userName: string;
        fullName: string;
    };
    permissions: Record<string, string>;
};

export const initialState: UserState = {
    profile: {
        userName: '',
        fullName: '',
    },
    permissions: {},
};

export function userReducer(state: UserState = initialState): UserState {
    return state;
}
