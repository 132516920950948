import { format } from 'date-fns';
import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';
import { SupplyUpdateEntity } from '#/src/types/supply';

import { suppliesModelsSelector } from '../selectors';

export const suppliesEditingStateSelector = (state: ApplicationState) =>
    suppliesModelsSelector(state).suppliesEditing;

export const editedDebtorsSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).editedDebtors;

export const debtorsDictionarySelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).debtorsDictionary;

export const editingSuppliesListSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).editingSuppliesList;

export const editingSupplySelector = (currentDebtorTin: string) => (state: ApplicationState) => {
    const suppliesWithSameDebtor = suppliesEditingStateSelector(state).editingSuppliesList.filter(
        ({ debtorTin }) => debtorTin === currentDebtorTin,
    );

    if (suppliesWithSameDebtor.length > 1) {
        return suppliesWithSameDebtor.reduce(
            (acc, supply) => ({
                ...supply,
                ...acc,
                supplyAgreementEditable:
                    supply.supplyAgreementEditable || acc.supplyAgreementEditable,
                supplyDelayPeriodEditable:
                    supply.supplyDelayPeriodEditable || acc.supplyDelayPeriodEditable,
                supplyReceiptDateEditable:
                    supply.supplyReceiptDateEditable || acc.supplyReceiptDateEditable,
            }),
            {
                supplyAgreementEditable: false,
                supplyDelayPeriodEditable: false,
                supplyReceiptDateEditable: false,
            },
        );
    }

    return suppliesEditingStateSelector(state).editingSuppliesList.find(
        ({ debtorTin }) => debtorTin === currentDebtorTin,
    );
};

export const editModalIsOpenSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).editModalIsOpen;

export const warningModalIsOpenSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).warningModalIsOpen;

export const suppliesEditingIsLoadingSelector = createSelector(
    suppliesEditingStateSelector,
    (state) => state.loadingState === 'loading',
);

export const editedDebtorsListSelector = createSelector(editedDebtorsSelector, (data) =>
    Object.keys(data).map((debtorTin) => ({ debtorTin, supplyAgreementNumber: data[debtorTin] })),
);

export const savingAvailableSelector = createSelector(editedDebtorsListSelector, (list) =>
    list.some(({ supplyAgreementNumber }) => supplyAgreementNumber),
);

export const editedSuppliesListSelector = createSelector(
    editingSuppliesListSelector,
    editedDebtorsSelector,
    (supplies, values) =>
        supplies.reduce((arr, supply) => {
            if (supply.id && supply.debtorTin && values[supply.debtorTin]) {
                const debtorValues = values[supply.debtorTin];
                const updatedSupply = {
                    id: supply.id,
                    isOneSupplyAgreement: debtorValues.isOneSupplyAgreement,
                    ...(debtorValues.contract &&
                        supply.supplyAgreementEditable && {
                            supplyAgreementNumber: debtorValues.contract,
                        }),
                    ...(debtorValues.delayPeriod &&
                        supply.supplyDelayPeriodEditable && {
                            delayPeriod: debtorValues.delayPeriod,
                        }),
                    ...(debtorValues.receiptDate &&
                        supply.supplyReceiptDateEditable && {
                            receiptDate: format(String(debtorValues.receiptDate), 'DD.MM.YYYY'),
                        }),
                };

                return arr.concat(updatedSupply);
            }

            return arr;
        }, [] as SupplyUpdateEntity[]),
);
