import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import { sourceOptions } from '#/src/dictionaries/supply-source';
import { updateSelectedFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';
import { SupplySource } from '#/src/types/supply';

export function SupplySourceTinFilter() {
    const dispatch = useDispatch();
    const { supplySource } = useSelector(selectedFiltersSelector);

    const handleChangeStatuses = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selected }) => {
            dispatch(updateSelectedFilters({ supplySource: selected?.value as SupplySource }));
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='supply-source-select'
            block={true}
            placeholder='Источник'
            label='Источник'
            optionsListWidth='field'
            selected={supplySource}
            options={sourceOptions}
            onChange={handleChangeStatuses}
        />
    );
}
