import { GetNavigationsResponse } from '#/src/server/services/api/navigation';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '') {
    const fetch = {
        getNavigations: fetchFactory<never, GetNavigationsResponse>(
            `${contextRoot}/secure/nodeapi/getNavigations`,
            'GET',
            { allowMultiRequest: true },
        ),
    };

    return {
        fetchNavigations() {
            return fetch.getNavigations();
        },
    };
}
