import { CountFilter, PeriodFilter } from '#/src/dictionaries/reports-dashboard';
import getDateForDashboardByPeriod from '#/src/utils/get-date-for-dashboard-by-period';

import { DashboardFiltersState } from './reducer';

export function getDefaultDashboardFiltersState(): DashboardFiltersState {
    const { dateTo, dateFrom } = getDateForDashboardByPeriod(PeriodFilter.WEEK);

    return {
        period: PeriodFilter.WEEK,
        debtorTin: [],
        dateFrom,
        dateTo,
        byCount: CountFilter.DEFAULT,
        stepDate: 0,
    };
}
