import { FiltersState } from '#/src/store/registers/registers-filters/reducer';
import { RegistersListState } from '#/src/store/registers/registers-list/reducer';
import { GetRegistersPayload } from '#/src/types/registers';
import { SortOrder } from '#/src/types/sort';
import { formatDateFromCalendar } from '#/src/utils/date-converter';

export function convertFiltersToRequestParams(
    filters: FiltersState,
    sort: RegistersListState['sort'],
    partyTin: string | null,
): GetRegistersPayload {
    const isDebtor = filters.role === 'debtor';

    return {
        partyTin: partyTin || undefined,
        debtor: isDebtor,
        descOrder: sort.order === SortOrder.DESC,
        statuses: filters.status,
        registerSource: filters.registerSource === 'ALL' ? [] : [filters.registerSource],
        from: formatDateFromCalendar(filters.dateFrom) || '',
        to: formatDateFromCalendar(filters.dateTo) || '',
        fileNumber: filters.fileNumber,
        registerRowFilter: {
            documentNumber: filters.documentNumber,
            sellersTin: isDebtor ? filters.sellersTin : [],
            debtorsTin: isDebtor ? [] : filters.debtorsTin,
        },
    };
}
