import { LoadingState } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

import { SignSuppliesState } from './reducer';

export const clearState = createAction('SUPPLIES / SIGN_SUPPLIES  / clearState', () => ({}));

export const changeSignSuppliesLoadingState = createAction(
    'SUPPLIES / SIGN_SUPPLIES / changeSignSuppliesLoadingState',
    (loadingState: LoadingState) => ({ loadingState }),
);

export const setPreparedSignData = createAction(
    'SUPPLIES / SIGN_SUPPLIES  / setPreparedSignData',
    (data: Pick<SignSuppliesState, 'availableAmount' | 'activeErf' | 'suppliesIds' | 'callback'>) =>
        data,
);

export const changeErfErrorModalState = createAction(
    'SUPPLIES / SIGN_SUPPLIES  / changeErfErrorModalState',
    (isOpen: boolean) => ({ isOpen }),
);

type SetCreatedSignDataArguments = Pick<
    SignSuppliesState,
    | 'documents'
    | 'filteredSuppliesIds'
    | 'createdSuppliesSignData'
    | 'createdRegisterSignData'
    | 'user'
    | 'permissions'
    | 'registerId'
    | 'warningModalIsOpen'
>;
export const setCreatedSignData = createAction(
    'SUPPLIES / SIGN_SUPPLIES  / setCreatedSignData',
    (data: SetCreatedSignDataArguments) => data,
);

export const changeAdditionalModalState = createAction(
    'SUPPLIES / SIGN_SUPPLIES  / changeAdditionalModalState',
    (isOpen: boolean) => ({ isOpen }),
);

export const changeWaitingErrorModalState = createAction(
    'SUPPLIES / SIGN_SUPPLIES  / changeWaitingErrorModalState',
    (isOpen: boolean) => ({ isOpen }),
);

export const changeWarningModalState = createAction(
    'SUPPLIES / SIGN_SUPPLIES  / changeWarningModalState',
    (isOpen: boolean) => ({ isOpen }),
);

export const changeEmptyFieldsWarningModalState = createAction(
    'SUPPLIES / SIGN_SUPPLIES  / changeEmptyFieldsWarningModalState',
    (isOpen: boolean) => ({ isOpen }),
);
