import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from '@alfalab/core-components/table';
import { Typography } from '@alfalab/core-components/typography';

import { setSort } from '#/src/store/supplies/supplies-list/action-creators';
import { suppliesSortSelector } from '#/src/store/supplies/supplies-list/selectors';
import { availableCellsSelector } from '#/src/store/supplies/table-cells-settings/selectors';
import { SortField, SortOrder } from '#/src/types/sort';

export function SuppliesTableHeaderCells() {
    const dispatch = useDispatch();
    const availableCells = useSelector(availableCellsSelector);
    const sort = useSelector(suppliesSortSelector);

    const handleSortByCost = useCallback(() => {
        const newSort = {
            field: SortField.COST,
            order: SortOrder.DESC,
        };

        if (sort.field === SortField.COST) {
            newSort.order = sort.order === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
        }

        dispatch(setSort(newSort));
    }, [dispatch, sort]);

    const handleSortByDate = useCallback(() => {
        const newSort = {
            field: SortField.DATE,
            order: SortOrder.DESC,
        };

        if (sort.field === SortField.DATE) {
            newSort.order = sort.order === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
        }

        dispatch(setSort(newSort));
    }, [dispatch, sort]);

    return (
        <React.Fragment>
            {[
                <Table.THeadCell />,
                <Table.THeadCell>
                    <Typography.Text color='secondary' view='secondary-large'>
                        Документ
                    </Typography.Text>
                </Table.THeadCell>,
                <Table.TSortableHeadCell
                    isSortedDesc={
                        sort.field === SortField.COST ? sort.order === SortOrder.DESC : undefined
                    }
                    onSort={handleSortByCost}
                >
                    <Typography.Text color='secondary' view='secondary-large'>
                        Сумма
                    </Typography.Text>
                </Table.TSortableHeadCell>,
                availableCells.includes('date') && (
                    <Table.TSortableHeadCell
                        isSortedDesc={
                            sort.field === SortField.DATE
                                ? sort.order === SortOrder.DESC
                                : undefined
                        }
                        onSort={handleSortByDate}
                    >
                        <Typography.Text color='secondary' view='secondary-large'>
                            Дата
                        </Typography.Text>
                    </Table.TSortableHeadCell>
                ),
                availableCells.includes('debtor') && (
                    <Table.THeadCell>
                        <Typography.Text color='secondary' view='secondary-large'>
                            Дебитор
                        </Typography.Text>
                    </Table.THeadCell>
                ),
                availableCells.includes('contract') && (
                    <Table.THeadCell>
                        <Typography.Text color='secondary' view='secondary-large'>
                            Контракт
                        </Typography.Text>
                    </Table.THeadCell>
                ),
                availableCells.includes('supplySource') && (
                    <Table.THeadCell>
                        <Typography.Text color='secondary' view='secondary-large'>
                            Источник
                        </Typography.Text>
                    </Table.THeadCell>
                ),
                <Table.THeadCell textAlign='right'>
                    <Typography.Text color='secondary' view='secondary-large'>
                        Статус
                    </Typography.Text>
                </Table.THeadCell>,
                <Table.THeadCell />,
            ].filter((cell) => !!cell)}
        </React.Fragment>
    );
}
