import React, { useMemo } from 'react';

import AmountInfo, { AmountInfoProps } from '#/src/components/amount-info';
import { CountFilter } from '#/src/dictionaries/reports-dashboard';

type LegendAmountProps = {
    amount: number;
    byCount: CountFilter;
    styled: boolean;
};

export default function ChartAmount({ amount, byCount, styled }: LegendAmountProps) {
    const amountInfoCustomParams: AmountInfoProps = useMemo(
        () =>
            styled
                ? {
                      value: amount * 100,
                      minority: 100,
                      view: 'withZeroMinorPart',
                      currency: 'RUR',
                      styled,
                  }
                : {
                      value: amount * 100,
                      minority: 100,
                      view: 'default',
                      styled,
                  },
        [amount, styled],
    );

    switch (byCount) {
        case CountFilter.THOUSANDS: {
            return (
                <AmountInfo {...amountInfoCustomParams} value={(amount / 1000) * 100} info='тыс' />
            );
        }
        case CountFilter.MILLIONS: {
            return (
                <AmountInfo
                    {...amountInfoCustomParams}
                    value={(amount / 1000000) * 100}
                    info='млн'
                />
            );
        }
        default:
            return <AmountInfo {...amountInfoCustomParams} />;
    }
}
