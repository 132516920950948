import React from 'react';
import { createCn } from 'bem-react-classname';

import { RegisterListTableBody } from '../register-list-table-body';
import { RegisterListTableHead } from '../register-list-table-head';
import { RegistersListPagination } from '../registers-list-pagination';

import './register-list-table.css';

const cn = createCn('register-list-table');

export function RegisterListTable() {
    return (
        <div className={cn()} data-test-id='registers-table'>
            <RegisterListTableHead />
            <RegisterListTableBody />
            <RegistersListPagination />
        </div>
    );
}
