import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';

import { AmountCertificateDocumentNumberFilter } from './amount-certificate-document-number-filter';
import { AmountRangeFilter } from './amount-range-filter';
import { DebtorsTinFilter } from './debtors-tin-filter';
import { FactoringAgreementNumberFilter } from './factoring-agreement-number-filter';
import { RegisterNumberFilter } from './register-number-filter';
import { SupplyAgreementNumberFilter } from './supply-agreement-number-filter';

export function AdditionalFiltersSection() {
    return (
        <React.Fragment>
            <Space size={[24, 0]} fullWidth={true} dataTestId='additional-filters-section'>
                <Grid.Row>
                    <Grid.Col width='6'>
                        <FactoringAgreementNumberFilter />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <RegisterNumberFilter />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col width='6'>
                        <DebtorsTinFilter />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <SupplyAgreementNumberFilter />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col width='6'>
                        <AmountCertificateDocumentNumberFilter />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <AmountRangeFilter />
                    </Grid.Col>
                </Grid.Row>
            </Space>
            <Gap size='xl' />
        </React.Fragment>
    );
}
