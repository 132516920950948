export const weekCategories = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
];

export const quarterCategoriesMap = {
    1: ['Январь', 'Февраль', 'Март'],
    2: ['Апрель', 'Май', 'Июнь'],
    3: ['Июль', 'Август', 'Сентябрь'],
    4: ['Октябрь', 'Ноябрь', 'Декабрь'],
};

export const yearCategories = ['1 квартал', '2 квартал', '3 квартал', '4 квартал'];
