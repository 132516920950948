import { LOADING_STATE } from '#/src/constants/loading-state';
import { CachedReport } from '#/src/types/reports';
import createAction from '#/src/utils/create-action';

export const getCachedReportsStart = createAction('REPORTS_XLS / getCachedReportsStart', () => ({
    loadingState: LOADING_STATE.LOADING,
}));

export const getCachedReportsSuccess = createAction(
    'REPORTS_XLS / getCachedReportsSuccess',
    (list: CachedReport[]) => ({ list, loadingState: LOADING_STATE.SUCCESS }),
);

export const getCachedReportsError = createAction('REPORTS_XLS / getCachedReportsError', () => ({
    loadingState: LOADING_STATE.ERROR,
}));

export const startGenerateCachedReport = createAction(
    'REPORTS_XLS / startGenerateCachedReport',
    () => ({}),
);

export const endGenerateCachedReport = createAction(
    'REPORTS_XLS / endGenerateCachedReport',
    () => ({}),
);

export const addRequestedReport = createAction(
    'REPORTS_XLS / addRequestedReport',
    (report: CachedReport) => ({ report }),
);

export const startDownloadCachedReport = createAction(
    'REPORTS_XLS / startDownloadCachedReport',
    () => ({}),
);

export const endDownloadCachedReport = createAction(
    'REPORTS_XLS / endDownloadCachedReport',
    () => ({}),
);
