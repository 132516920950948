import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';
import formatDate from 'date-fns/format';

import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { PointerDownMIcon } from '@alfalab/icons-glyph/PointerDownMIcon';

import { reportNames } from '#/src/constants/reports';
import ReportXlsStatusIcon from '#/src/containers/pages/reports-xls-page/report-xls-status-icon';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { downloadCachedReports } from '#/src/store/reports-xls/cached-reports/actions';
import { isLoadingDownloadCachedReportSelector } from '#/src/store/reports-xls/cached-reports/selectors';
import { debtorsSelector } from '#/src/store/reports-xls/debtors/selectors';
import { generalAgreementsSelector } from '#/src/store/reports-xls/general-agreements/selectors';
import { CachedReport, CachedReportStatus } from '#/src/types/reports';
import convertDateFormatToShort from '#/src/utils/convert-date-format-to-short';

import ReportsXlsTableCell from '../reports-xls-table-cell';
import ReportsXlsTableRow from '../reports-xls-table-row';

import './report-xls-row-info.css';

type ReportXlsRowProps = {
    report: CachedReport;
};

const cn = createCn('report-xls-row-info');

export default function ReportXlsRowInfo({ report }: ReportXlsRowProps) {
    const dispatch = useDispatch();
    const organizationId = useSelector(currentOrganizationIdSelector);
    const generalAgreements = useSelector(generalAgreementsSelector);
    const isLoadingDownloadCachedReport = useSelector(isLoadingDownloadCachedReportSelector);
    const debtors = useSelector(debtorsSelector);
    const handleDownloadCachedReport = useCallback(() => {
        if (organizationId) {
            dispatch(downloadCachedReports(organizationId, [report]));
        }
    }, [dispatch, organizationId, report]);

    return (
        <ReportsXlsTableRow>
            <ReportsXlsTableCell type='status'>
                <ReportXlsStatusIcon
                    className={cn('status')}
                    status={report.status}
                    errorCode={report.errorCode}
                />
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='name'>
                <Typography.Text tag='div' view='primary-small'>
                    {reportNames[report.fieldValuesMap.reportName]}
                </Typography.Text>
                <Typography.Text view='primary-small' color='secondary'>
                    {formatDate(report.dateTime, 'DD.MM.YY - HH:mm')}
                </Typography.Text>
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='date'>
                <Typography.Text view='primary-small' color='secondary'>
                    {report.fieldValuesMap.dateFrom
                        ? convertDateFormatToShort(report.fieldValuesMap.dateFrom)
                        : '-'}
                </Typography.Text>
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='date'>
                <Typography.Text view='primary-small' color='secondary'>
                    {report.fieldValuesMap.dateTo
                        ? convertDateFormatToShort(report.fieldValuesMap.dateTo)
                        : '-'}
                </Typography.Text>
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='agreement'>
                <Typography.Text view='primary-small' color='secondary'>
                    {(generalAgreements &&
                        report.fieldValuesMap.generalAgreementId &&
                        generalAgreements[+report.fieldValuesMap.generalAgreementId]) ||
                        '-'}
                </Typography.Text>
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='debtor'>
                <Typography.Text view='primary-small' color='secondary'>
                    {(debtors &&
                        report.fieldValuesMap.debtorId &&
                        debtors[+report.fieldValuesMap.debtorId]) ||
                        '-'}
                </Typography.Text>
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='document'>
                <Typography.Text view='primary-small' color='secondary'>
                    {report.fieldValuesMap.numDoc || '-'}
                </Typography.Text>
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='supply'>
                <Typography.Text view='primary-small' color='secondary'>
                    {report.fieldValuesMap.supplyNum || '-'}
                </Typography.Text>
            </ReportsXlsTableCell>
            <ReportsXlsTableCell type='controls'>
                <IconButton
                    onClick={handleDownloadCachedReport}
                    className={cn('download-icon')}
                    disabled={
                        report.status !== CachedReportStatus.SUCCESS ||
                        isLoadingDownloadCachedReport
                    }
                    size='xxs'
                    view='primary'
                    icon={PointerDownMIcon}
                />
            </ReportsXlsTableCell>
        </ReportsXlsTableRow>
    );
}
