import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateReportFilters } from '#/src/store/reports-xls/report-filters/action-creators';
import { reportFiltersSelector } from '#/src/store/reports-xls/report-filters/selectors';

const DocumentNumberFilter: FC = () => {
    const dispatch = useDispatch();

    const { documentNumber } = useSelector(reportFiltersSelector);

    const handleChangeDocumentNumber = useCallback<NonNullable<InputProps['onChange']>>(
        (_, { value }) => {
            dispatch(updateReportFilters({ documentNumber: value }));
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='document-number-input'
            label='Номер документа'
            block={true}
            value={documentNumber}
            onChange={handleChangeDocumentNumber}
        />
    );
};

export default DocumentNumberFilter;
