import { createAsyncThunk } from '@reduxjs/toolkit';

import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { AsyncThunkConfig } from '#/src/types/redux-toolkit';
import { OrganizationShortDto } from '#/src/types/user';
import { fetchers } from '#/src/utils/client-api';

import { CompanyType } from './reducer';

export const fetchCompaniesByType = createAsyncThunk<
    OrganizationShortDto[],
    { type: CompanyType; tin: string; forDashboard?: boolean },
    AsyncThunkConfig
>(
    'organizations/fetchCompaniesByType',
    async ({ type, tin, forDashboard }, { dispatch, rejectWithValue }) => {
        try {
            let fetcher = fetchers.fetchSellers;

            if (forDashboard) {
                fetcher = fetchers.fetchDashDebtors;
            } else if (type === 'debtors') {
                fetcher = fetchers.fetchDebtors;
            }

            return await fetcher(tin);
        } catch (error) {
            dispatch(dispatchErrorNotification(error));

            return rejectWithValue(error);
        }
    },
);
