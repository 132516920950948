import { createAsyncThunk } from '@reduxjs/toolkit';

import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { AsyncThunkConfig } from '#/src/types/redux-toolkit';
import { fetchers } from '#/src/utils/client-api';
import { downloadFile as downloadFileUtils } from '#/src/utils/download-file';

export const downloadFile = createAsyncThunk<
    Blob,
    { fileType: string; fileName: string },
    AsyncThunkConfig
>('files/downloadFile', async ({ fileType, fileName }, { dispatch, rejectWithValue }) => {
    try {
        const file = await fetchers.fetchDownloadFile(fileType);

        await downloadFileUtils(file, file.type, fileName);

        return file;
    } catch (error) {
        dispatch(dispatchErrorNotification('В процессе скачивания файла произошла ошибка'));

        return rejectWithValue(error);
    }
});
