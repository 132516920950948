import { createSelector } from 'reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { dashboardModelsSelector } from '../selectors';

export const dashboardChartsStateSelector = (state: ApplicationState) =>
    dashboardModelsSelector(state).dashboardCharts;

export const selectedTabSelector = (state: ApplicationState) =>
    dashboardChartsStateSelector(state).selectedTab;

export const dashboardLoadingStateSelector = (state: ApplicationState) =>
    dashboardChartsStateSelector(state).loadingState;

export const dashboardChartsIsLoadingSelector = createSelector(
    dashboardLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.LOADING,
);

export const dashboardChartsIsErrorSelector = createSelector(
    dashboardLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.ERROR,
);
