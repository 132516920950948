import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import verificationSvg from 'arui-private/icons/verification_nib.svg';

import { RegisterListBlock } from './register-list-block';

export function RegisterListEmpty() {
    return (
        <RegisterListBlock dataTestId='registers-table'>
            <React.Fragment>
                <img src={verificationSvg} alt='' />
                <Typography.Title tag='div' view='small' weight='bold'>
                    Загрузите реестр
                </Typography.Title>
                <Typography.Text tag='div' view='primary-medium'>
                    Мы не нашли ни одного реестра
                </Typography.Text>
                <Gap size='6xl' />
            </React.Fragment>
        </RegisterListBlock>
    );
}
