import { EffectCallback, useEffect, useRef } from 'react';

// hook that runs only on updates and ignore mount
export function useOnUpdate(fn: EffectCallback, dependencies: unknown[]) {
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;

            return;
        }

        // eslint-disable-next-line consistent-return
        return fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
