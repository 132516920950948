export enum DebtorsForReportsErrorNames {
    DEBTOR_NOT_FOUND = 'DEBTOR_NOT_FOUND',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const debtorsForReportsErrorsDictionary: Record<DebtorsForReportsErrorNames, string> = {
    [DebtorsForReportsErrorNames.DEBTOR_NOT_FOUND]:
        'Отсутствуют данные для отображения. Обратитесь к менеджеру.',
    [DebtorsForReportsErrorNames.UNKNOWN_ERROR]: 'Пожалуйста, обратитесь к менеджеру',
};
