import React from 'react';
import { createCn } from 'bem-react-classname';

import { Grid } from '@alfalab/core-components/grid';
import { ColProps } from '@alfalab/core-components/grid/col';

const cn = createCn('align-right-grid-col');

export function AlignRightGridCol({ children, className, ...otherProps }: ColProps) {
    return (
        <Grid.Col {...otherProps} className={cn()}>
            {children}
        </Grid.Col>
    );
}
