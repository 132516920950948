import { LOADING_STATE } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

export const getDebtorsStart = createAction('REPORTS_XLS / getDebtorsStart', () => ({
    loadingState: LOADING_STATE.LOADING,
}));

export const getDebtorsSuccess = createAction(
    'REPORTS_XLS / getDebtorsSuccess',
    (data: Record<number, string>) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const getDebtorsError = createAction('REPORTS_XLS / getDebtorsError', () => ({
    loadingState: LOADING_STATE.ERROR,
}));
