import React, { useCallback } from 'react';

import { Link, LinkProps } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { supplySourceDictionary } from '#/src/dictionaries/supply-source';
import { SupplySource } from '#/src/types/supply';

type SupplyRowStatusProps = {
    source?: SupplySource[];
    registerId?: number | null;
    registerNumber?: number | null;
    rpdNumber?: number | null;
};

export function SupplyRowSource({
    source,
    registerId,
    registerNumber,
    rpdNumber,
}: SupplyRowStatusProps) {
    const handleMoveToRegisterPage = useCallback<NonNullable<LinkProps['onClick']>>(
        (event) => {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }

            if (registerId) {
                window.location.assign(
                    routesDictionary.registerInfoPage.path.replace(/:id/g, registerId.toString()),
                );
            }
        },
        [registerId],
    );

    return (
        <Typography.Text view='secondary-large' tag='div'>
            {source?.map((item, index) => {
                const source = index ? ', ' : '';

                if (item === 'REGISTER') {
                    return (
                        <React.Fragment>
                            {source}
                            <Link
                                dataTestId='register-link'
                                onClick={handleMoveToRegisterPage}
                                href={`/secure/financing/registers/${registerId}`}
                            >
                                Реестр {registerNumber}
                            </Link>
                        </React.Fragment>
                    );
                }

                if (item === 'RPD' && rpdNumber) {
                    return `${source}${supplySourceDictionary[item]} ${rpdNumber}`;
                }

                return `${source}${supplySourceDictionary[item]}`;
            })}
        </Typography.Text>
    );
}
