import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Grid } from '@alfalab/core-components/grid';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';
import { ChevronRightShiftRightSIcon } from '@alfalab/icons-glyph/ChevronRightShiftRightSIcon';
import { ChevronUpSIcon } from '@alfalab/icons-glyph/ChevronUpSIcon';

import { setSort } from '#/src/store/registers/register-info/action-creators';
import { sortSelector } from '#/src/store/registers/register-info/selectors';
import { SortField, SortOrder } from '#/src/types/sort';

import './register-info-table-head.css';

const cn = createCn('register-info-table-head');

export function RegisterInfoTableHead() {
    const dispatch = useDispatch();
    const sort = useSelector(sortSelector);

    const setHandleSorting = (sortField: SortField) => () => {
        const order = sort.order === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;

        dispatch(
            setSort({
                field: sortField,
                order: sort.field === sortField ? order : SortOrder.DESC,
            }),
        );
    };

    const IconOrder = sort.order === SortOrder.ASC ? ChevronDownSIcon : ChevronUpSIcon;

    return (
        <div className={cn()}>
            <div className={cn('controls')}>
                <div className={cn('controls-sort')}>
                    <Typography.Text
                        color='secondary'
                        view='secondary-large'
                        className={cn('controls-label')}
                    >
                        Сортировать:
                    </Typography.Text>
                    <Typography.Text view='secondary-large'>
                        <Link
                            dataTestId='sort-by-amount-link'
                            pseudo={true}
                            onClick={setHandleSorting(SortField.COST)}
                            rightAddons={
                                sort.field === SortField.COST ? (
                                    <IconOrder />
                                ) : (
                                    <ChevronRightShiftRightSIcon className={cn('controls-icon')} />
                                )
                            }
                        >
                            По сумме
                        </Link>
                    </Typography.Text>
                </div>
                <div>
                    <Typography.Text
                        color='secondary'
                        view='secondary-large'
                        className={cn('controls-label')}
                    >
                        Сортировать:
                    </Typography.Text>
                    <Typography.Text view='secondary-large'>
                        <Link
                            dataTestId='sort-by-date-link'
                            pseudo={true}
                            onClick={setHandleSorting(SortField.DATE)}
                            rightAddons={
                                sort.field === SortField.DATE ? (
                                    <IconOrder />
                                ) : (
                                    <ChevronRightShiftRightSIcon className={cn('controls-icon')} />
                                )
                            }
                        >
                            По дате
                        </Link>
                    </Typography.Text>
                </div>
            </div>
            <div className={cn('head')}>
                <Grid.Col width='2' className={cn('head-cel')}>
                    Поставщик
                </Grid.Col>
                <Grid.Col width='2' className={cn('head-cel')}>
                    Дебитор
                </Grid.Col>
                <Grid.Col width='2' className={cn('head-cel')}>
                    Сумма
                </Grid.Col>
                <Grid.Col width='1' className={cn('head-cel')}>
                    Дата
                </Grid.Col>
                <Grid.Col width='2' className={cn('head-cel')}>
                    Документ
                </Grid.Col>
                <Grid.Col width='1' className={cn('head-cel')}>
                    Контракт
                </Grid.Col>
                <Grid.Col width='2' className={cn('head-cel')}>
                    Статус
                </Grid.Col>
            </div>
        </div>
    );
}
