import {
    GetDebtorsQuery,
    GetDebtorsResponse,
    GetSellersQuery,
    GetSellersResponse,
    GetUsernameInfoQuery,
    GetUsernameInfoResponse,
} from '#/src/server/services/api/user';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '') {
    const fetch = {
        getSellers: fetchFactory<GetSellersQuery, GetSellersResponse>(
            `${contextRoot}/secure/nodeapi/getSellers`,
            'GET',
            { allowMultiRequest: true },
        ),
        getDebtors: fetchFactory<GetDebtorsQuery, GetDebtorsResponse>(
            `${contextRoot}/secure/nodeapi/getDebtors`,
            'GET',
            { allowMultiRequest: true },
        ),
        getUsernameInfo: fetchFactory<GetUsernameInfoQuery, GetUsernameInfoResponse>(
            `${contextRoot}/secure/nodeapi/getUsernameInfo`,
        ),
    };

    return {
        fetchSellers(debtorTin: string) {
            return fetch.getSellers({ debtorTin });
        },
        fetchDebtors(supplierTin: string) {
            return fetch.getDebtors({ supplierTin });
        },
        fetchUsernameInfo(username: string) {
            return fetch.getUsernameInfo({ username });
        },
    };
}
