import React from 'react';

import { Button } from '@alfalab/core-components/button';
import { Tooltip } from '@alfalab/core-components/tooltip';

type DisabledSignButtonProps = {
    textButton: string;
};

export function DisabledSignButton({ textButton }: DisabledSignButtonProps) {
    return (
        <Tooltip
            content='У вас недостаточно прав для подписания документов'
            position='right'
            trigger='hover'
        >
            <Button disabled={true} view='primary' size='xxs' onClick={undefined}>
                {textButton}
            </Button>
        </Tooltip>
    );
}
