import { CheckPermissionSOResult } from '#/src/types/sign';

export default function combinePermissions(permissions: CheckPermissionSOResult[]) {
    return permissions.reduce<Record<string, boolean>>(
        (arr, item) => ({
            ...arr,
            [item.uid.split(':')[0]]: item.result,
        }),
        {},
    );
}
