import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import { RegistersListState } from '#/src/store/registers/registers-list/reducer';
import createAction from '#/src/utils/create-action';

export const changeRegistersListLoadingState = createAction(
    'REGISTERS / LIST / changeRegistersListLoadingState',
    (loadingState: LoadingState) => ({ loadingState }),
);

export const setRegistersList = createAction(
    'REGISTERS / LIST / setRegistersList',
    (data: Partial<RegistersListState>) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const clearRegistersList = createAction('REGISTERS / LIST / clearRegistersList', () => ({}));

export const toggleSelectedRegisters = createAction(
    'REGISTERS / LIST / toggleSelectedRegisters',
    () => ({}),
);

export const setSelectedRegisters = createAction(
    'REGISTERS / LIST / setSelectedRegisters',
    (selected: number[]) => ({ selected }),
);

export const setSort = createAction(
    'REGISTERS / LIST / setSort',
    (data: RegistersListState['sort']) => ({ data }),
);
