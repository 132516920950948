import { Permission } from '#/src/types/orgatiozations';
import { RouteInfo } from '#/src/types/routing';

export enum RouteNames {
    restoreLogin = 'restoreLogin',
    restorePassword = 'restorePassword',
    changePassword = 'changePassword',
    authPage = 'authPage',
    settingsPage = 'settingsPage',
    registersPage = 'registersPage',
    registerInfoPage = 'registerInfoPage',
    invoiceRegistryEdiSoftPage = 'invoiceRegistryEdiSoftPage',
    suppliesEdiSoftPage = 'suppliesEdiSoftPage',
    reportPage = 'reportPage',
    reportsXlsPage = 'reportsXlsPage',
    suppliesPage = 'suppliesPage',
}

export const routesDictionary: Record<RouteNames, RouteInfo> = {
    [RouteNames.authPage]: {
        path: '/',
        pathRouteRegex: /^\/?$/,
    },
    [RouteNames.restorePassword]: {
        path: '/restorepassword',
        pathRouteRegex: /^\/restorepassword\/?$/,
    },
    [RouteNames.changePassword]: {
        path: '/secure/changepassword',
        pathRouteRegex: /^\/secure\/changepassword\/?$/,
    },
    [RouteNames.restoreLogin]: {
        path: '/restorelogin',
        pathRouteRegex: /^\/restorelogin\/?$/,
    },
    [RouteNames.settingsPage]: {
        pageTitle: 'Настройки',
        path: '/secure/financing/settings',
        pathRouteRegex: /^\/secure\/financing\/settings\/?$/,
        availableWithoutOrganizations: true,
    },
    [RouteNames.registersPage]: {
        pageTitle: 'Реестры',
        path: '/secure/financing/registers',
        pathRouteRegex: /^\/secure\/financing\/registers\/?$/,
        permissions: [Permission.FACT_SHOW_SELLER_REGISTERS, Permission.FACT_SHOW_DEBTOR_REGISTERS],
        segment: 'TAB_REGISTERS',
    },
    [RouteNames.registerInfoPage]: {
        pageTitle: 'Реестр',
        path: '/secure/financing/registers/:id',
        pathRouteRegex: /^\/secure\/financing\/registers\/\d+$/,
        permissions: [Permission.FACT_SHOW_SELLER_REGISTERS, Permission.FACT_SHOW_DEBTOR_REGISTERS],
        segment: 'TAB_REGISTERS',
    },
    [RouteNames.invoiceRegistryEdiSoftPage]: {
        pageTitle: 'РПД/РПП',
        path: '/secure/financing/supplies/registry',
        pathRouteRegex: /^\/secure\/financing\/supplies\/registry\/?$/,
        availableWithoutOrganizations: true,
        segment: 'TAB_INVOICE_REGISTRY',
    },
    [RouteNames.suppliesEdiSoftPage]: {
        pageTitle: 'Документы X5',
        path: '/secure/financing/supplies/x5',
        pathRouteRegex: /^\/secure\/financing\/supplies\/x5\/?$/,
        availableWithoutOrganizations: true,
        segment: 'TAB_X5_COMPANIES_GROUP',
    },
    [RouteNames.reportPage]: {
        pageTitle: 'Отчёты',
        path: '/secure/financing/report',
        pathRouteRegex: /^\/secure\/financing\/report\/?$/,
        permissions: [Permission.FACT_SHOW_SELLER_REPORTS, Permission.FACT_SHOW_SELLER_REPORTS_NEW],
        segment: 'TAB_DASHBOARDS',
    },
    [RouteNames.reportsXlsPage]: {
        pageTitle: 'Отчёты',
        path: '/secure/financing/report/xls',
        pathRouteRegex: /^\/secure\/financing\/report\/xls\/?$/,
        permissions: [
            Permission.FACT_SHOW_DEBTOR_REPORTS_NEW,
            Permission.FACT_SHOW_SELLER_REPORTS_NEW,
        ],
        segment: 'TAB_DASHBOARDS',
    },
    [RouteNames.suppliesPage]: {
        pageTitle: 'Поставки',
        path: '/secure/financing',
        pathAlias: ['/secure/financing', '/secure/financing/supplies'],
        pathRouteRegex: /^\/secure\/financing\/?(supplies\/?)?$/,
        permissions: [Permission.SHOW_SUPPLIER_UI],
        segment: 'TAB_SUPPLY',
    },
};

export const routeInfoList = Object.values(routesDictionary);
