import React from 'react';
import { createCn } from 'bem-react-classname';

import Copyright from 'arui-private/copyright';

import ExternalsPagePhones from '../phones';

import ExternalPageFooterLinks from './external-page-footer-links';
import ExternalPageFooterManualLink from './external-page-footer-manual-link';

import './external-page-footer.css';

const cn = createCn('external-page-footer');

export default function ExternalPageFooter() {
    return (
        <div className={cn()}>
            <ExternalPageFooterLinks />
            <div className={cn('container')}>
                <div className={cn('row')}>
                    <div className={cn('left-column')}>
                        <ExternalPageFooterManualLink />
                        <Copyright className={cn('copyright')} />
                    </div>
                    <ExternalsPagePhones view='footer' />
                </div>
            </div>
        </div>
    );
}
