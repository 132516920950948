import { ThunkAction } from 'redux-thunk';

import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { GeneralAgreementsActionsType } from './types';

export function fetchGeneralAgreements(
    partyTin: string,
    isDebtor: boolean,
): ThunkAction<void, ApplicationState, void, GeneralAgreementsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getGeneralAgreementsStart());

        try {
            const response = await fetchers.fetchGeneralAgreementNames({ partyTin, isDebtor });

            dispatch(actionCreators.getGeneralAgreementsSuccess(response));
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
            dispatch(actionCreators.getGeneralAgreementsError());
        }
    };
}
