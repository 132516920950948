import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateSelectedFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';

export function RegisterNumberFilter() {
    const dispatch = useDispatch();
    const { registerNumber } = useSelector(selectedFiltersSelector);

    const handleChangeRegisterNumber = useCallback<NonNullable<InputProps['onChange']>>(
        (event) => {
            dispatch(updateSelectedFilters({ registerNumber: event.target.value }));
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='register-number-input'
            value={registerNumber || ''}
            block={true}
            label='Номер реестра'
            onChange={handleChangeRegisterNumber}
        />
    );
}
