import React from 'react';

import { Amount } from '@alfalab/core-components/amount';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { AttentionMarkMColorIcon } from '@alfalab/icons-classic/AttentionMarkMColorIcon';

import { CorrectionType, SupplyCorrectionHistoryDto } from '#/src/types/supply';

type SupplyCorrectionHistoryItemProps = {
    history: SupplyCorrectionHistoryDto;
};

const documentNames: Record<CorrectionType, string> = {
    CORRECTION: 'Коррект',
    RPD: 'РПД',
    REGISTER: 'Реестр',
};

export function SupplyCorrectionHistoryItem({ history }: SupplyCorrectionHistoryItemProps) {
    return (
        <React.Fragment>
            <Gap size='xs' />
            <Grid.Row justify='left' align='top'>
                <Grid.Col width={4}>
                    <Typography.Text view='secondary-large' tag='div'>
                        {documentNames[history.correctionType]} №{history.registerNumber} от&nbsp;
                        {history.registerDate}
                    </Typography.Text>
                </Grid.Col>
                <Grid.Col width={3}>
                    <Typography.Text view='secondary-large' tag='div'>
                        {history.correctionAmount ? (
                            <Amount
                                value={history.correctionAmount * 100}
                                minority={100}
                                view='withZeroMinorPart'
                                currency='RUR'
                                transparentMinor={true}
                                showPlus={true}
                            />
                        ) : (
                            <React.Fragment>&mdash;</React.Fragment>
                        )}
                    </Typography.Text>
                </Grid.Col>
                <Grid.Col width={3}>
                    <Typography.Text view='secondary-large' tag='div'>
                        <Space direction='horizontal' size={8} align='center'>
                            {history.supplyAmount ? (
                                <Amount
                                    value={history.supplyAmount * 100}
                                    minority={100}
                                    view='withZeroMinorPart'
                                    currency='RUR'
                                    transparentMinor={true}
                                />
                            ) : (
                                <div>&mdash;</div>
                            )}
                            {history.correctionStatus === 'NOT_APPLIED' &&
                                history.correctionType === 'CORRECTION' && (
                                    <Tooltip
                                        // eslint-disable-next-line max-len
                                        content='Сумма поставки не меняется после подписания/отправки на финансирование, обратитесь к своему менеджеру'
                                        position='right'
                                        trigger='hover'
                                    >
                                        <AttentionMarkMColorIcon width='16' height='16' />
                                    </Tooltip>
                                )}
                        </Space>
                    </Typography.Text>
                </Grid.Col>
            </Grid.Row>
        </React.Fragment>
    );
}
