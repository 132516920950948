import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Notification } from '@alfalab/core-components/notification';
import { NotificationManager } from '@alfalab/core-components/notification-manager';

import { removeNotification } from '#/src/store/notifications/action-creators';
import { notificationsSelector } from '#/src/store/notifications/selectors';

const NotificationManagerContainer: React.FC = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(notificationsSelector);
    const handleRemoveNotification = useCallback(
        (id: string) => {
            dispatch(removeNotification(id));
        },
        [dispatch],
    );

    const notificationElementList = useMemo(
        () =>
            notifications.map((notificationProps) => (
                <Notification {...notificationProps} autoCloseDelay={10000} />
            )),
        [notifications],
    );

    return (
        <NotificationManager
            data-test-id='notification-manager'
            notifications={notificationElementList}
            onRemoveNotification={handleRemoveNotification}
        />
    );
};

export default NotificationManagerContainer;
