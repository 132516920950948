import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateSelectedFilters } from '#/src/store/registers/registers-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';

export function DocumentNumberFilter() {
    const dispatch = useDispatch();
    const { documentNumber } = useSelector(selectedFiltersSelector);

    const handleChangeDocumentNumber = useCallback<NonNullable<InputProps['onChange']>>(
        (_, payload) => {
            dispatch(updateSelectedFilters({ documentNumber: payload.value }));
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='registers-filter-document-number-input'
            value={documentNumber || ''}
            block={true}
            label='Номер документа'
            name='input_document-number'
            maxLength={255}
            onChange={handleChangeDocumentNumber}
        />
    );
}
