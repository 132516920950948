import React from 'react';

import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { ChangePasswordActionsType } from './types';

export type ChangePasswordState = {
    error: React.ReactNode | null;
    loadingState: LoadingState;
};

export const initialState: ChangePasswordState = {
    error: null,
    loadingState: 'unknown',
};

export function changePasswordReducer(
    state: ChangePasswordState = initialState,
    action: ChangePasswordActionsType,
): ChangePasswordState {
    switch (action.type) {
        case actionCreators.changePasswordStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.changePasswordSuccess.type:
            return {
                ...state,
                error: null,
                loadingState: action.loadingState,
            };
        case actionCreators.changePasswordError.type:
            return {
                ...state,
                error: action.error,
                loadingState: action.loadingState,
            };
        default:
            return state;
    }
}
