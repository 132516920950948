import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@alfalab/core-components/typography';

import DownloadDocumentsResultModal from '#/src/components/download-documents-result-modal';
import { GENERAL_ERROR_PHRASE } from '#/src/constants/text';
import {
    requestedDocumentsSelector,
    successDocumentsSelector,
} from '#/src/store/documents/selectors';

export default function DownloadRegistersResultModal() {
    const successDocuments = useSelector(successDocumentsSelector);
    const requestedDocuments = useSelector(requestedDocumentsSelector);

    let title = `Скачано ${successDocuments.length} из ${requestedDocuments.length} реестров`;
    let text = (
        <React.Fragment>
            <Typography.Text tag='div'>
                Некоторые реестры удалены или содержат вирусы.
            </Typography.Text>
            <Typography.Text tag='div'>{GENERAL_ERROR_PHRASE}</Typography.Text>
        </React.Fragment>
    );

    if (!successDocuments.length) {
        if (successDocuments.length === 1) {
            title = 'Скачать реестры не получится';
            text = (
                <React.Fragment>
                    <Typography.Text tag='div'>
                        Реестры удалены или содержат вирусы.
                    </Typography.Text>
                    <Typography.Text tag='div'>{GENERAL_ERROR_PHRASE}</Typography.Text>
                </React.Fragment>
            );
        } else {
            title = 'Скачать реестр не получится';
            text = (
                <React.Fragment>
                    <Typography.Text tag='div'>Реестр удалён или содержит вирусы.</Typography.Text>
                    <Typography.Text tag='div'>{GENERAL_ERROR_PHRASE}</Typography.Text>
                </React.Fragment>
            );
        }
    }

    return <DownloadDocumentsResultModal text={text} title={title} />;
}
