import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';
import converter from '#/src/utils/convert-certificate-data-list-to-certificate-info-signed-supply-list';

import * as actionCreators from './action-creators';
import { SupplyCertificatesActionsType } from './types';

export function fetchSupplyCertifications(
    supplyId: number,
): ThunkAction<void, ApplicationState, void, SupplyCertificatesActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getSupplyCertificatesStart(supplyId));
        try {
            const response = await fetchers.fetchSupplyCertificates(supplyId);

            dispatch(actionCreators.getSupplyCertificatesSuccess(supplyId, converter(response)));
        } catch (e) {
            dispatch(actionCreators.getSupplyCertificatesError(supplyId));
        }
    };
}
