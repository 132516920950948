import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Collapse, CollapseProps } from '@alfalab/core-components/collapse';
import { Spinner } from '@alfalab/core-components/spinner';

import { ApplicationState } from '#/src/store/reducer';
import { removeSupplyDetails } from '#/src/store/supply/supply-details/action-creators';
import { fetchSupplyDetails } from '#/src/store/supply/supply-details/actions';
import {
    supplyDetailsIsLoadingSelector,
    supplyDetailsSelector,
} from '#/src/store/supply/supply-details/selectors';
import { supplyErrorsIsLoadingSelector } from '#/src/store/supply/supply-errors/selectors';
import { SupplyStatus } from '#/src/types/supply';

import { DownloadSupplyButton } from './download-supply-button';
import { SupplyCertificates } from './supply-certificates';
import { SupplyCorrectionHistory } from './supply-correction-history';
import { SupplyErrorList } from './supply-error-list';
import { SupplyHistoryList } from './supply-history-list';
import { SupplyPayments } from './supply-payments';

type SupplyDetailsSectionProps = {
    expanded: boolean;
    supplyId: number;
    supplyStatus: SupplyStatus;
};

export function SupplyDetailsSection({
    expanded,
    supplyId,
    supplyStatus,
}: SupplyDetailsSectionProps) {
    const dispatch = useDispatch();
    const isLoadingErrors = useSelector((state: ApplicationState) =>
        supplyErrorsIsLoadingSelector(state, supplyId),
    );
    const isLoadingDetails = useSelector((state: ApplicationState) =>
        supplyDetailsIsLoadingSelector(state, supplyId),
    );
    const supplyDetails = useSelector((state: ApplicationState) =>
        supplyDetailsSelector(state, supplyId),
    );

    const handleRemoveSupplyDetail = useCallback<NonNullable<CollapseProps['onTransitionEnd']>>(
        (expandedState) => {
            if (!expandedState) {
                dispatch(removeSupplyDetails(supplyId));
            }
        },
        [dispatch, supplyId],
    );

    useEffect(() => {
        if (expanded && supplyId) {
            dispatch(fetchSupplyDetails(supplyId));
        }
    }, [dispatch, supplyId, expanded]);

    return (
        <Collapse expanded={expanded} onTransitionEnd={handleRemoveSupplyDetail}>
            <div>
                {(isLoadingErrors || isLoadingDetails || !supplyDetails) && (
                    <Spinner size='s' visible={true} />
                )}
                {expanded && (
                    <React.Fragment>
                        <SupplyErrorList supplyId={supplyId} status={supplyStatus} />
                        <SupplyPayments supplyId={supplyId} />
                        <SupplyCorrectionHistory supplyId={supplyId} />
                        {!!supplyDetails && (
                            <SupplyHistoryList
                                financingAmount={supplyDetails.financingAmount}
                                lastOrderAcceptanceDate={supplyDetails.lastOrderAcceptanceDate}
                                eventList={supplyDetails.eventList}
                            />
                        )}
                        <SupplyCertificates supplyId={supplyId} />
                        {!!supplyDetails && <DownloadSupplyButton id={supplyDetails.documentId} />}
                    </React.Fragment>
                )}
            </div>
        </Collapse>
    );
}
