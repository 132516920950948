import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import { companiesTypeByRegisterRoleFilter } from '#/src/dictionaries/companies-type-by-register-role-filter';
import { fetchCompaniesByType } from '#/src/store/companies/actions';
import { currentOrganizationInfoSelector } from '#/src/store/organizations/selectors';
import { updateSelectedFilters } from '#/src/store/registers/registers-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';
import { Permission } from '#/src/types/orgatiozations';

export function RoleFilter() {
    const dispatch = useDispatch();
    const { role } = useSelector(selectedFiltersSelector);
    const organizationInfo = useSelector(currentOrganizationInfoSelector);

    const handleChangeRole = useCallback<NonNullable<SelectProps['onChange']>>(
        (payload) => {
            const newRole = (payload.selected?.key as 'supplier' | 'debtor') || null;

            dispatch(updateSelectedFilters({ role: newRole }));

            if (newRole && organizationInfo) {
                dispatch(
                    fetchCompaniesByType({
                        tin: organizationInfo.tin,
                        type: companiesTypeByRegisterRoleFilter[newRole],
                    }),
                );
            }
        },
        [dispatch, organizationInfo],
    );

    const options = useMemo(() => {
        const opts = [];

        if (organizationInfo?.actions.includes(Permission.FACT_SHOW_SELLER_REGISTERS)) {
            opts.push({
                key: 'supplier',
                value: 'supplier',
                content: 'Поставщик',
            });
        }

        if (organizationInfo?.actions.includes(Permission.FACT_SHOW_DEBTOR_REGISTERS)) {
            opts.push({
                key: 'debtor',
                value: 'debtor',
                content: 'Дебитор',
            });
        }

        return opts;
    }, [organizationInfo]);

    return (
        <Select
            dataTestId='registers-filter-role-select'
            block={true}
            placeholder='Роль'
            label='Роль'
            optionsListWidth='field'
            selected={role}
            options={options}
            onChange={handleChangeRole}
        />
    );
}
