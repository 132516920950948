import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import FatalErrorSvg from 'arui-private/icons/error.svg';

import { BlankResultModal } from '#/src/components/blank-result-modal';
import { actions } from '#/src/store/supplies/supplies-editing/reducer';
import { warningModalIsOpenSelector } from '#/src/store/supplies/supplies-editing/selectors';

export function WarningModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(warningModalIsOpenSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(actions.changeWarningModalState(false));
    }, [dispatch]);

    const handleClearState = useCallback(() => {
        dispatch(actions.clearState());
    }, [dispatch]);

    return (
        <BlankResultModal
            open={modalIsOpen}
            onClose={handleCloseModal}
            onUnmount={handleClearState}
            dataTestId='edit-supplies-warning-modal'
        >
            <img src={FatalErrorSvg} alt='' />
            <Gap size='l' />
            <Typography.Title view='small' font='system' tag='div'>
                Отсутствуют поставки, доступные для редактирования
            </Typography.Title>
            <Gap size='s' />
            <Typography.Text view='primary-medium' tag='p'>
                У выбранных поставок заполнены все данные, поставки доступны для подписания
            </Typography.Text>
            <Gap size='l' />
            <Button
                view='primary'
                size='s'
                onClick={handleCloseModal}
                dataTestId='edit-supplies-warning-modal-button'
            >
                К списку поставок
            </Button>
            <Gap size='m' />
        </BlankResultModal>
    );
}
