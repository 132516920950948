import React from 'react';

import { useMatchMedia } from '@alfalab/core-components/mq';

import ExternalPagePhonesModel from './external-page-phones-modal';
import ExternalPagePhonesSection from './external-page-phones-section';

type ExternalsPagePhonesProps = {
    view: 'header' | 'footer';
};

export default function ExternalsPagePhones({ view }: ExternalsPagePhonesProps) {
    const [isDesktop] = useMatchMedia('--desktop-s');
    let children = null;

    if (view === 'header' && !isDesktop) {
        children = <ExternalPagePhonesModel />;
    } else if (view === 'footer' && isDesktop) {
        children = <ExternalPagePhonesSection />;
    }

    return children;
}
