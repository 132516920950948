import { ThunkAction } from 'redux-thunk';

import {
    ChangePasswordErrorNames,
    changePasswordErrorsDictionary,
} from '#/src/dictionaries/change-password-errors';
import { logOut } from '#/src/store/auth/actions';
import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';
import { parseErrorMessage } from '#/src/utils/parse-error-message';

import * as actionCreators from './action-creators';
import { ChangePasswordActionsType } from './types';

export function changePassword(
    password: string,
    newPassword: string,
    newPasswordConfirm: string,
): ThunkAction<void, ApplicationState, void, ChangePasswordActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.changePasswordStart());

        try {
            await fetchers.fetchChangePassword({
                password,
                newPassword,
                newPasswordConfirm,
            });

            dispatch(actionCreators.changePasswordSuccess());

            dispatch(logOut());
        } catch (e) {
            const error = parseErrorMessage(e);

            dispatch(
                actionCreators.changePasswordError(
                    changePasswordErrorsDictionary[error as ChangePasswordErrorNames] ||
                        changePasswordErrorsDictionary.UNKNOWN_ERROR,
                ),
            );
        }
    };
}
