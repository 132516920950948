import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateSelectedFilters } from '#/src/store/registers/registers-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';

export function FileNumberFilter() {
    const dispatch = useDispatch();
    const { fileNumber } = useSelector(selectedFiltersSelector);

    const handleChangeFileNumber = useCallback<NonNullable<InputProps['onChange']>>(
        (_, payload) => {
            dispatch(updateSelectedFilters({ fileNumber: payload.value.replace(/[^0-9]/g, '') }));
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='registers-filter-field-register-number'
            value={fileNumber || ''}
            block={true}
            label='Номер реестра'
            name='input_file-number'
            onChange={handleChangeFileNumber}
        />
    );
}
