import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '#/src/constants/loading-state';

import { downloadFile } from './actions';

export type FilesState = {
    loadingState: LoadingState;
};

const initialState: FilesState = {
    loadingState: 'unknown',
};

export const {
    reducer: filesReducer,
    name,
    actions,
} = createSlice({
    name: 'files',
    initialState,
    reducers: {
        clearState(state) {
            state.loadingState = 'unknown';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(downloadFile.pending, (state) => {
            state.loadingState = 'loading';
        });
        builder.addCase(downloadFile.fulfilled, (state) => {
            state.loadingState = 'success';
        });
        builder.addCase(downloadFile.rejected, (state) => {
            state.loadingState = 'error';
        });
    },
});
