import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';

import { fetchSuppliesEditing } from '#/src/store/supplies/supplies-editing/actions';
import { suppliesEditingIsLoadingSelector } from '#/src/store/supplies/supplies-editing/selectors';
import { selectedIdsSelector } from '#/src/store/supplies/supplies-list/selectors';

import { EditModal } from './edit-modal';
import { WarningModal } from './warning-modal';

export function EditSuppliesControl() {
    const dispatch = useDispatch();
    const isLoading = useSelector(suppliesEditingIsLoadingSelector);
    const selectedIds = useSelector(selectedIdsSelector);

    const handleEditSupplies = useCallback(() => {
        dispatch(fetchSuppliesEditing(selectedIds));
    }, [dispatch, selectedIds]);

    return (
        <React.Fragment>
            <Button
                dataTestId='edit-supplies-control-button'
                loading={isLoading}
                view='tertiary'
                size='xxs'
                onClick={handleEditSupplies}
            >
                Редактировать
            </Button>
            <EditModal />
            <WarningModal />
        </React.Fragment>
    );
}
