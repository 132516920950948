import {
    CheckDocumentsPayload,
    CheckDocumentsResponse,
    DownloadDocumentsPayload,
} from '#/src/server/services/api/documents';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        checkDocuments: fetchFactory<CheckDocumentsPayload, CheckDocumentsResponse>(
            `${contextRoot}/secure/nodeapi/checkDocuments`,
            'POST',
            { allowMultiRequest: true, csrfCookieName },
        ),
        downloadDocuments: fetchFactory<DownloadDocumentsPayload, Blob>(
            `${contextRoot}/secure/nodeapi/downloadDocuments`,
            'POST',
            { csrfCookieName, allowMultiRequest: true, responseType: 'blob' },
        ),
    };

    return {
        fetchCheckDocuments(params: CheckDocumentsPayload) {
            return fetch.checkDocuments(params);
        },
        fetchDownloadDocuments(params: DownloadDocumentsPayload) {
            return fetch.downloadDocuments(params);
        },
    };
}
