import React, { useCallback } from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import './app-organization-list-item.css';

const cn = createCn('app-organization-list-item');

type AppOrganizationListItemProps = {
    name: string;
    tin: string;
    isSelected: boolean;
    onSelectOrganization: (value: string) => void;
};

export function AppOrganizationListItem({
    name,
    isSelected,
    tin,
    onSelectOrganization,
}: AppOrganizationListItemProps) {
    const handleSelectOrganization = useCallback(() => {
        onSelectOrganization(tin);
    }, [onSelectOrganization, tin]);

    return (
        <Typography.Text
            tag='div'
            className={cn({ selected: isSelected })}
            onClick={handleSelectOrganization}
            view='primary-small'
            role='option'
            dataTestId='app-organization-list-item'
        >
            {name}
        </Typography.Text>
    );
}
