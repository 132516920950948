import { LOADING_STATE } from '#/src/constants/loading-state';
import { ReportNames } from '#/src/types/reports';
import createAction from '#/src/utils/create-action';

export const getReportNamesStart = createAction('REPORTS_XLS / getReportNamesStart', () => ({
    loadingState: LOADING_STATE.LOADING,
}));

export const getReportNamesSuccess = createAction(
    'REPORTS_XLS / getReportNamesSuccess',
    (data: ReportNames[]) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const getReportNamesError = createAction('REPORTS_XLS / getReportNamesError', () => ({
    loadingState: LOADING_STATE.ERROR,
}));
