import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetSupplyAgreementsBaseQuery } from '#/src/server/services/api/agreement-cache';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { SupplyAgreementData } from '#/src/types/agreements';
import { AsyncThunkConfig } from '#/src/types/redux-toolkit';
import { fetchers } from '#/src/utils/client-api';

export const getAgreementsByDebtor = createAsyncThunk<
    SupplyAgreementData[],
    GetSupplyAgreementsBaseQuery & { id: string },
    AsyncThunkConfig
>('agreementsReducer/getAgreementsByDebtor', async (params, { dispatch, rejectWithValue }) => {
    try {
        return await fetchers.fetchSupplyAgreementsBase({
            sellerTin: params.sellerTin,
            debtorTin: params.debtorTin,
        });
    } catch (error) {
        dispatch(dispatchErrorNotification(error));

        return rejectWithValue(error);
    }
});
