export enum Permission {
    FACT_SHOW_DEBTOR_REPORTS = 'FACT_SHOW_DEBTOR_REPORTS',
    FACT_SHOW_SELLER_REPORTS = 'FACT_SHOW_SELLER_REPORTS',
    FACT_SHOW_SELLER_REPORTS_NEW = 'FACT_SHOW_SELLER_REPORTS_NEW',
    FACT_SHOW_DEBTOR_REPORTS_NEW = 'FACT_SHOW_DEBTOR_REPORTS_NEW',
    FACT_SHOW_SELLER_REGISTERS = 'FACT_SHOW_SELLER_REGISTERS',
    FACT_SHOW_DEBTOR_REGISTERS = 'FACT_SHOW_DEBTOR_REGISTERS',
    SHOW_SUPPLIER_UI = 'SHOW_SUPPLIER_UI',
    DEBTOR_DOCUMENTS_SIGN = 'DEBTOR_DOCUMENTS_SIGN',
    SUPPLIER_DOCUMENTS_SIGN = 'SUPPLIER_DOCUMENTS_SIGN',
    NEW_SIGN_REGISTERS = 'NEW_SIGN_REGISTERS',
    NEW_SIGN_SUPPLIES = 'NEW_SIGN_SUPPLIES',
}

export type Organization = {
    tin: string;
    name: string;
    actions: Permission[];
    debtor: boolean;
    supplier: boolean;
    upin: string;
};
