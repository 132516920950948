import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { stateSelector } from '#/src/store/selectors';

export const restorePasswordStateSelector = (state: ApplicationState) =>
    stateSelector(state).restorePassword;

export const restorePasswordErrorSelector = (state: ApplicationState) =>
    restorePasswordStateSelector(state).error;
export const restorePasswordLoadingStateSelector = (state: ApplicationState) =>
    restorePasswordStateSelector(state).loadingState;
export const restorePasswordIsLoadingSelector = (state: ApplicationState) =>
    restorePasswordLoadingStateSelector(state) === LOADING_STATE.LOADING;
export const restorePasswordIsSuccessSelector = (state: ApplicationState) =>
    restorePasswordLoadingStateSelector(state) === LOADING_STATE.SUCCESS;
