import React, { useCallback, useMemo } from 'react';

import {
    InputAutocomplete,
    InputAutocompleteProps,
} from '@alfalab/core-components/input-autocomplete';
import { OptionShape } from '@alfalab/core-components/select/typings';

import filterOptionsForCustomAutocomplete from '#/src/utils/filter-options-for-custom-autocomplete';
import getNewSelectedOptionsForCustomAutocomplete from '#/src/utils/get-new-selected-options-for-custom-autocomplete';
import { useOnUpdate } from '#/src/utils/use-on-update';

type CustomInputAutocompleteProps = Omit<InputAutocompleteProps, 'options' | 'selected'> & {
    options: OptionShape[];
    selected: string | string[] | undefined;
};

export function CustomInputAutocomplete({
    value: defaultValue = '',
    onChange,
    block = true,
    allowUnselect = true,
    closeOnSelect = true,
    size = 's',
    options,
    optionsListWidth = 'field',
    dataTestId = 'custom-input-autocomplete',
    selected,
    multiple,
    ...otherProps
}: CustomInputAutocompleteProps) {
    const [value, setValue] = React.useState(defaultValue);

    const handleChangeSelected = useCallback<NonNullable<InputAutocompleteProps['onChange']>>(
        (payload) => {
            if (onChange) {
                onChange(payload);
            }

            let newValue = '';

            if (payload.initiator) {
                if (multiple) {
                    if (payload.selectedMultiple.length) {
                        newValue = `${payload.selectedMultiple
                            .map((option) => option.content)
                            .join(', ')}, `;
                    }
                } else {
                    newValue = payload.selected?.content?.toString() || '';
                }
            }

            setValue(newValue);
        },
        [onChange, multiple],
    );

    const handleChangeValue = useCallback<NonNullable<InputAutocompleteProps['onInput']>>(
        (value) => {
            if (multiple && onChange) {
                const newSelectedOptions = getNewSelectedOptionsForCustomAutocomplete(
                    options,
                    value,
                    selected,
                );

                if (newSelectedOptions) {
                    onChange(newSelectedOptions);
                }
            }

            setValue(value);
        },
        [multiple, onChange, selected, options],
    );

    const handleCloseOptionsList = useCallback<NonNullable<InputAutocompleteProps['onOpen']>>(
        ({ open }) => {
            if (!open && multiple && value) {
                const valueArr = value.replace(/, /g, ',').split(',');

                valueArr.pop();

                let newValue = valueArr.join(', ');

                if (newValue) {
                    newValue = `${newValue}, `;
                }

                setValue(newValue);
            }
        },
        [value, multiple],
    );

    const filteredOptions = useMemo(
        () => filterOptionsForCustomAutocomplete(options, value, selected, multiple),
        [options, value, selected, multiple],
    );

    useOnUpdate(() => {
        if (!selected?.length && value) {
            setValue('');
        }
    }, [selected]);

    return (
        <InputAutocomplete
            {...otherProps}
            multiple={multiple}
            selected={selected}
            value={value}
            dataTestId={dataTestId}
            block={block}
            optionsListWidth={optionsListWidth}
            options={filteredOptions}
            allowUnselect={allowUnselect}
            closeOnSelect={closeOnSelect}
            size={size}
            onChange={handleChangeSelected}
            onInput={handleChangeValue}
            onOpen={handleCloseOptionsList}
        />
    );
}
