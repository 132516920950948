import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Grid } from '@alfalab/core-components/grid';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { currentOrganizationInfoSelector } from '#/src/store/organizations/selectors';
import { fetchRegisters, fetchRegistersByRole } from '#/src/store/registers/registers-list/actions';
import { Permission } from '#/src/types/orgatiozations';

export function FilterButtons() {
    const dispatch = useDispatch();
    const organizationInfo = useSelector(currentOrganizationInfoSelector);
    const handleApplyFilters = useCallback(() => {
        dispatch(fetchRegisters());
    }, [dispatch]);

    const handleResetFilters = useCallback(() => {
        batch(() => {
            dispatch(
                fetchRegistersByRole(
                    organizationInfo?.actions.find(
                        (item) => item === Permission.FACT_SHOW_SELLER_REGISTERS,
                    )
                        ? 'supplier'
                        : 'debtor',
                ),
            );
        });
    }, [dispatch, organizationInfo]);

    return (
        <Grid.Row justify='between' align='middle'>
            <Grid.Col width='auto'>
                <Button
                    dataTestId='submit-filters-button'
                    onClick={handleApplyFilters}
                    view='tertiary'
                    size='xxs'
                >
                    Применить фильтры
                </Button>
            </Grid.Col>
            <Grid.Col width='auto'>
                <Typography.Text view='primary-small'>
                    <Link
                        dataTestId='reset-filters-link'
                        pseudo={true}
                        onClick={handleResetFilters}
                    >
                        Сбросить фильтры
                    </Link>
                </Typography.Text>
            </Grid.Col>
        </Grid.Row>
    );
}
