import { combineReducers } from 'redux';

import { signSuppliesReducer, SignSuppliesState } from '#/src/store/supplies/sign-supplies/reducer';

import {
    suppliesDownloadReportReducer,
    SuppliesDownloadReportState,
} from './supplies-download-report/reducer';
import { suppliesEditingReducer, SuppliesEditingState } from './supplies-editing/reducer';
import { suppliesFiltersReducer, SuppliesFiltersState } from './supplies-filters/reducer';
import { suppliesListReducer, SuppliesListState } from './supplies-list/reducer';
import { TableCellsSettings, tableCellsSettingsReducer } from './table-cells-settings/reducer';

export type SuppliesState = {
    tableCellsSettings: TableCellsSettings;
    suppliesList: SuppliesListState;
    suppliesFilters: SuppliesFiltersState;
    suppliesDownloadReport: SuppliesDownloadReportState;
    signSupplies: SignSuppliesState;
    suppliesEditing: SuppliesEditingState;
};

export const suppliesReducer = combineReducers<SuppliesState>({
    tableCellsSettings: tableCellsSettingsReducer,
    suppliesList: suppliesListReducer,
    suppliesFilters: suppliesFiltersReducer,
    suppliesDownloadReport: suppliesDownloadReportReducer,
    signSupplies: signSuppliesReducer,
    suppliesEditing: suppliesEditingReducer,
});
