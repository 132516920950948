import React from 'react';
import { useSelector } from 'react-redux';

import {
    filteredEntitiesSelector,
    paginationSelector,
} from '#/src/store/registers/register-info/selectors';

import { RegisterInfoTableRow } from './register-info-table-row';

export function RegisterInfoTableBody() {
    const filteredEntities = useSelector(filteredEntitiesSelector);
    const pagination = useSelector(paginationSelector);

    const listForPage =
        Array.isArray(filteredEntities) && filteredEntities.length
            ? filteredEntities.slice(
                  pagination.page * pagination.pageSize - pagination.pageSize,
                  pagination.page * pagination.pageSize,
              )
            : [];

    return (
        <React.Fragment>
            {listForPage.map((entity) => (
                <RegisterInfoTableRow key={entity.id} registerRow={entity} />
            ))}
        </React.Fragment>
    );
}
