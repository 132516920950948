import { createSelector } from 'reselect';

import { reportFilters, requiredReportFilterKeys } from '#/src/constants/reports';
import { ApplicationState } from '#/src/store/reducer';

import { reportsXlsSelector } from '../selectors';

export const reportFiltersSelector = (state: ApplicationState) => reportsXlsSelector(state).filters;

export const reportFiltersIsFilledSelector = createSelector(
    reportFiltersSelector,
    (filters) =>
        !!filters.reportName &&
        reportFilters[filters.reportName].every((filterName) => {
            if (!requiredReportFilterKeys.includes(filterName)) {
                return true;
            }

            if (filterName === 'dateFrom;dateTo') {
                return filters.dateFrom && filters.dateTo;
            }

            return filters[filterName];
        }),
);
