import { DashboardTabs } from '#/src/dictionaries/reports-dashboard';
import {
    CommissionTotal,
    Filters,
    PaymentTotal,
    ReceivableTotal,
    RefundTotal,
} from '#/src/types/dashboard-reports';

export type ItemInfo = {
    color: 'yellow' | 'green' | 'orange' | 'none';
    value?: number;
    name: string;
};

const receivableTotalInfoItems: ItemInfo[] = [
    { color: 'yellow', name: 'Принято' },
    { color: 'orange', name: 'Профинансировано' },
    { color: 'green', name: 'Оплачено дебитором' },
    { color: 'green', name: 'Оплачено клиентом' },
];

const commissionTotalInfoItems: ItemInfo[] = [
    { color: 'yellow', name: 'Обработка документов' },
    { color: 'orange', name: 'ФУДЗ' },
    { color: 'green', name: 'Комиссия за финансирование' },
    { color: 'none', name: 'Всего' },
];

const paymentTotalInfoItems: ItemInfo[] = [
    { color: 'orange', name: 'Оплачено клиентом' },
    { color: 'green', name: 'Оплачено дебитором' },
];

const refundTotalInfoItems: ItemInfo[] = [
    { color: 'yellow', name: 'Оплачено клиентом' },
    { color: 'orange', name: 'Возврат по ранее оплаченым' },
];

export type TotalInfoProps = {
    tab: DashboardTabs;
    receivableTotal: ReceivableTotal;
    commissionTotal: CommissionTotal;
    paymentTotal: PaymentTotal;
    refundTotal: RefundTotal;
    filters: Filters;
};

export default function getTotalInfoItems({
    tab,
    receivableTotal,
    commissionTotal,
    paymentTotal,
    refundTotal,
    filters,
}: TotalInfoProps) {
    let totalInfoItems: ItemInfo[] = [];

    switch (tab) {
        case DashboardTabs.RECEIVABLES: {
            totalInfoItems = [...receivableTotalInfoItems];
            totalInfoItems[0].value = receivableTotal.totalArrive;
            totalInfoItems[1].value = receivableTotal.totalFinanced;
            totalInfoItems[2].value = receivableTotal.totalPaidDebtor;
            totalInfoItems[3].value = receivableTotal.totalPaidClient;
            break;
        }
        case DashboardTabs.COMMISSIONS: {
            totalInfoItems = [...commissionTotalInfoItems];
            totalInfoItems[0].value = commissionTotal.totalComPDAmount;
            totalInfoItems[1].value = commissionTotal.totalComUdzAmount;
            totalInfoItems[2].value = commissionTotal.totalComMAmount;
            totalInfoItems[3].value = commissionTotal.totalAmount;
            break;
        }
        case DashboardTabs.PAYMENTS: {
            totalInfoItems = [...paymentTotalInfoItems];
            totalInfoItems[0].value = paymentTotal.totalClientPayments;

            if (!filters.debtorTin.length) {
                totalInfoItems[1].value = paymentTotal.totalDebtorPayments;
            }

            break;
        }
        case DashboardTabs.REFUNDS: {
            totalInfoItems = [...refundTotalInfoItems];
            totalInfoItems[0].value = refundTotal.totalPaidByClient;
            totalInfoItems[1].value = refundTotal.totalPaidBefore;
            break;
        }
    }

    return totalInfoItems.filter(({ value }) => value !== undefined);
}
