export type AppState = {
    hasServerError: boolean;
};

export const initialState: AppState = {
    hasServerError: false,
};

export function appReducer(state: AppState = initialState): AppState {
    return state;
}
