import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { IconButton } from '@alfalab/core-components/icon-button';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { FormatXlsSBlackIcon } from '@alfalab/icons-classic/FormatXlsSBlackIcon';

import { downloadSuppliesReport } from '#/src/store/supplies/supplies-download-report/actions';
import { suppliesDownloadReportIsLoadingSelector } from '#/src/store/supplies/supplies-download-report/selectors';
import { selectedIdsSelector } from '#/src/store/supplies/supplies-list/selectors';

import { DownloadSuppliesReportNoticeModal } from './download-supplies-report-notice-modal';

import './download-supplies-report-control.css';

const cn = createCn('download-supplies-report-control');

export function DownloadSuppliesReportControl() {
    const dispatch = useDispatch();
    const [isOpen, setOpenState] = useState(false);
    const isLoading = useSelector(suppliesDownloadReportIsLoadingSelector);
    const selectedIds = useSelector(selectedIdsSelector);

    const handleCloseNodal = useCallback(() => {
        setOpenState(false);
    }, []);

    const handleDownloadSuppliesReport = useCallback(() => {
        if (selectedIds.length > 30000) {
            setOpenState(true);
        } else {
            dispatch(downloadSuppliesReport());
        }
        setOpenState(false);
    }, [dispatch, selectedIds]);

    return (
        <React.Fragment>
            <Tooltip
                content={<div>Скачать выделенные поставки.</div>}
                position='left'
                trigger='hover'
                fallbackPlacements={['bottom', 'top']}
            >
                <IconButton
                    className={cn()}
                    view='primary'
                    size='xs'
                    icon={FormatXlsSBlackIcon}
                    onClick={handleDownloadSuppliesReport}
                    disabled={isLoading}
                />
            </Tooltip>
            <DownloadSuppliesReportNoticeModal isOpen={isOpen} onClose={handleCloseNodal} />
        </React.Fragment>
    );
}
