import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getTime from 'date-fns/get_time';

import { Grid } from '@alfalab/core-components/grid';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { Typography } from '@alfalab/core-components/typography';
import { Input } from '@alfalab/core-components/input';
import { Calendar } from '@alfalab/core-components/calendar';
import { UniversalDateInput } from '@alfalab/core-components/universal-date-input';

import { getAgreementsByDebtor } from '#/src/store/agreements/actions';
import {
    agreementsIsLoadingByIdSelector,
    optionsByIdSelector,
} from '#/src/store/agreements/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { actions } from '#/src/store/supplies/supplies-editing/reducer';
import {
    debtorsDictionarySelector,
    editedDebtorsSelector,
    editingSupplySelector,
} from '#/src/store/supplies/supplies-editing/selectors';

type SupplyAgreementFieldRowProps = {
    debtorTin: string;
    sellerTin: string;
};

export function SupplyAgreementFieldRow({ debtorTin, sellerTin }: SupplyAgreementFieldRowProps) {
    const dispatch = useDispatch();
    const id = `${sellerTin}/${debtorTin}`;
    const debtorsDictionary = useSelector(debtorsDictionarySelector);
    const editedDebtors = useSelector(editedDebtorsSelector);
    const editingSupply = useSelector(editingSupplySelector(debtorTin));
    const agreementsIsLoading = useSelector((state: ApplicationState) =>
        agreementsIsLoadingByIdSelector(state, id),
    );
    const options = useSelector((state: ApplicationState) => optionsByIdSelector(state, id));

    const handleChangeAgreement = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selected }) => {
            dispatch(
                actions.setAgreementForDebtor({
                    debtorTin,
                    agreement: selected?.value || '',
                    isOneSupplyAgreement: options.length === 1,
                }),
            );
        },
        [dispatch, debtorTin, options.length],
    );

    const handleChangeDelay = useCallback(
        (event) => {
            dispatch(actions.setDelayPeriodForDebtor({ debtorTin, delay: event.target.value }));
        },
        [dispatch, debtorTin],
    );

    const handleChangeReceiptDate = useCallback(
        (value) => {
            dispatch(actions.setReceiptDateForDebtor({ debtorTin, receiptDate: value }));
        },
        [dispatch, debtorTin],
    );

    useEffect(() => {
        dispatch(getAgreementsByDebtor({ debtorTin, sellerTin, id }));
    }, [dispatch, debtorTin, sellerTin, id]);

    return (
        <Grid.Row align='middle' dataTestId='supply-agreement-field-row' justify='between'>
            <Grid.Col width='3' align='middle'>
                <Typography.Text view='primary-medium' color='secondary'>
                    {debtorsDictionary[debtorTin]}
                </Typography.Text>
            </Grid.Col>
            <Grid.Col width='3' align='middle'>
                <Select
                    dataTestId='supply-agreement-field-select'
                    block={true}
                    placeholder='Выберите контракт'
                    label='Контракт'
                    optionsListWidth='field'
                    allowUnselect={true}
                    selected={editedDebtors[debtorTin].contract}
                    options={options}
                    onChange={handleChangeAgreement}
                    disabled={agreementsIsLoading || !editingSupply?.supplyAgreementEditable}
                />
            </Grid.Col>
            <Grid.Col width='2' align='middle'>
                <Input
                    type='number'
                    min={0}
                    dataTestId='delay-input'
                    value={editedDebtors[debtorTin].delayPeriod}
                    block={true}
                    label='Отсрочка'
                    onChange={handleChangeDelay}
                    disabled={!editingSupply?.supplyDelayPeriodEditable}
                />
            </Grid.Col>
            <Grid.Col width='4' align='middle'>
                <UniversalDateInput
                    dataTestId='receipt-date-calendar'
                    placeholder='Начало отсрочки'
                    view='date'
                    picker={true}
                    value={editedDebtors[debtorTin].receiptDate}
                    Calendar={Calendar}
                    onChange={handleChangeReceiptDate}
                    maxDate={getTime(new Date())}
                    disabled={!editingSupply?.supplyReceiptDateEditable}
                />
            </Grid.Col>
        </Grid.Row>
    );
}
