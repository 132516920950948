import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import ExternalPagePhonesInfo from './external-page-phones-info';

import './external-page-phones-section.css';

const cn = createCn('external-page-phones-section');

export default function ExternalPagePhonesSection() {
    return (
        <div className={cn()}>
            <ExternalPagePhonesInfo view='rows' hasIcon={true} />
            <Typography.Text view='secondary-large' className={cn('text')}>
                Получение информации и проведение операций 24/7
            </Typography.Text>
        </div>
    );
}
