import { ErrorType } from '#/src/types/errors';

type ErrorParamType = ErrorType | string;

export function parseErrorMessage(error: unknown) {
    let message = '';
    const errorParam = (Array.isArray(error) && error[0] ? error[0] : error) as ErrorParamType;

    if (error) {
        if (typeof errorParam === 'string') {
            message = errorParam;
        } else if (typeof error === 'object') {
            message = Array.isArray(errorParam.detail)
                ? errorParam.detail[0]
                : errorParam.detail ||
                  errorParam.statusText ||
                  errorParam.error ||
                  errorParam.message ||
                  '';
        }
    }

    return message;
}
