import React, { MouseEventHandler, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Checkbox } from '@alfalab/core-components/checkbox';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowsCwCompactSIcon } from '@alfalab/icons-glyph/ArrowsCwCompactSIcon';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';
import { ChevronUpSIcon } from '@alfalab/icons-glyph/ChevronUpSIcon';

import DownloadRegistersButton from '#/src/containers/pages/registers-pages/download-registers-button';
import { SignRegistersControls } from '#/src/containers/pages/registers-pages/sign-registers-controls';
import { appliedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';
import { toggleSelectedRegisters } from '#/src/store/registers/registers-list/action-creators';
import { fetchRegisters, fetchRegistersBySort } from '#/src/store/registers/registers-list/actions';
import {
    countSelector,
    entitiesIsLoadingSelector,
    idsSelector,
    selectedSelector,
    sortSelector,
} from '#/src/store/registers/registers-list/selectors';
import { SortOrder } from '#/src/types/sort';

import './register-list-table-head.css';

const cn = createCn('register-list-table-head');

export function RegisterListTableHead() {
    const dispatch = useDispatch();
    const ids = useSelector(idsSelector);
    const selected = useSelector(selectedSelector);
    const sort = useSelector(sortSelector);
    const count = useSelector(countSelector);
    const entitiesIsLoading = useSelector(entitiesIsLoadingSelector);
    const appliedFilters = useSelector(appliedFiltersSelector);
    const areAllItemsChecked = selected.length === ids.length;

    const handleStopEventLifting = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
        event.stopPropagation();
    }, []);

    const handleChangeSort = useCallback(() => {
        const order = sort.order === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;

        dispatch(fetchRegistersBySort({ field: sort.field, order }));
    }, [dispatch, sort]);
    const handleReload = useCallback(() => {
        dispatch(fetchRegisters(true));
    }, [dispatch]);
    const handleToggleSelectedRegisters = useCallback(() => {
        dispatch(toggleSelectedRegisters());
    }, [dispatch]);

    return (
        <div className={cn()}>
            <div className={cn('controls')}>
                <div className={cn('controls-btn')}>
                    {!!ids.length && (
                        <React.Fragment>
                            <div
                                onClick={handleStopEventLifting}
                                className={cn('controls-checkbox')}
                                aria-hidden='true'
                            >
                                <Checkbox
                                    data-test-id='registers-table-controls-checkbox'
                                    size='s'
                                    checked={areAllItemsChecked && ids.length !== 0}
                                    onChange={handleToggleSelectedRegisters}
                                />
                            </div>
                            {selected.length ? (
                                <React.Fragment>
                                    <Typography.Text
                                        view='secondary-large'
                                        className={cn('controls-label')}
                                    >
                                        Отобрано {selected.length} реестров из {count}
                                    </Typography.Text>
                                    <div className={cn('controls-sign')}>
                                        <SignRegistersControls
                                            onSuccessSign={handleReload}
                                            isDebtor={appliedFilters.role === 'debtor'}
                                            selectedIds={selected}
                                        />
                                    </div>
                                    <DownloadRegistersButton ids={selected} />
                                </React.Fragment>
                            ) : (
                                <Typography.Text
                                    view='secondary-large'
                                    className={cn('controls-label')}
                                >
                                    Отобрано 0 реестров из {count}
                                </Typography.Text>
                            )}
                        </React.Fragment>
                    )}
                </div>
                <div className={cn('controls-sort')}>
                    <Typography.Text view='secondary-large' className={cn('controls-label')}>
                        Сортировать:
                    </Typography.Text>
                    <Typography.Text view='secondary-large'>
                        <Link
                            dataTestId='sort-by-date-link'
                            pseudo={true}
                            onClick={handleChangeSort}
                            rightAddons={
                                sort.order === SortOrder.ASC ? (
                                    <ChevronDownSIcon className={cn('controls-icon')} />
                                ) : (
                                    <ChevronUpSIcon className={cn('controls-icon')} />
                                )
                            }
                        >
                            По дате
                        </Link>
                    </Typography.Text>
                </div>
                <div className={cn('row-btn')}>
                    <IconButton
                        size='xxs'
                        disabled={entitiesIsLoading}
                        onClick={handleReload}
                        className={cn('reload-btn')}
                        icon={ArrowsCwCompactSIcon}
                    />
                </div>
            </div>
            <div className={cn('row')}>
                <Typography.Text tag='div' className={cn('cell-name')}>
                    Наименование файла
                </Typography.Text>
                <Typography.Text tag='div' className={cn('cell-number')}>
                    Номер
                </Typography.Text>
                <Typography.Text tag='div' className={cn('cell-type')}>
                    Признак
                </Typography.Text>
                <Typography.Text tag='div' className={cn('cell-date')}>
                    Дата загрузки
                </Typography.Text>
                <Typography.Text tag='div' className={cn('cell-status')}>
                    Статус
                </Typography.Text>
                <Typography.Text tag='div' className={cn('cell-note')}>
                    Комментарий
                </Typography.Text>
            </div>
        </div>
    );
}
