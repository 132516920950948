import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateSelectedFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';

export function SupplyAgreementNumberFilter() {
    const dispatch = useDispatch();
    const { supplyAgreementNumber } = useSelector(selectedFiltersSelector);

    const handleChangeSupplyAgreementNumber = useCallback<NonNullable<InputProps['onChange']>>(
        (event) => {
            dispatch(updateSelectedFilters({ supplyAgreementNumber: event.target.value }));
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='supply-agreement-number-input'
            value={supplyAgreementNumber || ''}
            block={true}
            label='Номер контракта'
            onChange={handleChangeSupplyAgreementNumber}
        />
    );
}
