import { CombinedRegisterErf, RegisterErf } from '#/src/types/registers';

export default function combineRegisterErf(erfList: RegisterErf[]): CombinedRegisterErf[] {
    const erfSet: Record<string, CombinedRegisterErf> = {};

    for (let i = 0; i < erfList.length; i++) {
        const { debtorTin, supplyAgreementNumber, erfValue, ...erf } = erfList[i];

        let erfValueArr = [erfValue];
        let supplyAgreementNumberArr = [supplyAgreementNumber];

        if (erfSet[debtorTin]) {
            erfValueArr = erfValueArr.concat(erfSet[debtorTin].erfValue);
            supplyAgreementNumberArr = supplyAgreementNumberArr.concat(
                erfSet[debtorTin].supplyAgreementNumber,
            );
        }

        erfSet[debtorTin] = {
            ...erf,
            debtorTin,
            supplyAgreementNumber: supplyAgreementNumberArr,
            erfValue: erfValueArr,
        };
    }

    return Object.values(erfSet);
}
