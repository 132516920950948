import React from 'react';

import { Grid } from '@alfalab/core-components/grid';

import { DebtorLimit } from '#/src/types/dashboard-reports';

import DebtorLimitsSliderItem from './debtor-limits-slider-item';

type DebtorLimitsGroupProps = {
    group: DebtorLimit[];
};

export default function DebtorLimitsGroup({ group }: DebtorLimitsGroupProps) {
    return (
        <Grid.Row justify='left'>
            {group.map((debtorLimit) => (
                <Grid.Col width='4'>
                    <DebtorLimitsSliderItem
                        name={debtorLimit.debtorName}
                        amount={debtorLimit.zakLim}
                    />
                </Grid.Col>
            ))}
        </Grid.Row>
    );
}
