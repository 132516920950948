import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { NavigationTab, Segment } from '#/src/types/navigations';

export const navigationsSortIndex: Record<Segment, number> = {
    TAB_PAYMENTS: 7,
    TAB_INVOICE_REGISTRY: 2,
    TAB_DASHBOARDS: 5,
    TAB_REPORTS: 6,
    TAB_REGISTERS: 4,
    TAB_BIGSTATEMENT: 8,
    TAB_EXCHANGECONTROL: 9,
    TAB_MAIL: 10,
    TAB_SUPPLY: 3,
    TAB_X5_COMPANIES_GROUP: 1,
    TAB_SETTINGS: 11,
};

export const bottomMenuNavigations: NavigationTab[] = [
    {
        segment: 'TAB_SETTINGS',
        url: routesDictionary.settingsPage.path,
        name: 'Настройки',
    },
];
