import React from 'react';

import { Button } from '@alfalab/core-components/button';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { BlankResultModal, BlankResultModalProps } from '#/src/components/blank-result-modal';

type DownloadRegistersLimitModalProps = Pick<BlankResultModalProps, 'onClose' | 'open'> & {
    limit: number;
};

export default function DownloadRegistersLimitModal({
    onClose,
    open,
    limit,
}: DownloadRegistersLimitModalProps) {
    return (
        <BlankResultModal open={open} onClose={onClose}>
            <Space size={12} direction='vertical' fullWidth={true} align='center'>
                <Typography.Text tag='div' color='attention'>
                    <ExclamationCircleMIcon width='52px' height='52px' />
                </Typography.Text>
                <Typography.Title view='small' tag='div' weight='bold'>
                    Выбрано слишком много реестров
                </Typography.Title>
                <Typography.Text tag='p'>
                    За один раз возможно скачать не более {limit}&nbsp;реестров
                </Typography.Text>
                <Button view='tertiary' onClick={onClose} size='s'>
                    Понятно
                </Button>
            </Space>
        </BlankResultModal>
    );
}
