import { LoadingState } from '#/src/constants/loading-state';
import { SupplyRepayment } from '#/src/types/supply';

import * as actionCreators from './action-creators';
import { SupplyRepaymentsActionsType } from './types';

export type SupplyRepaymentsById = {
    repayments: SupplyRepayment[];
    loadingState: LoadingState;
};

export type SupplyRepaymentsState = Record<number, SupplyRepaymentsById>;

export const supplyRepaymentsByIdDefault: SupplyRepaymentsById = {
    repayments: [],
    loadingState: 'unknown',
};

const initialState: SupplyRepaymentsState = {};

export function supplyRepaymentsReducer(
    state: SupplyRepaymentsState = initialState,
    action: SupplyRepaymentsActionsType,
): SupplyRepaymentsState {
    switch (action.type) {
        case actionCreators.getSupplyRepaymentsStart.type:
            return {
                ...state,
                [action.id]: {
                    ...supplyRepaymentsByIdDefault,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyRepaymentsSuccess.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    repayments: action.data,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyRepaymentsError.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.removeSupplyRepayments.type: {
            const { [action.id]: removeItem, ...items } = state;

            return {
                ...items,
            };
        }
        default:
            return state;
    }
}
