import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RadioGroup, RadioGroupProps } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';

import { CountFilter, countFilterDictionary } from '#/src/dictionaries/reports-dashboard';
import { companiesIsLoadingByTypeSelector } from '#/src/store/companies/selectors';
import { dashboardChartsIsLoadingSelector } from '#/src/store/dashboard/dashboard-charts/selectors';
import { updateFilters } from '#/src/store/dashboard/dashboard-filters/action-creators';
import { filtersSelector } from '#/src/store/dashboard/dashboard-filters/selectors';
import { ApplicationState } from '#/src/store/reducer';

export function ByCountFilter() {
    const dispatch = useDispatch();
    const { byCount } = useSelector(filtersSelector);
    const debtorsIsLoading = useSelector((state: ApplicationState) =>
        companiesIsLoadingByTypeSelector(state, 'debtors'),
    );
    const isLoading = useSelector(dashboardChartsIsLoadingSelector);

    const handleChangeByCount = useCallback<NonNullable<RadioGroupProps['onChange']>>(
        (event, payload) => {
            let newValue = payload.value as CountFilter;

            if (byCount === newValue) {
                newValue = CountFilter.DEFAULT;
            }

            dispatch(updateFilters({ byCount: newValue }));
        },
        [dispatch, byCount],
    );

    return (
        <RadioGroup
            onChange={handleChangeByCount}
            value={byCount}
            direction='horizontal'
            type='tag'
            label='По количеству'
            disabled={isLoading || debtorsIsLoading}
        >
            <Tag value={CountFilter.MILLIONS} size='xxs'>
                {countFilterDictionary.MILLIONS}
            </Tag>
            <Tag value={CountFilter.THOUSANDS} size='xxs'>
                {countFilterDictionary.THOUSANDS}
            </Tag>
        </RadioGroup>
    );
}
