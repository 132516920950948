import { SupplyDto, SupplySource } from '#/src/types/supply';

export function mapFirstSuppliesResponseToTotalResult(supplies: SupplyDto[]) {
    const result: {
        supplyIds: number[];
        totalPrice: number;
        allTotalPrice: Record<number, number>;
    } = {
        supplyIds: [],
        totalPrice: 0,
        allTotalPrice: {},
    };

    for (let i = 0; i < supplies.length; ++i) {
        const supply = supplies[i];

        if (supply.id) {
            const { id, supplyAmountCorrection = 0, supplyAmount = 0 } = supply;

            result.supplyIds.push(id);

            let supplyPrice = +supplyAmount;

            if (supplyAmountCorrection) {
                supplyPrice = +supplyAmount - +supplyAmountCorrection;
            }

            result.totalPrice += supplyPrice;
            result.allTotalPrice[id] = supplyPrice;
        }
    }

    return result;
}

export function mapAvailableSuppliesList(supplies: SupplyDto[]) {
    const result: {
        available: Record<number, number>;
        source: Record<number, SupplySource[]>;
        amountCertificationDocumentDate: Record<number, number>;
    } = {
        available: {},
        source: {},
        amountCertificationDocumentDate: {},
    };

    for (let i = 0; i < supplies.length; ++i) {
        const supply = supplies[i];

        if (
            supply.id &&
            !(
                result.source[supply.id] ||
                !supply.supplyAmount ||
                supply.financingType === 'NOT_FINANCE'
            ) &&
            !supply.supplySource?.every((element) => element === 'REGISTER')
        ) {
            let supplyPrice = +supply.supplyAmount;

            if (supply.supplyAmountCorrection) {
                supplyPrice = +supply.supplyAmount - +supply.supplyAmountCorrection;
            }

            result.available[supply.id] = supplyPrice;

            result.source[supply.id] = supply.supplySource as SupplySource[];

            if (supply.amountCertificationDocumentDate) {
                result.amountCertificationDocumentDate[supply.id] = Date.parse(
                    supply.amountCertificationDocumentDate,
                );
            } else {
                result.amountCertificationDocumentDate[supply.id] = Date.now();
            }
        }
    }

    return result;
}
