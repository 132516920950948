import { FiltersState } from '#/src/store/supplies/supplies-filters/reducer';
import { GetSuppliesPayload } from '#/src/types/supply';
import { formatDateFromCalendar } from '#/src/utils/date-converter';

export default function convertSuppliesFiltersToRequestPayload(
    sellerTin: string,
    filters: Partial<FiltersState> = {},
) {
    const requestData: GetSuppliesPayload = {
        sellerTin,
    };

    if (filters.correction) {
        requestData.correction = filters.correction;
    }

    if (filters.dateFrom) {
        requestData.dateFrom = formatDateFromCalendar(filters.dateFrom) || '';
    }
    if (filters.dateTo) {
        requestData.dateTo = formatDateFromCalendar(filters.dateTo) || '';
    }

    if (filters.amountCertificationDocumentNumber) {
        requestData.amountCertificationDocumentNumber = filters.amountCertificationDocumentNumber;
    }
    if (filters.supplyAgreementNumber) {
        requestData.supplyAgreementNumber = filters.supplyAgreementNumber;
    }
    if (filters.factoringAgreementNumber) {
        requestData.factoringAgreementNumber = filters.factoringAgreementNumber;
    }
    if (filters.supplySource) {
        requestData.supplySources = filters.supplySource === 'ALL' ? [] : [filters.supplySource];
    }
    if (filters.registerNumber) {
        requestData.registerNumber = filters.registerNumber;
    }
    if (filters.fromAmount) {
        requestData.fromAmount = +String(filters.fromAmount).replace(/,/, '.');
    }
    if (filters.toAmount) {
        requestData.toAmount = +String(filters.toAmount).replace(/,/, '.');
    }
    if (filters.statuses && filters.statuses.length) {
        requestData.statuses = filters.statuses;
    }
    if (filters.debtorsTin && filters.debtorsTin.length) {
        requestData.debtorsTin = filters.debtorsTin;
    }

    return requestData;
}
