import { LoadingState } from '#/src/constants/loading-state';
import { RegisterShortDto } from '#/src/types/registers';
import { SortField, SortOrder } from '#/src/types/sort';

import * as actionCreators from './action-creators';
import { RegistersListActionsType } from './types';

export type RegistersListState = {
    ids: number[];
    entities: RegisterShortDto[];
    loadingState: LoadingState;
    count: number;
    pagination: {
        page: number;
        pageSize: number;
    };
    selected: number[];
    selectRegisterId: null | string;
    sort: {
        field: SortField;
        order: SortOrder;
    };
};

const initialState: RegistersListState = {
    ids: [],
    entities: [],
    count: 0,
    pagination: {
        page: 1,
        pageSize: 20,
    },
    selected: [],
    selectRegisterId: null,
    sort: {
        field: SortField.DATE,
        order: SortOrder.DESC,
    },
    loadingState: 'unknown',
};

export function registersListReducer(
    state: RegistersListState = initialState,
    action: RegistersListActionsType,
): RegistersListState {
    switch (action.type) {
        case actionCreators.changeRegistersListLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.setRegistersList.type:
            return {
                ...state,
                ...action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.clearRegistersList.type:
            return initialState;
        case actionCreators.toggleSelectedRegisters.type:
            return {
                ...state,
                selected: state.ids.length === state.selected.length ? [] : state.ids,
            };
        case actionCreators.setSelectedRegisters.type:
            return {
                ...state,
                selected: action.selected,
            };
        case actionCreators.setSort.type:
            return {
                ...state,
                sort: {
                    ...action.data,
                },
            };
        default:
            return state;
    }
}
