import { combineReducers } from 'redux';

import { cachedReportsReducer, CachedReportsState } from './cached-reports/reducer';
import { debtorsReducer, DebtorsState } from './debtors/reducer';
import { generalAgreementsReducer, GeneralAgreementsState } from './general-agreements/reducer';
import { reportFiltersReducer, ReportFiltersState } from './report-filters/reducer';
import { reportNamesReducer, ReportNamesState } from './report-names/reducer';

export type ReportsXlsState = {
    cachedReports: CachedReportsState;
    reportNames: ReportNamesState;
    debtors: DebtorsState;
    generalAgreements: GeneralAgreementsState;
    filters: ReportFiltersState;
};

export const reportsXlsReducer = combineReducers({
    cachedReports: cachedReportsReducer,
    reportNames: reportNamesReducer,
    debtors: debtorsReducer,
    generalAgreements: generalAgreementsReducer,
    filters: reportFiltersReducer,
});
