import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownLineDownSIcon } from '@alfalab/icons-glyph/ArrowDownLineDownSIcon';

import { downloadDocuments } from '#/src/store/documents/actions';
import { documentsIsLoadingSelector } from '#/src/store/documents/selectors';

import DownloadSupplyResultModal from './download-supply-result-modal';

import './download-supply-button.css';

type DownloadSupplyButtonProps = {
    id: string | null;
};

const cn = createCn('download-supply-button');

export function DownloadSupplyButton({ id }: DownloadSupplyButtonProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector(documentsIsLoadingSelector);

    const handleDownload = useCallback(() => {
        if (!isLoading && id) {
            dispatch(downloadDocuments([+id]));
        }
    }, [dispatch, id, isLoading]);

    return (
        <React.Fragment>
            <Typography.Text view='secondary-large'>
                <Link
                    dataTestId='download-supply-archive-link'
                    className={cn({ disabled: !id })}
                    leftAddons={<ArrowDownLineDownSIcon width='18px' height='18px' />}
                    onClick={handleDownload}
                    pseudo={true}
                    rightAddons={<Spinner size='xs' visible={isLoading} />}
                >
                    Скачать архив документов
                </Link>
            </Typography.Text>
            <DownloadSupplyResultModal />
        </React.Fragment>
    );
}
