import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import './info-field.css';

type InfoFieldProps = {
    title: React.ReactNode;
    value: React.ReactNode;
};

const cn = createCn('info-field');

export default function InfoField({ title, value }: InfoFieldProps) {
    return (
        <div className={cn()}>
            <Typography.Text tag='div' view='secondary-large' color='secondary'>
                {title}
            </Typography.Text>
            <Typography.Text tag='div' view='secondary-large'>
                {value}
            </Typography.Text>
        </div>
    );
}
