import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';

import { ExternalPage } from '#/src/components/external-page';
import { ExternalPageForm } from '#/src/components/external-page-form';
import FormButton from '#/src/components/form-button';
import PlateError from '#/src/components/plate-error';
import SystemNotificationModal from '#/src/containers/system-notifications/system-notification-modal';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { logIn } from '#/src/store/auth/actions';
import { authErrorSelector, authIsLoadingSelector } from '#/src/store/auth/selectors';

import BannerManualVideo from './banner-manual-video';
import UserWithoutPermissionsModal from './user-without-permissions-modal';

import './auth-page.css';

const cn = createCn('auth-page');

export default function LoginPage() {
    const dispatch = useDispatch();
    const authIsLoading = useSelector(authIsLoadingSelector);
    const authError = useSelector(authErrorSelector);
    const handleSubmitLogin = useCallback(
        (formValues: Record<string, string>) => {
            dispatch(logIn(formValues.username, formValues.password));
        },
        [dispatch],
    );

    return (
        <ExternalPage title='Вход в Альфа-Финанс'>
            <ExternalPageForm className={cn('form')} form='auth' onSubmit={handleSubmitLogin}>
                {!!authError && <PlateError error={authError} />}
                <div className={cn('links')}>
                    <Link
                        dataTestId='reset-password-link'
                        href={routesDictionary.restorePassword.path}
                    >
                        Восстановить пароль
                    </Link>
                    <Link dataTestId='reset-login-link' href={routesDictionary.restoreLogin.path}>
                        Восстановить логин
                    </Link>
                </div>
                <FormButton form='auth' text='Войти' loading={authIsLoading} type='submit' />
            </ExternalPageForm>
            <BannerManualVideo />
            <UserWithoutPermissionsModal />
            <SystemNotificationModal />
        </ExternalPage>
    );
}
