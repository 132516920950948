import React from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import ApplicationMenu from 'arui-private/core/application-menu';

import { bottomMenuNavigations } from '#/src/dictionaries/navigations';
import { navigationsSelector } from '#/src/store/navigations/selectors';

import MenuItem from './menu-item';

import './app-menu.css';

const cn = createCn('app-menu');

export default function AppMenu() {
    const navigationsTop = useSelector(navigationsSelector);

    return (
        <ApplicationMenu className={cn()} data-test-id='app-menu'>
            <div className={cn('top')}>
                {navigationsTop.map((nav) => (
                    <MenuItem
                        key={nav.segment}
                        segment={nav.segment}
                        name={nav.name}
                        href={nav.url}
                    />
                ))}
            </div>
            <div className={cn('bottom')}>
                {bottomMenuNavigations.map((nav) => (
                    <MenuItem
                        key={nav.segment}
                        segment={nav.segment}
                        name={nav.name}
                        href={nav.url}
                    />
                ))}
            </div>
        </ApplicationMenu>
    );
}
