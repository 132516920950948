import { createSelector } from '#/node_modules/reselect';
import { navigationsSelector } from '#/src/store/navigations/selectors';
import { hasAvailableOrganizationsByPageSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { routeInfoSelector } from '#/src/store/router/selectors';

export const appSelector = (state: ApplicationState) => state.app;

export const hasServerErrorSelector = (state: ApplicationState) =>
    appSelector(state).hasServerError;

export const hasAvailableSegmentSelector = createSelector(
    routeInfoSelector,
    navigationsSelector,
    (routeInfo, navigations) =>
        routeInfo?.segment
            ? !!navigations.find(({ segment }) => segment === routeInfo.segment)
            : true,
);

export const pageMetaTitleSelector = createSelector(
    routeInfoSelector,
    hasAvailableOrganizationsByPageSelector,
    hasAvailableSegmentSelector,
    (routeInfo, hasAvailableOrganizationsByPage, hasAvailableSegment) => {
        const routeTitle =
            (hasAvailableOrganizationsByPage || routeInfo?.availableWithoutOrganizations) &&
            hasAvailableSegment
                ? routeInfo?.pageTitle
                : undefined;

        return routeTitle || 'Альфа-Финанс';
    },
);
