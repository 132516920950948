import { createCachedSelector } from 're-reselect';

import { ApplicationState } from '#/src/store/reducer';
import { stateSelector } from '#/src/store/selectors';
import convertOrganizationShortToOptions from '#/src/utils/convert-organization-short-to-options';

import { CompanyType } from './reducer';

export const companiesStateSelector = (state: ApplicationState) => stateSelector(state).companies;

const companyTypeSelector = (state: ApplicationState, companyType: CompanyType) => companyType;

export const companiesStateByTypeSelector = createCachedSelector(
    companyTypeSelector,
    companiesStateSelector,
    (companyType, companies) => companies[companyType],
)(companyTypeSelector);

export const companiesIsLoadingByTypeSelector = createCachedSelector(
    companiesStateByTypeSelector,
    (companies) => companies.loadingState === 'loading',
)(companyTypeSelector);

export const companiesIsErrorByTypeSelector = createCachedSelector(
    companiesStateByTypeSelector,
    (companies) => companies.loadingState === 'error',
)(companyTypeSelector);

export const companiesListByTypeSelector = createCachedSelector(
    companiesStateByTypeSelector,
    (companies) => companies.entities,
)(companyTypeSelector);

export const optionsByTypeSelector = createCachedSelector(companiesListByTypeSelector, (entities) =>
    convertOrganizationShortToOptions(entities),
)(companyTypeSelector);
