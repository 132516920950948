import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { AuthActionsType } from './types';

export type AuthState = {
    error: string | null;
    loadingState: LoadingState;
    userWithoutPermissionsModalIsOpen: boolean;
};

export const initialState: AuthState = {
    error: null,
    loadingState: 'unknown',
    userWithoutPermissionsModalIsOpen: false,
};

export function authReducer(state: AuthState = initialState, action: AuthActionsType): AuthState {
    switch (action.type) {
        case actionCreators.authStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.authSuccess.type:
            return {
                ...state,
                error: null,
                loadingState: action.loadingState,
            };
        case actionCreators.authError.type:
            return {
                ...state,
                error: action.error,
                loadingState: action.loadingState,
            };
        case actionCreators.setUserWithoutPermissionsModalState.type:
            return {
                ...state,
                userWithoutPermissionsModalIsOpen: action.isOpen,
            };
        default:
            return state;
    }
}
