import React from 'react';
import { createCn } from 'bem-react-classname';

import { RegisterInfoPagination } from '../register-info-pagination';
import { RegisterInfoTableBody } from '../register-info-table-body';
import { RegisterInfoTableHead } from '../register-info-table-head';

import './register-info-table.css';

const cn = createCn('register-info-table');

export function RegisterInfoTable() {
    return (
        <div className={cn()} data-test-id='register-info-table'>
            <RegisterInfoTableHead />
            <RegisterInfoTableBody />
            <RegisterInfoPagination />
        </div>
    );
}
