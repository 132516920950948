import { DATE_FORMAT } from '#/src/constants/date';
import { CertificateInfoSignedSupply } from '#/src/types/certificates';
import { formatDateForCalendar } from '#/src/utils/date-converter';

type Field = {
    id: Exclude<keyof CertificateInfoSignedSupply, 'signedDocuments'>;
    title: string;
    value: string;
    normalize?: (value: string) => string;
};
const infoFields: Field[] = [
    {
        id: 'companyName',
        title: 'Юридическое лицо',
        value: '',
    },
    {
        id: 'userName',
        title: 'Физическое лицо',
        value: '',
    },
    {
        id: 'serialNumber',
        title: 'Серийный номер',
        value: '',
    },
    {
        id: 'certIssuer',
        title: 'Выдан',
        value: '',
    },
];

const dateFields: Field[] = [
    {
        id: 'validFrom',
        title: 'Действителен с',
        value: '',
        normalize: (value) => formatDateForCalendar(new Date(value), DATE_FORMAT),
    },
    {
        id: 'validTo',
        title: 'Действителен по',
        value: '',
        normalize: (value) => formatDateForCalendar(new Date(value), DATE_FORMAT),
    },
];

function reduceFieldsCallback(certificate: CertificateInfoSignedSupply) {
    return (acc: Field[], { id, title, normalize }: Field) => {
        const value = certificate[id] as string;
        const normalizedValue = normalize?.(value) || value;

        return certificate[id] === null ? acc : acc.concat({ id, title, value: normalizedValue });
    };
}
export function getCertificateFields(certificate: CertificateInfoSignedSupply) {
    const infoFieldsFiltered = infoFields.reduce<Field[]>(reduceFieldsCallback(certificate), []);

    const dateFieldsFiltered = dateFields.reduce<Field[]>(reduceFieldsCallback(certificate), []);

    return [infoFieldsFiltered, dateFieldsFiltered];
}
