import { SignButtonProps } from '@alfa-bank/corp-sign-ui-react/components/SignButton';
import { DocumentWithContent } from '@alfa-bank/corp-sign-ui-react/types';

import { LoadingState } from '#/src/constants/loading-state';
import { CombinedActiveErf } from '#/src/types/erf';
import { CheckPermissionSOResult, CreateSignDataResponse } from '#/src/types/sign';
import { UserInfo } from '#/src/types/user';

import * as actionCreators from './action-creators';
import { SignSuppliesActionsType } from './types';

export type SignSuppliesState = {
    loadingState: LoadingState;
    registerId: number | null;
    activeErf: CombinedActiveErf[];
    suppliesIds: number[];
    availableAmount: number;
    callback: SignButtonProps['onClick'] | undefined;
    additionalInfoModalIsOpen: boolean;
    erfErrorModalIsOpen: boolean;
    documents: DocumentWithContent[];
    filteredSuppliesIds: number[];
    createdSuppliesSignData: CreateSignDataResponse;
    createdRegisterSignData: CreateSignDataResponse;
    user: UserInfo | null;
    permissions: CheckPermissionSOResult[];
    warningModalIsOpen: boolean;
    waitingErrorModalIsOpen: boolean;
    emptyFieldsWarningModalIsOpen: boolean;
};

const initialState: SignSuppliesState = {
    loadingState: 'unknown',
    registerId: null,
    activeErf: [],
    suppliesIds: [],
    availableAmount: 0,
    callback: undefined,
    additionalInfoModalIsOpen: false,
    erfErrorModalIsOpen: false,
    documents: [],
    filteredSuppliesIds: [],
    createdSuppliesSignData: [],
    createdRegisterSignData: [],
    user: null,
    permissions: [],
    warningModalIsOpen: false,
    waitingErrorModalIsOpen: false,
    emptyFieldsWarningModalIsOpen: false,
};

export function signSuppliesReducer(
    state: SignSuppliesState = initialState,
    action: SignSuppliesActionsType,
): SignSuppliesState {
    switch (action.type) {
        case actionCreators.changeAdditionalModalState.type:
            return {
                ...state,
                additionalInfoModalIsOpen: action.isOpen,
            };
        case actionCreators.changeWarningModalState.type:
            return {
                ...state,
                warningModalIsOpen: action.isOpen,
            };
        case actionCreators.changeErfErrorModalState.type:
            return {
                ...state,
                erfErrorModalIsOpen: action.isOpen,
            };
        case actionCreators.changeWaitingErrorModalState.type:
            return {
                ...state,
                waitingErrorModalIsOpen: action.isOpen,
            };
        case actionCreators.changeEmptyFieldsWarningModalState.type:
            return {
                ...state,
                emptyFieldsWarningModalIsOpen: action.isOpen,
            };
        case actionCreators.changeSignSuppliesLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.setPreparedSignData.type:
            return {
                ...state,
                loadingState: 'unknown',
                additionalInfoModalIsOpen: true,
                activeErf: action.activeErf,
                availableAmount: action.availableAmount,
                suppliesIds: action.suppliesIds,
                callback: action.callback,
            };
        case actionCreators.setCreatedSignData.type:
            return {
                ...state,
                loadingState: 'unknown',
                warningModalIsOpen: action.warningModalIsOpen,
                filteredSuppliesIds: action.filteredSuppliesIds,
                permissions: action.permissions,
                createdSuppliesSignData: action.createdSuppliesSignData,
                createdRegisterSignData: action.createdRegisterSignData,
                documents: action.documents,
                user: action.user,
                registerId: action.registerId,
            };
        case actionCreators.clearState.type:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
