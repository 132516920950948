import React, { useCallback, useState } from 'react';
import { createCn } from 'bem-react-classname';
import formatDate from 'date-fns/format';

import { Amount } from '@alfalab/core-components/amount';
import { Collapse } from '@alfalab/core-components/collapse';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';
import { ChevronUpSIcon } from '@alfalab/icons-glyph/ChevronUpSIcon';

import { DATE_FORMAT } from '#/src/constants/date';

import './supply-repayments-list.css';

type SupplyRepaymentsListProps = {
    repayments: any[];
};

const cn = createCn('supply-repayments-list');

const SupplyRepaymentsList: React.FC<SupplyRepaymentsListProps> = ({ repayments }) => {
    const [isExpanded, setExpanded] = useState(false);
    const handleExpandedToggle = useCallback((event?: React.MouseEvent) => {
        event?.preventDefault();
        setExpanded((prevState) => !prevState);
    }, []);

    return (
        <div className={cn()}>
            <Typography.Text view='secondary-large' tag='div'>
                <Link
                    dataTestId='toggle-expended-state-payments-link'
                    onClick={handleExpandedToggle}
                    pseudo={true}
                    rightAddons={
                        isExpanded ? (
                            <ChevronUpSIcon width='16px' height='16px' />
                        ) : (
                            <ChevronDownSIcon width='16px' height='16px' />
                        )
                    }
                >
                    Плановое погашение
                </Link>
            </Typography.Text>
            <Collapse expanded={isExpanded}>
                {repayments.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className={cn('item')}>
                        <Typography.Text view='secondary-large' tag='div'>
                            {formatDate(item.repaymentDate, DATE_FORMAT)} -{' '}
                            <Amount
                                value={item.repaymentAmount * 100}
                                minority={100}
                                currency='RUR'
                                view='withZeroMinorPart'
                            />
                        </Typography.Text>
                    </div>
                ))}
            </Collapse>
        </div>
    );
};

export default React.memo(SupplyRepaymentsList);
