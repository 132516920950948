import { RegisterShortDto } from '#/src/types/registers';

export default function getSourceType(register: RegisterShortDto) {
    if (register.registerType) {
        if (register.debtor) {
            return 'РПД';
        }

        return 'Реестр';
    }

    return 'Коррект';
}
