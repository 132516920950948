import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { RestorePasswordActionsType } from './types';

export type RestorePasswordState = {
    error: string | null;
    loadingState: LoadingState;
};

export const initialState: RestorePasswordState = {
    error: null,
    loadingState: 'unknown',
};

export function restorePasswordReducer(
    state: RestorePasswordState = initialState,
    action: RestorePasswordActionsType,
): RestorePasswordState {
    switch (action.type) {
        case actionCreators.restorePasswordStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.restorePasswordSuccess.type:
            return {
                ...state,
                error: null,
                loadingState: action.loadingState,
            };
        case actionCreators.restorePasswordError.type:
            return {
                ...state,
                error: action.error,
                loadingState: action.loadingState,
            };
        default:
            return state;
    }
}
