import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider } from '@alfalab/core-components/divider';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Modal } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';

import { actions } from '#/src/store/registers/register-erf/reducer';
import { erfListSelector, modalIsOpenSelector } from '#/src/store/registers/register-erf/selectors';
import combineRegisterErf from '#/src/utils/combine-register-erf';

import { RegisterErfRow } from './register-erf-row';

export function RegisterErfModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(modalIsOpenSelector);
    const erfList = useSelector(erfListSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(actions.setModalState(false));
    }, [dispatch]);

    const combinedErfList = useMemo(() => {
        if (!erfList) {
            return [];
        }

        return combineRegisterErf(erfList);
    }, [erfList]);

    return (
        <Modal open={modalIsOpen} hasCloser={true} onClose={handleCloseModal} size='l'>
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    Информация об ЭРФ
                </Typography.Title>
                <Gap size='2xl' />
                <Grid.Row justify='between'>
                    <Grid.Col width='5'>
                        <Typography.Text color='secondary'>Дебитор</Typography.Text>
                    </Grid.Col>
                    <Grid.Col width='available'>
                        <Typography.Text color='secondary'>Контракт</Typography.Text>
                    </Grid.Col>
                    <Grid.Col width='auto'>
                        <Typography.Text color='secondary'>ЭРФ</Typography.Text>
                    </Grid.Col>
                </Grid.Row>
                <Gap size='xs' />
                <Divider />
                {combinedErfList.map((erf) => (
                    <RegisterErfRow erf={erf} key={erf.id} />
                ))}
                <Gap size='xs' />
            </Modal.Content>
        </Modal>
    );
}
