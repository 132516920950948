import { ThunkAction } from 'redux-thunk';

import { redirectUserAfterAuthFlow } from '#/src/store/auth/actions';
import { ApplicationState } from '#/src/store/reducer';
import { SystemNotification } from '#/src/types/communication';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { SystemNotifyActionsType } from './types';

export function closeActualSystemNotifications(): ThunkAction<
    void,
    ApplicationState,
    void,
    SystemNotifyActionsType
> {
    return async (dispatch) => {
        dispatch(actionCreators.hideNotifications());
        dispatch(redirectUserAfterAuthFlow());
    };
}

export function fetchActualSystemNotifications(): ThunkAction<
    void,
    ApplicationState,
    void,
    SystemNotifyActionsType
> {
    return async (dispatch) => {
        try {
            const notifications: SystemNotification[] = await fetchers.fetchActualNotifications();

            if (notifications.length) {
                dispatch(actionCreators.showNotifications(notifications));
            }
        } catch (_e) {
            // об ошибке загрузки системных уведомлений уведомлять пользователя не нужно
        }
    };
}
