import { ThunkAction } from 'redux-thunk';
import { SignButtonProps } from '@alfa-bank/corp-sign-ui-react/components/SignButton';
import { DocumentWithContent, DocumentWithSign } from '@alfa-bank/corp-sign-ui-react/types';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { availableStatusesForSign } from '#/src/dictionaries/registers-status';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import {
    currentOrganizationIdSelector,
    currentOrganizationSelector,
} from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import {
    createdSignDataSelector,
    registerIdsSelector,
    signButtonCallbackSelector,
} from '#/src/store/registers/sign-registers/selectors';
import { userNameSelector } from '#/src/store/user/selectors';
import { Organization } from '#/src/types/orgatiozations';
import { RegisterSourceType } from '#/src/types/registers';
import { fetchers } from '#/src/utils/client-api';
import combinePermissions from '#/src/utils/combine-permissions';

import * as actionCreators from './action-creators';
import { SignRegistersActionsType } from './types';

const COMMON_ERROR = 'Реестр(ы) нельзя отправить на финансирование';

export function prepareSignRegistersData(
    ids: number[],
    props: Partial<SignButtonProps>,
): ThunkAction<void, ApplicationState, void, SignRegistersActionsType> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.clearState());
        dispatch(actionCreators.changeSignRegistersLoadingState(LOADING_STATE.LOADING));

        const state = getState();
        const partyTin = currentOrganizationIdSelector(state) as string;

        try {
            const { registerDataList } = await fetchers.fetchRegisters({
                ids,
                needFields: ['id', 'registerSource', 'debtor'],
                needCompress: false,
                partyTin,
                statuses: availableStatusesForSign,
            });
            const registerIds: number[] = [];

            registerDataList?.forEach(({ registerSource, id, debtor }) => {
                if (!(registerSource === RegisterSourceType.REGISTER_FROM_RPD && !debtor)) {
                    registerIds.push(id as number);
                }
            });

            if (registerIds.length) {
                const fullCounts = await fetchers.fetchFullCounts({
                    registerIds,
                    statuses: availableStatusesForSign,
                });

                if (fullCounts?.registerIds?.length) {
                    dispatch(
                        actionCreators.setPreparedSignData({
                            callback: props.onClick,
                            registerIds: fullCounts.registerIds,
                            counts: fullCounts.counts,
                        }),
                    );

                    return;
                }
            }
            dispatch(dispatchErrorNotification(COMMON_ERROR));
            dispatch(actionCreators.changeSignRegistersLoadingState(LOADING_STATE.ERROR));
        } catch (e) {
            dispatch(dispatchErrorNotification(COMMON_ERROR));
            dispatch(actionCreators.changeSignRegistersLoadingState(LOADING_STATE.ERROR));
        }
    };
}

export function createSignRegistersData(): ThunkAction<
    void,
    ApplicationState,
    void,
    SignRegistersActionsType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeSignRegistersLoadingState(LOADING_STATE.LOADING));

        const state = getState();
        const username = userNameSelector(state);
        const registerIds = registerIdsSelector(state);
        const signButtonCallback = signButtonCallbackSelector(state);
        const currentOrganization = currentOrganizationSelector(state) as Organization;

        try {
            if (registerIds.length) {
                const user = await fetchers.fetchUsernameInfo(username);
                const commonUserData = { xpin: user.xpin, upin: currentOrganization.upin };
                const createdSignData = await fetchers.fetchCreateRegisterSignData({
                    financingType: 'FINANCE',
                    signDocuments: registerIds.map((id) => ({
                        id: id.toString(),
                        ...commonUserData,
                    })),
                });
                const permissions = await fetchers.fetchCheckSignPermission(
                    createdSignData.map((item) => ({
                        uid: `${item.signId}:register`,
                        ...commonUserData,
                    })),
                );

                const documents: DocumentWithContent[] = [];
                const filteredRegisterIds: number[] = [];
                const result = combinePermissions(permissions);

                createdSignData.forEach((item) => {
                    if (result[item.signId]) {
                        documents.push({ documentId: item.signId, content: item.data });
                        filteredRegisterIds.push(+item.id);
                    }
                });

                const warningModalIsOpen = Boolean(!documents.length && permissions.length);

                dispatch(
                    actionCreators.setCreatedSignData({
                        documents,
                        createdSignData,
                        filteredRegisterIds,
                        user,
                        permissions,
                        warningModalIsOpen,
                    }),
                );

                if (signButtonCallback && !warningModalIsOpen) {
                    signButtonCallback();
                }

                return;
            }

            dispatch(dispatchErrorNotification(COMMON_ERROR));
            dispatch(actionCreators.clearState());
        } catch (e) {
            dispatch(dispatchErrorNotification(COMMON_ERROR));
            dispatch(actionCreators.clearState());
        }
    };
}

export function sendSignRegistersToFactor(
    documents: DocumentWithSign[],
): ThunkAction<void, ApplicationState, void, SignRegistersActionsType> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeSignRegistersLoadingState(LOADING_STATE.LOADING));

        const state = getState();
        const createdSignData = createdSignDataSelector(state);

        try {
            const dataIds = createdSignData.reduce<Record<string, string>>(
                (arr, item) => ({
                    ...arr,
                    [item.signId]: item.id,
                }),
                {},
            );

            const result = await fetchers.fetchSendSignRegistersToFactor(
                documents.map((item) => ({
                    id: dataIds[item.documentId as string],
                    signId: item.documentId as string,
                    signature: item.sign,
                })),
            );

            dispatch(actionCreators.setResult(result));
        } catch (e) {
            if (e) {
                dispatch(actionCreators.clearState());
                dispatch(dispatchErrorNotification(e, false));
            } else {
                dispatch(actionCreators.setResult(null));
            }
        }
    };
}
