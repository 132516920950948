import {
    GetCommissionsQuery,
    GetCommissionsResponse,
    GetDashDebtorsQuery,
    GetDashDebtorsResponse,
    GetLimitsQuery,
    GetLimitsResponse,
    GetPaymentsQuery,
    GetPaymentsResponse,
    GetReceivablesQuery,
    GetReceivablesResponse,
    GetRefundsQuery,
    GetRefundsResponse,
} from '#/src/server/services/api/dashboard';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '') {
    const fetch = {
        getDashDebtors: fetchFactory<GetDashDebtorsQuery, GetDashDebtorsResponse>(
            `${contextRoot}/secure/nodeapi/getDashDebtors`,
            'GET',
            { allowMultiRequest: true },
        ),
        getReceivables: fetchFactory<GetReceivablesQuery, GetReceivablesResponse>(
            `${contextRoot}/secure/nodeapi/getReceivables`,
            'GET',
            { allowMultiRequest: true },
        ),
        getCommissions: fetchFactory<GetCommissionsQuery, GetCommissionsResponse>(
            `${contextRoot}/secure/nodeapi/getCommissions`,
            'GET',
            { allowMultiRequest: true },
        ),
        getPayments: fetchFactory<GetPaymentsQuery, GetPaymentsResponse>(
            `${contextRoot}/secure/nodeapi/getPayments`,
            'GET',
            { allowMultiRequest: true },
        ),
        getRefunds: fetchFactory<GetRefundsQuery, GetRefundsResponse>(
            `${contextRoot}/secure/nodeapi/getRefunds`,
            'GET',
            { allowMultiRequest: true },
        ),
        getLimits: fetchFactory<GetLimitsQuery, GetLimitsResponse>(
            `${contextRoot}/secure/nodeapi/getLimits`,
            'GET',
            { allowMultiRequest: true },
        ),
    };

    return {
        fetchDashDebtors(sellerTin: string) {
            return fetch.getDashDebtors({ sellerTin });
        },
        fetchReceivables(params: GetReceivablesQuery) {
            return fetch.getReceivables(params);
        },
        fetchCommissions(params: GetCommissionsQuery) {
            return fetch.getCommissions(params);
        },
        fetchPayments(params: GetPaymentsQuery) {
            return fetch.getPayments(params);
        },
        fetchRefunds(params: GetRefundsQuery) {
            return fetch.getRefunds(params);
        },
        fetchLimits(params: GetLimitsQuery) {
            return fetch.getLimits(params);
        },
    };
}
