import { ReactNode } from 'react';

import { CreateRegisterErrorCode } from '#/src/types/registers';

export const createRegisterFromFileErrorsDictionary: Record<CreateRegisterErrorCode, ReactNode> = {
    REGISTER_CANNOT_BE_SAVED:
        'Реестр не может быть сохранен. Загрузите реестр заново или обратитесь в поддержку',
    FILE_CANNOT_BE_SAVED:
        'Файл не может быть сохранен. Загрузите реестр заново или обратитесь в поддержку',
    QUEUE_IS_UNAVAILABLE:
        'Реестр не может быть передан на обработку. Загрузите реестр заново или обратитесь в поддержку',
};
