export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum SortField {
    DATE = 'DATE',
    COST = 'COST',
}

export type SortState = {
    field: SortField;
    order: SortOrder;
};
