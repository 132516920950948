import { createCachedSelector } from 're-reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { supplySelector } from '../selectors';

import { supplyRepaymentsByIdDefault } from './reducer';

const supplyIdSelector = (state: ApplicationState, supplyId: number) => supplyId;

export const supplyRepaymentsStateSelector = (state: ApplicationState) =>
    supplySelector(state).repayments;

export const supplyRepaymentsByIdSelector = createCachedSelector(
    supplyRepaymentsStateSelector,
    supplyIdSelector,
    (supplyRepaymentsMap, supplyId) => supplyRepaymentsMap[supplyId] || supplyRepaymentsByIdDefault,
)(supplyIdSelector);

export const supplyRepaymentsIsLoadingSelector = createCachedSelector(
    supplyRepaymentsByIdSelector,
    (supplyRepaymentsById) => supplyRepaymentsById.loadingState === LOADING_STATE.LOADING,
)(supplyIdSelector);

export const supplyRepaymentsIsUnknownSelector = createCachedSelector(
    supplyRepaymentsByIdSelector,
    (supplyRepaymentsById) => supplyRepaymentsById.loadingState === LOADING_STATE.UNKNOWN,
)(supplyIdSelector);

export const supplyRepaymentsSelector = createCachedSelector(
    supplyRepaymentsByIdSelector,
    (supplyRepaymentsById) => supplyRepaymentsById.repayments,
)(supplyIdSelector);
