import React from 'react';
import { createCn } from 'bem-react-classname';

import { Badge } from '@alfalab/core-components/badge';
import { Plate } from '@alfalab/core-components/plate';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';

import './plate-error.css';

const cn = createCn('plate-error');

type PlateErrorProps = {
    error: React.ReactNode | string;
    dataTestId?: string;
};

export default function PlateError({ error, dataTestId = 'plate-error' }: PlateErrorProps) {
    return (
        <Plate
            dataTestId={dataTestId}
            className={cn()}
            view='negative'
            leftAddons={<Badge view='icon' iconColor='negative' content={<AlertCircleMIcon />} />}
        >
            {error}
        </Plate>
    );
}
