import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '#/src/constants/loading-state';
import { OrganizationShortDto } from '#/src/types/user';

import { fetchCompaniesByType } from './actions';

export type CompanyType = 'debtors' | 'sellers';

export type CompanyTypeState = {
    loadingState: LoadingState;
    entities: OrganizationShortDto[];
};

export type CompaniesState = Record<CompanyType, CompanyTypeState>;

const initialCompanyTypeState: CompanyTypeState = {
    loadingState: 'unknown',
    entities: [],
};

const initialState: CompaniesState = {
    debtors: initialCompanyTypeState,
    sellers: initialCompanyTypeState,
};

export const { reducer: companiesReducer, name } = createSlice({
    name: 'companies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCompaniesByType.pending, (state, action) => {
            state[action.meta.arg.type].loadingState = 'loading';
        });
        builder.addCase(fetchCompaniesByType.fulfilled, (state, action) => {
            state[action.meta.arg.type].loadingState = 'success';
            state[action.meta.arg.type].entities = action.payload;
        });
        builder.addCase(fetchCompaniesByType.rejected, (state, action) => {
            state[action.meta.arg.type].loadingState = 'error';
        });
    },
});
