import { FormType } from './types';

export function validateFormValues(form: FormType, fieldValues: Record<string, string>) {
    let formIsValid = true;
    const validateMessages = Object.keys(fieldValues).reduce<Record<string, string>>(
        (acc, fieldName) => {
            const validMessage = fieldValues[fieldName] ? '' : 'Заполните это поле';

            if (validMessage) {
                formIsValid = false;
            }

            return { ...acc, [fieldName]: validMessage };
        },
        {},
    );

    if (form === 'changePassword') {
        if (
            !validateMessages.newPassword &&
            !validateMessages.newPasswordConfirm &&
            fieldValues.newPasswordConfirm &&
            fieldValues.newPassword !== fieldValues.newPasswordConfirm
        ) {
            formIsValid = false;
            validateMessages.newPassword = 'Пароли не совпадают';
            validateMessages.newPasswordConfirm = 'Пароли не совпадают';
        }
    }

    return { formIsValid, validateMessages };
}
