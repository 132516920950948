import { ThunkAction } from 'redux-thunk';

import { reportFileNames } from '#/src/constants/reports';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import {
    currentOrganizationIdSelector,
    currentOrganizationIsDebtorSelector,
} from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { CachedReport, ReportNames } from '#/src/types/reports';
import { fetchers } from '#/src/utils/client-api';
import { downloadFile } from '#/src/utils/download-file';

import * as actionCreators from './action-creators';
import { CachedReportsActionsType } from './types';

export function fetchCachedReports(): ThunkAction<
    void,
    ApplicationState,
    void,
    CachedReportsActionsType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.getCachedReportsStart());

        try {
            const state = getState();
            const partyTin = currentOrganizationIdSelector(state) as string;
            const isDebtor = currentOrganizationIsDebtorSelector(state);
            const response: Record<string, CachedReport> = await fetchers.fetchCachedReports(
                partyTin,
            );

            dispatch(
                actionCreators.getCachedReportsSuccess(
                    Object.values(response).filter((report) => report.isDebtor === isDebtor),
                ),
            );
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
            dispatch(actionCreators.getCachedReportsError());
        }
    };
}

export function downloadCachedReports(
    partyTin: string,
    reports: CachedReport[],
): ThunkAction<void, ApplicationState, void, CachedReportsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.startDownloadCachedReport());

        try {
            const cachedReportPromises = reports.map(({ id }) =>
                fetchers.fetchDownloadCachedReport({ partyTin, id }),
            );
            const responses = await Promise.all(cachedReportPromises);

            responses.forEach((response, index) => {
                const reportFileName = reportFileNames[reports[index].fieldValuesMap.reportName];

                downloadFile(response, 'application/vnd.ms-excel', `${reportFileName}.xlsx`);
            });
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
        }

        dispatch(actionCreators.endDownloadCachedReport());
    };
}

export function generateCachedReport({
    partyTin,
    isDebtor,
    reportName,
    dateFrom,
    dateTo,
    debtorId,
    documentNumber,
    supplyNum,
    generalAgreementId,
}: {
    partyTin: string;
    isDebtor: boolean;
    reportName: ReportNames | '';
    dateFrom: string;
    dateTo: string;
    debtorId: string;
    documentNumber: string;
    supplyNum: string;
    generalAgreementId: string;
}): ThunkAction<void, ApplicationState, void, CachedReportsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.startGenerateCachedReport());

        try {
            const response: CachedReport = await fetchers.fetchByteReport({
                partyTin,
                isDebtor,
                reportName: encodeURIComponent(reportName) as ReportNames,
                from: encodeURIComponent(dateFrom),
                to: encodeURIComponent(dateTo),
                debtorId: encodeURIComponent(debtorId),
                documentNumber: encodeURIComponent(documentNumber),
                supplyNum: encodeURIComponent(supplyNum),
                generalAgreementId: encodeURIComponent(generalAgreementId),
            });

            dispatch(actionCreators.addRequestedReport(response));
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
            dispatch(actionCreators.getCachedReportsError());
        }

        dispatch(actionCreators.endGenerateCachedReport());
    };
}
