import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import { statusesOptions } from '#/src/dictionaries/supplies-statuses';
import { updateSelectedFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';
import { SupplyStatus } from '#/src/types/supply';

export function StatusesFilter() {
    const dispatch = useDispatch();
    const { statuses } = useSelector(selectedFiltersSelector);

    const handleChangeStatuses = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selectedMultiple }) => {
            dispatch(
                updateSelectedFilters({
                    statuses: selectedMultiple.map(({ key }) => key as SupplyStatus),
                }),
            );
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='statuses-select'
            allowUnselect={true}
            multiple={true}
            block={true}
            placeholder='Статус'
            label='Статус'
            optionsListWidth='field'
            selected={statuses}
            options={statusesOptions}
            onChange={handleChangeStatuses}
        />
    );
}
