import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import {
    CreateCorrectionFromFileResponse,
    CreateRegisterFromFileResponse,
} from '#/src/types/registers';
import { FinancingType } from '#/src/types/sign';

import * as actionCreators from './action-creators';
import { SendRegisterActionsType } from './types';

export type SendRegisterForm = {
    file: File | null;
    partyTin: string | null;
    type: 'debtor' | 'supplier' | null;
    isRegister: boolean;
    financingType: FinancingType | null;
};

export type SendRegisterState = {
    error: string | null;
    loadingState: LoadingState;
    sendModalIsOpen: boolean;
    result: CreateRegisterFromFileResponse | CreateCorrectionFromFileResponse | null;
    resultModalIsOpen: boolean;
    form: SendRegisterForm;
};

const initialState: SendRegisterState = {
    error: null,
    loadingState: 'unknown',
    sendModalIsOpen: false,
    result: null,
    resultModalIsOpen: false,
    form: {
        partyTin: null,
        type: null,
        file: null,
        isRegister: true,
        financingType: null,
    },
};

export function sendRegisterReducer(
    state: SendRegisterState = initialState,
    action: SendRegisterActionsType,
): SendRegisterState {
    switch (action.type) {
        case actionCreators.changeStateSendModal.type:
            return {
                ...state,
                sendModalIsOpen: action.isOpen,
            };
        case actionCreators.changeStateResultModal.type:
            return {
                ...state,
                resultModalIsOpen: action.isOpen,
            };
        case actionCreators.changeStateFile.type:
            return {
                ...state,
                form: {
                    ...state.form,
                    file: action.file,
                },
            };
        case actionCreators.changeStatePartyTin.type:
            return {
                ...state,
                form: {
                    ...state.form,
                    partyTin: action.partyTin,
                },
            };
        case actionCreators.changeStateType.type:
            return {
                ...state,
                form: {
                    ...state.form,
                    type: action.partyType,
                },
            };
        case actionCreators.changeRegisterType.type:
            return {
                ...state,
                form: {
                    ...state.form,
                    isRegister: action.isRegister,
                },
            };
        case actionCreators.changeFinancingType.type:
            return {
                ...state,
                form: {
                    ...state.form,
                    financingType: action.financingType,
                },
            };
        case actionCreators.sendRegisterStart.type:
            return {
                ...state,
                loadingState: LOADING_STATE.LOADING,
            };
        case actionCreators.sendRegisterError.type:
            return {
                ...state,
                loadingState: LOADING_STATE.ERROR,
            };
        case actionCreators.sendRegisterSuccess.type:
            return {
                ...state,
                loadingState: LOADING_STATE.SUCCESS,
                sendModalIsOpen: false,
                resultModalIsOpen: true,
                result: action.data,
            };
        case actionCreators.clearState.type:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
