export enum RestorePasswordErrorNames {
    EMPTY_USERNAME = 'EMPTY_USERNAME',
    EMPTY_KEYWORD = 'EMPTY_KEYWORD',
    WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
    KEYWORD_NOT_FOUND_IN_DB = 'KEYWORD_NOT_FOUND_IN_DB',
    WRONG_KEYWORD = 'WRONG_KEYWORD',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const restorePasswordErrorsDictionary: Record<RestorePasswordErrorNames, string> = {
    [RestorePasswordErrorNames.EMPTY_USERNAME]: 'Не заполнено поле "Логин"',
    [RestorePasswordErrorNames.EMPTY_KEYWORD]: 'Не заполнено поле "Кодовое слово"',
    [RestorePasswordErrorNames.WRONG_CREDENTIALS]: 'Логин указан неверно',
    [RestorePasswordErrorNames.KEYWORD_NOT_FOUND_IN_DB]:
        'Кодовое слово не задано. Обратитесь к менеджеру',
    [RestorePasswordErrorNames.WRONG_KEYWORD]: 'Кодовое слово указано неверно',
    [RestorePasswordErrorNames.UNKNOWN_ERROR]:
        'Операция отклонена. В процессе восстановления пароля возникла ошибка! Вы можете восстановить ваш пароль, позвонив в службу поддержки.',
};
