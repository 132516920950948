import React from 'react';

import { Typography } from '@alfalab/core-components/typography';

import DownloadDocumentsResultModal from '#/src/components/download-documents-result-modal';
import { GENERAL_ERROR_PHRASE } from '#/src/constants/text';

export default function DownloadSupplyResultModal() {
    const title = 'Скачать поставку не получится';
    const text = (
        <React.Fragment>
            <Typography.Text tag='div'>Поставка удалена или содержит вирусы.</Typography.Text>
            <Typography.Text tag='div'>{GENERAL_ERROR_PHRASE}</Typography.Text>
        </React.Fragment>
    );

    return <DownloadDocumentsResultModal text={text} title={title} />;
}
