import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { DebtorsActionsType } from './types';

export type DebtorsState = {
    loadingState: LoadingState;
    data: Record<string, string> | null;
};

const initialState: DebtorsState = {
    data: null,
    loadingState: 'unknown',
};

export function debtorsReducer(
    state: DebtorsState = initialState,
    action: DebtorsActionsType,
): DebtorsState {
    switch (action.type) {
        case actionCreators.getDebtorsStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.getDebtorsSuccess.type:
            return {
                ...state,
                data: action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.getDebtorsError.type:
            return {
                ...state,
                data: null,
                loadingState: action.loadingState,
            };
        default:
            return state;
    }
}
