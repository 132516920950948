import {
    DownloadCachedReportQuery,
    GetByteReportQuery,
    GetByteReportResponse,
    GetCachedReportsQuery,
    GetCachedReportsResponse,
    GetGeneralAgreementNamesQuery,
    GetGeneralAgreementNamesResponse,
    GetOrganizationNamesForReportsQuery,
    GetOrganizationNamesForReportsResponse,
    GetReportNamesQuery,
    GetReportNamesResponse,
} from '#/src/server/services/api/reports';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '') {
    const fetch = {
        getReportNames: fetchFactory<GetReportNamesQuery, GetReportNamesResponse>(
            `${contextRoot}/secure/nodeapi/getReportNames`,
            'GET',
            { allowMultiRequest: true },
        ),
        getOrganizationNamesForReports: fetchFactory<
            GetOrganizationNamesForReportsQuery,
            GetOrganizationNamesForReportsResponse
        >(`${contextRoot}/secure/nodeapi/getOrganizationNamesForReports`, 'GET', {
            allowMultiRequest: true,
        }),
        getGeneralAgreementNames: fetchFactory<
            GetGeneralAgreementNamesQuery,
            GetGeneralAgreementNamesResponse
        >(`${contextRoot}/secure/nodeapi/getGeneralAgreementNames`, 'GET', {
            allowMultiRequest: true,
        }),
        getByteReport: fetchFactory<GetByteReportQuery, GetByteReportResponse>(
            `${contextRoot}/secure/nodeapi/getByteReport`,
            'GET',
            { allowMultiRequest: true },
        ),
        getCachedReports: fetchFactory<GetCachedReportsQuery, GetCachedReportsResponse>(
            `${contextRoot}/secure/nodeapi/getCachedReports`,
            'GET',
            { allowMultiRequest: true },
        ),
        downloadCachedReport: fetchFactory<DownloadCachedReportQuery, Blob>(
            `${contextRoot}/secure/nodeapi/downloadCachedReport`,
            'GET',
            { allowMultiRequest: true, responseType: 'blob' },
        ),
    };

    return {
        fetchReportNames(isDebtor: boolean) {
            return fetch.getReportNames({ isDebtor });
        },
        fetchOrganizationNamesForReports(params: GetOrganizationNamesForReportsQuery) {
            return fetch.getOrganizationNamesForReports(params);
        },
        fetchGeneralAgreementNames(params: GetGeneralAgreementNamesQuery) {
            return fetch.getGeneralAgreementNames(params);
        },
        fetchByteReport(params: GetByteReportQuery) {
            return fetch.getByteReport(params);
        },
        fetchCachedReports(partyTin: string) {
            return fetch.getCachedReports({ partyTin });
        },
        fetchDownloadCachedReport(params: DownloadCachedReportQuery) {
            return fetch.downloadCachedReport(params);
        },
    };
}
