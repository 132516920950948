import createAgreementCache from './agreement-cache';
import createCommunicationFetchers from './communication';
import createDashboardFetchers from './dashboard';
import createDocumentsFetchers from './documents';
import createEmployeeFetchers from './employee';
import createInternalFetchers from './internal';
import createLogoutFetchers from './logout';
import createNavigationFetchers from './navigation';
import createRegisterFetchers from './register';
import createReportsFetchers from './reports';
import createSignFetchers from './sign';
import createSuppliesFetchers from './supplies';
import createSupplyFetchers from './supply';
import createUserFetchers from './user';

export function create(contextRoot = '', csrfCookieName: string) {
    return {
        ...createRegisterFetchers(contextRoot, csrfCookieName),
        ...createSignFetchers(contextRoot, csrfCookieName),
        ...createSupplyFetchers(contextRoot, csrfCookieName),
        ...createCommunicationFetchers(contextRoot),
        ...createUserFetchers(contextRoot),
        ...createAgreementCache(contextRoot, csrfCookieName),
        ...createEmployeeFetchers(contextRoot, csrfCookieName),
        ...createNavigationFetchers(contextRoot),
        ...createDashboardFetchers(contextRoot),
        ...createLogoutFetchers(contextRoot, csrfCookieName),
        ...createSuppliesFetchers(contextRoot),
        ...createReportsFetchers(contextRoot),
        ...createInternalFetchers(contextRoot, csrfCookieName),
        ...createDocumentsFetchers(contextRoot, csrfCookieName),
    };
}
