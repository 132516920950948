import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alfalab/core-components/spinner';

import { ApplicationState } from '#/src/store/reducer';
import { fetchSupplyCorrectionHistory } from '#/src/store/supply/supply-correction-history/actions';
import {
    supplyCorrectionHistoryIsLoadingSelector,
    supplyCorrectionHistoryIsUnknownSelector,
    supplyCorrectionHistorySelector,
} from '#/src/store/supply/supply-correction-history/selectors';

import { SupplyCorrectionHistoryList } from './supply-correction-history-list';

type SupplyCorrectionHistoryProps = {
    supplyId: number;
};

export function SupplyCorrectionHistory({ supplyId }: SupplyCorrectionHistoryProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: ApplicationState) =>
        supplyCorrectionHistoryIsLoadingSelector(state, supplyId),
    );
    const isUnknown = useSelector((state: ApplicationState) =>
        supplyCorrectionHistoryIsUnknownSelector(state, supplyId),
    );
    const history = useSelector((state: ApplicationState) =>
        supplyCorrectionHistorySelector(state, supplyId),
    );

    useEffect(() => {
        dispatch(fetchSupplyCorrectionHistory(supplyId));
    }, [dispatch, supplyId]);

    return (
        <div>
            {(isLoading || isUnknown) && <Spinner size='s' visible={true} />}
            {!(isLoading || isUnknown) && !!history.length && (
                <SupplyCorrectionHistoryList history={history} />
            )}
        </div>
    );
}
