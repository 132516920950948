import React from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Modal } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';

type DownloadSuppliesReportNoticeModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export function DownloadSuppliesReportNoticeModal({
    isOpen,
    onClose,
}: DownloadSuppliesReportNoticeModalProps) {
    return (
        <Modal
            dataTestId='download-supplies-report-notice-modal'
            open={isOpen}
            hasCloser={true}
            size='m'
            onClose={onClose}
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    Невозможно сформировать отчет
                </Typography.Title>
                <Gap size='2xl' />
                <Typography.Text view='primary-small'>
                    В отчет может быть выгружено не более 1000 записей.
                    <br /> Уменьшите количество выбранных поставок
                </Typography.Text>
            </Modal.Content>
            <Modal.Footer>
                <Button dataTestId='cancel-modal-button' size='s' view='primary' onClick={onClose}>
                    Понятно
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
