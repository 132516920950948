import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { GeneralAgreementsActionsType } from './types';

export type GeneralAgreementsState = {
    loadingState: LoadingState;
    data: Record<number, string> | null;
};

const initialState: GeneralAgreementsState = {
    data: null,
    loadingState: 'unknown',
};

export function generalAgreementsReducer(
    state: GeneralAgreementsState = initialState,
    action: GeneralAgreementsActionsType,
): GeneralAgreementsState {
    switch (action.type) {
        case actionCreators.getGeneralAgreementsStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.getGeneralAgreementsSuccess.type:
            return {
                ...state,
                data: action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.getGeneralAgreementsError.type:
            return {
                ...state,
                data: null,
                loadingState: action.loadingState,
            };
        default:
            return state;
    }
}
