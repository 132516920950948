import { LoadingState } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

import { SignRegistersState } from './reducer';

export const clearState = createAction('REGISTERS / SIGN_REGISTERS  / clearState', () => ({}));

export const changeSignRegistersLoadingState = createAction(
    'REGISTERS / SIGN_REGISTERS / changeSignRegistersLoadingState',
    (loadingState: LoadingState) => ({ loadingState }),
);

export const setPreparedSignData = createAction(
    'REGISTERS / SIGN_REGISTERS  / setPreparedSignData',
    (data: Pick<SignRegistersState, 'counts' | 'registerIds' | 'callback'>) => data,
);

type SetCreatedSignDataArguments = Pick<
    SignRegistersState,
    | 'documents'
    | 'createdSignData'
    | 'filteredRegisterIds'
    | 'user'
    | 'permissions'
    | 'warningModalIsOpen'
>;
export const setCreatedSignData = createAction(
    'REGISTERS / SIGN_REGISTERS  / setCreatedSignData',
    (data: SetCreatedSignDataArguments) => data,
);

export const changeAdditionalModalState = createAction(
    'REGISTERS / SIGN_REGISTERS  / changeAdditionalModalState',
    (isOpen: boolean) => ({ isOpen }),
);

export const setResult = createAction(
    'REGISTERS / SIGN_REGISTERS  / setResult',
    (result: SignRegistersState['result']) => ({ result }),
);

export const changeSuccessResultModalState = createAction(
    'REGISTERS / SIGN_REGISTERS  / changeSuccessResultModalState',
    (isOpen: boolean) => ({ isOpen }),
);

export const changeProcessedResultModalState = createAction(
    'REGISTERS / SIGN_REGISTERS  / changeProcessedResultModalState',
    (isOpen: boolean) => ({ isOpen }),
);

export const changeWarningModalState = createAction(
    'REGISTERS / SIGN_REGISTERS  / changeWarningModalState',
    (isOpen: boolean) => ({ isOpen }),
);
