import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SignRegistersControls } from '#/src/containers/pages/registers-pages/sign-registers-controls';
import { availableStatusesForSign } from '#/src/dictionaries/registers-status';
import { currentOrganizationInfoSelector } from '#/src/store/organizations/selectors';
import { fetchRegisterInfo } from '#/src/store/registers/register-info/actions';
import { registerInfoSelector } from '#/src/store/registers/register-info/selectors';

export function RegisterInfoSignSection() {
    const dispatch = useDispatch();
    const register = useSelector(registerInfoSelector);
    const organizationInfo = useSelector(currentOrganizationInfoSelector);

    const handleReloadRegister = useCallback(() => {
        if (register && organizationInfo) {
            dispatch(fetchRegisterInfo(register.id, organizationInfo.tin));
        }
    }, [dispatch, register, organizationInfo]);

    if (register && organizationInfo && availableStatusesForSign.includes(register.status)) {
        return (
            <SignRegistersControls
                onSuccessSign={handleReloadRegister}
                isDebtor={Boolean(register.debtor)}
                selectedIds={[register.id]}
                register={register}
            />
        );
    }

    return null;
}
