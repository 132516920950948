import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { BlankResultModal } from '#/src/components/blank-result-modal';
import {
    changeSuccessResultModalState,
    clearState,
} from '#/src/store/registers/sign-registers/action-creators';
import {
    filteredRegisterIdsSelector,
    resultSelector,
    successResultModalIsOpenSelector,
} from '#/src/store/registers/sign-registers/selectors';
import { RegisterData } from '#/src/types/registers';

import { getSuccessModalImg } from './utils/get-success-modal-img';
import { getSuccessModalText } from './utils/get-success-modal-text';
import { getSuccessModalTitle } from './utils/get-success-modal-title';

type SuccessResultModalProps = {
    isDebtor: boolean;
    onSuccessSign: () => void;
    register?: RegisterData;
};

export function SuccessResultModal({ isDebtor, register, onSuccessSign }: SuccessResultModalProps) {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(successResultModalIsOpenSelector);
    const registerIds = useSelector(filteredRegisterIdsSelector);
    const result = useSelector(resultSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(changeSuccessResultModalState(false));
        onSuccessSign();
    }, [dispatch, onSuccessSign]);

    const handleClearState = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    return (
        <BlankResultModal
            open={modalIsOpen}
            onClose={handleCloseModal}
            onUnmount={handleClearState}
        >
            {getSuccessModalImg(result, registerIds, register)}
            <Typography.Title view='small' font='system' tag='div'>
                {getSuccessModalTitle(result, isDebtor)}
            </Typography.Title>
            <Gap size='s' />
            <Typography.Text tag='p'>
                {getSuccessModalText(result, registerIds, register, isDebtor)}
            </Typography.Text>
            <Gap size='l' />
            <Button view='primary' size='xs' onClick={handleCloseModal}>
                {register ? 'К реестру' : 'К списку реестров'}
            </Button>
            <Gap size='m' />
        </BlankResultModal>
    );
}
