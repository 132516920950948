import { SeriesColumnOptions } from 'highcharts';

import { gradientColorGreen, gradientColorOrange, gradientColorYellow } from './colors';

export const receivablesSeries: SeriesColumnOptions[] = [
    {
        type: 'column',
        name: 'Принято',
        color: gradientColorYellow,
    },
    {
        type: 'column',
        name: 'Профинансировано',
        color: gradientColorOrange,
    },
    {
        type: 'column',
        name: 'Оплачено',
        color: gradientColorGreen,
    },
];

export const commissionsSeries: SeriesColumnOptions[] = [
    {
        type: 'column',
        name: 'Обработка документов',
        color: gradientColorYellow,
    },
    {
        type: 'column',
        name: 'ФУДЗ',
        color: gradientColorOrange,
    },
    {
        type: 'column',
        name: 'Комиссия за финансирование',
        color: gradientColorGreen,
    },
];

export const paymentsSeries: SeriesColumnOptions[] = [
    {
        type: 'column',
        name: 'Оплачено клиентом',
        color: gradientColorOrange,
    },
    {
        type: 'column',
        name: 'Оплачено дебитором',
        color: gradientColorGreen,
    },
];

export const refundsSeries: SeriesColumnOptions[] = [
    {
        type: 'column',
        name: 'Оплачено клиентом',
        color: gradientColorYellow,
    },
    {
        type: 'column',
        name: 'Возврат по ранее оплаченым',
        color: gradientColorOrange,
    },
];
