import { createCachedSelector } from 're-reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { supplySelector } from '../selectors';

import { supplyCorrectionHistoryByIdDefault } from './reducer';

const supplyIdSelector = (state: ApplicationState, supplyId: number) => supplyId;

export const supplyCorrectionHistoryStateSelector = (state: ApplicationState) =>
    supplySelector(state).correctionHistory;

export const supplyCorrectionHistoryByIdSelector = createCachedSelector(
    supplyCorrectionHistoryStateSelector,
    supplyIdSelector,
    (supplyCorrectionHistoryMap, supplyId) =>
        supplyCorrectionHistoryMap[supplyId] || supplyCorrectionHistoryByIdDefault,
)(supplyIdSelector);

export const supplyCorrectionHistoryIsLoadingSelector = createCachedSelector(
    supplyCorrectionHistoryByIdSelector,
    (supplyCorrectionHistoryById) =>
        supplyCorrectionHistoryById.loadingState === LOADING_STATE.LOADING,
)(supplyIdSelector);

export const supplyCorrectionHistoryIsUnknownSelector = createCachedSelector(
    supplyCorrectionHistoryByIdSelector,
    (supplyCorrectionHistoryById) =>
        supplyCorrectionHistoryById.loadingState === LOADING_STATE.UNKNOWN,
)(supplyIdSelector);

export const supplyCorrectionHistorySelector = createCachedSelector(
    supplyCorrectionHistoryByIdSelector,
    (supplyCorrectionHistoryById) => supplyCorrectionHistoryById.history,
)(supplyIdSelector);
