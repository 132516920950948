import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import FatalErrorSvg from 'arui-private/icons/error.svg';

import { BlankResultModal } from '#/src/components/blank-result-modal';
import {
    changeEmptyFieldsWarningModalState,
    clearState,
} from '#/src/store/supplies/sign-supplies/action-creators';
import { emptyFieldsWarningModalIsOpenSelector } from '#/src/store/supplies/sign-supplies/selectors';

export function EmptyFieldsWarningModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(emptyFieldsWarningModalIsOpenSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(changeEmptyFieldsWarningModalState(false));
    }, [dispatch]);

    const handleClearState = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    return (
        <BlankResultModal
            open={modalIsOpen}
            onClose={handleCloseModal}
            onUnmount={handleClearState}
        >
            <img src={FatalErrorSvg} alt='' />
            <Gap size='l' />
            <Typography.Title view='small' font='system' tag='div'>
                По поставкам заполнены не все данные
            </Typography.Title>
            <Gap size='s' />
            <Typography.Text view='primary-medium' tag='p'>
                Отредактируйте поставки и попробуйте подписать ещё раз
            </Typography.Text>
            <Gap size='l' />
            <Button view='primary' size='s' onClick={handleCloseModal}>
                К списку поставок
            </Button>
            <Gap size='m' />
        </BlankResultModal>
    );
}
