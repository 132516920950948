import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import { DebtorLimit } from '#/src/types/dashboard-reports';

import * as actionCreators from './action-creators';
import { DashboardLimitsActionsType } from './types';

export type DashboardLimitsState = {
    loadingState: LoadingState;
    list: DebtorLimit[];
    info: {
        totalProdLim: number;
        totalDebtorLim: number;
        totalAvailableLimit: number;
    };
};

const initialState: DashboardLimitsState = {
    loadingState: LOADING_STATE.UNKNOWN,
    list: [],
    info: {
        totalProdLim: 0,
        totalDebtorLim: 0,
        totalAvailableLimit: 0,
    },
};

export function dashboardLimitsReducer(
    state: DashboardLimitsState = initialState,
    action: DashboardLimitsActionsType,
): DashboardLimitsState {
    switch (action.type) {
        case actionCreators.changeDashboardLimitsLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.setDashboardLimits.type:
            return {
                ...state,
                ...action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.clearLimits.type:
            return {
                ...initialState,
                loadingState: state.loadingState,
            };
        default:
            return state;
    }
}
