import React from 'react';
import { createCn } from 'bem-react-classname';

import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';

import { CountFilter } from '#/src/dictionaries/reports-dashboard';

import ChartAmount from '../../chart-amount';
import { ItemInfo } from '../get-total-info-items';

import './total-info-item.css';

type TotalInfoItemProps = {
    byCount: CountFilter;
    info: ItemInfo;
    isLoading: boolean;
};

const cn = createCn('total-info-item');

export default function TotalInfoItem({
    info: { value, name, color },
    byCount,
    isLoading,
}: TotalInfoItemProps) {
    return (
        <div className={cn({ color })}>
            <Typography.Text
                tag='div'
                view={color === 'none' ? 'secondary-large' : 'secondary-medium'}
                color={color === 'none' ? 'primary' : 'secondary'}
                className={cn('name')}
            >
                <Skeleton visible={isLoading}>{name}</Skeleton>
            </Typography.Text>
            <Skeleton visible={isLoading}>
                <ChartAmount amount={value as number} byCount={byCount} styled={true} />
            </Skeleton>
        </div>
    );
}
