import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Modal } from '@alfalab/core-components/modal';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { updateSupplies } from '#/src/store/supplies/supplies-editing/actions';
import { actions } from '#/src/store/supplies/supplies-editing/reducer';
import {
    editedDebtorsListSelector,
    editModalIsOpenSelector,
    savingAvailableSelector,
    suppliesEditingIsLoadingSelector,
} from '#/src/store/supplies/supplies-editing/selectors';

import { SupplyAgreementFieldRow } from './supply-agreement-field-row';

export function EditModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(editModalIsOpenSelector);
    const editedDebtorsList = useSelector(editedDebtorsListSelector);
    const savingAvailable = useSelector(savingAvailableSelector);
    const suppliesEditingIsLoading = useSelector(suppliesEditingIsLoadingSelector);
    const sellerTin = useSelector(currentOrganizationIdSelector) as string;

    const handleCloseModal = useCallback(() => {
        dispatch(actions.changeEditModalState(false));
    }, [dispatch]);

    const handleClearState = useCallback(() => {
        dispatch(actions.clearState());
    }, [dispatch]);

    const handleUpdateSupplies = useCallback(() => {
        dispatch(updateSupplies());
    }, [dispatch]);

    return (
        <Modal
            dataTestId='supplies-edit-modal'
            open={modalIsOpen}
            hasCloser={true}
            onClose={handleCloseModal}
            size='l'
            onUnmount={handleClearState}
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    Редактирование
                </Typography.Title>
                <Gap size='m' />
                <Space size={8} direction='vertical' fullWidth={true}>
                    {editedDebtorsList.map(({ debtorTin }) => (
                        <SupplyAgreementFieldRow
                            key={debtorTin}
                            debtorTin={debtorTin}
                            sellerTin={sellerTin}
                        />
                    ))}
                </Space>
                <Gap size='l' />
                <Space size={8} direction='horizontal'>
                    <Button
                        dataTestId='supplies-edit-modal-save-btn'
                        loading={suppliesEditingIsLoading}
                        view='primary'
                        size='xs'
                        onClick={handleUpdateSupplies}
                        disabled={!savingAvailable}
                    >
                        Сохранить
                    </Button>
                    <Button
                        dataTestId='supplies-edit-modal-close-btn'
                        view='tertiary'
                        size='xs'
                        onClick={handleCloseModal}
                    >
                        Отмена
                    </Button>
                </Space>
            </Modal.Content>
        </Modal>
    );
}
