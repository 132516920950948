import endOfWeek from 'date-fns/end_of_iso_week';
import endOfISOYear from 'date-fns/end_of_iso_year';
import endOfQuarter from 'date-fns/end_of_quarter';
import getISOWeek from 'date-fns/get_iso_week';
import getISOYear from 'date-fns/get_iso_year';
import getQuarter from 'date-fns/get_quarter';
import setISOWeek from 'date-fns/set_iso_week';
import setISOYear from 'date-fns/set_iso_year';
import setQuarter from 'date-fns/set_quarter';
import startOfWeek from 'date-fns/start_of_iso_week';
import startOfISOYear from 'date-fns/start_of_iso_year';
import startOfQuarter from 'date-fns/start_of_quarter';

import { DATE_ISO_FORMAT } from '#/src/constants/date';
import { PeriodFilter } from '#/src/dictionaries/reports-dashboard';
import { convertUnixTimeToDateString } from '#/src/utils/date-converter';
import getDateForDashboardByPeriod from '#/src/utils/get-date-for-dashboard-by-period';

export default function getDateForDashboardByStep(
    newStep: number,
    currentStep: number,
    dateFrom: string,
    period: PeriodFilter,
) {
    if (!newStep) {
        return getDateForDashboardByPeriod(period);
    }

    const currentDateFrom = new Date(dateFrom);
    let newDateFrom = dateFrom;
    let newDateTo = dateFrom;

    if (PeriodFilter.WEEK === period) {
        const currentNumberWeek = getISOWeek(currentDateFrom);

        let date = setISOWeek(currentDateFrom, currentNumberWeek - 1);

        if (newStep > currentStep) {
            date = setISOWeek(currentDateFrom, currentNumberWeek + 1);
        }
        newDateFrom = convertUnixTimeToDateString(startOfWeek(date), DATE_ISO_FORMAT);
        newDateTo = convertUnixTimeToDateString(endOfWeek(date), DATE_ISO_FORMAT);
    } else if (PeriodFilter.QUARTER === period) {
        const currentNumberQuarter = getQuarter(currentDateFrom);

        let date = setQuarter(currentDateFrom, currentNumberQuarter - 1);

        if (newStep > currentStep) {
            date = setQuarter(currentDateFrom, currentNumberQuarter + 1);
        }
        newDateFrom = convertUnixTimeToDateString(startOfQuarter(date), DATE_ISO_FORMAT);
        newDateTo = convertUnixTimeToDateString(endOfQuarter(date), DATE_ISO_FORMAT);
    } else if (PeriodFilter.YEAR === period) {
        const currentNumberYear = getISOYear(currentDateFrom);

        let date = setISOYear(currentDateFrom, currentNumberYear - 1);

        if (newStep > currentStep) {
            date = setISOYear(currentDateFrom, currentNumberYear + 1);
        }
        newDateFrom = convertUnixTimeToDateString(startOfISOYear(date), DATE_ISO_FORMAT);
        newDateTo = convertUnixTimeToDateString(endOfISOYear(date), DATE_ISO_FORMAT);
    }

    return { dateFrom: newDateFrom, dateTo: newDateTo };
}
