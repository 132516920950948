import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { IconButton } from '@alfalab/core-components/icon-button';
import { LogOutMIcon } from '@alfalab/icons-glyph/LogOutMIcon';
import Header from 'arui-private/core/header';

import getHeaderPropsByPathname from '#/src/containers/app/app-header/get-header-props-by-pathname';
import { AppOrganizationSelect } from '#/src/containers/app/app-organization-select';
import { logOut } from '#/src/store/auth/actions';
import { pathnameSelector } from '#/src/store/router/selectors';
import { contextRootSelector } from '#/src/store/settings/selectors';
import { ViewPageType } from '#/src/types/pages';

import AppMenu from '../app-menu';

import './app-header.css';

type AppHeaderProps = {
    viewPageType: ViewPageType;
};

const cn = createCn('app-header');

export function AppHeader({ viewPageType }: AppHeaderProps) {
    const dispatch = useDispatch();
    const contextRoot = useSelector(contextRootSelector);
    const pathname = useSelector(pathnameSelector);
    const handleLogout = useCallback(() => {
        dispatch(logOut());
    }, [dispatch]);
    const { customerSelectDisabled = false, ...headerPropsByPathname } = useMemo(
        () => getHeaderPropsByPathname(dispatch, pathname),
        [dispatch, pathname],
    );

    return (
        <Header
            data-test-id='app-header'
            className={cn()}
            rootUrl={contextRoot || '/'}
            menu={<AppMenu />}
            rightContent={
                <div className={cn('right-content', { viewPageType })}>
                    {viewPageType !== 'pageWithIframe' && (
                        <AppOrganizationSelect disabled={customerSelectDisabled} />
                    )}
                    <IconButton
                        className={cn('logout', { viewPageType })}
                        onClick={handleLogout}
                        view='secondary'
                        icon={LogOutMIcon}
                    />
                </div>
            }
            {...headerPropsByPathname}
        />
    );
}
