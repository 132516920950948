import { createSelector } from 'reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { suppliesModelsSelector } from '../selectors';

export const suppliesListStateSelector = (state: ApplicationState) =>
    suppliesModelsSelector(state).suppliesList;

export const suppliesSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).entities;

export const serverRequestTimeSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).serverRequestTime || '';

export const suppliesIdsSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).ids;

export const selectedIdsSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).selectedIds;

export const needUpdateSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).needUpdate;

export const suppliesSortSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).sort;

export const suppliesCountSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).ids.length;

export const suppliesPaginationSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).pagination;

export const suppliesListLoadingStateSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).loadingState;

export const allTotalPriceSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).allTotalPrice;

export const totalPriceSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).totalPrice;

export const availableSuppliesSelector = (state: ApplicationState) =>
    suppliesListStateSelector(state).availableSupplies;

export const entitiesIsLoadingSelector = createSelector(
    suppliesListLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.LOADING,
);

export const entitiesIsErrorSelector = createSelector(
    suppliesListLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.ERROR,
);

export const entitiesIsUnknownSelector = createSelector(
    suppliesListLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.UNKNOWN,
);

export const areAllSuppliesSelectedSelector = createSelector(
    suppliesIdsSelector,
    selectedIdsSelector,
    (ids, selectedIds) => ids.length === selectedIds.length,
);

export const currentAmountForSelectedSuppliesSelector = createSelector(
    selectedIdsSelector,
    allTotalPriceSelector,
    (selectedIds, allTotalPrice) => {
        let amount = 0;

        for (let i = 0; i < selectedIds.length; i++) {
            if (!Number.isNaN(+allTotalPrice[selectedIds[i]])) {
                amount += +allTotalPrice[selectedIds[i]];
            }
        }

        return amount;
    },
);

export const availableSuppliesInfoSelector = createSelector(
    availableSuppliesSelector,
    selectedIdsSelector,
    (availableSupplies, selectedIds) => {
        const ids = [];
        let amount = 0;

        for (let i = 0; i < selectedIds.length; i++) {
            const key = selectedIds[i];

            if (availableSupplies.available[key]) {
                ids.push(+key);
                amount += availableSupplies.available[key];
            }
        }

        return { ids, amount };
    },
);
