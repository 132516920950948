import { BaseSelectChangePayload, OptionShape } from '@alfalab/core-components/select/typings';

export default function getNewSelectedOptionsForCustomAutocomplete(
    options: OptionShape[],
    value: string,
    selected: string | string[] | undefined,
) {
    let newSelectedOptions: BaseSelectChangePayload | null = null;

    if (Array.isArray(selected) && selected.length) {
        if (value) {
            const inputValues = value.replace(/, /g, ',').split(',');
            const selectedOptions = options.filter(
                (option) => option.content && inputValues.includes(option.content.toString()),
            );

            if (
                selectedOptions.length !== selected.length ||
                selectedOptions.some((item) => !selected.includes(item.key))
            ) {
                newSelectedOptions = {
                    selected: null,
                    selectedMultiple: selectedOptions,
                    initiator: selectedOptions[0],
                };
            }
        } else {
            newSelectedOptions = { selected: null, selectedMultiple: [], initiator: null };
        }
    }

    return newSelectedOptions;
}
