import React from 'react';
import { Route, RouteComponentProps, RouteProps, Switch } from 'react-router';

import App from '#/src/containers/app';
import { ErrorWrapper } from '#/src/containers/error-wrapper';
import NotFound from '#/src/containers/not-found';
import AuthPage from '#/src/containers/pages/auth-page';
import ChangePasswordPage from '#/src/containers/pages/change-password-page';
import { DashboardPage } from '#/src/containers/pages/dashboard-page';
import InvoiceRegistryEdiSoftPage from '#/src/containers/pages/invoice-registry-edisoft-page';
import { RegisterInfoPage } from '#/src/containers/pages/registers-pages/register-info-page';
import { RegistersListPage } from '#/src/containers/pages/registers-pages/registers-list-page';
import ReportsXlsPage from '#/src/containers/pages/reports-xls-page';
import RestoreLoginPage from '#/src/containers/pages/restore-login-page';
import RestorePasswordPage from '#/src/containers/pages/restore-password-page';
import SettingsPage from '#/src/containers/pages/settings-page';
import SuppliesEdiSoftPage from '#/src/containers/pages/supplies-edisoft-page';
import { SuppliesListPage } from '#/src/containers/pages/supplies-pages/supplies-list-page';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { ViewPageType } from '#/src/types/pages';
import { History, StaticContext } from '#/src/types/routing';

const AppWrappedComponent = (
    Component: React.ComponentClass | React.FC<RouteProps>,
    props: RouteComponentProps,
    viewPageType: ViewPageType = 'oldCorporatePage',
) => (
    <App {...props} viewPageType={viewPageType}>
        <Component {...props} />
    </App>
);

export default (history: History, staticContext?: StaticContext) => (
    <ErrorWrapper history={history}>
        <Switch>
            <Route exact={true} path={routesDictionary.authPage.path} component={AuthPage} />
            <Route
                exact={true}
                path={routesDictionary.restoreLogin.path}
                component={RestoreLoginPage}
            />
            <Route
                exact={true}
                path={routesDictionary.restorePassword.path}
                component={RestorePasswordPage}
            />
            <Route
                exact={true}
                path={routesDictionary.changePassword.path}
                component={ChangePasswordPage}
            />
            <Route
                exact={true}
                path={routesDictionary.reportPage.path}
                render={(props) => AppWrappedComponent(DashboardPage, props, 'newCorporatePage')}
            />
            <Route
                exact={true}
                path={routesDictionary.suppliesEdiSoftPage.path}
                render={(props) =>
                    AppWrappedComponent(SuppliesEdiSoftPage, props, 'pageWithIframe')
                }
            />
            <Route
                exact={true}
                path={routesDictionary.invoiceRegistryEdiSoftPage.path}
                render={(props) =>
                    AppWrappedComponent(InvoiceRegistryEdiSoftPage, props, 'pageWithIframe')
                }
            />
            <Route
                exact={true}
                path={routesDictionary.suppliesPage.pathAlias}
                render={(props) => AppWrappedComponent(SuppliesListPage, props, 'newCorporatePage')}
            />
            <Route
                exact={true}
                path={routesDictionary.settingsPage.path}
                render={(props) => AppWrappedComponent(SettingsPage, props)}
            />
            <Route
                exact={true}
                path={routesDictionary.reportsXlsPage.path}
                render={(props) => AppWrappedComponent(ReportsXlsPage, props)}
            />
            <Route
                exact={true}
                path={routesDictionary.registersPage.path}
                render={(props) => AppWrappedComponent(RegistersListPage, props)}
            />
            <Route
                exact={true}
                path={routesDictionary.registerInfoPage.path}
                render={(props) => AppWrappedComponent(RegisterInfoPage, props)}
            />
            <NotFound history={history} staticContext={staticContext} />
        </Switch>
    </ErrorWrapper>
);
