import { OptionShape } from '@alfalab/core-components/select/typings';

import { SupplySource } from '#/src/types/supply';

export const supplySourceDictionary: Record<SupplySource, string> = {
    ALL: 'Все',
    EDI: 'ЭДО',
    RPD: 'РПД',
    REGISTER: 'Реестр',
};

export const sourceOptions: OptionShape[] = Object.keys(supplySourceDictionary).map(
    (key: SupplySource) => ({
        key,
        value: key,
        content: supplySourceDictionary[key],
    }),
);
