import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Checkbox, CheckboxProps } from '@alfalab/core-components/checkbox';
import { Table } from '@alfalab/core-components/table';
import { TCellProps } from '@alfalab/core-components/table/components/tcell';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';
import { ChevronUpSIcon } from '@alfalab/icons-glyph/ChevronUpSIcon';

import { supplyDocumentTypeDictionary } from '#/src/dictionaries/supply-document-type';
import { setSelectedSupplies } from '#/src/store/supplies/supplies-list/action-creators';
import { selectedIdsSelector } from '#/src/store/supplies/supplies-list/selectors';
import { availableCellsSelector } from '#/src/store/supplies/table-cells-settings/selectors';
import { SupplyDto } from '#/src/types/supply';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import { SupplyRowAmount } from '../supply-row-amount';
import { SupplyRowSource } from '../supply-row-source';
import { SupplyRowStatus } from '../supply-row-status';

import './supply-row-cells.css';

const cn = createCn('supply-row-cells');

type SupplyRowCellsProps = {
    supply: SupplyDto;
    isExpanded: boolean;
};

export function SupplyRowCells({ supply, isExpanded }: SupplyRowCellsProps) {
    const dispatch = useDispatch();
    const availableCells = useSelector(availableCellsSelector);
    const selected = useSelector(selectedIdsSelector);

    const handleSelectSupply = useCallback<NonNullable<CheckboxProps['onClick']>>(() => {
        if (supply.id) {
            const selectedSupplies = [...selected];
            const index = selectedSupplies.indexOf(supply.id);

            if (index === -1) {
                selectedSupplies.push(supply.id);
            } else {
                selectedSupplies.splice(index, 1);
            }

            dispatch(setSelectedSupplies(selectedSupplies));
        }
    }, [dispatch, selected, supply.id]);

    const handleClickCheckboxCell = useCallback<NonNullable<TCellProps['onClick']>>((event) => {
        event.stopPropagation();
    }, []);

    return (
        <React.Fragment>
            {[
                <Table.TCell onClick={handleClickCheckboxCell}>
                    <Checkbox
                        size='s'
                        onClick={handleSelectSupply}
                        checked={Boolean(supply.id && selected.includes(supply.id))}
                    />
                </Table.TCell>,
                <Table.TCell className={cn('document-number')}>
                    <Typography.Text view='secondary-large' tag='div'>
                        {supply.amountCertificationDocumentNumber}
                    </Typography.Text>
                    <Typography.Text view='secondary-large' color='secondary' tag='div'>
                        {(supply.amountCertificationDocumentType &&
                            supplyDocumentTypeDictionary[supply.amountCertificationDocumentType]) ||
                            supply.amountCertificationDocumentType}
                    </Typography.Text>
                </Table.TCell>,
                <Table.TCell>
                    <SupplyRowAmount
                        supplyAmount={supply.supplyAmount}
                        supplyAmountCorrection={supply.supplyAmountCorrection}
                        requestedAmount={supply.requestedAmount}
                    />
                </Table.TCell>,
                availableCells.includes('date') && (
                    <Table.TCell>
                        <Typography.Text view='secondary-large' tag='div'>
                            {supply.amountCertificationDocumentDate
                                ? formatDateForCalendar(
                                      new Date(supply.amountCertificationDocumentDate),
                                  )
                                : ''}
                        </Typography.Text>
                    </Table.TCell>
                ),
                availableCells.includes('debtor') && (
                    <Table.TCell>
                        <Typography.Text view='secondary-large' tag='div'>
                            {supply.debtorName}
                        </Typography.Text>
                    </Table.TCell>
                ),
                availableCells.includes('contract') && (
                    <Table.TCell>
                        <Typography.Text view='secondary-large' tag='div'>
                            {supply.supplyAgreementNumber}
                        </Typography.Text>
                    </Table.TCell>
                ),
                availableCells.includes('supplySource') && (
                    <Table.TCell>
                        <SupplyRowSource
                            source={supply.supplySource}
                            registerId={supply.registerId}
                            registerNumber={supply.registerNumber}
                            rpdNumber={supply.rpdNumber}
                        />
                    </Table.TCell>
                ),
                <Table.TCell>
                    <SupplyRowStatus status={supply.status} financingType={supply.financingType} />
                </Table.TCell>,
                <Table.TCell>{isExpanded ? <ChevronUpSIcon /> : <ChevronDownSIcon />}</Table.TCell>,
            ].filter((cell) => Boolean(cell))}
        </React.Fragment>
    );
}
