import { batch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { NotificationsActionsType } from '#/src/store/notifications/types';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { GetLimitsQuery } from '#/src/types/dashboard-reports';
import { fetchers } from '#/src/utils/client-api';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import * as actionCreators from './action-creators';
import { DashboardLimitsActionsType } from './types';

export function fetchDashboardLimits(): ThunkAction<
    void,
    ApplicationState,
    void,
    DashboardLimitsActionsType | NotificationsActionsType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeDashboardLimitsLoadingState(LOADING_STATE.LOADING));

        try {
            const state = getState();
            const currentOrganizationId = currentOrganizationIdSelector(state) as string;

            const requestParams: GetLimitsQuery = {
                sellerTin: currentOrganizationId,
                date: formatDateForCalendar(Date.now()),
            };

            const { limits, ...info } = await fetchers.fetchLimits(requestParams);

            dispatch(actionCreators.setDashboardLimits({ list: limits, info }));
        } catch (error) {
            batch(() => {
                dispatch(dispatchErrorNotification(error));
                dispatch(actionCreators.changeDashboardLimitsLoadingState(LOADING_STATE.ERROR));
                dispatch(actionCreators.clearLimits());
            });
        }
    };
}
