import React from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import Heading from 'arui-feather/heading';
import Link from 'arui-feather/link';
import TabItem from 'arui-feather/tab-item';
import Tabs from 'arui-feather/tabs';

import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { userProfileSelector } from '#/src/store/user/selectors';

import './settings-page.css';

const cn = createCn('settings-page');

export default function SettingsPage() {
    const profile = useSelector(userProfileSelector);

    return (
        <div className={cn()}>
            <Tabs>
                <TabItem checked={true}>Ваш профиль</TabItem>
            </Tabs>

            <Heading size='s' className={cn('profile-name')}>
                {profile.fullName}
            </Heading>

            <Link
                data-test-id='change-password-link'
                url={routesDictionary.changePassword.path}
                size='s'
            >
                <Button>Смена пароля</Button>
            </Link>
        </div>
    );
}
