export const DATE_ISO_FORMAT = 'YYYY-MM-DD';
export const DATE_SHORT_FORMAT = 'DD.MM.YY';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const MINUTES_IN_MONTH = 43200;
export const MINUTES_IN_YEAR = 525600;

export enum PeriodDictionary {
    Today = 'today',
    Yesterday = 'yesterday',
    Week = 'week',
    Month = 'month',
    Custom = 'custom',
}

export enum PeriodDictionaryRus {
    Today = 'Сегодня',
    Yesterday = 'Вчера',
    Week = 'Неделя',
    Month = 'Месяц',
}
