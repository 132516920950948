import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Modal } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';
import ConnectedSvg from 'arui-private/icons/connected.svg';
import FatalErrorSvg from 'arui-private/icons/error.svg';

import { createRegisterFromFileErrorsDictionary } from '#/src/dictionaries/create-register-from-file-errors';
import { changeOrganizationForPage } from '#/src/store/organizations/actions';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { appliedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';
import { fetchRegistersByRole } from '#/src/store/registers/registers-list/actions';
import {
    changeStateResultModal,
    clearState,
} from '#/src/store/registers/send-register/action-creators';
import {
    partyTinSelector,
    resultModalIsOpenSelector,
    resultSelector,
    typeSelector,
} from '#/src/store/registers/send-register/selectors';
import { CreateRegisterErrorCode } from '#/src/types/registers';

import './result-modal.css';

const cn = createCn('result-modal');

export function ResultModal() {
    const dispatch = useDispatch();
    const currentOrganizationId = useSelector(currentOrganizationIdSelector);
    const partyTin = useSelector(partyTinSelector);
    const type = useSelector(typeSelector);
    const { role } = useSelector(appliedFiltersSelector);
    const resultModalIsOpen = useSelector(resultModalIsOpenSelector);
    const result = useSelector(resultSelector);

    const handleMoveToRegistersList = useCallback(() => {
        if (partyTin && type) {
            if (partyTin !== currentOrganizationId || role !== type) {
                dispatch(changeOrganizationForPage(partyTin, type));
            } else {
                dispatch(fetchRegistersByRole(type));
            }
            dispatch(changeStateResultModal(false));
        }
    }, [dispatch, currentOrganizationId, role, type, partyTin]);

    const handleClearFormState = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    const content = useMemo(() => {
        const data: {
            img: typeof FatalErrorSvg | typeof ConnectedSvg;
            text: React.ReactNode;
            title: React.ReactNode;
            note: React.ReactNode;
        } = {
            img: ConnectedSvg,
            text: '',
            title: 'Реестр передан на обработку',
            note: 'Для передачи реестра в банк подпишите его',
        };

        if (result?.errorCode) {
            data.img = FatalErrorSvg;
            data.title = 'Дальнейшая обработка реестра невозможна';
            data.text =
                createRegisterFromFileErrorsDictionary[result.errorCode as CreateRegisterErrorCode];
            data.note = '';
        }

        return data;
    }, [result]);

    useEffect(
        () => () => {
            dispatch(clearState());
        },
        [dispatch],
    );

    return (
        <Modal
            size='m'
            open={resultModalIsOpen}
            hasCloser={true}
            onClose={handleMoveToRegistersList}
            dataTestId='result-modal'
            onUnmount={handleClearFormState}
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content className={cn()}>
                <img src={content.img} alt='' />
                <Typography.Title view='small' font='system' tag='div'>
                    {content.title}
                </Typography.Title>
                <Gap size='m' />
                <Typography.Text tag='div'>{content.text}</Typography.Text>
                <Typography.Text weight='bold' tag='div' color='accent'>
                    {content.note}
                </Typography.Text>
                <Gap size='m' />
                <Button
                    data-test-id='registers-link'
                    size='xs'
                    view='primary'
                    className={cn('modal-move-to-register-btn')}
                    onClick={handleMoveToRegistersList}
                >
                    К списку реестров
                </Button>
            </Modal.Content>
        </Modal>
    );
}
