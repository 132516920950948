import React, { useCallback } from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';
import WaitSvg from 'arui-private/icons/wait.svg';

import FormButton from '#/src/components/form-button';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';

import './restore-password-success.css';

const cn = createCn('restore-password-success');

export default function RestorePasswordSuccess() {
    const handleMoveToAuthPage = useCallback(() => {
        window.location.assign(routesDictionary.authPage.path);
    }, []);

    return (
        <React.Fragment>
            <img src={WaitSvg} alt='Успех!' />
            <Typography.Title tag='div' view='small' className={cn('title')}>
                Успех!
            </Typography.Title>
            <Typography.Text view='primary-medium' tag='p' className={cn('text')}>
                Мы отправили пароль на ваш телефон
            </Typography.Text>
            <FormButton form='restorePassword' text='Хорошо' onClick={handleMoveToAuthPage} />
        </React.Fragment>
    );
}
