import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alfalab/core-components/spinner';

import { ApplicationState } from '#/src/store/reducer';
import { fetchSupplyCertifications } from '#/src/store/supply/supply-certificates/actions';
import {
    supplyCertificatesIsLoadingSelector,
    supplyCertificatesIsUnknownSelector,
    supplyCertificatesSelector,
} from '#/src/store/supply/supply-certificates/selectors';

import { SupplyCertificatesList } from './supply-certificates-list';

type SupplyCertificatesProps = {
    supplyId: number;
};

export function SupplyCertificates({ supplyId }: SupplyCertificatesProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: ApplicationState) =>
        supplyCertificatesIsLoadingSelector(state, supplyId),
    );
    const isUnknown = useSelector((state: ApplicationState) =>
        supplyCertificatesIsUnknownSelector(state, supplyId),
    );
    const certificates = useSelector((state: ApplicationState) =>
        supplyCertificatesSelector(state, supplyId),
    );

    useEffect(() => {
        dispatch(fetchSupplyCertifications(supplyId));
    }, [dispatch, supplyId]);

    return (
        <div>
            {(isLoading || isUnknown) && <Spinner size='s' visible={true} />}
            {!(isLoading || isUnknown) && !!certificates.length && (
                <SupplyCertificatesList certificates={certificates} />
            )}
        </div>
    );
}
