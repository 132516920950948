import differenceInMinutes from 'date-fns/difference_in_minutes';
import format from 'date-fns/format';
import getHours from 'date-fns/get_hours';
import getMinutes from 'date-fns/get_minutes';
import isToday from 'date-fns/is_today';
import isValid from 'date-fns/is_valid';
import isYesterday from 'date-fns/is_yesterday';
import ru from 'date-fns/locale/ru';

import {
    DATE_FORMAT,
    DATE_ISO_FORMAT,
    MINUTES_IN_YEAR,
    PeriodDictionaryRus,
} from '#/src/constants/date';

export const isValidDate = (date: Date | number | null | undefined): date is Date | number =>
    typeof date === 'number' || (date instanceof Date && isValid(date));

export const convertUnixTimeToDateString = (date: Date | number, dateFormat: string): string =>
    isValidDate(date) ? format(date, dateFormat, { locale: ru }) : '';

export const formatDateForList = (date: Date | number, fullDate = false): string => {
    if (!isValidDate(date)) {
        return '';
    }

    if (!fullDate) {
        if (isToday(date)) {
            return PeriodDictionaryRus.Today;
        }
        if (isYesterday(date)) {
            return PeriodDictionaryRus.Yesterday;
        }
        if (differenceInMinutes(Date.now(), date) <= MINUTES_IN_YEAR) {
            return convertUnixTimeToDateString(date, 'DD MMMM');
        }
    }

    return convertUnixTimeToDateString(date, 'DD MMMM YYYY');
};

export const formatDateForCalendar = (
    date: Date | number | null | undefined,
    dateFormat = DATE_FORMAT,
): string => (isValidDate(date) ? convertUnixTimeToDateString(new Date(date), dateFormat) : '');

export const formatDateFromCalendar = (
    dateFormatted: string | number | null | undefined,
): string | null => {
    if (!dateFormatted) {
        return null;
    }

    if (typeof dateFormatted === 'number') {
        return convertUnixTimeToDateString(dateFormatted, DATE_ISO_FORMAT);
    }

    const splittedDate = dateFormatted?.split('.') || [];

    if (splittedDate.length !== 3 || splittedDate[2].length < 4) {
        return null;
    }

    return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
};

export const formatDateTimeToUnixTime = (date: string, time: string): number => {
    const splittedDate = date?.split('.') || [];

    const dateInJSFormat = `${splittedDate[1]}.${splittedDate[0]}.${splittedDate[2]}`;

    return Date.parse(`${dateInJSFormat} ${time}`);
};

export const getMinutesFromTimeStamp = (date: Date | string | number) => {
    const minutes = getMinutes(date).toString();

    return minutes.length === 1 ? `0${minutes}` : minutes;
};

export const getHoursFromTimeStamp = (date: Date | string | number) => {
    const hours = getHours(date).toString();

    return hours.length === 1 ? `0${hours}` : hours;
};
