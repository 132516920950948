import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Collapse } from '@alfalab/core-components/collapse';
import { Grid } from '@alfalab/core-components/grid';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';
import { ChevronUpSIcon } from '@alfalab/icons-glyph/ChevronUpSIcon';

import { fetchCompaniesByType } from '#/src/store/companies/actions';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import {
    updateAllFilters,
    updateErrorsFilters,
} from '#/src/store/supplies/supplies-filters/action-creators';
import {
    errorsFiltersSelector,
    isAdditionalFiltersDirtySelector,
    selectedFiltersSelector,
} from '#/src/store/supplies/supplies-filters/selectors';
import { getDefaultSuppliesFiltersState } from '#/src/store/supplies/supplies-filters/utils';

import { AdditionalFiltersSection } from './additional-filters-section';
import { MainFiltersSection } from './main-filters-section';

import './supplies-filters.css';

const cn = createCn('supplies-filters');

export function SuppliesFilters() {
    const isAdditionalFiltersDirty = useSelector(isAdditionalFiltersDirtySelector);
    const selectedFilters = useSelector(selectedFiltersSelector);
    const errors = useSelector(errorsFiltersSelector);
    const [isExpanded, setExpandedState] = useState(isAdditionalFiltersDirty);
    const dispatch = useDispatch();
    const organizationId = useSelector(currentOrganizationIdSelector);

    const handleToggleExpandedState = useCallback(() => {
        setExpandedState((prevState) => !prevState);
    }, []);

    const handleResetFilters = useCallback(() => {
        dispatch(updateAllFilters(getDefaultSuppliesFiltersState()));
        dispatch(updateErrorsFilters({ dateFrom: null, dateTo: null, amountRange: null }));
    }, [dispatch]);

    const handleSubmitFilters = useCallback(() => {
        dispatch(updateAllFilters(selectedFilters));
        dispatch(updateErrorsFilters({ dateFrom: null, dateTo: null, amountRange: null }));
    }, [dispatch, selectedFilters]);

    useEffect(() => {
        if (organizationId) {
            dispatch(fetchCompaniesByType({ type: 'debtors', tin: organizationId }));
        }
    }, [dispatch, organizationId]);

    return (
        <div className={cn()}>
            <Typography.Text tag='div' view='primary-large' weight='bold' className={cn('title')}>
                Фильтры
            </Typography.Text>

            <MainFiltersSection />

            <Collapse expanded={isExpanded}>
                <AdditionalFiltersSection />
            </Collapse>

            <Grid.Row justify='between' align='middle'>
                <Grid.Col width='auto'>
                    <Button
                        dataTestId='submit-supplies-filters-button'
                        onClick={handleSubmitFilters}
                        view='tertiary'
                        size='xxs'
                        disabled={Boolean(errors.dateTo || errors.dateFrom || errors.amountRange)}
                    >
                        Применить фильтры
                    </Button>
                </Grid.Col>
                <Grid.Col width='auto'>
                    <Typography.Text view='primary-small'>
                        <Link
                            dataTestId='reset-supplies-filters-link'
                            view='primary'
                            pseudo={true}
                            onClick={handleResetFilters}
                        >
                            Сбросить фильтры
                        </Link>
                    </Typography.Text>
                </Grid.Col>
                <Grid.Col width='available' className={cn('view-toggle')}>
                    <Typography.Text view='primary-small'>
                        <Link
                            dataTestId='toggle-expended-state-supplies-filters-link'
                            view='primary'
                            pseudo={true}
                            onClick={handleToggleExpandedState}
                            rightAddons={
                                isExpanded ? (
                                    <ChevronUpSIcon width='18px' height='20px' />
                                ) : (
                                    <ChevronDownSIcon width='18px' height='20px' />
                                )
                            }
                        >
                            {isExpanded ? 'Свернуть фильтры' : 'Все фильтры'}
                        </Link>
                    </Typography.Text>
                </Grid.Col>
            </Grid.Row>
        </div>
    );
}
