import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { IconButton } from '@alfalab/core-components/icon-button';
import { Tab, Tabs, TabsProps } from '@alfalab/core-components/tabs';
import { RepeatSIcon } from '@alfalab/icons-classic/RepeatSIcon';

import { DashboardTabs } from '#/src/dictionaries/reports-dashboard';
import { fetchCompaniesByType } from '#/src/store/companies/actions';
import { setSelectedTab } from '#/src/store/dashboard/dashboard-charts/action-creators';
import { fetchDashboardCharts } from '#/src/store/dashboard/dashboard-charts/actions';
import {
    dashboardChartsIsLoadingSelector,
    selectedTabSelector,
} from '#/src/store/dashboard/dashboard-charts/selectors';
import { filtersSelector } from '#/src/store/dashboard/dashboard-filters/selectors';
import { fetchDashboardLimits } from '#/src/store/dashboard/dashboard-limits/actions';
import {
    dashboardLimitsIsLoadingSelector,
    limitsInfoSelector,
    limitsListSelector,
} from '#/src/store/dashboard/dashboard-limits/selectors';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';

import { DashboardTabContent } from './dashboard-tab-content';
import DebtorLimitsSlider from './debtor-limits-slider';
import GeneralLimits from './general-limits';

import './dashboard-content.css';

const cn = createCn('dashboard-content');

export function DashboardContent() {
    const dispatch = useDispatch();
    const selectedTab = useSelector(selectedTabSelector);
    const isLoading = useSelector(dashboardChartsIsLoadingSelector);
    const limitsIsLoading = useSelector(dashboardLimitsIsLoadingSelector);
    const limitsInfo = useSelector(limitsInfoSelector);
    const limitsList = useSelector(limitsListSelector);
    const { debtorTin, period, dateTo, dateFrom } = useSelector(filtersSelector);
    const currentOrganizationId = useSelector(currentOrganizationIdSelector) as string;

    const progressValue = (limitsInfo.totalAvailableLimit / limitsInfo.totalProdLim) * 100;

    useEffect(() => {
        dispatch(fetchDashboardLimits());
        dispatch(
            fetchCompaniesByType({
                type: 'debtors',
                tin: currentOrganizationId,
                forDashboard: true,
            }),
        );
    }, [dispatch, currentOrganizationId]);

    useEffect(() => {
        dispatch(fetchDashboardCharts());
    }, [debtorTin, period, dateTo, dateFrom, currentOrganizationId, selectedTab, dispatch]);

    const handleChangeTab = useCallback<NonNullable<TabsProps['onChange']>>(
        (event, payload) => {
            if (payload.selectedId) {
                dispatch(setSelectedTab(payload.selectedId as DashboardTabs));
            }
        },
        [dispatch],
    );

    const handleReloadData = useCallback(() => {
        dispatch(fetchDashboardCharts());
    }, [dispatch]);

    return (
        <div>
            <div className={cn()}>
                <Tabs onChange={handleChangeTab} className={cn('list')} selectedId={selectedTab}>
                    <Tab id={DashboardTabs.RECEIVABLES} title='Дебиторская задолженность'>
                        <DashboardTabContent />
                    </Tab>
                    <Tab id={DashboardTabs.COMMISSIONS} title='Комиссии'>
                        <DashboardTabContent />
                    </Tab>
                    <Tab id={DashboardTabs.PAYMENTS} title='Платежи'>
                        <DashboardTabContent />
                    </Tab>
                    <Tab id={DashboardTabs.REFUNDS} title='Возвраты'>
                        <DashboardTabContent />
                    </Tab>
                </Tabs>
                <div className={cn('reload-holder')}>
                    <IconButton
                        size='xxs'
                        view='primary'
                        icon={RepeatSIcon}
                        disabled={isLoading}
                        onClick={handleReloadData}
                        className={cn('reload-button')}
                    />
                </div>
            </div>
            <GeneralLimits
                isLoading={limitsIsLoading}
                totalLimit={limitsInfo.totalProdLim}
                availableLimit={limitsInfo.totalAvailableLimit}
                usedLimit={limitsInfo.totalDebtorLim}
                availablePercentageLimit={progressValue}
            />
            {(limitsList.length || limitsIsLoading) && (
                <DebtorLimitsSlider debtorLimits={limitsList} isLoading={limitsIsLoading} />
            )}
        </div>
    );
}
