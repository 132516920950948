import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { SupplyErrorsActionsType } from './types';

export type SupplyErrorsById = {
    errors: string[];
    loadingState: LoadingState;
};

export type SupplyErrorsState = Record<number, SupplyErrorsById>;

export const supplyErrorsByIdDefault: SupplyErrorsById = {
    errors: [],
    loadingState: 'unknown',
};

const initialState: SupplyErrorsState = {};

export function supplyErrorsReducer(
    state: SupplyErrorsState = initialState,
    action: SupplyErrorsActionsType,
): SupplyErrorsState {
    switch (action.type) {
        case actionCreators.getSupplyErrorsStart.type:
            return {
                ...state,
                [action.id]: {
                    ...supplyErrorsByIdDefault,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyErrorsSuccess.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    errors: action.data,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyErrorsError.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.removeSupplyErrors.type: {
            const { [action.id]: removeItem, ...items } = state;

            return {
                ...items,
            };
        }
        default:
            return state;
    }
}
