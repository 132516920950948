import {
    DownloadFileQuery,
    GetActualNotificationsResponse,
} from '#/src/server/services/api/communication';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '') {
    const fetch = {
        getActualNotifications: fetchFactory<never, GetActualNotificationsResponse>(
            `${contextRoot}/secure/nodeapi/getActualNotifications`,
        ),
        downloadFile: fetchFactory<DownloadFileQuery, Blob>(
            `${contextRoot}/secure/nodeapi/downloadFile`,
            'GET',
            { responseType: 'blob' },
        ),
    };

    return {
        fetchActualNotifications() {
            return fetch.getActualNotifications();
        },
        fetchDownloadFile(type: string) {
            return fetch.downloadFile({ type });
        },
    };
}
