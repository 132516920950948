import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import ConnectedSvg from 'arui-private/icons/connected.svg';

import { BlankResultModal } from '#/src/components/blank-result-modal';
import { changeProcessedResultModalState } from '#/src/store/registers/sign-registers/action-creators';
import { processedResultModalIsOpenSelector } from '#/src/store/registers/sign-registers/selectors';

type ProcessedResultModalProps = {
    onSuccessSign?: () => void;
};

export function ProcessedResultModal({ onSuccessSign }: ProcessedResultModalProps) {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(processedResultModalIsOpenSelector);
    const handleCloseModal = useCallback(() => {
        dispatch(changeProcessedResultModalState(false));

        if (onSuccessSign) {
            onSuccessSign();
        }
    }, [dispatch, onSuccessSign]);

    return (
        <BlankResultModal open={modalIsOpen} onClose={handleCloseModal}>
            <img src={ConnectedSvg} alt='Успех' />;
            <Typography.Title view='small' font='system' tag='div'>
                Реестр обрабатывается
            </Typography.Title>
            <Gap size='s' />
            <Typography.Text tag='p'>
                Обработка реестра занимает чуть больше времени, чем обычно. Статус будет обновлен
                после окончания.
            </Typography.Text>
            <Gap size='l' />
            <Button dataTestId='success-button' view='primary' size='m' onClick={handleCloseModal}>
                Понятно
            </Button>
            <Gap size='m' />
        </BlankResultModal>
    );
}
