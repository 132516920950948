import React from 'react';
import { createCn } from 'bem-react-classname';

import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { pluralize } from 'arui-private/lib/formatters';

import { currencyCodes } from '#/src/dictionaries/currency';
import { CurrencyCount } from '#/src/types/registers';

import './register-currency-counts.css';

type RegisterCurrencyCountsProps = {
    currencyCounts: CurrencyCount[];
    count: number;
};

const cn = createCn('register-currency-counts');

export function RegisterCurrencyCounts({ currencyCounts, count }: RegisterCurrencyCountsProps) {
    return (
        <div className={cn()}>
            <div className={cn('wrap')}>
                <Typography.Text
                    tag='div'
                    view='secondary-large'
                    color='secondary'
                    className={cn('total-count')}
                    dataTestId='register-info-total-count'
                >
                    Обработано {count} {pluralize(count, ['строка', 'строки', 'строк'])} реестра
                </Typography.Text>
                <div className={cn('entities')}>
                    {currencyCounts
                        .filter((item) => !!currencyCodes[item.type])
                        .map((item) => (
                            <div>
                                {!!currencyCodes[item.type] && (
                                    <Amount
                                        data-test-id='register-info-amount'
                                        currency={currencyCodes[item.type]}
                                        value={item.amount * 100}
                                        minority={100}
                                        view='withZeroMinorPart'
                                    />
                                )}
                                <Typography.Text
                                    tag='div'
                                    view='secondary-large'
                                    color='secondary'
                                    className={cn('entity-total-count')}
                                    dataTestId='register-info-total-item-count'
                                >
                                    {item.rowCount}{' '}
                                    {pluralize(item.rowCount, ['поставка', 'поставки', 'поставок'])}
                                </Typography.Text>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
