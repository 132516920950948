import { LOADING_STATE } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

export const getSupplyErrorsStart = createAction('SUPPLY / getSupplyErrorsStart', (id: number) => ({
    id,
    loadingState: LOADING_STATE.LOADING,
}));

export const getSupplyErrorsSuccess = createAction(
    'SUPPLY / getSupplyErrorsSuccess',
    (id: number, data: string[]) => ({
        id,
        data,
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const getSupplyErrorsError = createAction('SUPPLY / getSupplyErrorsError', (id: number) => ({
    id,
    loadingState: LOADING_STATE.ERROR,
}));

export const removeSupplyErrors = createAction('SUPPLY / removeSupplyErrors', (id: number) => ({
    id,
}));
