import React from 'react';
import ReactDOMServer from 'react-dom/server';
import addDays from 'date-fns/add_days';
import addMonths from 'date-fns/add_months';
import endOfMonth from 'date-fns/end_of_month';
import formatDate from 'date-fns/format';
import { SeriesColumnOptions, TooltipFormatterCallbackFunction } from 'highcharts';

import { DATE_FORMAT } from '#/src/constants/date';
import { DashboardTabs, PeriodFilter } from '#/src/dictionaries/reports-dashboard';
import { Filters } from '#/src/types/dashboard-reports';

import HighChartsTooltip, { TooltipListItem } from './high-charts-tooltip';

const receivablesNames = ['Принято', 'Профинансировано', 'Оплачено'];
const commissionsNames = ['Обработка документов', 'ФУДЗ', 'Комиссия за финансирование'];
const paymentsNames = ['Оплачено клиентом', 'Оплачено дебитором'];
const refundsNames = ['Оплачено клиентом', 'Возврат по ранее оплаченым'];

function dataListMapper(names: string[], series: SeriesColumnOptions[], pointIndex: number) {
    return names.map((name, index) => ({
        name,
        value: series[index].data?.[pointIndex] as number,
    }));
}

export default function tooltipFormatterCreator(
    tab: DashboardTabs,
    filters: Filters,
    series: SeriesColumnOptions[],
): TooltipFormatterCallbackFunction {
    return function tooltipFormatter() {
        let list: TooltipListItem[] = [];
        const seriesIndex = this.point.series.index;
        const pointIndex = this.point.index;

        switch (tab) {
            case DashboardTabs.RECEIVABLES: {
                list = dataListMapper(receivablesNames, series, pointIndex);
                break;
            }
            case DashboardTabs.COMMISSIONS: {
                list = dataListMapper(commissionsNames, series, pointIndex);
                break;
            }
            case DashboardTabs.PAYMENTS: {
                list = dataListMapper(paymentsNames, series, pointIndex);
                break;
            }
            case DashboardTabs.REFUNDS: {
                list = dataListMapper(refundsNames, series, pointIndex);
                break;
            }
        }

        let title = '';

        switch (filters.period) {
            case PeriodFilter.WEEK: {
                title = formatDate(addDays(filters.dateFrom, pointIndex), DATE_FORMAT);
                break;
            }
            case PeriodFilter.QUARTER: {
                const dateFrom = formatDate(addMonths(filters.dateFrom, pointIndex), DATE_FORMAT);
                const dateTo = formatDate(
                    endOfMonth(addMonths(filters.dateFrom, pointIndex)),
                    DATE_FORMAT,
                );

                title = `${dateFrom} - ${dateTo}`;
                break;
            }
            case PeriodFilter.YEAR: {
                const dateFrom = formatDate(
                    addMonths(filters.dateFrom, pointIndex * 3),
                    DATE_FORMAT,
                );
                const dateTo = formatDate(
                    endOfMonth(addMonths(filters.dateFrom, pointIndex * 3)),
                    DATE_FORMAT,
                );

                title = `${dateFrom} - ${dateTo}`;
                break;
            }
        }

        return ReactDOMServer.renderToString(
            <HighChartsTooltip
                title={title}
                tab={tab}
                list={list}
                activeColumn={seriesIndex}
                byCount={filters.byCount}
            />,
        );
    };
}
