import createAction from '#/src/utils/create-action';

import { Notification } from './types';

export const addNotification = createAction(
    'NOTIFICATIONS / addNotification',
    (notification: Notification) => ({
        notification,
    }),
);

export const removeNotification = createAction(
    'NOTIFICATIONS / removeNotification',
    (id: string) => ({
        id,
    }),
);
