import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultUIRenderers, QESSignComponent } from '@alfa-bank/corp-sign-ui-react';
import { DocumentWithSign } from '@alfa-bank/corp-sign-ui-react/types';

import { DisabledSignButton } from '#/src/components/disabled-sign-button';
import { currentOrganizationSelector } from '#/src/store/organizations/selectors';
import { sendSignRegistersToFactor } from '#/src/store/registers/sign-registers/actions';
import {
    documentsSelector,
    permissionsSelector,
    signRegistersIsLoadingSelector,
    userSelector,
} from '#/src/store/registers/sign-registers/selectors';
import { Organization, Permission } from '#/src/types/orgatiozations';
import { RegisterData } from '#/src/types/registers';
import { fetchers } from '#/src/utils/client-api';

import { AdditionalInfoModal } from './additional-info-modal';
import { ChooseCertificateRegistersSignModal } from './choose-certificate-registers-sign-modal';
import { ProcessedResultModal } from './processed-result-modal';
import { SignButton } from './sign-button';
import { SuccessResultModal } from './success-result-modal';
import { WarningModal } from './warning-modal';

type SignRegistersControlsProps = {
    onSuccessSign: () => void;
    isDebtor: boolean;
    selectedIds: number[];
    register?: RegisterData;
};
export function SignRegistersControls({
    isDebtor,
    register,
    selectedIds,
    onSuccessSign,
}: SignRegistersControlsProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector(signRegistersIsLoadingSelector);
    const permissions = useSelector(permissionsSelector);
    const documents = useSelector(documentsSelector);
    const user = useSelector(userSelector);
    const currentOrganization = useSelector(currentOrganizationSelector) as Organization;

    let textButton = 'Подписать и отправить';
    let signAvailable = currentOrganization.actions.includes(Permission.SUPPLIER_DOCUMENTS_SIGN);

    if (isDebtor) {
        textButton = 'Отправить реестр';
        signAvailable = currentOrganization.actions.includes(Permission.DEBTOR_DOCUMENTS_SIGN);
    }

    const handleSendSignRegistersToFactor = useCallback(
        (data: { documents: DocumentWithSign[] }) =>
            dispatch(sendSignRegistersToFactor(data.documents)),
        [dispatch],
    );

    const handlePreSign = useCallback(
        () =>
            Promise.resolve({
                methodInfo: {
                    name: `${user?.firstName}${user?.middleName ? ` ${user?.middleName}` : ''}`,
                    lastName: user?.lastName || '',
                    snils: user?.snils || '',
                },
                documents,
            }),
        [documents, user],
    );

    let content: React.ReactNode = <DisabledSignButton textButton={textButton} />;

    if (signAvailable) {
        content = (
            <React.Fragment>
                <QESSignComponent
                    logging={{
                        logHandler: (infoData) => {
                            fetchers
                                .sendCertificateData(infoData)
                                .then(() => console.log('send certificate data success'))
                                .catch(() => console.log('send certificate data error'));
                        },
                        logLevel: 1,
                    }}
                    UIrenderers={{
                        ...defaultUIRenderers,
                        getSignChooseCertificateModal: (props) => (
                            <ChooseCertificateRegistersSignModal
                                {...props}
                                isDebtor={isDebtor}
                                permissions={permissions}
                                documents={documents}
                            />
                        ),
                        getSignButton: (props) => (
                            <SignButton
                                isLoading={isLoading}
                                textButton={textButton}
                                selectedIds={selectedIds}
                                {...props}
                            />
                        ),
                    }}
                    preSign={handlePreSign}
                    postSign={handleSendSignRegistersToFactor}
                />
                <WarningModal isDebtor={isDebtor} />
                <AdditionalInfoModal
                    isDebtor={isDebtor}
                    selectedIds={selectedIds}
                    register={register}
                />
                <SuccessResultModal
                    isDebtor={isDebtor}
                    register={register}
                    onSuccessSign={onSuccessSign}
                />
                <ProcessedResultModal onSuccessSign={onSuccessSign} />
            </React.Fragment>
        );
    }

    return <React.Fragment>{content}</React.Fragment>;
}
