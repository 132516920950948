import React from 'react';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';

import './external-page-footer-links.css';

const cn = createCn('external-page-footer-links');

export default function ExternalPageFooterLinks() {
    return (
        <div className={cn()}>
            <div className={cn('links')}>
                <Link dataTestId='bank-site-link' href='https://alfabank.ru' className={cn('link')}>
                    Сайт банка
                </Link>
                <Link
                    dataTestId='alfa-business-online-link'
                    href='https://link.alfabank.ru'
                    className={cn('link')}
                >
                    Альфа-Бизнес Онлайн
                </Link>
                <Link
                    dataTestId='news-digest-link'
                    href='https://alfacorp.digital'
                    className={cn('link')}
                >
                    Новостной дайджест
                </Link>
            </div>
        </div>
    );
}
