import { createCachedSelector } from 're-reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { supplySelector } from '../selectors';

import { supplyDetailsByIdDefault } from './reducer';

const supplyIdSelector = (state: ApplicationState, supplyId: number) => supplyId;

export const supplyDetailsStateSelector = (state: ApplicationState) =>
    supplySelector(state).details;

export const supplyDetailsStateByIdSelector = createCachedSelector(
    supplyDetailsStateSelector,
    supplyIdSelector,
    (supplyDetailsMap, supplyId) => supplyDetailsMap[supplyId] || supplyDetailsByIdDefault,
)(supplyIdSelector);

export const supplyDetailsIsLoadingSelector = createCachedSelector(
    supplyDetailsStateByIdSelector,
    (supplyDetailsByWarrant) => supplyDetailsByWarrant.loadingState === LOADING_STATE.LOADING,
)(supplyIdSelector);

export const supplyDetailsIsUnknownSelector = createCachedSelector(
    supplyDetailsStateByIdSelector,
    (supplyDetailsByWarrant) => supplyDetailsByWarrant.loadingState === LOADING_STATE.UNKNOWN,
)(supplyIdSelector);

export const supplyDetailsSelector = createCachedSelector(
    supplyDetailsStateByIdSelector,
    (supplyDetails) => supplyDetails.info,
)(supplyIdSelector);
