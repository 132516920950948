export type DocType =
    | '27'
    | '30'
    | '35'
    | '36'
    | '37'
    | '38'
    | '40'
    | '41'
    | '42'
    | '551'
    | '829'
    | '841';

export const docTypeDictionary: Record<DocType, string> = {
    27: 'СЧФ',
    829: 'ТОРГ-12',
    40: 'Акт',
    841: 'Увед.о приемке',
    41: 'УПД',
    42: 'УКД',
    30: 'ТОРГ-12 на возврат товаров',
    551: 'КС-2/Акт о прием. выпол. работ',
    35: 'КС-3/Справка о ст-ти выпол. работ',
    36: 'КС-11/Акт о прием. недвиж-ти',
    37: 'КС-14/Док-т о прием. недвиж. и зачис. в ОС',
    38: 'Иной документ',
};
