import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';

import { ExternalPage } from '#/src/components/external-page';
import { ExternalPageForm } from '#/src/components/external-page-form';
import FormButton from '#/src/components/form-button';
import PlateError from '#/src/components/plate-error';
import RestorePasswordSuccess from '#/src/containers/pages/restore-password-page/restore-password-success';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { restorePassword } from '#/src/store/restore-password/actions';
import {
    restorePasswordErrorSelector,
    restorePasswordIsLoadingSelector,
    restorePasswordIsSuccessSelector,
} from '#/src/store/restore-password/selectors';

import './restore-password-page.css';

const cn = createCn('restore-password-page');

export default function RestorePasswordPage() {
    const dispatch = useDispatch();
    const restorePasswordIsLoading = useSelector(restorePasswordIsLoadingSelector);
    const restorePasswordIsSuccess = useSelector(restorePasswordIsSuccessSelector);
    const restorePasswordError = useSelector(restorePasswordErrorSelector);
    const handleSubmitLogin = useCallback(
        (formValues: Record<string, string>) => {
            dispatch(restorePassword(formValues.username, formValues.keyword));
        },
        [dispatch],
    );

    return (
        <ExternalPage title='Восстановление пароля'>
            {restorePasswordIsSuccess ? (
                <RestorePasswordSuccess />
            ) : (
                <ExternalPageForm
                    form='restorePassword'
                    className={cn('form')}
                    onSubmit={handleSubmitLogin}
                >
                    {!!restorePasswordError && <PlateError error={restorePasswordError} />}
                    <FormButton
                        type='submit'
                        text='Восстановить'
                        disabled={restorePasswordIsLoading}
                        form='restorePassword'
                    />
                    <div className={cn('link')}>
                        <Link
                            dataTestId='cancel-link'
                            view='primary'
                            href={routesDictionary.authPage.path}
                        >
                            Отмена
                        </Link>
                    </div>
                </ExternalPageForm>
            )}
        </ExternalPage>
    );
}
