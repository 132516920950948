import { LoadingState } from '#/src/constants/loading-state';
import { SupplyCorrectionHistoryDto } from '#/src/types/supply';

import * as actionCreators from './action-creators';
import { SupplyCorrectionHistoryActionsType } from './types';

export type SupplyCorrectionHistoryById = {
    history: SupplyCorrectionHistoryDto[];
    loadingState: LoadingState;
};

export type SupplyCorrectionHistoryState = Record<number, SupplyCorrectionHistoryById>;

export const supplyCorrectionHistoryByIdDefault: SupplyCorrectionHistoryById = {
    history: [],
    loadingState: 'unknown',
};

const initialState: SupplyCorrectionHistoryState = {};

export function supplyCorrectionHistoryReducer(
    state: SupplyCorrectionHistoryState = initialState,
    action: SupplyCorrectionHistoryActionsType,
): SupplyCorrectionHistoryState {
    switch (action.type) {
        case actionCreators.getSupplyCorrectionHistoryStart.type:
            return {
                ...state,
                [action.id]: {
                    ...supplyCorrectionHistoryByIdDefault,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyCorrectionHistorySuccess.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    history: action.data,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyCorrectionHistoryError.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.removeSupplyCorrectionHistory.type: {
            const { [action.id]: removeItem, ...items } = state;

            return {
                ...items,
            };
        }
        default:
            return state;
    }
}
