import React, { useCallback, useEffect, useState } from 'react';
import { SignChooseCertificateModalProps } from '@alfa-bank/corp-sign-ui-react/components/SignChooseCertificateModal';
import { Certificate, DocumentWithContent } from '@alfa-bank/corp-sign-ui-react/types';

import { Alert } from '@alfalab/core-components/alert';
import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Modal, ModalProps } from '@alfalab/core-components/modal';
import { RadioGroup } from '@alfalab/core-components/radio-group';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { CertificateRadio } from '#/src/components/choose-certificate-sign-modal/certificate-radio';
import { filterCertificates } from '#/src/components/choose-certificate-sign-modal/filter-certificates';
import { PermissionErrors } from '#/src/components/choose-certificate-sign-modal/permission-errors';
import { CheckPermissionSOResult } from '#/src/types/sign';

export type ChooseCertificateSignModalProps = {
    declensions: [string, string, string];
    title: string;
    permissions: CheckPermissionSOResult[];
    documents: DocumentWithContent[];
    certificatesAreHidden?: boolean;
    onCloseModal: () => void;
    signButtonIsDisabled?: boolean;
    onUnmount?: ModalProps['onUnmount'];
} & Partial<SignChooseCertificateModalProps>;

export function ChooseCertificateSignModal({
    open,
    certificates,
    onButtonClick,
    onCloseModal,
    messages,
    permissions,
    documents,
    certificatesAreHidden = false,
    declensions,
    title,
    signButtonIsDisabled = false,
    onUnmount,
}: ChooseCertificateSignModalProps) {
    const [certificate, setCertificate] = useState<string | null>(null);
    const [certificatesCache, setCertificatesCache] = useState<Certificate[]>(certificates || []);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (certificates?.length) {
            setCertificatesCache(filterCertificates(certificates));
        }
    }, [certificates]);

    useEffect(() => {
        if (certificate && error) {
            setError(null);
        }
    }, [certificate, error]);

    const handleClickSignButton = useCallback(() => {
        if (certificate) {
            if (onButtonClick) {
                onButtonClick(certificate);
            }
        } else {
            setError('Должен быть выбран хотя бы один сертификат для подписания');
        }
    }, [certificate, onButtonClick]);

    const handleCloseModal = useCallback(() => {
        onCloseModal();
    }, [onCloseModal]);

    let content: React.ReactNode = null;

    if (!certificatesAreHidden) {
        if (certificatesCache?.length) {
            content = (
                <React.Fragment>
                    <Gap size='xs' />
                    <RadioGroup
                        error={error}
                        value={certificate}
                        label={
                            <Typography.Text view='primary-small' color='secondary'>
                                Выберите сертификат подписания:
                            </Typography.Text>
                        }
                    >
                        {messages &&
                            certificatesCache.map((item) => (
                                <React.Fragment>
                                    <CertificateRadio
                                        key={item.Thumbprint}
                                        certificate={item}
                                        selectedCertificate={certificate}
                                        fields={
                                            messages.common.chooseCertificateModal.default.fields
                                        }
                                        onChangeCertificate={setCertificate}
                                    />
                                    <Gap size='m' />
                                </React.Fragment>
                            ))}
                    </RadioGroup>
                    <Gap size='m' />
                </React.Fragment>
            );
        } else {
            content = (
                <Alert view='negative'>
                    <Typography.Text view='secondary-large'>
                        Нет валидных сертификатов подписания
                    </Typography.Text>
                </Alert>
            );
        }
    }

    const disabledButton = signButtonIsDisabled || !documents?.length || !certificatesCache.length;

    return (
        <Modal
            open={Boolean(open)}
            hasCloser={true}
            onClose={handleCloseModal}
            size='m'
            disableBackdropClick={true}
            onUnmount={onUnmount}
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    {title}
                </Typography.Title>
                <Gap size='2xl' />
                <PermissionErrors declensions={declensions} permissions={permissions} />
                {content}
                <Gap size='l' />
                <Space size={8} direction='horizontal'>
                    <Button
                        disabled={disabledButton}
                        view='primary'
                        size='xs'
                        onClick={handleClickSignButton}
                    >
                        Продолжить
                    </Button>
                    <Button view='tertiary' size='xs' onClick={handleCloseModal}>
                        Отмена
                    </Button>
                </Space>
            </Modal.Content>
        </Modal>
    );
}
