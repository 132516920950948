import formatDate from 'date-fns/format';
import subMonths from 'date-fns/sub_months';

import { DATE_FORMAT } from '#/src/constants/date';
import { reportFilters } from '#/src/constants/reports';
import { ReportNames } from '#/src/types/reports';

import * as actionCreators from './action-creators';
import { ReportFiltersActionsType } from './types';

export type ReportFiltersState = {
    reportName: ReportNames | '';
    dateFrom: string;
    dateTo: string;
    debtorId: string;
    documentNumber: string;
    supplyNum: string;
    generalAgreementId: string;
};

export const initialState: ReportFiltersState = {
    reportName: '',
    dateFrom: '',
    dateTo: '',
    debtorId: '',
    documentNumber: '',
    supplyNum: '',
    generalAgreementId: '',
};

const getDefaultDatesByReportName = (reportName?: ReportFiltersState['reportName']) => {
    if (reportName) {
        const currentFilters = reportFilters[reportName];
        const currentDate = Date.now();
        const currentDateFormatted = formatDate(currentDate, DATE_FORMAT);

        switch (true) {
            case currentFilters.includes('dateFrom'):
                return {
                    dateFrom: currentDateFormatted,
                };
            case currentFilters.includes('dateTo'):
                return {
                    dateTo: currentDateFormatted,
                };
            case currentFilters.includes('dateFrom;dateTo'):
                return {
                    dateFrom: formatDate(subMonths(currentDate, 1), DATE_FORMAT),
                    dateTo: currentDateFormatted,
                };
        }
    }

    return {};
};

export function reportFiltersReducer(
    state: ReportFiltersState = initialState,
    action: ReportFiltersActionsType,
) {
    switch (action.type) {
        case actionCreators.initializeReportFilters.type:
            return {
                ...initialState,
                ...getDefaultDatesByReportName(action.filters.reportName),
                ...action.filters,
            };
        case actionCreators.updateReportFilters.type:
            return {
                ...state,
                ...action.filters,
            };
        case actionCreators.resetReportFilters.type:
            return initialState;
        default:
            return state;
    }
}
