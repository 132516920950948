import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

import { DashboardLimitsState } from './reducer';

export const changeDashboardLimitsLoadingState = createAction(
    'DASHBOARD / CHARTS / changeDashboardLimitsLoadingState',
    (loadingState: LoadingState) => ({ loadingState }),
);

export const setDashboardLimits = createAction(
    'DASHBOARD / CHARTS / setDashboardLimits',
    (data: Partial<DashboardLimitsState>) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const clearLimits = createAction('DASHBOARD / CHARTS / clearLimits', () => ({}));
