import React from 'react';
import { createCn } from 'bem-react-classname';

import { Amount } from '@alfalab/core-components/amount';
import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';

import './limit-amount.css';

type LimitAmountProps = {
    isLoading: boolean;
    amount: number;
    title: string;
};

const cn = createCn('limit-amount');

export default function LimitAmount({ isLoading, title, amount }: LimitAmountProps) {
    return (
        <React.Fragment>
            <Skeleton visible={isLoading}>
                <Typography.Text tag='div' view='secondary-medium' color='secondary'>
                    {title}
                </Typography.Text>
            </Skeleton>
            <div className={cn()}>
                <Skeleton visible={isLoading}>
                    <Amount
                        value={amount * 100}
                        currency='RUR'
                        minority={100}
                        view='withZeroMinorPart'
                    />
                </Skeleton>
            </div>
        </React.Fragment>
    );
}
