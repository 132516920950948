import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';
import { Segment } from '#/src/types/navigations';

export const navigationsSelector = (state: ApplicationState) => state.navigations;

const IframeUrlSelectorCreator = (segment: Segment, defaultUrl: string) =>
    createSelector(
        navigationsSelector,
        (navigations) =>
            navigations.find((nav) => nav.segment === segment)?.iframeUrl || defaultUrl,
    );

export const suppliesEdiSoftIframeUrlSelector = IframeUrlSelectorCreator(
    'TAB_X5_COMPANIES_GROUP',
    '/secure/edi/ui/?module=documents&tab=tosign',
);
export const invoiceRegistryEdiSoftIframeUrlSelector = IframeUrlSelectorCreator(
    'TAB_INVOICE_REGISTRY',
    '/secure/edi/ui/index.jsp?module=accept.registry',
);
