import React, { ReactNode } from 'react';
import { createCn } from 'bem-react-classname';

import './reports-xls-table-row.css';

const cn = createCn('reports-xls-table-row');

type ReportsXlsTableRowProps = {
    children: ReactNode;
};

export default function ReportsXlsTableRow({ children }: ReportsXlsTableRowProps) {
    return <div className={cn()}>{children}</div>;
}
