import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import { DashboardTabs } from '#/src/dictionaries/reports-dashboard';
import createAction from '#/src/utils/create-action';

import { DashboardChartsState } from './reducer';

export const changeDashboardChartsLoadingState = createAction(
    'DASHBOARD / CHARTS / changeDashboardChartsLoadingState',
    (loadingState: LoadingState) => ({ loadingState }),
);

export const setDashboardCharts = createAction(
    'DASHBOARD / CHARTS / setDashboardCharts',
    (data: Partial<DashboardChartsState>) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const setSelectedTab = createAction(
    'DASHBOARD / CHARTS / setSelectedTab',
    (tab: DashboardTabs) => ({ tab }),
);

export const clearCharts = createAction('DASHBOARD / CHARTS / clearCharts', () => ({}));
