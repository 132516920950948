import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';

import { suppliesModelsSelector } from '../selectors';

export const filtersStateSelector = (state: ApplicationState) =>
    suppliesModelsSelector(state).suppliesFilters;

export const selectedFiltersSelector = (state: ApplicationState) =>
    filtersStateSelector(state).selected;
export const appliedFiltersSelector = (state: ApplicationState) =>
    filtersStateSelector(state).applied;

export const errorsFiltersSelector = (state: ApplicationState) =>
    filtersStateSelector(state).errors;

export const isAdditionalFiltersDirtySelector = createSelector(selectedFiltersSelector, (filters) =>
    Boolean(
        filters.debtorsTin.length ||
            filters.registerNumber ||
            filters.supplyAgreementNumber ||
            filters.amountCertificationDocumentNumber ||
            filters.factoringAgreementNumber ||
            filters.fromAmount ||
            filters.toAmount,
    ),
);
