import { Organization } from '#/src/types/orgatiozations';

import * as actionCreators from './action-creators';
import { OrganizationsActionsType } from './types';

export type OrganizationsState = {
    list: Organization[];
    current: string | null;
    type: 'supplier' | 'debtor' | null;
};

export const initialState: OrganizationsState = {
    list: [],
    current: null,
    type: null,
};

export function organizationsReducer(
    state: OrganizationsState = initialState,
    action: OrganizationsActionsType,
): OrganizationsState {
    switch (action.type) {
        case actionCreators.changeOrganization.type:
            return {
                ...state,
                current: action.id,
                type: action.roleType,
            };
        default:
            return state;
    }
}
