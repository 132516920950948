import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Amount } from '@alfalab/core-components/amount';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { Typography } from '@alfalab/core-components/typography';

import { toggleSelectedSupplies } from '#/src/store/supplies/supplies-list/action-creators';
import {
    areAllSuppliesSelectedSelector,
    currentAmountForSelectedSuppliesSelector,
    selectedIdsSelector,
    suppliesIdsSelector,
    totalPriceSelector,
} from '#/src/store/supplies/supplies-list/selectors';

import './checkbox-supplies-control.css';

const cn = createCn('checkbox-supplies-control');

export function CheckboxSuppliesControl() {
    const dispatch = useDispatch();
    const areAllSuppliesSelected = useSelector(areAllSuppliesSelectedSelector);
    const selectedIds = useSelector(selectedIdsSelector);
    const suppliesIds = useSelector(suppliesIdsSelector);
    const totalPrice = useSelector(totalPriceSelector);
    const currentAmountForSelected = useSelector(currentAmountForSelectedSuppliesSelector);

    const handleToggleSelectedSupplies = useCallback(() => {
        dispatch(toggleSelectedSupplies());
    }, [dispatch]);

    let currentPageAmount = totalPrice;

    let totalItemsCount = suppliesIds.length;

    if (selectedIds.length > 0) {
        currentPageAmount = currentAmountForSelected;
        totalItemsCount = selectedIds.length;
    }

    const summaryAmount = (
        <Typography.Text view='secondary-large'>
            на&nbsp;сумму&nbsp;
            <Amount
                value={currentPageAmount * 100}
                minority={100}
                view='withZeroMinorPart'
                bold='none'
                transparentMinor={true}
                currency='RUR'
            />
        </Typography.Text>
    );

    return (
        <div className={cn()}>
            <Checkbox
                size='s'
                checked={areAllSuppliesSelected && suppliesIds.length !== 0}
                onChange={handleToggleSelectedSupplies}
                disabled={suppliesIds.length === 0}
                label={
                    <Typography.Text className={cn('text')} view='secondary-large'>
                        {selectedIds.length > 0 ? 'Выбрано поставок:' : 'Отобрано поставок:'}
                        &nbsp;
                        {totalItemsCount}
                        &nbsp;
                        {summaryAmount}
                    </Typography.Text>
                }
            />
        </div>
    );
}
