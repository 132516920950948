import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { createCn } from 'bem-react-classname';

import { Grid } from '@alfalab/core-components/grid';
import {
    Pagination as CorePagination,
    PaginationProps as CorePaginationProps,
} from '@alfalab/core-components/pagination';
import { Typography } from '@alfalab/core-components/typography';

import { ApplicationState } from '#/src/store/reducer';
import { fetchRegistersByPage } from '#/src/store/registers/registers-list/actions';
import { TypedDispatch } from '#/src/types/redux-toolkit';

import { SizeNavigationControls } from './size-navigation-controls';

import './pagination.css';

type PaginationParams = { page: number; pageSize: number };

export type PaginationProps = {
    paginationSelector: (state: ApplicationState) => PaginationParams;
    totalItemsSelector: (state: ApplicationState) => number;
    onChangePageAction: (
        params: PaginationParams,
    ) => AnyAction | ReturnType<typeof fetchRegistersByPage>;
    dataTestId?: string;
};

const cn = createCn('pagination');

export function Pagination({
    totalItemsSelector,
    onChangePageAction,
    paginationSelector,
    dataTestId = 'pagination',
}: PaginationProps) {
    const dispatch = useDispatch<TypedDispatch>();
    const pagination = useSelector(paginationSelector);
    const totalItems = useSelector(totalItemsSelector);
    const totalPages = useMemo(() => {
        if (pagination.pageSize && totalItems) {
            return Math.ceil(totalItems / pagination.pageSize);
        }

        return 1;
    }, [pagination.pageSize, totalItems]);

    const handlePageNumberChange = useCallback<NonNullable<CorePaginationProps['onPageChange']>>(
        (value) => {
            window.scrollTo(0, 0);
            dispatch(onChangePageAction({ page: value + 1, pageSize: pagination.pageSize }));
        },
        [dispatch, onChangePageAction, pagination.pageSize],
    );

    return (
        <div className={cn()} data-test-id={dataTestId}>
            <Grid.Row justify='right'>
                <Grid.Col width='available'>
                    <CorePagination
                        currentPageIndex={pagination.page - 1}
                        sidePadding={1}
                        view='default'
                        pagesCount={totalPages}
                        onPageChange={handlePageNumberChange}
                    />
                </Grid.Col>
                <Grid.Col width='auto' align='middle'>
                    <SizeNavigationControls
                        size={pagination.pageSize}
                        onChangePageAction={onChangePageAction}
                    />
                </Grid.Col>
                <Grid.Col width='auto' align='middle'>
                    <Typography.Text view='primary-small'>
                        Всего:&nbsp;
                        <Typography.Text weight='bold' view='primary-small'>
                            {totalItems}
                        </Typography.Text>
                    </Typography.Text>
                </Grid.Col>
            </Grid.Row>
        </div>
    );
}
