import { ReactNode } from 'react';
import { ThunkAction } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';

import { ApplicationState } from '#/src/store/reducer';
import { parseErrorMessage } from '#/src/utils/parse-error-message';

import { addNotification } from './action-creators';
import { Notification, NotificationsActionsType } from './types';

export function dispatchErrorNotification(
    error: unknown,
    errorIsParsed = false,
): ThunkAction<void, ApplicationState, void, NotificationsActionsType> {
    return (dispatch) => {
        let errorMessage: ReactNode = '';

        if (errorIsParsed) {
            errorMessage = error as ReactNode;
        } else {
            try {
                errorMessage = parseErrorMessage(error);
            } catch (_e) {
                errorMessage = 'В работе приложения произошёл сбой';
            }
        }

        dispatch(
            addNotification({
                children: errorMessage,
                title: 'Произошла ошибка',
                badge: 'negative',
                id: uuidv4(),
            }),
        );
    };
}

export function dispatchSuccessNotification(
    title: Notification['title'],
): ThunkAction<void, ApplicationState, void, NotificationsActionsType> {
    return (dispatch) => {
        dispatch(
            addNotification({
                title,
                badge: 'positive',
                id: uuidv4(),
            }),
        );
    };
}
