import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { stateSelector } from '#/src/store/selectors';

export const authStateSelector = (state: ApplicationState) => stateSelector(state).auth;

export const authErrorSelector = (state: ApplicationState) => authStateSelector(state).error;
export const authLoadingStateSelector = (state: ApplicationState) =>
    authStateSelector(state).loadingState;
export const authIsLoadingSelector = (state: ApplicationState) =>
    authLoadingStateSelector(state) === LOADING_STATE.LOADING;
export const userWithoutPermissionsModalIsOpenSelector = (state: ApplicationState) =>
    authStateSelector(state).userWithoutPermissionsModalIsOpen;
