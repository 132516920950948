import { OptionShape } from '@alfalab/core-components/select/typings';
import { StatusProps } from '@alfalab/core-components/status';

import { RegisterStatusType } from '#/src/types/registers';

export const statusColors: Record<RegisterStatusType, StatusProps['color']> = {
    DUPLICATE: 'grey',
    SUCCESS_PROCESSED: 'blue',
    PARTIALLY_PROCESSED: 'blue',
    ERROR_PROCESSED: 'red',
    FINANCING_REQUESTED: 'orange',
    PROCESSING: 'orange',
    ERROR_PROCESSING: 'red',
    AF_RECEIVED: 'teal',
    AF_PARTIALLY_RECEIVED: 'teal',
    PARTIALLY_VERIFIED: 'purple',
    VERIFIED: 'purple',
    PARTIALLY_FINANCED: 'teal',
    FINANCED: 'green',
    PARTIALLY_PAID: 'teal',
    CLOSED: 'green',
    REJECTED: 'red',
    PARTIALLY_APPROVED: 'blue',
    APPROVED: 'blue',
    ON_PROCESSING: 'blue',
};

export const statusDictionary: Record<RegisterStatusType, string> = {
    DUPLICATE: 'Дубликат',
    SUCCESS_PROCESSED: 'Обработан',
    PARTIALLY_PROCESSED: 'Частично обработан',
    ERROR_PROCESSED: 'Ошибка обработки',
    FINANCING_REQUESTED: 'Отправлено',
    ERROR_PROCESSING: 'Ошибка',
    AF_RECEIVED: 'Получено',
    AF_PARTIALLY_RECEIVED: 'Частично получено',
    PARTIALLY_VERIFIED: 'Частично принято',
    VERIFIED: 'Принято',
    PARTIALLY_FINANCED: 'Частично профинансировано',
    FINANCED: 'Профинансировано',
    PARTIALLY_PAID: 'Частично оплачено',
    CLOSED: 'Закрыто',
    REJECTED: 'Отклонено',
    PARTIALLY_APPROVED: 'Частично подтверждено',
    APPROVED: 'Подтверждено',
    PROCESSING: 'Отправлено на сверку',
    ON_PROCESSING: 'В обработке',
};

export const statusBySellerOptions: OptionShape[] = Object.keys(statusDictionary)
    .filter((status) => status !== 'PROCESSING')
    .map((status: RegisterStatusType) => ({
        value: status,
        key: status,
        content: statusDictionary[status],
    }));

export const statusByDebtorOptions: OptionShape[] = Object.keys(statusDictionary)
    .filter((status) => status !== 'FINANCING_REQUESTED')
    .map((status: RegisterStatusType) => ({
        value: status,
        key: status,
        content: statusDictionary[status],
    }));

export const availableStatusesForSign: RegisterStatusType[] = [
    'SUCCESS_PROCESSED',
    'ERROR_PROCESSING',
    'PARTIALLY_PROCESSED',
];
