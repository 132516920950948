import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';

import { reportsXlsSelector } from '../selectors';

export const generalAgreementsStateSelector = (state: ApplicationState) =>
    reportsXlsSelector(state).generalAgreements;

export const generalAgreementsSelector = (state: ApplicationState) =>
    generalAgreementsStateSelector(state).data;

export const generalAgreementsOptionsSelector = createSelector(
    generalAgreementsSelector,
    (generalAgreements) =>
        generalAgreements
            ? Object.keys(generalAgreements).map((value) => ({
                  key: value,
                  value,
                  content: generalAgreements[+value],
              }))
            : [],
);

export const generalAgreementsLoadingStateSelector = createSelector(
    generalAgreementsStateSelector,
    (state) => state.loadingState,
);
