import React, { MouseEventHandler, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';
import { push } from 'connected-react-router';

import { Checkbox } from '@alfalab/core-components/checkbox';
import { Link } from '@alfalab/core-components/link';
import { Status } from '@alfalab/core-components/status';
import { Typography } from '@alfalab/core-components/typography';

import { registerErrorsDictionary } from '#/src/dictionaries/register-info-errors';
import { statusColors, statusDictionary } from '#/src/dictionaries/registers-status';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { setSelectedRegisters } from '#/src/store/registers/registers-list/action-creators';
import { selectedSelector } from '#/src/store/registers/registers-list/selectors';
import { RegisterShortDto } from '#/src/types/registers';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import getSourceType from './get-source-type';

import './register-list-table-row.css';

const cn = createCn('register-list-table-row');

type RegisterListTableRowProps = {
    register: RegisterShortDto;
};

export function RegisterListTableRow({ register }: RegisterListTableRowProps) {
    const dispatch = useDispatch();
    const selected = useSelector(selectedSelector);
    const registerId = register?.id || '';

    const handleStopEventLifting = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
        event.stopPropagation();
    }, []);

    const handleMoveToRegisterPage = useCallback(() => {
        if (register.id && register.partyTin) {
            const url = routesDictionary.registerInfoPage.path.replace(
                /:id/g,
                register.id.toString(),
            );

            dispatch(push(url));
        }
    }, [dispatch, register.id, register.partyTin]);

    const handleToggleSelected = useCallback(() => {
        if (registerId) {
            const selectedRegisters = [...selected];
            const index = selectedRegisters.indexOf(registerId);

            if (index === -1) {
                selectedRegisters.push(registerId);
            } else {
                selectedRegisters.splice(index, 1);
            }
            dispatch(setSelectedRegisters(selectedRegisters));
        }
    }, [dispatch, registerId, selected]);

    let fileNumber: string | number = 'Отсутствует';

    if (register.fileNumber || register.fileNumber === 0) {
        if (register.fileNumber === -1) {
            fileNumber = '-';
        } else {
            fileNumber = register.fileNumber;
        }
    }

    let textForRegisterLink = 'Подробнее';

    if (register.deleted) {
        textForRegisterLink = 'Удалён';
    }

    if (register.financingType === 'NOT_FINANCE') {
        textForRegisterLink = 'Без финансирования';
    }

    return (
        <Typography.Text
            tag='div'
            view='secondary-large'
            data-test-id='registers-table-row'
            aria-hidden='true'
            className={cn({ deleted: Boolean(register.deleted) })}
            onClick={handleMoveToRegisterPage}
        >
            <div className={cn('cell-name')} data-test-id='registers-table-cell-filename'>
                <div
                    aria-hidden='true'
                    onClick={handleStopEventLifting}
                    className={cn('cell-checkbox')}
                    data-test-id='registers-table-row-cell-checkbox'
                >
                    <Checkbox
                        data-test-id='registers-table-row-checkbox'
                        size='s'
                        checked={Boolean(register.id && selected.includes(register.id))}
                        onChange={handleToggleSelected}
                    />
                </div>
                {register.filename || 'Отсутствует'}
            </div>
            <div className={cn('cell-number')} data-test-id='registers-table-cell-file-number'>
                {fileNumber}
            </div>
            <div className={cn('cell-type')} data-test-id='registers-table-cell-type'>
                {getSourceType(register)}
            </div>
            <div className={cn('cell-date')} data-test-id='registers-table-cell-date'>
                {register.createDate ? formatDateForCalendar(register.createDate) : 'Отсутствует'}
            </div>
            <div className={cn('cell-status')} data-test-id='registers-table-cell-status'>
                {register.status && statusDictionary[register.status] ? (
                    <Status
                        color={register.deleted ? 'grey' : statusColors[register.status] || 'grey'}
                    >
                        {statusDictionary[register.status]}
                    </Status>
                ) : (
                    'Отсутствует'
                )}
            </div>
            <div className={cn('cell-note')} data-test-id='registers-table-cell-note'>
                {register.error &&
                ['ERROR_PROCESSED', 'REJECTED', 'ERROR_PROCESSING'].includes(
                    register.status as string,
                ) ? (
                    registerErrorsDictionary[register.error] ||
                    'При обработке реестра произошла ошибка. Обратитесь к вашему менеджеру'
                ) : (
                    <Link
                        dataTestId='register-link'
                        pseudo={true}
                        className={cn('cell-link', {
                            deleted: Boolean(register.deleted),
                        })}
                        onClick={handleMoveToRegisterPage}
                    >
                        {textForRegisterLink}
                    </Link>
                )}
            </div>
        </Typography.Text>
    );
}
