import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '#/src/store/reducer';
import { serverRequestTimeSelector } from '#/src/store/supplies/supplies-list/selectors';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { SupplyDetailsActionsType } from './types';

export function fetchSupplyDetails(
    supplyId: number,
): ThunkAction<void, ApplicationState, void, SupplyDetailsActionsType> {
    return async (dispatch, getState) => {
        const state = getState();
        const serverRequestTime = serverRequestTimeSelector(state);

        dispatch(actionCreators.getSupplyDetailsStart(supplyId));
        try {
            const response = await fetchers.fetchSuppliesDetails(supplyId, serverRequestTime);

            dispatch(actionCreators.getSupplyDetailsSuccess(supplyId, response));
        } catch (e) {
            dispatch(actionCreators.getSupplyDetailsError(supplyId));
        }
    };
}
