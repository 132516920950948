import { LOADING_STATE } from '#/src/constants/loading-state';
import { SupplyDetails } from '#/src/types/supply';
import createAction from '#/src/utils/create-action';

export const getSupplyDetailsStart = createAction(
    'SUPPLY / getSupplyDetailsStart',
    (id: number) => ({
        id,
        loadingState: LOADING_STATE.LOADING,
    }),
);

export const getSupplyDetailsSuccess = createAction(
    'SUPPLY / getSupplyDetailsSuccess',
    (id: number, data: SupplyDetails) => ({
        id,
        data,
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const getSupplyDetailsError = createAction(
    'SUPPLY / getSupplyDetailsError',
    (id: number) => ({
        id,
        loadingState: LOADING_STATE.ERROR,
    }),
);

export const removeSupplyDetails = createAction('SUPPLY / removeSupplyDetails', (id: number) => ({
    id,
}));
