import { LOADING_STATE } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

export const getGeneralAgreementsStart = createAction(
    'REPORTS_XLS / getGeneralAgreementsStart',
    () => ({ loadingState: LOADING_STATE.LOADING }),
);

export const getGeneralAgreementsSuccess = createAction(
    'REPORTS_XLS / getGeneralAgreementsSuccess',
    (data: Record<number, string>) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const getGeneralAgreementsError = createAction(
    'REPORTS_XLS / getGeneralAgreementsError',
    () => ({ loadingState: LOADING_STATE.ERROR }),
);
