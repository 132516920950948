import { LOADING_STATE } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

export const restorePasswordStart = createAction('RESTORE_PASSWORD / restorePasswordStart', () => ({
    loadingState: LOADING_STATE.LOADING,
}));

export const restorePasswordSuccess = createAction(
    'RESTORE_PASSWORD / restorePasswordSuccess',
    () => ({
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const restorePasswordError = createAction(
    'RESTORE_PASSWORD / restorePasswordError',
    (error: string) => ({
        error,
        loadingState: LOADING_STATE.ERROR,
    }),
);
