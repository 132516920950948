import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateSelectedFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';

export function AmountCertificateDocumentNumberFilter() {
    const dispatch = useDispatch();
    const { amountCertificationDocumentNumber } = useSelector(selectedFiltersSelector);

    const handleChangeAmountCertificateDocumentNumber = useCallback<
        NonNullable<InputProps['onChange']>
    >(
        (event) => {
            dispatch(
                updateSelectedFilters({ amountCertificationDocumentNumber: event.target.value }),
            );
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='amount-certification-document-number-input'
            value={amountCertificationDocumentNumber || ''}
            block={true}
            label='Номер поставки'
            onChange={handleChangeAmountCertificateDocumentNumber}
        />
    );
}
