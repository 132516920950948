import { GradientColorObject } from 'highcharts';

const gradientColorCustom: GradientColorObject = {
    linearGradient: {
        x1: 1,
        x2: 1,
        y1: 1,
        y2: 0,
    },
    stops: [],
};

export const gradientColorYellow: GradientColorObject = {
    ...gradientColorCustom,
    stops: [
        [0, '#fcd116'],
        [1, '#fddf5c'],
    ],
};

export const gradientColorOrange: GradientColorObject = {
    ...gradientColorCustom,
    stops: [
        [0, '#ff993a'],
        [1, '#ffb775'],
    ],
};

export const gradientColorGreen: GradientColorObject = {
    ...gradientColorCustom,
    stops: [
        [0, '#2fc26e'],
        [1, '#6dd49a'],
    ],
};
