import React, { useMemo } from 'react';

import { Typography } from '@alfalab/core-components/typography';
import { pluralize } from 'arui-private/lib/formatters';

import { CheckPermissionSOResult } from '#/src/types/sign';

type ErrorCode = 'NO_DOCUMENT' | 'INVALID_USER' | 'DB_FAIL' | 'NO_ACTIONS';

const errorsText: Record<ErrorCode, string> = {
    NO_DOCUMENT: 'Не удалось сформировать документ для подписания',
    INVALID_USER: 'Не удалось проверить данные пользователя',
    DB_FAIL: 'Техническая ошибка при проверке полномочий',
    NO_ACTIONS: 'Нет полномочий на подписание',
};

type PermissionErrorsProps = {
    declensions: [string, string, string];
    permissions: CheckPermissionSOResult[];
};

export function PermissionErrors({ declensions, permissions }: PermissionErrorsProps) {
    const errors = useMemo(
        () =>
            permissions.reduce<Record<ErrorCode, number>>((arr, item) => {
                if (item.result || !(item.description in errorsText)) {
                    return arr;
                }

                return {
                    ...arr,
                    [item.description]:
                        item.description in arr ? arr[item.description as ErrorCode] + 1 : 1,
                };
            }, {} as Record<ErrorCode, number>),
        [permissions],
    );

    if (Object.keys(errors).length) {
        return (
            <React.Fragment>
                {Object.keys(errors).map((key: ErrorCode) => (
                    <Typography.Text key={key} view='primary-medium' color='primary' tag='p'>
                        {errorsText[key]} ({errors[key]} {pluralize(errors[key], declensions)})
                    </Typography.Text>
                ))}
            </React.Fragment>
        );
    }

    return null;
}
