import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { InputProps } from '@alfalab/core-components/input';
import { Space } from '@alfalab/core-components/space';

import { getFormFields } from '#/src/components/external-page-form/get-form-fields';
import { validateFormValues } from '#/src/components/external-page-form/validate-form-values';

import FormFieldInput from './form-field-input';
import { FormType } from './types';

import './external-page-form.css';

export type ExternalPageFormProps = {
    form: FormType;
    children: React.ReactNode;
    onSubmit: (formValues: Record<string, string>) => void;
    className: string;
};

const cn = createCn('external-page-form');

export function ExternalPageForm({ form, children, onSubmit, className }: ExternalPageFormProps) {
    const { initState, formFields } = useMemo(() => {
        const fields = getFormFields(form);

        return {
            initState: fields.reduce<Record<string, string>>(
                (acc, field) => ({ ...acc, [field.name]: '' }),
                {},
            ),
            formFields: fields,
        };
    }, [form]);
    const [formValues, setFormValues] = useState(initState);
    const [validMessages, setValidMessages] = useState(initState);
    const handleChangeFieldValue = useCallback<NonNullable<InputProps['onChange']>>(
        (event, { value }) => {
            const fieldName = event?.target?.getAttribute('name');

            if (fieldName) {
                setFormValues((prevState) => ({ ...prevState, [fieldName]: value || '' }));
                setValidMessages((prevState) => ({
                    ...prevState,
                    [fieldName]: value && prevState[fieldName] ? '' : prevState[fieldName],
                }));
            }
        },
        [],
    );
    const handleSubmitForm = useCallback(
        (event: FormEvent) => {
            event.preventDefault();
            const { formIsValid, validateMessages } = validateFormValues(form, formValues);

            if (formIsValid) {
                return onSubmit(formValues);
            }

            return setValidMessages(validateMessages);
        },
        [form, formValues, onSubmit],
    );

    return (
        <form className={className} onSubmit={handleSubmitForm} data-test-id={`${form}-form`}>
            <Space direction='vertical' fullWidth={true} size={24} className={cn('fields')}>
                {formFields.map(({ hasHiddenValue = false, ...field }) => (
                    <FormFieldInput
                        form={form}
                        key={field.name}
                        value={formValues[field.name]}
                        onChange={handleChangeFieldValue}
                        error={validMessages[field.name]}
                        name={field.name}
                        label={field.label}
                        hasHiddenValue={hasHiddenValue}
                    />
                ))}
            </Space>
            {children}
        </form>
    );
}
