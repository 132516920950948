import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { AmountInput, AmountInputProps } from '@alfalab/core-components/amount-input';
import { Typography } from '@alfalab/core-components/typography';

import {
    updateErrorsFilters,
    updateSelectedFilters,
} from '#/src/store/supplies/supplies-filters/action-creators';
import {
    errorsFiltersSelector,
    selectedFiltersSelector,
} from '#/src/store/supplies/supplies-filters/selectors';

import './amount-range-filter.css';

const cn = createCn('amount-range-filter');
const errorText = '"Сумма от" должна быть меньше "Суммы до"';

export function AmountRangeFilter() {
    const dispatch = useDispatch();
    const { toAmount, fromAmount } = useSelector(selectedFiltersSelector);
    const { amountRange } = useSelector(errorsFiltersSelector);

    const handleChangeFromAmount = useCallback<NonNullable<AmountInputProps['onChange']>>(
        (_, payload) => {
            const amount = { fromAmount: payload.valueString.replace(/\s+/g, '') };

            let amountError = false;

            if (amount.fromAmount && toAmount) {
                amountError =
                    +amount.fromAmount.replace(/,/, '.') >
                    +String(toAmount || '')
                        .replace(/\s+/g, '')
                        .replace(/,/, '.');
            }

            batch(() => {
                dispatch(updateErrorsFilters({ amountRange: amountError ? errorText : null }));
                dispatch(updateSelectedFilters({ ...amount }));
            });
        },
        [dispatch, toAmount],
    );

    const handleChangeToAmount = useCallback<NonNullable<AmountInputProps['onChange']>>(
        (_, payload) => {
            const amount = { toAmount: payload.valueString.replace(/\s+/g, '') };

            let amountError = false;

            if (amount.toAmount && fromAmount) {
                amountError =
                    +amount.toAmount.replace(/,/, '.') <
                    +String(fromAmount || '')
                        .replace(/\s+/g, '')
                        .replace(/,/, '.');
            }

            batch(() => {
                dispatch(updateErrorsFilters({ amountRange: amountError ? errorText : null }));
                dispatch(updateSelectedFilters({ ...amount }));
            });
        },
        [dispatch, fromAmount],
    );

    return (
        <React.Fragment>
            <div className={cn()}>
                <div className={cn('column')}>
                    <AmountInput
                        dataTestId='amount-range-from'
                        block={true}
                        integerLength={20}
                        integersOnly={true}
                        bold={false}
                        label='Сумма от'
                        suffix=''
                        minority={100}
                        value={+fromAmount * 100}
                        onChange={handleChangeFromAmount}
                    />
                </div>
                <span className={cn('divider')} />
                <div className={cn('column')}>
                    <AmountInput
                        dataTestId='amount-range-to'
                        block={true}
                        integerLength={20}
                        integersOnly={true}
                        bold={false}
                        label='Сумма до'
                        suffix=''
                        minority={100}
                        value={+toAmount * 100}
                        onChange={handleChangeToAmount}
                    />
                </div>
            </div>
            {!!amountRange && (
                <Typography.Text
                    tag='div'
                    view='secondary-large'
                    className={cn('error')}
                    color='accent'
                >
                    {amountRange}
                </Typography.Text>
            )}
        </React.Fragment>
    );
}
