import React from 'react';
import { createCn } from 'bem-react-classname';

import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { CollapseToggleSection } from '#/src/components/collapse-toggle-section';
import { SupplyCorrectionHistoryDto } from '#/src/types/supply';

import { SupplyCorrectionHistoryItem } from '../supply-correction-history-item';

import './supply-correction-history-list.css';

type SupplyCorrectionHistoryListProps = {
    history: SupplyCorrectionHistoryDto[];
};

const cn = createCn('supply-correction-history-list');

export function SupplyCorrectionHistoryList({ history }: SupplyCorrectionHistoryListProps) {
    return (
        <div className={cn()}>
            <CollapseToggleSection
                linkTextExpanded='История поставки'
                linkTextCollapsed='История поставки'
                linkDataTestId='toggle-expended-state-supply-correction-history-link'
            >
                <React.Fragment>
                    <Gap size='s' />
                    <Grid.Row justify='left' align='top'>
                        <Grid.Col width={4}>
                            <Typography.Text view='secondary-large' color='secondary' tag='div'>
                                Документ
                            </Typography.Text>
                        </Grid.Col>
                        <Grid.Col width={3}>
                            <Typography.Text view='secondary-large' color='secondary' tag='div'>
                                Размер корректировки
                            </Typography.Text>
                        </Grid.Col>
                        <Grid.Col width={3}>
                            <Typography.Text view='secondary-large' color='secondary' tag='div'>
                                Итоговая сумма
                            </Typography.Text>
                        </Grid.Col>
                    </Grid.Row>
                </React.Fragment>
                {history.map((item) => (
                    <SupplyCorrectionHistoryItem key={item.id} history={item} />
                ))}
            </CollapseToggleSection>
        </div>
    );
}
