import React from 'react';
import ReactDOMServer from 'react-dom/server';
import getQuarter from 'date-fns/get_quarter';
import { AxisLabelsFormatterCallbackFunction, AxisOptions, SeriesColumnOptions } from 'highcharts';

import { CountFilter, DashboardTabs, PeriodFilter } from '#/src/dictionaries/reports-dashboard';
import { Commission, Filters, Payment, Receivable, Refund } from '#/src/types/dashboard-reports';

import { quarterCategoriesMap, weekCategories, yearCategories } from './categories';
import HighChartsAxisLabel from './high-charts-axis-label';
import { commissionsSeries, paymentsSeries, receivablesSeries, refundsSeries } from './series';
import tooltipFormatterCreator from './tooltip-formatter-creator';

export type OptionsProps = {
    tab: DashboardTabs;
    receivables: Receivable[];
    commissions: Commission[];
    payments: Payment[];
    refunds: Refund[];
    filters: Filters;
};

function setSeriesData(series: SeriesColumnOptions, value: number): SeriesColumnOptions {
    return {
        ...series,
        data: series?.data?.length ? [...series.data, value] : [value],
    };
}

function formatterCreator(byCount: CountFilter): AxisLabelsFormatterCallbackFunction {
    return function formatter({ value }) {
        return ReactDOMServer.renderToString(
            <HighChartsAxisLabel amount={+value} byCount={byCount} />,
        );
    };
}

export default function getOptions({
    tab,
    receivables,
    commissions,
    payments,
    refunds,
    filters,
}: OptionsProps) {
    let series: SeriesColumnOptions[] = [];

    switch (tab) {
        case DashboardTabs.RECEIVABLES: {
            series = [...receivablesSeries];
            receivables.forEach((receivable) => {
                series[0] = setSeriesData(series[0], receivable.arrive);
                series[1] = setSeriesData(series[1], receivable.financed);
                series[2] = setSeriesData(series[2], receivable.paid);
            });
            break;
        }
        case DashboardTabs.COMMISSIONS: {
            series = [...commissionsSeries];
            commissions.forEach((commission) => {
                series[0] = setSeriesData(series[0], commission.comPDAmount);
                series[1] = setSeriesData(series[1], commission.comUdzAmount);
                series[2] = setSeriesData(series[2], commission.comMAmount);
            });
            break;
        }
        case DashboardTabs.PAYMENTS: {
            series = [...paymentsSeries];
            payments.forEach((payment) => {
                series[0] = setSeriesData(series[0], payment.clientPayments);
                series[1] = setSeriesData(series[1], payment.debtorPayments);
            });
            break;
        }
        case DashboardTabs.REFUNDS: {
            series = [...refundsSeries];
            refunds.forEach((refund) => {
                series[0] = setSeriesData(series[0], refund.paidByClient);
                series[1] = setSeriesData(series[1], refund.paidBefore);
            });
            break;
        }
    }

    let categories: AxisOptions['categories'] = [];

    switch (filters.period) {
        case PeriodFilter.WEEK: {
            categories = weekCategories;
            break;
        }
        case PeriodFilter.QUARTER: {
            const currentQuarterNumber = getQuarter(filters.dateFrom) as 1 | 2 | 3 | 4;

            categories = quarterCategoriesMap[currentQuarterNumber];
            break;
        }
        case PeriodFilter.YEAR: {
            categories = yearCategories;
            break;
        }
    }

    const formatter = formatterCreator(filters.byCount);

    return {
        chart: {
            type: 'column',
            height: 300,
        },
        title: {
            text: null,
        },
        plotOptions: {
            series: {
                minPointLength: 3,
                borderRadius: 3,
            },
        },
        legend: {
            enabled: false,
        },
        series,
        xAxis: {
            title: {
                text: null,
            },
            categories,
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                formatter,
                useHTML: true,
            },
            gridLineColor: 'transparent',
            gridLineDashStyle: 'dash',
        },
        tooltip: {
            borderColor: '#e6e8eb',
            borderRadius: 8,
            borderWidth: 1,
            backgroundColor: '#ffffff',
            formatter: tooltipFormatterCreator(tab, filters, series),
            shared: false,
            useHTML: true,
            outside: true,
            crosshairs: false,
        },
    };
}
