import { OptionShape } from '@alfalab/core-components/select/typings';

import { SupplyCorrectionType } from '#/src/types/supply';

export const suppliesFiltersCorrectionTypeDictionary: Record<SupplyCorrectionType, string> = {
    NO_CORRECTIONS: 'Без корректировок',
    HAS_CORRECTIONS: 'Содержит корректы',
    HAS_NOT_APPLIED_CORRECTIONS: 'Содержит неучтенные корректы',
};

export const suppliesFiltersCorrectionTypeOptions: OptionShape[] = Object.keys(
    suppliesFiltersCorrectionTypeDictionary,
).map((sourceKey: SupplyCorrectionType) => ({
    value: sourceKey,
    key: sourceKey,
    content: suppliesFiltersCorrectionTypeDictionary[sourceKey],
}));
