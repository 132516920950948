import React from 'react';

import { LOADING_STATE } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

export const changePasswordStart = createAction('CHANGE_PASSWORD / changePasswordStart', () => ({
    loadingState: LOADING_STATE.LOADING,
}));

export const changePasswordSuccess = createAction(
    'CHANGE_PASSWORD / changePasswordSuccess',
    () => ({
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const changePasswordError = createAction(
    'CHANGE_PASSWORD / changePasswordError',
    (error: React.ReactNode) => ({
        error,
        loadingState: LOADING_STATE.ERROR,
    }),
);
