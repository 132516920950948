import React from 'react';
import { createCn } from 'bem-react-classname';

import { Skeleton } from '@alfalab/core-components/skeleton';

import './limit-progress-bar.css';

type LimitProgressBarProps = {
    isLoading: boolean;
    percentageValue: number;
};

const cn = createCn('limit-progress-bar');

export default function LimitProgressBar({ isLoading, percentageValue }: LimitProgressBarProps) {
    return (
        <Skeleton visible={isLoading}>
            <div className={cn()}>
                <div className={cn('value')} style={{ width: `${percentageValue}%` }} />
            </div>
        </Skeleton>
    );
}
