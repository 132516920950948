import { CachedReportErrorCode } from '#/src/types/reports';

export const cachedReportsErrorsDictionary: Record<
    CachedReportErrorCode | 'UNKNOWN_ERROR',
    string
> = {
    UNKNOWN_ERROR:
        'Ошибка формирования отчета. Сформируйте отчет заново. При повторной ошибке обратитесь к менеджеру.',
    TECH_ERROR: 'Ошибка формирования отчета. Обратитесь к менеджеру.',
    // eslint-disable-next-line max-len
    QUEUE_IS_FULL:
        'Ошибка формирования отчета. Сформируйте отчет заново через 30 минут. При повторной ошибке обратитесь к менеджеру.',
};
