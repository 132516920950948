import { fetchFactory } from '#/src/server/services/lib/fetch-factory';
import { ChangePasswordPayload, LoginPayload, RestorePasswordPayload } from '#/src/types/employee';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        login: fetchFactory<LoginPayload, string>(`${contextRoot}/secure/nodeapi/logIn`, 'POST', {
            allowMultiRequest: true,
            csrfCookieName,
        }),
        logout: fetchFactory(`${contextRoot}/logout`, 'POST', {
            allowMultiRequest: true,
            csrfCookieName,
        }),
        changePassword: fetchFactory<ChangePasswordPayload, string>(
            `${contextRoot}/secure/nodeapi/changePassword`,
            'POST',
            {
                allowMultiRequest: true,
                csrfCookieName,
            },
        ),
        restorePassword: fetchFactory<RestorePasswordPayload, string>(
            `${contextRoot}/secure/nodeapi/restorePassword`,
            'POST',
            {
                allowMultiRequest: true,
                csrfCookieName,
            },
        ),
    };

    return {
        fetchLogin(params: LoginPayload) {
            return fetch.login(params);
        },
        fetchLogout() {
            return fetch.logout();
        },
        fetchChangePassword(params: ChangePasswordPayload) {
            return fetch.changePassword(params);
        },
        fetchRestorePassword(params: RestorePasswordPayload) {
            return fetch.restorePassword(params);
        },
    };
}
