import { ActiveErf, CombinedActiveErf } from '#/src/types/erf';

export default function combineActiveErf(activeErf: ActiveErf[]) {
    const activeErfSet: Record<string, CombinedActiveErf> = {};

    for (let i = 0; i < activeErf.length; i++) {
        const { debtorTin, supplyAgreementNumber, erfValue, ...erf } = activeErf[i];

        if (activeErfSet[debtorTin]) {
            const sameActiveErf = activeErfSet[debtorTin];

            activeErfSet[debtorTin] = {
                ...erf,
                debtorTin,
                erfValue: [erfValue].concat(sameActiveErf.erfValue),
                supplyAgreementNumber: [supplyAgreementNumber].concat(
                    sameActiveErf.supplyAgreementNumber,
                ),
            };
        } else {
            activeErfSet[debtorTin] = {
                debtorTin,
                supplyAgreementNumber: [supplyAgreementNumber],
                erfValue: [erfValue],
                ...erf,
            };
        }
    }

    return Object.values(activeErfSet);
}
