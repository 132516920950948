import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingState } from '#/src/constants/loading-state';
import { RegisterErf } from '#/src/types/registers';

import { fetchRegisterErf } from './actions';

export type RegisterErfState = {
    loadingState: LoadingState;
    erfList: RegisterErf[] | null;
    modalIsOpen: boolean;
};

const initialState: RegisterErfState = {
    loadingState: 'unknown',
    erfList: null,
    modalIsOpen: false,
};

export const {
    reducer: registerErfReducer,
    name,
    actions,
} = createSlice({
    name: 'registerErf',
    initialState,
    reducers: {
        clearState(state) {
            state.loadingState = 'unknown';
            state.erfList = null;
            state.modalIsOpen = false;
        },
        setModalState(state, action: PayloadAction<boolean>) {
            state.modalIsOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRegisterErf.pending, (state) => {
            state.loadingState = 'loading';
        });
        builder.addCase(fetchRegisterErf.fulfilled, (state, action) => {
            state.loadingState = 'success';
            state.erfList = action.payload;
            state.modalIsOpen = true;
        });
        builder.addCase(fetchRegisterErf.rejected, (state) => {
            state.loadingState = 'error';
            state.erfList = null;
        });
    },
});
