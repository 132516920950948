import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RadioGroup, RadioGroupProps } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';

import { changeOrganizationForPage } from '#/src/store/organizations/actions';
import {
    currentOrganizationInfoSelector,
    currentOrganizationTypeSelector,
} from '#/src/store/organizations/selectors';
import { Permission } from '#/src/types/orgatiozations';

const OrganizationTypeRadioGroup: FC = () => {
    const dispatch = useDispatch();
    const organizationType = useSelector(currentOrganizationTypeSelector);
    const organizationInfo = useSelector(currentOrganizationInfoSelector);

    const handleChangeOrganizationType = useCallback<NonNullable<RadioGroupProps['onChange']>>(
        (event, payload) => {
            if (organizationInfo) {
                dispatch(
                    changeOrganizationForPage(
                        organizationInfo.tin,
                        (payload?.value as 'supplier' | 'debtor') || null,
                    ),
                );
            }
        },
        [dispatch, organizationInfo],
    );

    return organizationType && organizationInfo ? (
        <div>
            <RadioGroup
                onChange={handleChangeOrganizationType}
                value={organizationType}
                direction='horizontal'
                label='Выберите'
                type='tag'
            >
                {organizationInfo.actions.includes(Permission.FACT_SHOW_SELLER_REPORTS_NEW) && (
                    <Tag value='supplier' size='xs'>
                        Поставщик
                    </Tag>
                )}
                {organizationInfo.actions.includes(Permission.FACT_SHOW_DEBTOR_REPORTS_NEW) && (
                    <Tag value='debtor' size='xs'>
                        Дебитор
                    </Tag>
                )}
            </RadioGroup>
        </div>
    ) : null;
};

export default OrganizationTypeRadioGroup;
