import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';
import { ArrowsCwMIcon } from '@alfalab/icons-glyph/ArrowsCwMIcon';

import { reloadSuppliesPage } from '#/src/store/supplies/supplies-list/action-creators';
import { needUpdateSelector } from '#/src/store/supplies/supplies-list/selectors';

import './supplies-need-update-notification.css';

const cn = createCn('supplies-need-update-notification');

export function SuppliesNeedUpdateNotification() {
    const dispatch = useDispatch();
    const needUpdate = useSelector(needUpdateSelector);

    const handleReloadSuppliesPage = useCallback(() => {
        dispatch(reloadSuppliesPage());
    }, [dispatch]);

    if (needUpdate) {
        return (
            <div className={cn('head')}>
                <div className={cn('content')}>
                    <Typography.Text
                        view='secondary-large'
                        tag='div'
                        color='positive'
                        className={cn('icon')}
                    >
                        <AlertCircleMIcon />
                    </Typography.Text>
                    <Typography.Text view='primary-small' tag='div' weight='bold'>
                        Сменились статусы/поступили новые поставки
                    </Typography.Text>
                    <Typography.Text view='secondary-large' tag='div' className={cn('link')}>
                        <Link
                            dataTestId='reload-supplies-link'
                            rightAddons={<ArrowsCwMIcon width='16px' height='16px' />}
                            pseudo={true}
                            onClick={handleReloadSuppliesPage}
                        >
                            Обновить список
                        </Link>
                    </Typography.Text>
                </div>
            </div>
        );
    }

    return null;
}
