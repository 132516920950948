import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { SupplyRepaymentsActionsType } from './types';

export function fetchSupplyRepayments(
    supplyId: number,
): ThunkAction<void, ApplicationState, void, SupplyRepaymentsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getSupplyRepaymentsStart(supplyId));
        try {
            const response = await fetchers.fetchSupplyRepayments(supplyId);

            dispatch(actionCreators.getSupplyRepaymentsSuccess(supplyId, response));
        } catch (e) {
            dispatch(actionCreators.getSupplyRepaymentsError(supplyId));
        }
    };
}
