import React, { useMemo } from 'react';
import { v4 as generateId } from 'uuid';

import { Typography } from '@alfalab/core-components/typography';

import Slider from '#/src/components/slider';
import { DebtorLimit } from '#/src/types/dashboard-reports';

import DebtorLimitsGroup from './debtor-limits-group';
import DebtorLimitsGroupSkeleton from './debtor-limits-group-skeleton';

type DebtorLimitsSliderProps = {
    debtorLimits: DebtorLimit[];
    isLoading?: boolean;
};

const skeletonElements = [<DebtorLimitsGroupSkeleton key={generateId()} />];

export default function DebtorLimitsSlider({ debtorLimits, isLoading }: DebtorLimitsSliderProps) {
    const elements = useMemo(() => {
        const debtorLimitGroups = debtorLimits.reduce((arr, item, index) => {
            const result = [...arr];
            const i = index ? Math.floor(index / 3) : 0;

            result[i] = arr[i] ? [...arr[i], item] : [item];

            return result;
        }, [] as DebtorLimit[][]);

        return debtorLimitGroups.map((group) => (
            <DebtorLimitsGroup key={generateId()} group={group} />
        ));
    }, [debtorLimits]);

    return (
        <React.Fragment>
            <Typography.Title tag='h3' view='medium' weight='bold' defaultMargins={true}>
                Мои лимиты в разбивке по дебиторам
            </Typography.Title>
            <Slider elements={isLoading ? skeletonElements : elements} />
        </React.Fragment>
    );
}
