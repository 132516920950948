import React, { useCallback, useState } from 'react';

import { IconButton } from '@alfalab/core-components/icon-button';
import { Modal } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';
import { PhoneLineMIcon } from '@alfalab/icons-glyph/PhoneLineMIcon';

import ExternalPagePhonesInfo from './external-page-phones-info';

export default function ExternalPagePhonesModel() {
    const [modalIsOpen, setModalState] = useState(false);
    const handleCloseModal = useCallback(() => setModalState(false), []);
    const handleOpenModal = useCallback(() => setModalState(true), []);

    return (
        <React.Fragment>
            <IconButton icon={PhoneLineMIcon} size='s' onClick={handleOpenModal} />
            <Modal open={modalIsOpen} hasCloser={true} onClose={handleCloseModal} fullscreen={true}>
                <Modal.Header hasCloser={true} />
                <Modal.Content>
                    <Typography.Title tag='div' view='small' weight='bold'>
                        Информация и проведение операций 24/7
                    </Typography.Title>
                    <ExternalPagePhonesInfo view='columns' hasIcon={false} />
                </Modal.Content>
            </Modal>
        </React.Fragment>
    );
}
