import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { NeedUpdatePayload } from '#/src/server/services/api/supply';
import { addNotification } from '#/src/store/notifications/action-creators';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { updateAllFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { appliedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';
import {
    needUpdateSelector,
    selectedIdsSelector,
    serverRequestTimeSelector,
    suppliesIdsSelector,
    suppliesPaginationSelector,
    suppliesSortSelector,
} from '#/src/store/supplies/supplies-list/selectors';
import {
    GetSuppliesPayload,
    GetSuppliesResponse,
    SupplyDto,
    SupplyStatus,
} from '#/src/types/supply';
import { fetchers } from '#/src/utils/client-api';
import convertSuppliesFiltersToRequestPayload from '#/src/utils/convert-supplies-filters-to-request-payload';

import {
    changeSuppliesListLoadingState,
    getSupplies,
    getSuppliesByPage,
    needUpdateToggle,
    reloadSuppliesPage,
    setNeedUpdate,
    setSort,
    setSupplies,
    setSuppliesList,
} from './action-creators';
import { mapAvailableSuppliesList, mapFirstSuppliesResponseToTotalResult } from './utils';

const needFieldsForPage = [
    'id',
    'supplyNumber',
    'supplyAgreementNumber',
    'debtorName',
    'supplyAmount',
    'amountCertificationDocumentDate',
    'status',
    'supplyAmountCorrection',
    'amountCertificationDocumentType',
    'amountCertificationDocumentNumber',
    'error',
    'supplySource',
    'registerNumber',
    'registerId',
    'rpdNumber',
    'financingType',
    'requestedAmount',
];

export function* getSuppliesWorker(action: ReturnType<typeof getSupplies>) {
    try {
        yield put(needUpdateToggle(false));
        yield put(changeSuppliesListLoadingState(LOADING_STATE.LOADING));

        const { page, pageSize, needMergeSelected = false, reloadPage = false } = action;
        const filters: ReturnType<typeof appliedFiltersSelector> = yield select(
            appliedFiltersSelector,
        );
        const currentOrganizationId: ReturnType<typeof currentOrganizationIdSelector> =
            yield select(currentOrganizationIdSelector);
        const sort: ReturnType<typeof suppliesSortSelector> = yield select(suppliesSortSelector);
        const serverRequestTime: ReturnType<typeof serverRequestTimeSelector> = yield select(
            serverRequestTimeSelector,
        );
        const currentSelectedIds: ReturnType<typeof selectedIdsSelector> = yield select(
            selectedIdsSelector,
        );
        const needUpdateState: ReturnType<typeof needUpdateSelector> = yield select(
            needUpdateSelector,
        );
        const currenSupplyIds: ReturnType<typeof suppliesIdsSelector> = yield select(
            suppliesIdsSelector,
        );

        if (reloadPage && needUpdateState) yield put(setNeedUpdate(false));

        const paramsByIds: GetSuppliesPayload = {
            ...convertSuppliesFiltersToRequestPayload(currentOrganizationId as string, filters),
            needCompressedAnswer: false,
            needToGetShortSupplyAnswer: false,
            needFields: ['id', 'supplyAmount', 'supplyAmountCorrection'],
        };

        if (needMergeSelected && !reloadPage && serverRequestTime) {
            paramsByIds.serverRequestTime = serverRequestTime;
            paramsByIds.needToGetShortSupplyAnswer = false;
        }
        if (sort) {
            paramsByIds.sortField = sort.field;
            paramsByIds.sortOrder = sort.order;
        }

        const {
            supplies: idsSupplies,
            serverRequestTime: newServerRequestTime,
        }: GetSuppliesResponse = yield call(fetchers.fetchSupplies, paramsByIds);

        const { supplyIds, totalPrice, allTotalPrice } = mapFirstSuppliesResponseToTotalResult(
            idsSupplies as SupplyDto[],
        );

        if (!supplyIds.length) {
            yield put(needUpdateToggle(true));

            yield put(
                setSupplies({
                    ids: [],
                    availableSupplies: {
                        available: {},
                        source: {},
                        amountCertificationDocumentDate: {},
                    },
                    selectedIds: [],
                    entities: [],
                    pagination: {
                        page,
                        pageSize,
                    },
                    totalPrice: 0,
                    allTotalPrice: {},
                    serverRequestTime:
                        !serverRequestTime || reloadPage ? newServerRequestTime : serverRequestTime,
                }),
            );

            return;
        }

        let availableEdiSupplies: SupplyDto[] = [];
        let availableRpdSupplies: SupplyDto[] = [];

        const statusesByAvailableIdsForEdi: SupplyStatus[] = ['RECEIVED', 'ERROR'];
        const statusesByAvailableIdsForRpd: SupplyStatus[] = ['ERROR', 'APPROVED'];
        const statusesByAvailableIdsRequestForEdi = filters.statuses.length
            ? statusesByAvailableIdsForEdi.filter((f) => filters.statuses.includes(f))
            : statusesByAvailableIdsForEdi;
        const statusesByAvailableIdsRequestForRpd = filters.statuses.length
            ? statusesByAvailableIdsForRpd.filter((f) => filters.statuses.includes(f))
            : statusesByAvailableIdsForRpd;

        if (
            statusesByAvailableIdsRequestForEdi.length ||
            statusesByAvailableIdsRequestForRpd.length
        ) {
            const paramsByAvailableIds: GetSuppliesPayload = {
                ...convertSuppliesFiltersToRequestPayload(currentOrganizationId as string, filters),
                needCompressedAnswer: false,
                needToGetShortSupplyAnswer: false,
                needFields: [
                    'id',
                    'supplyAmount',
                    'supplyAmountCorrection',
                    'supplySource',
                    'amountCertificationDocumentDate',
                    'financingType',
                ],
                supplyIds,
            };

            if (sort) {
                paramsByAvailableIds.sortField = sort.field;
                paramsByAvailableIds.sortOrder = sort.order;
            }

            if (needMergeSelected && !reloadPage && serverRequestTime) {
                paramsByAvailableIds.serverRequestTime = serverRequestTime;
                paramsByAvailableIds.needToGetShortSupplyAnswer = false;
            }

            if (
                (filters.supplySource === 'ALL' || filters.supplySource === 'EDI') &&
                statusesByAvailableIdsRequestForEdi.length
            ) {
                const { supplies }: GetSuppliesResponse = yield call(fetchers.fetchSupplies, {
                    ...paramsByAvailableIds,
                    supplySources: ['EDI'],
                    statuses: statusesByAvailableIdsRequestForEdi,
                });

                availableEdiSupplies = supplies as SupplyDto[];
            }
            if (
                (filters.supplySource === 'ALL' || filters.supplySource === 'RPD') &&
                statusesByAvailableIdsRequestForRpd.length
            ) {
                const { supplies }: GetSuppliesResponse = yield call(fetchers.fetchSupplies, {
                    ...paramsByAvailableIds,
                    supplySources: ['RPD'],
                    statuses: statusesByAvailableIdsRequestForRpd,
                });

                availableRpdSupplies = supplies as SupplyDto[];
            }
        }

        const paramsByList = {
            ...convertSuppliesFiltersToRequestPayload(currentOrganizationId as string, filters),
            needCompressedAnswer: false,
            needToGetShortSupplyAnswer: false,
            supplyIds: supplyIds.slice((page - 1) * pageSize, pageSize * page),
            needFields: needFieldsForPage,
        };

        if (needMergeSelected && !reloadPage && serverRequestTime) {
            paramsByList.serverRequestTime = serverRequestTime;
        }

        if (sort) {
            paramsByList.sortField = sort.field;
            paramsByList.sortOrder = sort.order;
        }

        const { supplies: entities }: GetSuppliesResponse = yield call(
            fetchers.fetchSupplies,
            paramsByList,
        );

        const availableSupplies = [...availableEdiSupplies, ...availableRpdSupplies];
        let newSelectedSupplies: number[] = [];

        const availableSuppliesFiltered = mapAvailableSuppliesList(availableSupplies);

        if (needMergeSelected) {
            if (currentSelectedIds.length && currenSupplyIds.length === currentSelectedIds.length) {
                newSelectedSupplies = supplyIds;
            } else {
                newSelectedSupplies = currentSelectedIds.filter((id) => supplyIds.includes(id));
            }
        }

        yield put(
            setSupplies({
                ids: supplyIds,
                availableSupplies: availableSuppliesFiltered,
                selectedIds: newSelectedSupplies,
                entities: entities as SupplyDto[],
                serverRequestTime:
                    !serverRequestTime || reloadPage ? newServerRequestTime : serverRequestTime,
                pagination: {
                    page,
                    pageSize,
                },
                totalPrice,
                allTotalPrice,
            }),
        );
        yield put(needUpdateToggle(true));
        if (reloadPage) yield put(setNeedUpdate(false));
    } catch (error) {
        yield put(changeSuppliesListLoadingState(LOADING_STATE.ERROR));
        yield put(
            addNotification({
                title: 'Произошла ошибка',
                badge: 'negative',
                id: uuidv4(),
            }),
        );
    }
}

export function* getSupplyListWorker({ page, pageSize }: ReturnType<typeof getSuppliesByPage>) {
    try {
        yield put(changeSuppliesListLoadingState(LOADING_STATE.LOADING));
        const currentOrganizationId: ReturnType<typeof currentOrganizationIdSelector> =
            yield select(currentOrganizationIdSelector);
        const filters: ReturnType<typeof appliedFiltersSelector> = yield select(
            appliedFiltersSelector,
        );
        const sort: ReturnType<typeof suppliesSortSelector> = yield select(suppliesSortSelector);
        const serverRequestTime: ReturnType<typeof serverRequestTimeSelector> = yield select(
            serverRequestTimeSelector,
        );
        const supplyIds: ReturnType<typeof suppliesIdsSelector> = yield select(suppliesIdsSelector);

        const paramsByList: GetSuppliesPayload = {
            ...convertSuppliesFiltersToRequestPayload(currentOrganizationId as string, filters),
            needCompressedAnswer: false,
            needToGetShortSupplyAnswer: false,
            supplyIds: supplyIds.slice((page - 1) * pageSize, pageSize * page),
            needFields: needFieldsForPage,
            serverRequestTime,
        };

        if (sort) {
            paramsByList.sortField = sort.field;
            paramsByList.sortOrder = sort.order;
        }

        const { supplies: entities }: GetSuppliesResponse = yield call(
            fetchers.fetchSupplies,
            paramsByList,
        );

        yield put(
            setSuppliesList({
                entities: entities as SupplyDto[],
                pagination: {
                    page,
                    pageSize,
                },
            }),
        );
    } catch (error) {
        yield put(changeSuppliesListLoadingState(LOADING_STATE.ERROR));
        yield put(
            addNotification({
                title: 'Произошла ошибка',
                badge: 'negative',
                id: uuidv4(),
            }),
        );
    }
}

export function* suppliesWorker() {
    while (true) {
        const { type } = yield take([updateAllFilters.type, reloadSuppliesPage.type, setSort.type]);

        switch (type) {
            case updateAllFilters.type: {
                yield put(getSupplies({ reloadPage: true }));
                break;
            }
            case reloadSuppliesPage.type: {
                const { page, pageSize }: ReturnType<typeof suppliesPaginationSelector> =
                    yield select(suppliesPaginationSelector);

                yield put(
                    getSupplies({ page, pageSize, needMergeSelected: true, reloadPage: true }),
                );
                yield put(setNeedUpdate(false));
                break;
            }
            case setSort.type: {
                const { pageSize }: ReturnType<typeof suppliesPaginationSelector> = yield select(
                    suppliesPaginationSelector,
                );

                yield put(getSupplies({ pageSize, needMergeSelected: true }));
                break;
            }
        }
    }
}

export function* needUpdateWorker(action: ReturnType<typeof needUpdateToggle>) {
    while (action.needUpdate) {
        yield delay(10000);
        try {
            const currentOrganizationId: ReturnType<typeof currentOrganizationIdSelector> =
                yield select(currentOrganizationIdSelector);
            const filters: ReturnType<typeof appliedFiltersSelector> = yield select(
                appliedFiltersSelector,
            );
            const serverRequestTime: ReturnType<typeof serverRequestTimeSelector> = yield select(
                serverRequestTimeSelector,
            );

            const requestData: NeedUpdatePayload = {
                ...convertSuppliesFiltersToRequestPayload(currentOrganizationId as string, filters),
                serverRequestTime,
            };

            const needUpdate = JSON.parse(yield call(fetchers.fetchNeedUpdate, requestData));

            yield put(setNeedUpdate(needUpdate));

            if (needUpdate) yield put(needUpdateToggle(false));
        } catch (error) {
            yield put(
                addNotification({
                    title: 'Произошла ошибка',
                    badge: 'negative',
                    id: uuidv4(),
                }),
            );
        }
    }
}

export default function* suppliesSaga() {
    yield all([
        takeLatest([getSupplies.type], getSuppliesWorker),
        takeLatest([getSuppliesByPage.type], getSupplyListWorker),
        suppliesWorker(),
        takeLatest([needUpdateToggle.type], needUpdateWorker),
    ]);
}
