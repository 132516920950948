import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRoot } from '#/src/browser-root';
import { configureStore } from '#/src/configure-store';
import { ApplicationState } from '#/src/store/reducer';
import { contextRootSelector } from '#/src/store/settings/selectors';
import { initClientApi } from '#/src/utils/client-api';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    window.__main = (state: ApplicationState) => {
        const contextRoot = contextRootSelector(state);
        const { store, history } = configureStore(contextRoot || '/')(state);

        initClientApi(contextRoot);

        ReactDOM.hydrate(
            <BrowserRoot store={store} history={history} />,
            document.getElementById('react-app'),
        );

        if (!IS_PRODUCTION && module.hot) {
            module.hot.accept(['./root', './configure-store'], () => {
                ReactDOM.render(
                    <BrowserRoot store={store} history={history} />,
                    document.getElementById('react-app'),
                );
            });
        }
    };
}
