import getMonth from 'date-fns/get_month';
import getYear from 'date-fns/get_year';

import { monthNames, PeriodFilter } from '#/src/dictionaries/reports-dashboard';
import { formatDateForCalendar } from '#/src/utils/date-converter';

export default function getPeriodValue(dateFrom: string, dateTo: string, period: PeriodFilter) {
    if (PeriodFilter.QUARTER === period) {
        return `${monthNames[getMonth(dateFrom)]} - ${monthNames[getMonth(dateTo)]} ${getYear(
            dateFrom,
        )}`;
    }

    if (PeriodFilter.YEAR === period) {
        return getYear(dateFrom);
    }

    return `${formatDateForCalendar(new Date(dateFrom))} - ${formatDateForCalendar(
        new Date(dateTo),
    )}`;
}
