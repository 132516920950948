import React from 'react';
import { useSelector } from 'react-redux';

import ApplicationMenuItem from 'arui-private/core/application-menu-item';
import { ApplicationMenuItemProps } from 'arui-private/core/application-menu-item/application-menu-item';

import { pathnameSelector } from '#/src/store/router/selectors';

type MenuItemProps = {
    name: string;
    segment: string;
    href: ApplicationMenuItemProps['href'];
};

export default function MenuItem({ name, segment, href }: MenuItemProps) {
    const currentPath = useSelector(pathnameSelector);

    return (
        <ApplicationMenuItem
            dataTestId={`app-menu-item-${segment.toLocaleLowerCase()}`}
            checked={currentPath === href}
            href={href}
        >
            {name}
        </ApplicationMenuItem>
    );
}
