import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { IconButton } from '@alfalab/core-components/icon-button';
import { ArrowForwardCircleMIcon } from '@alfalab/icons-glyph/ArrowForwardCircleMIcon';
import { TrashCanMIcon } from '@alfalab/icons-glyph/TrashCanMIcon';

import { deleteRegister } from '#/src/store/registers/register-info/actions';

import './register-delete-toggle-button.css';

type RegisterDeleteToggleButtonProps = {
    id: number;
    deleted: boolean;
    partyTin: string;
};

const cn = createCn('register-delete-toggle-button');

export default function RegisterDeleteToggleButton({
    deleted,
    id,
    partyTin,
}: RegisterDeleteToggleButtonProps) {
    const dispatch = useDispatch();
    let IconComponent = TrashCanMIcon;

    if (deleted) {
        IconComponent = ArrowForwardCircleMIcon;
    }

    const handleToggleDeleteState = useCallback(
        () => dispatch(deleteRegister(id, partyTin, !deleted)),
        [dispatch, deleted, id, partyTin],
    );

    return (
        <IconButton
            dataTestId='delete-registry-button'
            className={cn()}
            view='primary'
            size='xs'
            onClick={handleToggleDeleteState}
            icon={IconComponent}
        />
    );
}
