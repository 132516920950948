import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SignButtonProps as SignButtonCoreProps } from '@alfa-bank/corp-sign-ui-react/components/SignButton';

import { Button } from '@alfalab/core-components/button';

import { prepareSignSuppliesData } from '#/src/store/supplies/sign-supplies/actions';
import { signSuppliesIsLoadingSelector } from '#/src/store/supplies/sign-supplies/selectors';
import { selectedIdsSelector } from '#/src/store/supplies/supplies-list/selectors';

export function SignButton(props: Partial<SignButtonCoreProps>) {
    const dispatch = useDispatch();
    const isLoading = useSelector(signSuppliesIsLoadingSelector);
    const selectedIds = useSelector(selectedIdsSelector);

    const handleSignButtonClick = useCallback(() => {
        dispatch(prepareSignSuppliesData(selectedIds, props));
    }, [dispatch, selectedIds, props]);

    return (
        <Button
            loading={isLoading || props.loading}
            view='primary'
            size='xxs'
            onClick={handleSignButtonClick}
        >
            Подписать и отправить
        </Button>
    );
}
