import React from 'react';

import { Spinner } from '@alfalab/core-components/spinner';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { AttentionMarkMColorIcon } from '@alfalab/icons-classic/AttentionMarkMColorIcon';
import { FormatXlsMBlackIcon } from '@alfalab/icons-classic/FormatXlsMBlackIcon';
import { OkMColorIcon } from '@alfalab/icons-classic/OkMColorIcon';

import { cachedReportsErrorsDictionary } from '#/src/dictionaries/cached-reports-errors';
import { CachedReportErrorCode, CachedReportStatus } from '#/src/types/reports';

type ReportXlsStatusIconProps = {
    status: CachedReportStatus;
    requestedInCurrentSession?: boolean;
    className?: string;
    errorCode: CachedReportErrorCode | null;
};

export default function ReportXlsStatusIcon({
    status,
    className,
    requestedInCurrentSession = false,
    errorCode,
}: ReportXlsStatusIconProps) {
    switch (true) {
        case status === CachedReportStatus.SUCCESS && requestedInCurrentSession:
            return <OkMColorIcon className={className} />;
        case status === CachedReportStatus.ERROR:
            return (
                <Tooltip
                    content={
                        (errorCode && cachedReportsErrorsDictionary[errorCode]) ||
                        cachedReportsErrorsDictionary.UNKNOWN_ERROR
                    }
                    position='right'
                    trigger='hover'
                >
                    <AttentionMarkMColorIcon className={className} />
                </Tooltip>
            );
        case status === CachedReportStatus.PROCESS:
            return <Spinner visible={true} size='s' className={className} />;
        default:
            return <FormatXlsMBlackIcon className={className} />;
    }
}
