import { ThunkAction } from 'redux-thunk';

import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { RepaymentsActionsType } from './types';

export function fetchRepayments(
    id: number,
): ThunkAction<void, ApplicationState, void, RepaymentsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getRepaymentsStart(id));

        try {
            const response = await fetchers.fetchRegisterRepayments(id);

            dispatch(actionCreators.getRepaymentsSuccess(response));
        } catch (e) {
            dispatch(actionCreators.getRepaymentsError());
            dispatch(dispatchErrorNotification(e));
        }
    };
}
