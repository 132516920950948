import { OptionShape } from '@alfalab/core-components/select/typings';
import { StatusProps } from '@alfalab/core-components/status';

import { RegisterRowStatusType } from '#/src/types/registers';

export const statusColors: Record<RegisterRowStatusType, StatusProps['color']> = {
    DUPLICATED: 'grey',
    RECEIVED: 'blue',
    APPROVED: 'blue',
    ERROR_PROCESSED: 'red',
    FINANCING_REQUESTED: 'orange',
    PROCESSING: 'orange',
    AF_RECEIVED: 'teal',
    PARTIALLY_FINANCED: 'teal',
    VERIFIED: 'purple',
    PARTIALLY_PAID: 'teal',
    FINANCED: 'green',
    CLOSED: 'green',
    REJECTED: 'red',
    ERROR: 'red',
};

export const statusDictionary: Record<RegisterRowStatusType, string> = {
    DUPLICATED: 'Дубликат',
    RECEIVED: 'Обработана',
    ERROR_PROCESSED: 'Ошибка обработки',
    FINANCING_REQUESTED: 'Отправлено',
    AF_RECEIVED: 'Получено',
    VERIFIED: 'Принято',
    PARTIALLY_FINANCED: 'Частично профинансировано',
    FINANCED: 'Профинансировано',
    PARTIALLY_PAID: 'Частично оплачено',
    CLOSED: 'Закрыто',
    REJECTED: 'Отклонено',
    APPROVED: 'Подтверждено',
    PROCESSING: 'Отправлено на сверку',
    ERROR: 'Ошибка',
};

export const statusBySellerOptions: OptionShape[] = Object.keys(statusDictionary)
    .filter((status) => status !== 'PROCESSING')
    .map((status: RegisterRowStatusType) => ({
        value: status,
        key: status,
        content: statusDictionary[status],
    }));

export const statusByDebtorOptions: OptionShape[] = Object.keys(statusDictionary)
    .filter((status) => status !== 'FINANCING_REQUESTED')
    .map((status: RegisterRowStatusType) => ({
        value: status,
        key: status,
        content: statusDictionary[status],
    }));
