import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { registersModelsSelector } from '#/src/store/registers/selectors';

export const sendRegisterStateSelector = (state: ApplicationState) =>
    registersModelsSelector(state).sendRegister;

export const sendModalIsOpenSelector = (state: ApplicationState) =>
    sendRegisterStateSelector(state).sendModalIsOpen;

export const formSelector = (state: ApplicationState) => sendRegisterStateSelector(state).form;

export const fileSelector = (state: ApplicationState) => formSelector(state).file;

export const partyTinSelector = (state: ApplicationState) => formSelector(state).partyTin;

export const typeSelector = (state: ApplicationState) => formSelector(state).type;

export const isRegisterSelector = (state: ApplicationState) => formSelector(state).isRegister;

export const financingTypeSelector = (state: ApplicationState) => formSelector(state).financingType;

export const loadingStateSelector = (state: ApplicationState) =>
    sendRegisterStateSelector(state).loadingState;

export const sendRegisterIsLoadingSelector = (state: ApplicationState) =>
    loadingStateSelector(state) === LOADING_STATE.LOADING;

export const resultModalIsOpenSelector = (state: ApplicationState) =>
    sendRegisterStateSelector(state).resultModalIsOpen;

export const resultSelector = (state: ApplicationState) => sendRegisterStateSelector(state).result;
