import React from 'react';
import { useSelector } from 'react-redux';

import {
    entitiesIsErrorSelector,
    suppliesSelector,
} from '#/src/store/supplies/supplies-list/selectors';

import { SupplyTableRow } from '../../supply-table-row';

import { SuppliesTableBodyEmpty } from './supplies-table-body-empty';

export function SuppliesTableBody() {
    const supplies = useSelector(suppliesSelector);
    const isError = useSelector(entitiesIsErrorSelector);

    if (!supplies.length && !isError) {
        return <SuppliesTableBodyEmpty />;
    }

    return (
        <React.Fragment>
            {supplies.map((supply) => (
                <SupplyTableRow key={supply.id} supply={supply} />
            ))}
        </React.Fragment>
    );
}
