import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { appliedFiltersSelector } from '#/src/store/registers/register-info-filters/selectors';
import { RegisterRow } from '#/src/types/registers';
import { SortField } from '#/src/types/sort';
import { formatDateTimeToUnixTime } from '#/src/utils/date-converter';

import { registersModelsSelector } from '../selectors';

export const registerInfoStateSelector = (state: ApplicationState) =>
    registersModelsSelector(state).registerInfo;

export const entitiesSelector = (state: ApplicationState) =>
    registerInfoStateSelector(state).entities;

export const idSelector = (state: ApplicationState) => registerInfoStateSelector(state).id;

export const sortSelector = (state: ApplicationState) => registerInfoStateSelector(state).sort;

export const countSelector = (state: ApplicationState) => registerInfoStateSelector(state).count;

export const paginationSelector = (state: ApplicationState) =>
    registerInfoStateSelector(state).pagination;

export const registerInfoSelector = (state: ApplicationState) =>
    registerInfoStateSelector(state).info;

export const currencyCountsSelector = (state: ApplicationState) =>
    registerInfoStateSelector(state).currencyCounts;

export const selectedRowIdSelector = (state: ApplicationState) =>
    registerInfoStateSelector(state).selectedRowId;

export const registerInfoLoadingStateSelector = (state: ApplicationState) =>
    registerInfoStateSelector(state).loadingState;

export const registerInfoIsLoadingSelector = createSelector(
    registerInfoLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.LOADING,
);

export const registerInfoIsErrorSelector = createSelector(
    registerInfoLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.ERROR,
);

export const registerInfoIsSuccessSelector = createSelector(
    registerInfoLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.SUCCESS,
);

export const selectedRowSelector = createSelector(
    selectedRowIdSelector,
    entitiesSelector,
    (id, entities) => (id ? entities.find((entity) => entity.id === id) : null),
);

export const filteredEntitiesSelector = createSelector(
    entitiesSelector,
    appliedFiltersSelector,
    sortSelector,
    registerInfoSelector,
    (entities, filters, sort, register) =>
        orderBy(
            entities.filter((entity) => {
                if (filters.status.length) {
                    if (!filters.status.includes(entity.status)) {
                        return false;
                    }
                }
                if (entity.supplyDate) {
                    const entityDate = entity.supplyDate;

                    if (
                        (filters.dateFrom &&
                            formatDateTimeToUnixTime(filters.dateFrom, '00:00:00') > entityDate) ||
                        (filters.dateTo &&
                            formatDateTimeToUnixTime(filters.dateTo, '23:59:59') < entityDate)
                    ) {
                        return false;
                    }
                }

                const debtors = register?.debtor ? filters.sellersTin : filters.debtorsTin;

                if (debtors.length) {
                    if (
                        register?.debtor
                            ? !debtors.includes(entity.sellerTin)
                            : !debtors.includes(entity.debtorTin)
                    ) {
                        return false;
                    }
                }

                if (filters.agreementNumber) {
                    if (
                        filters.agreementNumber.trim().toLocaleLowerCase() !==
                        String(entity.agreementNumber).toLocaleLowerCase()
                    ) {
                        return false;
                    }
                }

                if (filters.documentNumber) {
                    if (
                        filters.documentNumber.trim().toLocaleLowerCase() !==
                        String(entity.documentNumber).toLocaleLowerCase()
                    ) {
                        return false;
                    }
                }

                if (entity.supplyAmount !== null) {
                    if (
                        (filters.amountFrom && +filters.amountFrom > entity.supplyAmount) ||
                        (filters.amountTo && +filters.amountTo < entity.supplyAmount)
                    ) {
                        return false;
                    }
                }

                return true;
            }),
            [
                (entity: RegisterRow) =>
                    sort.field === SortField.DATE ? entity.supplyDate : entity.supplyAmount * 100,
                'documentNumber',
            ],
            [sort.order.toLocaleLowerCase() as 'desc' | 'asc', 'asc'],
        ),
);

export const countsOfFilteredEntitiesSelector = createSelector(
    filteredEntitiesSelector,
    (entities) => entities.length,
);
