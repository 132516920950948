import { FiltersState } from '#/src/store/registers/registers-filters/reducer';
import { RegisterSourceAllType } from '#/src/types/registers';
import { formatDateForCalendar } from '#/src/utils/date-converter';

export function getDefaultRegistersFiltersState(): FiltersState {
    return {
        role: null,
        status: [],
        dateFrom: formatDateForCalendar(Date.now() - 604800000),
        dateTo: formatDateForCalendar(Date.now()),
        fileNumber: '',
        registerSource: RegisterSourceAllType.ALL,
        documentNumber: '',
        sellersTin: [],
        debtorsTin: [],
    };
}
