import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    changeWarningModalState,
    clearState,
} from '#/src/store/registers/sign-registers/action-creators';
import {
    documentsSelector,
    permissionsSelector,
    warningModalIsOpenSelector,
} from '#/src/store/registers/sign-registers/selectors';

import { ChooseCertificateRegistersSignModal } from './choose-certificate-registers-sign-modal';

type SuccessResultModalProps = {
    isDebtor: boolean;
};

export function WarningModal({ isDebtor }: SuccessResultModalProps) {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(warningModalIsOpenSelector);
    const permissions = useSelector(permissionsSelector);
    const documents = useSelector(documentsSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(changeWarningModalState(false));
    }, [dispatch]);

    const handleClearState = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    return (
        <ChooseCertificateRegistersSignModal
            onClose={handleCloseModal}
            onUnmount={handleClearState}
            open={modalIsOpen}
            permissions={permissions}
            isDebtor={isDebtor}
            documents={documents}
            certificatesAreHidden={true}
        />
    );
}
