import React from 'react';
import { createCn } from 'bem-react-classname';

import { Skeleton } from '@alfalab/core-components/skeleton';

import './high-charts-report-skeleton.css';

const cn = createCn('high-charts-report-skeleton');

export default function HighChartsReportSkeleton() {
    return (
        <React.Fragment>
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
            <Skeleton visible={true} className={cn('column')} />
        </React.Fragment>
    );
}
