import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import { suppliesFiltersCorrectionTypeOptions } from '#/src/dictionaries/supplies-filters-correction-type';
import { updateSelectedFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';
import { SupplyCorrectionType } from '#/src/types/supply';

export function CorrectionFilter() {
    const dispatch = useDispatch();
    const { correction } = useSelector(selectedFiltersSelector);

    const handleChangeCorrection = useCallback<NonNullable<SelectProps['onChange']>>(
        (payload) => {
            dispatch(
                updateSelectedFilters({
                    correction: (payload.selected?.key as SupplyCorrectionType) || null,
                }),
            );
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='supplies-filter-correction-select'
            allowUnselect={true}
            block={true}
            placeholder='Корректировка суммы'
            label='Корректировка суммы'
            optionsListWidth='field'
            selected={correction}
            options={suppliesFiltersCorrectionTypeOptions}
            onChange={handleChangeCorrection}
        />
    );
}
