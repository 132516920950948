import { LogoutResponse } from '#/src/server/services/api/logout';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        logout: fetchFactory<never, LogoutResponse>(
            `${contextRoot}/secure/nodeapi/logout`,
            'POST',
            { csrfCookieName },
        ),
    };

    return {
        fetchLogout() {
            return fetch.logout();
        },
    };
}
