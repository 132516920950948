import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';
import warningNIBSvg from 'arui-private/icons/warning_nib.svg';

import { SystemNotification, SystemNotificationType } from '#/src/types/communication';

import jobNotificationSvg from './images/job_notification.svg';

import './single-system-notification.css';

const cn = createCn('single-system-notification');

type SingleSystemNotificationProps = {
    notification: SystemNotification;
};

export default function SingleSystemNotification({ notification }: SingleSystemNotificationProps) {
    return (
        <div className={cn()} data-test-id='single-system-notification'>
            <img
                src={
                    notification.notifyType === SystemNotificationType.JOB_NOTIFICATION
                        ? jobNotificationSvg
                        : warningNIBSvg
                }
                alt='Системное уведомление'
            />
            <Typography.Title weight='bold' tag='div' view='small' className={cn('title')}>
                {notification.title}
            </Typography.Title>
            <Typography.Text tag='p'>{notification.message}</Typography.Text>
        </div>
    );
}
