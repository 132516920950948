import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import { generalAgreementsOptionsSelector } from '#/src/store/reports-xls/general-agreements/selectors';
import { updateReportFilters } from '#/src/store/reports-xls/report-filters/action-creators';
import { reportFiltersSelector } from '#/src/store/reports-xls/report-filters/selectors';

const GeneralAgreementFilter: FC = () => {
    const dispatch = useDispatch();

    const { generalAgreementId } = useSelector(reportFiltersSelector);
    const options = useSelector(generalAgreementsOptionsSelector);

    const handleChangeGeneralAgreementId = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selected }) => {
            dispatch(updateReportFilters({ generalAgreementId: selected?.value || '' }));
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='general-agreement-select'
            block={true}
            placeholder='Генеральный договор'
            optionsListWidth='field'
            selected={generalAgreementId}
            options={options}
            onChange={handleChangeGeneralAgreementId}
        />
    );
};

export default GeneralAgreementFilter;
