import React from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import TabItem from 'arui-feather/tab-item';
import Tabs from 'arui-feather/tabs';

import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { pathnameSelector } from '#/src/store/router/selectors';
import { userPermissionsSelector } from '#/src/store/user/selectors';

import './report-links.css';

const cn = createCn('report-links');

export function ReportLinks() {
    const pathname = useSelector(pathnameSelector);
    const permissions = useSelector(userPermissionsSelector);

    if (
        permissions.FACT_SHOW_SELLER_REPORTS_NEW ||
        (permissions.FACT_SHOW_SELLER_REPORTS && permissions.FACT_SHOW_DEBTOR_REPORTS_NEW)
    ) {
        return (
            <Tabs className={cn()}>
                <TabItem
                    url={routesDictionary.reportPage.path}
                    checked={routesDictionary.reportPage.pathRouteRegex.test(pathname)}
                >
                    Графики
                </TabItem>
                <TabItem
                    url={routesDictionary.reportsXlsPage.path}
                    checked={routesDictionary.reportsXlsPage.pathRouteRegex.test(pathname)}
                >
                    Отчеты (.xls)
                </TabItem>
            </Tabs>
        );
    }

    return null;
}
