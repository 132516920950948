import React from 'react';

import ErrorList from '#/src/components/error-list';
import { RegisterData } from '#/src/types/registers';

type RegisterErrorListProps = {
    register: RegisterData;
};

export function RegisterErrorList({ register }: RegisterErrorListProps) {
    return (
        <React.Fragment>
            {Array.isArray(register.errors) &&
                ['ERROR_PROCESSED', 'REJECTED', 'ERROR_PROCESSING'].includes(register.status) && (
                    <ErrorList errors={register.errors} />
                )}
            {!register.debtor && register.status === 'APPROVED' && (
                <ErrorList
                    errors={[
                        // eslint-disable-next-line max-len
                        'Реестр не был подписан, поэтому будет удален в течение часа после создания. Для отправки поставок на финансирование необходимо перейти на вкладку "Поставки" и подписать поставки еще раз',
                    ]}
                />
            )}
        </React.Fragment>
    );
}
