import { CountFilter, PeriodFilter } from '#/src/dictionaries/reports-dashboard';
import { getDefaultDashboardFiltersState } from '#/src/store/dashboard/dashboard-filters/utils';

import * as actionCreators from './action-creators';
import { DashboardFiltersActionsType } from './types';

export type DashboardFiltersState = {
    period: PeriodFilter;
    debtorTin: string[];
    dateFrom: string;
    dateTo: string;
    byCount: CountFilter;
    stepDate: number;
};

export const initialFiltersState: DashboardFiltersState = getDefaultDashboardFiltersState();

export function dashboardFiltersReducer(
    state: DashboardFiltersState = initialFiltersState,
    action: DashboardFiltersActionsType,
): DashboardFiltersState {
    switch (action.type) {
        case actionCreators.updateFilters.type:
            return {
                ...state,
                ...action.filters,
            };
        case actionCreators.resetFilters.type:
            return { ...initialFiltersState };
        default:
            return state;
    }
}
