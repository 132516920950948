import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { stateSelector } from '#/src/store/selectors';

export const changePasswordStateSelector = (state: ApplicationState) =>
    stateSelector(state).changePassword;

export const changePasswordErrorSelector = (state: ApplicationState) =>
    changePasswordStateSelector(state).error;
export const changePasswordLoadingStateSelector = (state: ApplicationState) =>
    changePasswordStateSelector(state).loadingState;
export const changePasswordIsLoadingSelector = (state: ApplicationState) =>
    changePasswordLoadingStateSelector(state) === LOADING_STATE.LOADING;
export const changePasswordIsSuccessSelector = (state: ApplicationState) =>
    changePasswordLoadingStateSelector(state) === LOADING_STATE.SUCCESS;
