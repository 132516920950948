import { ApplicationState } from '#/src/store/reducer';

import { registersModelsSelector } from '../selectors';

export const filtersStateSelector = (state: ApplicationState) =>
    registersModelsSelector(state).registersFilters;

export const selectedFiltersSelector = (state: ApplicationState) =>
    filtersStateSelector(state).selected;
export const appliedFiltersSelector = (state: ApplicationState) =>
    filtersStateSelector(state).applied;
