import { GetActiveErfRequest } from '#/src/types/erf';
import { SupplyDto } from '#/src/types/supply';

export default function combineErfRequestParams(
    supplies: SupplyDto[],
    currentOrganizationId: string,
) {
    const erfParams: Record<string, GetActiveErfRequest> = {};

    for (let i = 0; i < supplies.length; i++) {
        const { debtorTin = '', supplyAgreementNumber = '' } = supplies[i];
        const key = `${currentOrganizationId}${debtorTin}${supplyAgreementNumber}`;

        if (key && !erfParams[key]) {
            erfParams[key] = { debtorTin, supplyAgreementNumber, sellerTin: currentOrganizationId };
        }
    }

    return Object.values(erfParams);
}
