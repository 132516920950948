import { LOADING_STATE } from '#/src/constants/loading-state';
import { SupplyRepayment } from '#/src/types/supply';
import createAction from '#/src/utils/create-action';

export const getSupplyRepaymentsStart = createAction(
    'SUPPLY / getSupplyRepaymentsStart',
    (id: number) => ({ id, loadingState: LOADING_STATE.LOADING }),
);

export const getSupplyRepaymentsSuccess = createAction(
    'SUPPLY / getSupplyRepaymentsSuccess',
    (id: number, data: SupplyRepayment[]) => ({
        id,
        data,
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const getSupplyRepaymentsError = createAction(
    'SUPPLY / getSupplyRepaymentsError',
    (id: number) => ({ id, loadingState: LOADING_STATE.ERROR }),
);

export const removeSupplyRepayments = createAction(
    'SUPPLY / removeSupplyRepayments',
    (id: number) => ({ id }),
);
