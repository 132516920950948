import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Divider } from '@alfalab/core-components/divider';
import { Spinner } from '@alfalab/core-components/spinner';
import { Table } from '@alfalab/core-components/table';

import { getSupplies } from '#/src/store/supplies/supplies-list/action-creators';
import {
    entitiesIsLoadingSelector,
    entitiesIsUnknownSelector,
} from '#/src/store/supplies/supplies-list/selectors';

import { SuppliesControls } from '../supplies-controls';

import { SuppliesTableBody } from './supplies-table-body';
import { SuppliesTableHeaderCells } from './supplies-table-header-cells';
import { SuppliesTablePagination } from './supplies-table-pagination';

import './supplies-table.css';

const cn = createCn('supplies-table');

export function SuppliesTable() {
    const dispatch = useDispatch();
    const isLoading = useSelector(entitiesIsLoadingSelector);
    const isUnknown = useSelector(entitiesIsUnknownSelector);

    useEffect(() => {
        dispatch(getSupplies({ reloadPage: true }));
    }, [dispatch]);

    if (isLoading || isUnknown) {
        return (
            <div data-test-id='supplies-page-spinner' className={cn({ loading: true })}>
                <Spinner size='m' visible={true} />
            </div>
        );
    }

    return (
        <div className={cn()}>
            <SuppliesControls />
            <Table wrapper={false}>
                <Table.THead>
                    <SuppliesTableHeaderCells />
                </Table.THead>
                <Table.TBody>
                    <SuppliesTableBody />
                </Table.TBody>
            </Table>
            <Divider />
            <SuppliesTablePagination />
        </div>
    );
}
