import React from 'react';
import { createCn } from 'bem-react-classname';

import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';

import { CountFilter, DashboardTabs } from '#/src/dictionaries/reports-dashboard';

import getTotalInfoItems, { TotalInfoProps } from './get-total-info-items';
import TotalInfoItem from './total-info-item';

import './chart-report-total-info.css';

type ChartReportTotalInfoProps = TotalInfoProps & {
    byCount: CountFilter;
    isLoading: boolean;
};

const cn = createCn('chart-report-total-info');

export default function ChartReportTotalInfo(props: ChartReportTotalInfoProps) {
    const totalInfoItems = getTotalInfoItems(props);

    return (
        <div className={cn()}>
            <Typography.Text tag='div' className={cn('title')} weight='bold' view='primary-large'>
                <Skeleton visible={props.isLoading}>
                    {props.tab === DashboardTabs.COMMISSIONS ? 'За период, с НДС' : 'За период'}
                </Skeleton>
            </Typography.Text>
            {totalInfoItems.map((item) => (
                <TotalInfoItem
                    key={item.name}
                    byCount={props.byCount}
                    info={item}
                    isLoading={props.isLoading}
                />
            ))}
        </div>
    );
}
