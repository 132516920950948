import { LOADING_STATE } from '#/src/constants/loading-state';
import { SupplyCorrectionHistoryDto } from '#/src/types/supply';
import createAction from '#/src/utils/create-action';

export const getSupplyCorrectionHistoryStart = createAction(
    'SUPPLY / getSupplyCorrectionHistoryStart',
    (id: number) => ({ id, loadingState: LOADING_STATE.LOADING }),
);

export const getSupplyCorrectionHistorySuccess = createAction(
    'SUPPLY / getSupplyCorrectionHistorySuccess',
    (id: number, data: SupplyCorrectionHistoryDto[]) => ({
        id,
        data,
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const getSupplyCorrectionHistoryError = createAction(
    'SUPPLY / getSupplyCorrectionHistoryError',
    (id: number) => ({ id, loadingState: LOADING_STATE.ERROR }),
);

export const removeSupplyCorrectionHistory = createAction(
    'SUPPLY / removeSupplyCorrectionHistory',
    (id: number) => ({ id }),
);
