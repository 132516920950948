export enum SystemNotificationType {
    WARNING_NOTIFICATION = 'WARNING_NOTIFICATION',
    JOB_NOTIFICATION = 'JOB_NOTIFICATION',
}

export enum ClientType {
    ALL = 'ALL',
    EDISOFT = 'EDISOFT',
    OTHER = 'OTHER',
}

export type SystemNotification = {
    id: string;
    title: string;
    message: string;
    fromDate: string;
    toDate: string;
    fromTime: string;
    toTime: string;
    notifyType: SystemNotificationType;
    clientType: ClientType;
    creatingDate: string;
    isActive: boolean;
    isDeleted: boolean;
};
