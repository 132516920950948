import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Table } from '@alfalab/core-components/table';
import { Typography } from '@alfalab/core-components/typography';
import VerificationSvg from 'arui-private/icons/verification.svg';

import { updateAllFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { getDefaultSuppliesFiltersState } from '#/src/store/supplies/supplies-filters/utils';
import { availableCellsSelector } from '#/src/store/supplies/table-cells-settings/selectors';

import './supplies-table-body-empty.css';

const cn = createCn('supplies-table-body-empty');

export function SuppliesTableBodyEmpty() {
    const dispatch = useDispatch();

    const availableCells = useSelector(availableCellsSelector);

    const handleResetFilter = useCallback(() => {
        dispatch(updateAllFilters(getDefaultSuppliesFiltersState()));
    }, [dispatch]);

    return (
        <Table.TRow dataTestId='supplies-table-body-empty'>
            <Table.TCell colSpan={availableCells.length + 2}>
                <div className={cn()}>
                    <img src={VerificationSvg} alt='Ничего не найдено' />
                    <Typography.Title tag='div' view='small' weight='bold'>
                        Ничего не найдено
                    </Typography.Title>
                    <Gap size='xs' />
                    <Typography.Text tag='div' view='secondary-large'>
                        Мы не нашли ни одной поставки с такими параметрами
                    </Typography.Text>
                    <Gap size='m' />
                    <Button
                        dataTestId='reset-supplies-filters-button'
                        onClick={handleResetFilter}
                        view='tertiary'
                        size='xs'
                    >
                        Сбросить фильтры
                    </Button>
                </div>
            </Table.TCell>
        </Table.TRow>
    );
}
