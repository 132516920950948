import {
    CreateRegisterFromFilePayload,
    CreateRegisterPayload,
    CreateRegisterQuery,
    CreateRegisterResponse,
    CreateSignRegisterPayload,
    CreateSignRegisterResponse,
    DeleteRegisterPayload,
    DeleteRegisterResponse,
    DownloadXlsPayload,
    DownloadXlsResponse,
    GetFullCountsPayload,
    GetFullCountsResponse,
    GetHashesPayload,
    GetHashesResponse,
    GetRegisterErfQuery,
    GetRegisterErfResponse,
    GetRegisterInfoQuery,
    GetRegisterInfoResponse,
    GetRegisterRepaymentsQuery,
    GetRegisterRepaymentsResponse,
    GetRegistersPayload,
    GetRegistersResponse,
    SendSignRegistersToFactorPayload,
    SendSignRegistersToFactorResponse,
    SendToFactorPayload,
    SendToFactorResponse,
} from '#/src/server/services/api/register';
import { CreateCorrectionFromFilePayload } from '#/src/server/services/api/register/create-correct-from-file';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';
import {
    CreateCorrectionFromFileResponse,
    CreateRegisterFromFileResponse,
} from '#/src/types/registers';

const queryStringForCreateRegister = 'username={username}&partyTin={partyTin}';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        getRegisters: fetchFactory<GetRegistersPayload, GetRegistersResponse>(
            `${contextRoot}/secure/nodeapi/getRegisters`,
            'POST',
            { csrfCookieName },
        ),
        downloadReportByRegisters: fetchFactory<DownloadXlsPayload, DownloadXlsResponse>(
            `${contextRoot}/secure/nodeapi/downloadReportByRegisters`,
            'POST',
            { csrfCookieName, allowMultiRequest: true, responseType: 'blob' },
        ),
        getRegisterInfo: fetchFactory<GetRegisterInfoQuery, GetRegisterInfoResponse>(
            `${contextRoot}/secure/nodeapi/getRegisterInfo`,
            'GET',
            { allowMultiRequest: true },
        ),
        deleteRegister: fetchFactory<DeleteRegisterPayload, DeleteRegisterResponse>(
            `${contextRoot}/secure/nodeapi/deleteRegister`,
            'POST',
            { csrfCookieName, allowMultiRequest: true },
        ),
        getRegisterRepayments: fetchFactory<
            GetRegisterRepaymentsQuery,
            GetRegisterRepaymentsResponse
        >(`${contextRoot}/secure/nodeapi/getRegisterRepayments`, 'GET', {
            allowMultiRequest: true,
        }),
        createRegister: fetchFactory<CreateRegisterPayload, CreateRegisterResponse>(
            `${contextRoot}/secure/nodeapi/createRegister?${queryStringForCreateRegister}`,
            'POST',
            { csrfCookieName, allowMultiRequest: true },
        ),
        createRegisterSignData: fetchFactory<CreateSignRegisterPayload, CreateSignRegisterResponse>(
            `${contextRoot}/secure/nodeapi/createRegisterSignData`,
            'POST',
            { csrfCookieName, allowMultiRequest: true },
        ),
        getFullCounts: fetchFactory<GetFullCountsPayload, GetFullCountsResponse>(
            `${contextRoot}/secure/nodeapi/getFullCounts`,
            'POST',
            { csrfCookieName, allowMultiRequest: true },
        ),
        getHashes: fetchFactory<GetHashesPayload, GetHashesResponse>(
            `${contextRoot}/secure/nodeapi/getHashes`,
            'POST',
            { csrfCookieName, allowMultiRequest: true },
        ),
        sendSignRegistersToFactor: fetchFactory<
            SendSignRegistersToFactorPayload,
            SendSignRegistersToFactorResponse
        >(`${contextRoot}/secure/nodeapi/sendSignRegistersToFactor`, 'POST', {
            csrfCookieName,
            allowMultiRequest: true,
        }),
        sendToFactor: fetchFactory<SendToFactorPayload, SendToFactorResponse>(
            `${contextRoot}/secure/nodeapi/sendToFactor`,
            'POST',
            { csrfCookieName, allowMultiRequest: true },
        ),
        getRegisterErf: fetchFactory<GetRegisterErfQuery, GetRegisterErfResponse>(
            `${contextRoot}/secure/nodeapi/getRegisterErf`,
        ),
        createRegisterFromFile: fetchFactory<
            CreateRegisterFromFilePayload,
            CreateRegisterFromFileResponse
        >(`${contextRoot}/secure/nodeapi/createRegisterFromFile`, 'POST', {
            csrfCookieName,
            allowMultiRequest: true,
        }),
        createCorrectionFromFile: fetchFactory<
            CreateCorrectionFromFilePayload,
            CreateCorrectionFromFileResponse
        >(`${contextRoot}/secure/nodeapi/createCorrectionFromFile`, 'POST', {
            csrfCookieName,
            allowMultiRequest: true,
        }),
    };

    return {
        fetchRegisters(params: GetRegistersPayload) {
            return fetch.getRegisters(params);
        },
        fetchDownloadReportByRegisters(params: DownloadXlsPayload) {
            return fetch.downloadReportByRegisters(params);
        },
        fetchRegisterInfo(params: GetRegisterInfoQuery) {
            return fetch.getRegisterInfo(params);
        },
        fetchDeleteRegister(params: DeleteRegisterPayload) {
            return fetch.deleteRegister(params);
        },
        fetchRegisterRepayments(registerRowId: number) {
            return fetch.getRegisterRepayments({ registerRowId });
        },
        fetchCreateRegister(params: CreateRegisterPayload, query: CreateRegisterQuery) {
            return fetch.createRegister(params, undefined, query);
        },
        fetchCreateRegisterSignData(params: CreateSignRegisterPayload) {
            return fetch.createRegisterSignData(params);
        },
        fetchFullCounts(params: GetFullCountsPayload) {
            return fetch.getFullCounts(params);
        },
        fetchHashes(params: GetHashesPayload) {
            return fetch.getHashes(params);
        },
        fetchSendSignRegistersToFactor(params: SendSignRegistersToFactorPayload) {
            return fetch.sendSignRegistersToFactor(params);
        },
        fetchSendToFactor(params: SendToFactorPayload) {
            return fetch.sendToFactor(params);
        },
        fetchRegisterErf(id: number) {
            return fetch.getRegisterErf({ id });
        },
        fetchCreateRegisterFromFile(data: CreateRegisterFromFilePayload) {
            return fetch.createRegisterFromFile(data);
        },
        fetchCreateCorrectionFromFile(data: CreateCorrectionFromFilePayload) {
            return fetch.createCorrectionFromFile(data);
        },
    };
}
