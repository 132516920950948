import { OptionShape } from '@alfalab/core-components/select/typings';
import { StatusProps } from '@alfalab/core-components/status';

import { SupplyStatus } from '#/src/types/supply';

export const statusTypeColor: Record<SupplyStatus, StatusProps['color']> = {
    DUPLICATED: 'grey',
    RECEIVED: 'blue',
    APPROVED: 'blue',
    ERROR_PROCESSED: 'red',
    FINANCING_REQUESTED: 'orange',
    PROCESSING: 'orange',
    AF_RECEIVED: 'teal',
    PARTIALLY_FINANCED: 'teal',
    VERIFIED: 'purple',
    PARTIALLY_PAID: 'teal',
    FINANCED: 'green',
    CLOSED: 'green',
    REJECTED: 'red',
    ERROR: 'red',
    AF_PARTIALLY_RECEIVED: 'teal',
};

export const statusTypeTitle: Record<SupplyStatus, string> = {
    DUPLICATED: 'Дубликат',
    RECEIVED: 'Обработана',
    ERROR_PROCESSED: 'Ошибка обработки',
    FINANCING_REQUESTED: 'Отправлено',
    AF_RECEIVED: 'Получено',
    VERIFIED: 'Принято',
    PARTIALLY_FINANCED: 'Частично профинансировано',
    FINANCED: 'Профинансировано',
    PARTIALLY_PAID: 'Частично оплачено',
    CLOSED: 'Закрыто',
    REJECTED: 'Отклонено',
    APPROVED: 'Подтверждено',
    PROCESSING: 'Отправлено на сверку',
    ERROR: 'Ошибка',
    AF_PARTIALLY_RECEIVED: 'Частично получено',
};

const unavailableStatuses = [
    'DUPLICATED',
    'ERROR_PROCESSED',
    'PROCESSING',
    'AF_PARTIALLY_RECEIVED',
];

export const statusesOptions: OptionShape[] = Object.keys(statusTypeTitle)
    .filter((status) => !unavailableStatuses.includes(status))
    .map((status: SupplyStatus) => ({
        value: status,
        key: status,
        content: statusTypeTitle[status],
    }));
