import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SignButtonProps as SignButtonCoreProps } from '@alfa-bank/corp-sign-ui-react/components/SignButton';

import { Button } from '@alfalab/core-components/button';

import { prepareSignRegistersData } from '#/src/store/registers/sign-registers/actions';

type SignButtonProps = {
    textButton: string;
    isLoading: boolean;
    selectedIds: number[];
} & Partial<SignButtonCoreProps>;

export function SignButton({ textButton, isLoading, selectedIds, ...props }: SignButtonProps) {
    const dispatch = useDispatch();
    const handleSignButtonClick = useCallback(() => {
        dispatch(prepareSignRegistersData(selectedIds, props));
    }, [dispatch, selectedIds, props]);

    return (
        <Button
            loading={isLoading || props.loading}
            view='primary'
            size='xxs'
            onClick={handleSignButtonClick}
        >
            {textButton}
        </Button>
    );
}
