import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from '@alfalab/core-components/link';
import { Spinner } from '@alfalab/core-components/spinner';

import { fetchRegisterErf } from '#/src/store/registers/register-erf/actions';
import { registerErfIsLoadingSelector } from '#/src/store/registers/register-erf/selectors';

import { RegisterErfModal } from './register-erf-modal';

type RegisterErfProps = {
    id: number;
};

export function RegisterErf({ id }: RegisterErfProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector(registerErfIsLoadingSelector);

    const handleOpenModal = useCallback(() => {
        if (!isLoading) {
            dispatch(fetchRegisterErf(id));
        }
    }, [dispatch, id, isLoading]);

    return (
        <React.Fragment>
            <Link
                dataTestId='register-erf-link'
                pseudo={true}
                onClick={handleOpenModal}
                rightAddons={<Spinner size='xs' visible={isLoading} />}
            >
                Информация об ЭРФ
            </Link>
            <RegisterErfModal />
        </React.Fragment>
    );
}
