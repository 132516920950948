import React from 'react';
import { createCn } from 'bem-react-classname';

import './register-list-block.css';

const cn = createCn('register-list-block');

type RegisterListBlockProps = {
    children: React.ReactNode;
    dataTestId: string;
};

export function RegisterListBlock({
    children,
    dataTestId = 'register-list-block',
}: RegisterListBlockProps) {
    return (
        <div className={cn()} data-test-id={dataTestId}>
            {children}
        </div>
    );
}
