import { ThunkAction } from 'redux-thunk';

import {
    RestorePasswordErrorNames,
    restorePasswordErrorsDictionary,
} from '#/src/dictionaries/restore-password-errors';
import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';
import { parseErrorMessage } from '#/src/utils/parse-error-message';

import * as actionCreators from './action-creators';
import { RestorePasswordActionsType } from './types';

export function restorePassword(
    userName: string,
    keyWord: string,
): ThunkAction<void, ApplicationState, void, RestorePasswordActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.restorePasswordStart());

        try {
            await fetchers.fetchRestorePassword({ userName, keyWord });

            dispatch(actionCreators.restorePasswordSuccess());
        } catch (e) {
            const error = parseErrorMessage(e);

            dispatch(
                actionCreators.restorePasswordError(
                    restorePasswordErrorsDictionary[error as RestorePasswordErrorNames] ||
                        restorePasswordErrorsDictionary.UNKNOWN_ERROR,
                ),
            );
        }
    };
}
