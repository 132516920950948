import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import ErrorSvg from 'arui-private/icons/error.svg';

import { RegisterListBlock } from './register-list-block';

export function RegisterListErrorSection() {
    return (
        <RegisterListBlock dataTestId='registers-table'>
            <img src={ErrorSvg} alt='Ошибка' />
            <Typography.Title tag='div' view='small' weight='bold'>
                Обратитесь в службу поддержки
            </Typography.Title>
            <Typography.Text tag='div' view='primary-medium'>
                Во время загрузки реестра произошла ошибка
            </Typography.Text>
            <Gap size='6xl' />
        </RegisterListBlock>
    );
}
