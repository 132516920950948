export enum PeriodFilter {
    WEEK = 'WEEK',
    QUARTER = 'QUARTER',
    YEAR = 'YEAR',
}

export const periodFilterDictionary: Record<PeriodFilter, string> = {
    [PeriodFilter.WEEK]: 'Неделя',
    [PeriodFilter.QUARTER]: 'Квартал',
    [PeriodFilter.YEAR]: 'Год',
};

export enum CountFilter {
    DEFAULT = 'DEFAULT',
    MILLIONS = 'MILLIONS',
    THOUSANDS = 'THOUSANDS',
}

export const countFilterDictionary: Record<CountFilter, string> = {
    [CountFilter.DEFAULT]: '',
    [CountFilter.MILLIONS]: 'Миллионов',
    [CountFilter.THOUSANDS]: 'Тысяч',
};

export enum DashboardTabs {
    RECEIVABLES = 'RECEIVABLES',
    COMMISSIONS = 'COMMISSIONS',
    PAYMENTS = 'PAYMENTS',
    REFUNDS = 'REFUNDS',
}

export const dashboardTabsDictionary: Record<DashboardTabs, string> = {
    [DashboardTabs.RECEIVABLES]: 'Дебиторская задолженность',
    [DashboardTabs.COMMISSIONS]: 'Комиссии',
    [DashboardTabs.PAYMENTS]: 'Платежи',
    [DashboardTabs.REFUNDS]: 'Возвраты',
};

export const monthNames: Record<number, string> = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
};
