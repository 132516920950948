import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import { registersFiltersSourceTypeOptions } from '#/src/dictionaries/registers-filters-source-type';
import { updateSelectedFilters } from '#/src/store/registers/register-info-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/register-info-filters/selectors';
import { FiltersSourceType } from '#/src/types/registers';

const options = [registersFiltersSourceTypeOptions[0]];

export function SourceTypeFilter() {
    const dispatch = useDispatch();
    const { registerSource } = useSelector(selectedFiltersSelector);

    const handleChangeRegisterSource = useCallback<NonNullable<SelectProps['onChange']>>(
        (payload) => {
            dispatch(
                updateSelectedFilters({
                    registerSource: (payload.selected?.key as FiltersSourceType) || 'ALL',
                }),
            );
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='registers-filter-source-select'
            block={true}
            placeholder='Источник'
            label='Источник'
            optionsListWidth='field'
            selected={registerSource}
            options={options}
            onChange={handleChangeRegisterSource}
        />
    );
}
