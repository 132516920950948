import { OptionShape } from '@alfalab/core-components/select/typings';

import { OrganizationShortDto } from '#/src/types/user';

export default function convertOrganizationShortToOptions(
    organizations: OrganizationShortDto[],
): OptionShape[] {
    return organizations.map(({ name, tin }) => ({
        key: tin,
        content: name,
        value: tin,
    }));
}
