import { Assign } from 'utility-types';

import { OptionShape } from '@alfalab/core-components/select/typings';

export const DOWNLOAD_LIMIT_COUNT = 100;

export const DOWNLOAD_LIMIT_COUNT_FOR_REPORT = 1000;

export type OptionKey = 'SOURCE_FILE' | 'ALL_FILES' | 'REPORT';

export const inaccessibleOptionKeysForSingleRegister: OptionKey[] = ['REPORT'];

export const optionsForDownloadDocuments: Array<Assign<OptionShape, { key: OptionKey }>> = [
    {
        key: 'SOURCE_FILE',
        content: 'Документ в исходном формате',
    },
    {
        key: 'ALL_FILES',
        content: 'Документооборот целиком',
    },
    {
        key: 'REPORT',
        content: 'Отчёт',
    },
];
