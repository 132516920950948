import React from 'react';

import List from 'arui-feather/list';

export enum ChangePasswordErrorNames {
    EMPTY_USERNAME = 'EMPTY_USERNAME',
    EMPTY_PASSWORD = 'EMPTY_KEYWORD',
    WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
    WRONG_PASSWORD_CONFIRM = 'KEYWORD_NOT_FOUND_IN_DB',
    NEW_OLD_PASSWORDS_EQUALS = 'NEW_OLD_PASSWORDS_EQUALS',
    ACCOUNT_IS_BLOCKED = 'ACCOUNT_IS_BLOCKED',
    PASSWORD_REQUIREMENTS_VIOLATED = 'PASSWORD_REQUIREMENTS_VIOLATED',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const changePasswordErrorsDictionary: Record<ChangePasswordErrorNames, React.ReactNode> = {
    [ChangePasswordErrorNames.EMPTY_USERNAME]: 'Не заполнено поле "Логин"',
    [ChangePasswordErrorNames.EMPTY_PASSWORD]:
        'Не заполнено одно из полей: "Старый пароль", "Новый пароль", "Пароль повторно"',
    [ChangePasswordErrorNames.WRONG_CREDENTIALS]: 'Старый пароль указан неверно',
    [ChangePasswordErrorNames.WRONG_PASSWORD_CONFIRM]:
        'Пароли, указанные в поле "Новый пароль" и "Пароль повторно" не совпадают',
    [ChangePasswordErrorNames.NEW_OLD_PASSWORDS_EQUALS]:
        'Старый и новый пароль не должны совпадать',
    [ChangePasswordErrorNames.ACCOUNT_IS_BLOCKED]: 'Пользователь заблокирован',
    [ChangePasswordErrorNames.PASSWORD_REQUIREMENTS_VIOLATED]: (
        <React.Fragment>
            Пароль не соответствует политике безопасности.
            <br />
            Пароль должен содержать:
            <br />
            <List
                items={[
                    {
                        key: 'one',
                        value: '8 символов и более',
                    },
                    {
                        key: 'two',
                        value: 'цифры',
                    },
                    {
                        key: 'three',
                        value: 'прописные латинские буквы',
                    },
                    {
                        key: 'four',
                        value: 'строчные латинские буквы',
                    },
                ]}
            />
        </React.Fragment>
    ),
    [ChangePasswordErrorNames.UNKNOWN_ERROR]:
        'Операция отклонена. В процессе изменения пароля возникла ошибка! Попробуйте снова или позвоните в службу поддержки.',
};
