import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { registersModelsSelector } from '#/src/store/registers/selectors';

export const repaymentsStateSelector = (state: ApplicationState) =>
    registersModelsSelector(state).repayments;

export const rowIdSelector = (state: ApplicationState) => repaymentsStateSelector(state).rowId;
export const repaymentsLoadingStateSelector = (state: ApplicationState) =>
    repaymentsStateSelector(state).loadingState;
export const repaymentsIsLoadingSelector = (state: ApplicationState) =>
    repaymentsLoadingStateSelector(state) === LOADING_STATE.LOADING;
export const repaymentsIsSuccessSelector = (state: ApplicationState) =>
    repaymentsLoadingStateSelector(state) === LOADING_STATE.SUCCESS;
export const repaymentsSelector = (state: ApplicationState) =>
    repaymentsStateSelector(state).entities;
