import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Select, SelectProps } from '@alfalab/core-components/select';

import { initializeReportFilters } from '#/src/store/reports-xls/report-filters/action-creators';
import { reportFiltersSelector } from '#/src/store/reports-xls/report-filters/selectors';
import { reportNameOptionsSortedSelector } from '#/src/store/reports-xls/report-names/selectors';

const ReportsXlsNames: FC = () => {
    const dispatch = useDispatch();
    const options = useSelector(reportNameOptionsSortedSelector);
    const { reportName } = useSelector(reportFiltersSelector);

    const handleChangeReportName = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selected }) => {
            dispatch(initializeReportFilters({ reportName: selected?.value || '' }));
        },
        [dispatch],
    );

    return (
        <Grid.Row>
            <Grid.Col width={{ desktop: 9, tablet: 9, mobile: 12 }}>
                <Select
                    dataTestId='report-name-select'
                    block={true}
                    placeholder='Выберите тип отчета'
                    optionsListWidth='field'
                    selected={reportName}
                    options={options}
                    onChange={handleChangeReportName}
                />
            </Grid.Col>
        </Grid.Row>
    );
};

export default ReportsXlsNames;
