import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { SuppliesDownloadReportType } from './types';

export type SuppliesDownloadReportState = {
    loadingState: LoadingState;
};

const initialState: SuppliesDownloadReportState = {
    loadingState: 'unknown',
};

export function suppliesDownloadReportReducer(
    state: SuppliesDownloadReportState = initialState,
    action: SuppliesDownloadReportType,
): SuppliesDownloadReportState {
    switch (action.type) {
        case actionCreators.changeDownloadReportLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        default:
            return state;
    }
}
