import { formatDateForCalendar } from '#/src/utils/date-converter';

import { FiltersState } from './reducer';

export function getDefaultSuppliesFiltersState(): FiltersState {
    return {
        correction: null,
        statuses: [],
        dateFrom: formatDateForCalendar(Date.now() - 604800000),
        dateTo: formatDateForCalendar(Date.now()),
        registerNumber: '',
        supplySource: 'ALL',
        fromAmount: '',
        toAmount: '',
        debtorsTin: [],
        factoringAgreementNumber: '',
        supplyAgreementNumber: '',
        amountCertificationDocumentNumber: '',
    };
}
