import React, { useCallback, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Collapse } from '@alfalab/core-components/collapse';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';
import { ChevronUpSIcon } from '@alfalab/icons-glyph/ChevronUpSIcon';

import { AgreementNumberFilter } from './agreement-number-filter';
import { AmountRangeFilter } from './amount-range-filter';
import { DatesFilter } from './dates-filter';
import { DebtorsFilter } from './debtors-filter';
import { DocumentNumberFilter } from './document-number-filter';
import { FilterButtons } from './filter-buttons';
import { SourceTypeFilter } from './source-type-filter';
import { StatusFilter } from './status-filter';

import './register-info-filters.css';

const cn = createCn('register-info-filters');

export function RegisterInfoFilters() {
    const [expanded, setExpanded] = useState(false);

    const handleChangeExpended = useCallback(() => {
        setExpanded((prevState) => !prevState);
    }, [setExpanded]);

    return (
        <div className={cn()}>
            <Typography.Title view='xsmall' tag='div' weight='bold'>
                Фильтры
            </Typography.Title>
            <Gap size='xl' />
            <Space direction='vertical' fullWidth={true} size={24}>
                <Grid.Row gutter='24'>
                    <Grid.Col width='6'>
                        <DatesFilter />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <SourceTypeFilter />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter='24'>
                    <Grid.Col width='12'>
                        <StatusFilter />
                    </Grid.Col>
                </Grid.Row>
            </Space>
            <Collapse expanded={expanded}>
                <Gap size='xl' />
                <Space direction='vertical' fullWidth={true} size={24}>
                    <Grid.Row gutter='24'>
                        <Grid.Col width='6'>
                            <DebtorsFilter />
                        </Grid.Col>
                        <Grid.Col width='6'>
                            <AgreementNumberFilter />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row gutter='24'>
                        <Grid.Col width='6'>
                            <DocumentNumberFilter />
                        </Grid.Col>
                        <Grid.Col width='6'>
                            <AmountRangeFilter />
                        </Grid.Col>
                    </Grid.Row>
                </Space>
            </Collapse>
            <Gap size='xl' />
            <Grid.Row gutter='24'>
                <Grid.Col width='auto'>
                    <FilterButtons />
                </Grid.Col>
                <Grid.Col width='6' align='middle'>
                    <Typography.Text tag='div' className={cn('toggle-link')} view='primary-small'>
                        <Link
                            dataTestId='toggle-expended-state-filters-link'
                            onClick={handleChangeExpended}
                            pseudo={true}
                            rightAddons={
                                expanded ? (
                                    <ChevronUpSIcon width='18px' height='20px' />
                                ) : (
                                    <ChevronDownSIcon width='18px' height='20px' />
                                )
                            }
                        >
                            {expanded ? 'Свернуть фильтры' : 'Все фильтры'}
                        </Link>
                    </Typography.Text>
                </Grid.Col>
            </Grid.Row>
        </div>
    );
}
