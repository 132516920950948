import { ThunkAction } from 'redux-thunk';

import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { ReportNamesActionsType } from './types';

export function fetchReportNames(
    isDebtor: boolean,
): ThunkAction<void, ApplicationState, void, ReportNamesActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getReportNamesStart());

        try {
            const response = await fetchers.fetchReportNames(isDebtor);

            dispatch(actionCreators.getReportNamesSuccess(response));
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
            dispatch(actionCreators.getReportNamesError());
        }
    };
}
