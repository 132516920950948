import React from 'react';

import { History } from '#/src/types/routing';

import createRoutes from './routes';

type RootProps = {
    history: History;
};

export const Root: React.FC<RootProps> = ({ history }) => createRoutes(history);
