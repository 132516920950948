import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ErrorList from '#/src/components/error-list';
import { ApplicationState } from '#/src/store/reducer';
import { removeSupplyErrors } from '#/src/store/supply/supply-errors/action-creators';
import { fetchSupplyErrors } from '#/src/store/supply/supply-errors/actions';
import {
    supplyErrorsIsLoadingSelector,
    supplyErrorsSelector,
} from '#/src/store/supply/supply-errors/selectors';
import { SupplyStatus } from '#/src/types/supply';

type SupplyErrorListProps = {
    supplyId: number;
    status: SupplyStatus;
};

export function SupplyErrorList({ supplyId, status }: SupplyErrorListProps) {
    const dispatch = useDispatch();
    const supplyErrors = useSelector((state: ApplicationState) =>
        supplyErrorsSelector(state, supplyId),
    );
    const isLoading = useSelector((state: ApplicationState) =>
        supplyErrorsIsLoadingSelector(state, supplyId),
    );

    useEffect(() => {
        dispatch(fetchSupplyErrors(supplyId));

        return () => {
            dispatch(removeSupplyErrors(supplyId));
        };
    }, [dispatch, supplyId]);

    if (Boolean(supplyErrors.length) && !isLoading && ['REJECTED', 'ERROR'].includes(status)) {
        return <ErrorList errors={supplyErrors} />;
    }

    return null;
}
