import createAction from '#/src/utils/create-action';

import { ReportFiltersState } from './reducer';

export const initializeReportFilters = createAction(
    'REPORTS_XLS / initializeReportFilters',
    (filters: Partial<ReportFiltersState>) => ({ filters }),
);

export const updateReportFilters = createAction(
    'REPORTS_XLS / updateReportFilters',
    (filters: Partial<ReportFiltersState>) => ({ filters }),
);

export const resetReportFilters = createAction('REPORTS_XLS / resetReportFilters', () => ({}));
