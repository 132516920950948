import React from 'react';

import { Pagination } from '#/src/components/pagination';
import { getSuppliesByPage } from '#/src/store/supplies/supplies-list/action-creators';
import {
    suppliesCountSelector,
    suppliesPaginationSelector,
} from '#/src/store/supplies/supplies-list/selectors';

export function SuppliesTablePagination() {
    return (
        <Pagination
            paginationSelector={suppliesPaginationSelector}
            onChangePageAction={getSuppliesByPage}
            totalItemsSelector={suppliesCountSelector}
        />
    );
}
