import React from 'react';

import { Typography } from '@alfalab/core-components/typography';

import { CachedReport } from '#/src/types/reports';

import ReportXlsRowInfo from './report-xls-row-info';
import ReportsXlsTableCell from './reports-xls-table-cell';
import ReportsXlsTableRow from './reports-xls-table-row';

type ReportXlsTableProps = {
    cachedReports: CachedReport[];
};

export default function ReportXlsTable({ cachedReports }: ReportXlsTableProps) {
    return (
        <div>
            <ReportsXlsTableRow>
                <ReportsXlsTableCell type='name'>
                    <Typography.Text view='primary-small' color='secondary'>
                        Название и дата формирования
                    </Typography.Text>
                </ReportsXlsTableCell>
                <ReportsXlsTableCell type='date'>
                    <Typography.Text view='primary-small' color='secondary'>
                        Начало периода
                    </Typography.Text>
                </ReportsXlsTableCell>
                <ReportsXlsTableCell type='date'>
                    <Typography.Text view='primary-small' color='secondary'>
                        Конец периода
                    </Typography.Text>
                </ReportsXlsTableCell>
                <ReportsXlsTableCell type='agreement'>
                    <Typography.Text view='primary-small' color='secondary'>
                        Номер договора
                    </Typography.Text>
                </ReportsXlsTableCell>
                <ReportsXlsTableCell type='debtor'>
                    <Typography.Text view='primary-small' color='secondary'>
                        Контрагент
                    </Typography.Text>
                </ReportsXlsTableCell>
                <ReportsXlsTableCell type='document'>
                    <Typography.Text view='primary-small' color='secondary'>
                        Номер документа
                    </Typography.Text>
                </ReportsXlsTableCell>
                <ReportsXlsTableCell type='supply'>
                    <Typography.Text view='primary-small' color='secondary'>
                        Поставка
                    </Typography.Text>
                </ReportsXlsTableCell>
            </ReportsXlsTableRow>
            {cachedReports &&
                !!cachedReports.length &&
                cachedReports.map((report) => <ReportXlsRowInfo key={report.id} report={report} />)}
        </div>
    );
}
