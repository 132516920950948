import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultUIRenderers, QESSignComponent } from '@alfa-bank/corp-sign-ui-react';
import { DocumentWithSign } from '@alfa-bank/corp-sign-ui-react/types';

import { DisabledSignButton } from '#/src/components/disabled-sign-button';
import { currentOrganizationSelector } from '#/src/store/organizations/selectors';
import { sendSignSuppliesToFactor } from '#/src/store/supplies/sign-supplies/actions';
import {
    documentsSelector,
    filteredSuppliesIdsSelector,
    permissionsSelector,
    registerIdSelector,
    userSelector,
} from '#/src/store/supplies/sign-supplies/selectors';
import { availableSuppliesSelector } from '#/src/store/supplies/supplies-list/selectors';
import { Organization, Permission } from '#/src/types/orgatiozations';
import { fetchers } from '#/src/utils/client-api';

import { AdditionalInfoModal } from './additional-info-modal';
import { ChooseCertificateSuppliesSignModal } from './choose-certificate-supplies-sign-modal';
import { EmptyFieldsWarningModal } from './empty-fields-warning-modal';
import { ErfErrorModal } from './erf-error-modal';
import { SignButton } from './sign-button';
import { WaitingErrorModal } from './waiting-error-modal';
import { WarningModal } from './warning-modal';

export function SignSuppliesControls() {
    const dispatch = useDispatch();
    const documents = useSelector(documentsSelector);
    const user = useSelector(userSelector);
    const registerId = useSelector(registerIdSelector);
    const permissions = useSelector(permissionsSelector);
    const filteredSuppliesIds = useSelector(filteredSuppliesIdsSelector);
    const availableSupplies = useSelector(availableSuppliesSelector);
    const organization = useSelector(currentOrganizationSelector) as Organization;
    const signAvailable = organization.actions.includes(Permission.SUPPLIER_DOCUMENTS_SIGN);

    const handlePreSign = useCallback(
        () =>
            Promise.resolve({
                methodInfo: {
                    name: `${user?.firstName}${user?.middleName ? ` ${user?.middleName}` : ''}`,
                    lastName: user?.lastName || '',
                    snils: user?.snils || '',
                },
                documents,
            }),
        [documents, user],
    );

    const handleSendSignSuppliesToFactor = useCallback(
        (data: { documents: DocumentWithSign[] }) =>
            dispatch(sendSignSuppliesToFactor(data.documents)),
        [dispatch],
    );

    let content: React.ReactNode = <DisabledSignButton textButton='Подписать и отправить' />;

    if (signAvailable) {
        content = (
            <React.Fragment>
                <QESSignComponent
                    logging={{
                        logHandler: (infoData) => {
                            fetchers
                                .sendCertificateData(infoData)
                                .then(() => console.log('send certificate data success'))
                                .catch(() => console.log('send certificate data error'));
                        },
                        logLevel: 1,
                    }}
                    UIrenderers={{
                        ...defaultUIRenderers,
                        getSignChooseCertificateModal: (props) => (
                            <ChooseCertificateSuppliesSignModal
                                {...props}
                                suppliesIds={filteredSuppliesIds}
                                availableSupplies={availableSupplies.available}
                                permissions={permissions}
                                registerId={registerId}
                                documents={documents}
                            />
                        ),
                        getSignButton: (props) => <SignButton {...props} />,
                    }}
                    preSign={handlePreSign}
                    postSign={handleSendSignSuppliesToFactor}
                />
                <WarningModal />
                <AdditionalInfoModal />
                <ErfErrorModal />
                <WaitingErrorModal />
                <EmptyFieldsWarningModal />
            </React.Fragment>
        );
    }

    return <React.Fragment>{content} </React.Fragment>;
}
