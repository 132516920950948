import React from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Grid } from '@alfalab/core-components/grid';

import {
    needUpdateSelector,
    selectedIdsSelector,
} from '#/src/store/supplies/supplies-list/selectors';

import { SuppliesNeedUpdateNotification } from '../supplies-need-update-notification';

import { AvailableColumnControl } from './available-column-control';
import { CheckboxSuppliesControl } from './checkbox-supplies-control';
import { DownloadSuppliesReportControl } from './download-supplies-report-control';
import { EditSuppliesControl } from './edit-supplies-control';
import { SignSuppliesControls } from './sign-supplies-controls';
import useIsFixed from './use-is-fixed';

import './supplies-controls.css';

const cn = createCn('supplies-controls');

export function SuppliesControls() {
    const [isFixed, containerRef, width] = useIsFixed();
    const selectedIds = useSelector(selectedIdsSelector);
    const needUpdate = useSelector(needUpdateSelector);
    let controls: React.ReactNode = null;

    if (selectedIds.length > 0) {
        controls = (
            <div className={cn('sign', { fixed: isFixed })}>
                <Grid.Row justify='left' align='middle' gutter='8'>
                    <Grid.Col width='auto'>
                        <SignSuppliesControls />
                    </Grid.Col>
                    <Grid.Col width='auto'>
                        <EditSuppliesControl />
                    </Grid.Col>
                    <Grid.Col width='available'>
                        <DownloadSuppliesReportControl />
                    </Grid.Col>
                </Grid.Row>
            </div>
        );
    }

    const style = { width: '100%' };

    if (width) {
        style.width = `${width}px`;
    }

    return (
        <div>
            <div className={cn()} ref={containerRef}>
                <div className={cn('holder', { fixed: isFixed })} style={style}>
                    <div className={cn('row', { fixed: isFixed })}>
                        <div className={cn('choose-and-sign-top')}>
                            <CheckboxSuppliesControl />
                            {controls}
                            <AvailableColumnControl />
                        </div>
                    </div>
                    {isFixed && needUpdate && <SuppliesNeedUpdateNotification />}
                </div>
            </div>
        </div>
    );
}
