export enum AuthErrorNames {
    ACCOUNT_IS_BLOCKED = 'ACCOUNT_IS_BLOCKED',
    WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const authErrorDictionary: Record<AuthErrorNames, string> = {
    [AuthErrorNames.ACCOUNT_IS_BLOCKED]: 'Ваша учетная запись заблокирована. Обратитесь в Банк',
    [AuthErrorNames.WRONG_CREDENTIALS]: 'Неверный логин или пароль',
    [AuthErrorNames.UNKNOWN_ERROR]:
        'Операция отклонена. В процессе авторизации возникла ошибка! Попробуйте авторизоваться снова или позвоните в службу поддержки.',
};
