import React from 'react';
import { createCn } from 'bem-react-classname';

import { Status } from '@alfalab/core-components/status';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { RubOffMIcon } from '@alfalab/icons-glyph/RubOffMIcon';

import { statusTypeColor, statusTypeTitle } from '#/src/dictionaries/supplies-statuses';
import { FinancingType } from '#/src/types/sign';
import { SupplyStatus } from '#/src/types/supply';

import './supply-row-status.css';

const cn = createCn('supply-row-status');

type SupplyRowStatusProps = {
    status?: SupplyStatus;
    financingType?: FinancingType;
};

export function SupplyRowStatus({ status, financingType }: SupplyRowStatusProps) {
    return (
        <div className={cn()}>
            <Status color={status ? statusTypeColor[status] : 'grey'} view='soft'>
                {status ? statusTypeTitle[status] : 'Отсутствует'}
            </Status>
            {financingType === 'NOT_FINANCE' && (
                <Tooltip
                    content='Без финансирования'
                    position='right'
                    trigger='hover'
                    targetClassName={cn('icon')}
                >
                    <RubOffMIcon width='13px' height='13px' />
                </Tooltip>
            )}
        </div>
    );
}
