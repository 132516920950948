import {
    CreateCorrectionFromFileResponse,
    CreateRegisterFromFileResponse,
} from '#/src/types/registers';
import { FinancingType } from '#/src/types/sign';
import createAction from '#/src/utils/create-action';

export const changeStateSendModal = createAction(
    'REGISTERS / SEND_REGISTER  / changeStateSendModal',
    (isOpen: boolean) => ({ isOpen }),
);

export const changeStateResultModal = createAction(
    'REGISTERS / SEND_REGISTER  / changeStateResultModal',
    (isOpen: boolean) => ({ isOpen }),
);

export const changeStateFile = createAction(
    'REGISTERS / SEND_REGISTER  / changeStateFile',
    (file: File | null) => ({ file }),
);

export const changeStatePartyTin = createAction(
    'REGISTERS / SEND_REGISTER  / changeStatePartyTin',
    (partyTin: string) => ({ partyTin }),
);

export const changeStateType = createAction(
    'REGISTERS / SEND_REGISTER  / changeStateType',
    (partyType: 'supplier' | 'debtor') => ({ partyType }),
);

export const changeRegisterType = createAction(
    'REGISTERS / SEND_REGISTER  / changeRegisterType',
    (isRegister: boolean) => ({ isRegister }),
);

export const clearState = createAction('REGISTERS / SEND_REGISTER  / clearState', () => ({}));

export const sendRegisterStart = createAction(
    'REGISTERS / SEND_REGISTER  / sendRegisterStart',
    () => ({}),
);

export const sendRegisterError = createAction(
    'REGISTERS / SEND_REGISTER  / sendRegisterError',
    () => ({}),
);

export const sendRegisterSuccess = createAction(
    'REGISTERS / SEND_REGISTER  / sendRegisterSuccess',
    (data: CreateRegisterFromFileResponse | CreateCorrectionFromFileResponse) => ({ data }),
);

export const changeFinancingType = createAction(
    'REGISTERS / SEND_REGISTER  / changeFinancingType',
    (financingType: FinancingType | null) => ({ financingType }),
);
