import React from 'react';
import { useSelector } from 'react-redux';

import ErrorPage from 'arui-private/error-page';

import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { hasServerErrorSelector } from '#/src/store/app/selectors';
import { History } from '#/src/types/routing';

import 'arui-feather/main.css';

type ErrorWrapperProps = {
    history: History;
    children: React.ReactNode;
};
/**
 * Компонент верхнего уровня для роутера, который хендлит рендер 404х и 500х экранов
 * Работает только если роутинг осуществлен браузером.
 */
export const ErrorWrapper = (props: ErrorWrapperProps) => {
    const hasServerError = useSelector(hasServerErrorSelector);

    if (props.history.location.state?.notFoundError) {
        return <ErrorPage errorCode={404} returnUrl={routesDictionary.authPage.path} />;
    }

    if (hasServerError) {
        return <ErrorPage errorCode={500} returnUrl={routesDictionary.authPage.path} />;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
};
