import { Organization } from '#/src/types/orgatiozations';
import { RouteInfo } from '#/src/types/routing';

export default function filterOrganizationListByPage(
    organizations: Organization[],
    routeInfo?: RouteInfo,
) {
    return organizations.filter((org) => {
        if (routeInfo?.permissions) {
            return (
                org.actions.length &&
                org.actions.some((action) => routeInfo.permissions?.includes(action))
            );
        }

        return true;
    });
}
