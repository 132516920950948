import React, { useCallback, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Alert } from '@alfalab/core-components/alert';
import { Link } from '@alfalab/core-components/link';
import { List } from '@alfalab/core-components/list';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { ChevronUpMIcon } from '@alfalab/icons-glyph/ChevronUpMIcon';

import './error-list.css';

const cn = createCn('error-list');

type ErrorListProps = {
    errors: string[];
};
export default function ErrorList({ errors }: ErrorListProps) {
    const [expandedState, setExpandedState] = useState(false);
    const handleExpandedToggle = useCallback(() => {
        setExpandedState((prevState) => !prevState);
    }, []);

    return (
        <div className={cn('wrap')}>
            <Alert view='negative'>
                {errors.length > 1 ? (
                    <React.Fragment>
                        <List tag='ol' className={cn()}>
                            {expandedState ? (
                                errors.map((error, index) => (
                                    <Typography.Text
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${index}/${error}`}
                                        view='secondary-large'
                                    >
                                        {error}
                                    </Typography.Text>
                                ))
                            ) : (
                                <Typography.Text view='secondary-large'>
                                    {errors[0]}
                                </Typography.Text>
                            )}
                        </List>
                        <Typography.Text view='secondary-large'>
                            <Link
                                dataTestId='toggle-expended-state-error-list-link'
                                onClick={handleExpandedToggle}
                                pseudo={true}
                                rightAddons={expandedState ? ChevronUpMIcon : ChevronDownMIcon}
                            >
                                {expandedState ? 'Свернуть' : 'Развернуть'}
                            </Link>
                        </Typography.Text>
                    </React.Fragment>
                ) : (
                    <Typography.Text view='secondary-large'>{errors[0]}</Typography.Text>
                )}
            </Alert>
        </div>
    );
}
