import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';
import getRouteInfo from '#/src/utils/get-route-info';

export const routerStateSelector = (state: ApplicationState) => state.router;
export const locationSelector = (state: ApplicationState) => routerStateSelector(state).location;
export const pathnameSelector = (state: ApplicationState) => locationSelector(state).pathname;
export const routeInfoSelector = createSelector(pathnameSelector, getRouteInfo);
export const pageTitleSelector = createSelector(routeInfoSelector, (info) => info?.pageTitle);
