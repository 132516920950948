import { OptionShape } from '@alfalab/core-components/select/typings';

import matchOptionByKeyAndContent from './match-option-by-key-and-content';

export default function filterOptionsForCustomAutocomplete(
    options: OptionShape[],
    value: string,
    selected: string | string[] | undefined,
    multiple: boolean | undefined,
) {
    if (multiple && Array.isArray(selected)) {
        const inputValues = value.trim().replace(/, /g, ', ').split(',');

        if (!inputValues[inputValues.length - 1] || inputValues.length === selected.length) {
            return options;
        }

        return options.filter(
            (option) =>
                selected.includes(option.key) ||
                matchOptionByKeyAndContent(
                    option,
                    inputValues[inputValues.length - 1].trim().toLowerCase(),
                ),
        );
    }

    const valueFormatted = value.toLowerCase().trim();

    if (valueFormatted) {
        return options.filter((option) => matchOptionByKeyAndContent(option, valueFormatted));
    }

    return options;
}
