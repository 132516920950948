import { createSelector } from 'reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { stateSelector } from '../selectors';

export const filesSelector = (state: ApplicationState) => stateSelector(state).files;

export const fileIsLoadingSelector = createSelector(
    filesSelector,
    (state) => state.loadingState === LOADING_STATE.LOADING,
);
