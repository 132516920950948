import React from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Grid } from '@alfalab/core-components/grid';

import { DashboardTabs } from '#/src/dictionaries/reports-dashboard';
import { selectedTabSelector } from '#/src/store/dashboard/dashboard-charts/selectors';

import { ByCountFilter } from './by-count-filter';
import { DataSwitcherFilter } from './data-switcher-filter';
import { DebtorsFilter } from './debtors-filter';
import { PeriodFilter } from './period-filter';

import './dashboard-filters.css';

const cn = createCn('dashboard-filters');

export function DashboardFilters() {
    const selectedTab = useSelector(selectedTabSelector);

    return (
        <div className={cn()}>
            <div className={cn('left')}>
                <Grid.Row justify='left' align='bottom'>
                    <Grid.Col width='auto'>
                        <PeriodFilter />
                    </Grid.Col>
                    <Grid.Col width='auto'>
                        <DataSwitcherFilter />
                    </Grid.Col>
                    <Grid.Col width='auto'>
                        <ByCountFilter />
                    </Grid.Col>
                </Grid.Row>
            </div>
            {selectedTab !== DashboardTabs.REFUNDS && (
                <div className={cn('right')}>
                    <DebtorsFilter />
                </div>
            )}
        </div>
    );
}
