import React from 'react';
import { createCn } from 'bem-react-classname';

import { Amount } from '@alfalab/core-components/amount';
import { Divider } from '@alfalab/core-components/divider';
import { Grid } from '@alfalab/core-components/grid';
import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';

import LimitAmount from './limit-amount';
import LimitProgressBar from './limit-progress-bar';

import './general-limits.css';

type GeneralLimitsProps = {
    isLoading: boolean;
    totalLimit: number;
    availableLimit: number;
    usedLimit: number;
    availablePercentageLimit: number;
};

const cn = createCn('general-limits');

export default function GeneralLimits({
    isLoading,
    totalLimit,
    availableLimit,
    usedLimit,
    availablePercentageLimit,
}: GeneralLimitsProps) {
    return (
        <React.Fragment>
            <Typography.Title tag='h3' view='medium' weight='bold' defaultMargins={true}>
                Мои лимиты
            </Typography.Title>
            <div className={cn()}>
                <Skeleton visible={isLoading}>
                    <Typography.Text tag='div' view='primary-large'>
                        <Amount
                            value={totalLimit * 100}
                            currency='RUR'
                            minority={100}
                            view='withZeroMinorPart'
                        />
                    </Typography.Text>
                </Skeleton>
                <Divider className={cn('divider')} />
                <Grid.Row className={cn('limits-range')}>
                    <Grid.Col width='6' className={cn('limit-available')}>
                        <LimitAmount
                            isLoading={isLoading}
                            amount={availableLimit}
                            title='Доступно'
                        />
                    </Grid.Col>
                    <Grid.Col width='6' className={cn('limit-used')}>
                        <LimitAmount
                            isLoading={isLoading}
                            amount={usedLimit}
                            title='Используемый'
                        />
                    </Grid.Col>
                </Grid.Row>
                <LimitProgressBar
                    isLoading={isLoading}
                    percentageValue={availablePercentageLimit}
                />
            </div>
        </React.Fragment>
    );
}
