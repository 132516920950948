import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PickerButton, PickerButtonProps } from '@alfalab/core-components/picker-button';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { itemsPerPageOptions } from '#/src/dictionaries/items-per-page';
import { TypedDispatch } from '#/src/types/redux-toolkit';

import { PaginationProps } from '.';

type SizeNavigationControlsProps = Pick<PaginationProps, 'onChangePageAction'> & { size: number };

export function SizeNavigationControls({ size, onChangePageAction }: SizeNavigationControlsProps) {
    const dispatch = useDispatch<TypedDispatch>();
    const handleChangeSize = useCallback<NonNullable<PickerButtonProps['onChange']>>(
        (payload) => {
            if (payload.selected?.key) {
                window.scrollTo(0, 0);
                dispatch(onChangePageAction({ page: 1, pageSize: +payload.selected.key }));
            }
        },
        [dispatch, onChangePageAction],
    );

    return (
        <Space direction='horizontal' align='center'>
            <Typography.Text view='primary-small'>На странице:</Typography.Text>
            <PickerButton
                view='ghost'
                size='xs'
                options={itemsPerPageOptions}
                onChange={handleChangeSize}
                label={size}
                popoverPosition='top'
                dataTestId='size-navigation-button'
            />
        </Space>
    );
}
