import React from 'react';

import { Typography } from '@alfalab/core-components/typography';

import { RegisterRow } from '#/src/types/registers';

type RegisterRowErrorsProps = {
    registerRow: RegisterRow;
};

export function RegisterRowErrors({ registerRow }: RegisterRowErrorsProps) {
    const hasError =
        (Array.isArray(registerRow.errors) && !!registerRow.errors.length) || !!registerRow.warning;

    if (
        ['ERROR_PROCESSED', 'REJECTED', 'ERROR', 'DUPLICATED'].includes(registerRow.status) &&
        hasError
    ) {
        return (
            <div data-test-id='register-row-errors'>
                <Typography.Title tag='div' view='xsmall' weight='bold' defaultMargins={true}>
                    Ошибки
                </Typography.Title>
                {Array.isArray(registerRow.errors) &&
                    registerRow.errors.map((err) => (
                        <Typography.Text tag='p' view='secondary-large' defaultMargins={true}>
                            {err}
                        </Typography.Text>
                    ))}
                {!!registerRow.warning && (
                    <Typography.Text tag='p' view='secondary-large' defaultMargins={true}>
                        {registerRow.warning}
                    </Typography.Text>
                )}
            </div>
        );
    }

    return null;
}
