import React from 'react';

import { Amount } from '@alfalab/core-components/amount';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

type SupplyRowAmountProps = Partial<{
    supplyAmount: number | null;
    supplyAmountCorrection: number | null;
    requestedAmount: number | null;
}>;

export function SupplyRowAmount({
    supplyAmount,
    supplyAmountCorrection,
    requestedAmount,
}: SupplyRowAmountProps) {
    const amount =
        supplyAmount && supplyAmountCorrection
            ? supplyAmount - supplyAmountCorrection
            : supplyAmount || 0;

    return (
        <Typography.Text view='secondary-large' tag='div'>
            <Amount
                value={amount * 100}
                minority={100}
                view='withZeroMinorPart'
                currency='RUR'
                bold='none'
            />
            {!!requestedAmount && (
                <React.Fragment>
                    <Gap size='3xs' />
                    <Amount
                        value={requestedAmount * 100}
                        minority={100}
                        view='withZeroMinorPart'
                        currency='RUR'
                        bold='none'
                    />
                </React.Fragment>
            )}
        </Typography.Text>
    );
}
