import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Modal } from '@alfalab/core-components/modal';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { clearState } from '#/src/store/registers/send-register/action-creators';
import { sendRegister } from '#/src/store/registers/send-register/actions';
import {
    fileSelector,
    sendModalIsOpenSelector,
    sendRegisterIsLoadingSelector,
} from '#/src/store/registers/send-register/selectors';

import { FinancingTypeField } from './financing-type-field';
import { OrganizationTypeField } from './organization-type-field';
import { OrganizationsField } from './organizations-field';
import { RegisterFileAttach } from './register-file-attach';
import { RegisterTypeField } from './register-type-field';

export function SendRegisterModal() {
    const dispatch = useDispatch();
    const sendModalIsOpen = useSelector(sendModalIsOpenSelector);
    const file = useSelector(fileSelector);
    const sendRegisterIsLoading = useSelector(sendRegisterIsLoadingSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    const handleSendRegister = useCallback(() => {
        dispatch(sendRegister());
    }, [dispatch]);

    return (
        <Modal
            size='m'
            open={sendModalIsOpen}
            hasCloser={true}
            onClose={handleCloseModal}
            dataTestId='send-register-modal'
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    Загрузить реестр
                </Typography.Title>
                <Gap size='2xl' />
                <RegisterFileAttach />
                <Gap size='2xl' />
                <OrganizationsField />
                <Gap size='2xl' />
                <OrganizationTypeField />
                <RegisterTypeField />
                <FinancingTypeField />
                <Space direction='horizontal' size={20}>
                    <Button
                        data-test-id='load-register-success-button'
                        disabled={!file}
                        view='primary'
                        size='xs'
                        onClick={handleSendRegister}
                        loading={sendRegisterIsLoading}
                    >
                        Продолжить
                    </Button>
                    <Button
                        data-test-id='load-register-cancel-button'
                        size='xs'
                        view='tertiary'
                        onClick={handleCloseModal}
                    >
                        Отмена
                    </Button>
                </Space>
            </Modal.Content>
        </Modal>
    );
}
