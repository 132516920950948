import { LOADING_STATE } from '#/src/constants/loading-state';
import { RegisterRepayment } from '#/src/types/registers';
import createAction from '#/src/utils/create-action';

export const getRepaymentsStart = createAction(
    'REGISTERS / REPAYMENTS  / getRepaymentsStart',
    (rowId: number) => ({
        loadingState: LOADING_STATE.LOADING,
        rowId,
    }),
);

export const getRepaymentsSuccess = createAction(
    'REGISTERS / REPAYMENTS  / getRepaymentsSuccess',
    (data: RegisterRepayment[]) => ({
        loadingState: LOADING_STATE.SUCCESS,
        data,
    }),
);

export const getRepaymentsError = createAction(
    'REGISTERS / REPAYMENTS  / getRepaymentsError',
    () => ({ loadingState: LOADING_STATE.ERROR }),
);

export const clearRepayments = createAction('REGISTERS / REPAYMENTS / clearRepayments', () => ({}));
