import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import { CountFilter } from '#/src/dictionaries/reports-dashboard';

import ChartAmount from '../../chart-amount';

import './high-charts-axis-label.css';

type HighChartsAxisLabelProps = {
    amount: number;
    byCount: CountFilter;
};

const cn = createCn('high-charts-axis-label');

export default function HighChartsAxisLabel({ amount, byCount }: HighChartsAxisLabelProps) {
    return (
        <Typography.Text tag='div' view='secondary-small' className={cn()}>
            <ChartAmount amount={amount} byCount={byCount} styled={false} />
        </Typography.Text>
    );
}
