import { OptionShape } from '@alfalab/core-components/select/typings';

import { SupplyAgreementData } from '#/src/types/agreements';

export function convertAgreementsToOptions(agreements: SupplyAgreementData[]): OptionShape[] {
    return agreements.map(({ agrNumber }) => ({
        key: agrNumber,
        content: agrNumber,
        value: agrNumber,
    }));
}
