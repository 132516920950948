import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Modal } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';
import warningNIBSvg from 'arui-private/icons/warning_nib.svg';

import { setUserWithoutPermissionsModalState } from '#/src/store/auth/action-creators';
import { userWithoutPermissionsModalIsOpenSelector } from '#/src/store/auth/selectors';

import './user-without-permissions-modal.css';

const cn = createCn('user-without-permissions-modal');

export default function UserWithoutPermissionsModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(userWithoutPermissionsModalIsOpenSelector);
    const handleCloseModal = useCallback(() => {
        dispatch(setUserWithoutPermissionsModalState(false));
    }, [dispatch]);

    return (
        <Modal
            open={modalIsOpen}
            hasCloser={true}
            onClose={handleCloseModal}
            dataTestId='user-without-permissions-modal'
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <div className={cn('container')}>
                    <img src={warningNIBSvg} alt='Пользователь без полномочий' />
                    <Typography.Title view='small' tag='div' className={cn('title')}>
                        Вам не хватает полномочий
                    </Typography.Title>
                    <Typography.Text tag='div'>
                        У вас отсутствуют права доступа в Альфа-Финанс.
                    </Typography.Text>
                    <Typography.Text tag='div'>Обратитесь к менеджеру.</Typography.Text>
                    <Button
                        view='primary'
                        dataTestId='success-button'
                        onClick={handleCloseModal}
                        className={cn('button')}
                        size='m'
                    >
                        Хорошо
                    </Button>
                </div>
            </Modal.Content>
        </Modal>
    );
}
