import React from 'react';

import { Divider } from '@alfalab/core-components/divider';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { AlignRightGridCol } from '#/src/components/align-right-grid-col';
import { CombinedActiveErf } from '#/src/types/erf';

type ItemOfActiveErfRowProps = {
    item: CombinedActiveErf;
};

export function ItemOfActiveErfRow({ item }: ItemOfActiveErfRowProps) {
    return (
        <React.Fragment>
            <Gap size='s' />
            <Grid.Row justify='between'>
                <Grid.Col width='5'>{item.debtorName}</Grid.Col>
                <Grid.Col width='available'>
                    {item.supplyAgreementNumber.map((value) => (
                        <Typography.Text tag='div'>{value}</Typography.Text>
                    ))}
                </Grid.Col>
                <AlignRightGridCol width='auto'>
                    {item.erfValue.map((value) => (
                        <Typography.Text tag='div'>{value}%</Typography.Text>
                    ))}
                </AlignRightGridCol>
            </Grid.Row>
            <Gap size='xs' />
            <Divider />
        </React.Fragment>
    );
}
