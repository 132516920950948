import { LoadingState } from '#/src/constants/loading-state';
import { CachedReport } from '#/src/types/reports';

import * as actionCreators from './action-creators';
import { CachedReportsActionsType } from './types';

export type CachedReportsState = {
    loadingState: LoadingState;
    list: CachedReport[] | null;
    isLoadingGenerateCachedReport: boolean;
    isLoadingDownloadCachedReport: boolean;
    requestedReportIds: string[];
};

const initialState: CachedReportsState = {
    loadingState: 'unknown',
    list: [],
    isLoadingGenerateCachedReport: false,
    isLoadingDownloadCachedReport: false,
    requestedReportIds: [],
};

export function cachedReportsReducer(
    state: CachedReportsState = initialState,
    action: CachedReportsActionsType,
): CachedReportsState {
    switch (action.type) {
        case actionCreators.getCachedReportsStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.getCachedReportsSuccess.type:
            return {
                ...state,
                list: action.list,
                loadingState: action.loadingState,
            };
        case actionCreators.getCachedReportsError.type:
            return {
                ...state,
                list: null,
                loadingState: action.loadingState,
            };
        case actionCreators.startGenerateCachedReport.type:
            return {
                ...state,
                isLoadingGenerateCachedReport: true,
            };
        case actionCreators.endGenerateCachedReport.type:
            return {
                ...state,
                isLoadingGenerateCachedReport: false,
            };
        case actionCreators.startDownloadCachedReport.type:
            return {
                ...state,
                isLoadingDownloadCachedReport: true,
            };
        case actionCreators.endDownloadCachedReport.type:
            return {
                ...state,
                isLoadingDownloadCachedReport: false,
            };
        case actionCreators.addRequestedReport.type:
            return {
                ...state,
                list: state.list ? state.list.concat(action.report) : [action.report],
                requestedReportIds: state.requestedReportIds.concat(action.report.id),
            };
        default:
            return state;
    }
}
