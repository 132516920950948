import { navigationsSortIndex } from '#/src/dictionaries/navigations';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { NavigationTab } from '#/src/types/navigations';
import { Permission } from '#/src/types/orgatiozations';

export default function navigationsMap(
    navigations: NavigationTab[],
    permissions?: Record<Permission, Permission>,
) {
    return navigations
        .map((item) => {
            switch (true) {
                case item.segment === 'TAB_X5_COMPANIES_GROUP':
                    return {
                        ...item,
                        iframeUrl: item.url || '/secure/edi/ui/?module=documents&tab=tosign',
                        url: routesDictionary.suppliesEdiSoftPage.path,
                    };
                case item.segment === 'TAB_DASHBOARDS':
                    return permissions &&
                        !permissions.FACT_SHOW_SELLER_REPORTS &&
                        !permissions.FACT_SHOW_SELLER_REPORTS_NEW
                        ? {
                              ...item,
                              url: routesDictionary.reportsXlsPage.path,
                          }
                        : item;
                case item.segment === 'TAB_INVOICE_REGISTRY':
                    return {
                        ...item,
                        iframeUrl: item.url || '/secure/edi/ui/index.jsp?module=accept.registry',
                        url: routesDictionary.invoiceRegistryEdiSoftPage.path,
                    };
                default:
                    return item;
            }
        })
        .slice()
        .sort((a, b) => {
            if (!navigationsSortIndex[a.segment]) {
                return 1;
            }
            if (!navigationsSortIndex[b.segment]) {
                return -1;
            }

            return navigationsSortIndex[a.segment] - navigationsSortIndex[b.segment];
        });
}
