import { LoadingState } from '#/src/constants/loading-state';

import * as actionCreators from './action-creators';
import { DocumentsActionsType } from './types';

export type DocumentsState = {
    error: string | null;
    loadingState: LoadingState;
    successDocuments: number[];
    requestedDocuments: number[];
    report: string | null;
    resultModalIsOpen: boolean;
};

const initialState: DocumentsState = {
    error: null,
    loadingState: 'unknown',
    successDocuments: [],
    requestedDocuments: [],
    report: null,
    resultModalIsOpen: false,
};

export function documentsReducer(
    state: DocumentsState = initialState,
    action: DocumentsActionsType,
): DocumentsState {
    switch (action.type) {
        case actionCreators.downloadStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.downloadSuccess.type:
            return {
                ...state,
                error: null,
                loadingState: action.loadingState,
                successDocuments: action.data.successDocuments,
                requestedDocuments: action.data.ids,
                report: action.data.report,
                resultModalIsOpen: action.data.resultModalIsOpen,
            };
        case actionCreators.downloadError.type:
            return {
                ...state,
                error: action.error,
                loadingState: action.loadingState,
                successDocuments: [],
                requestedDocuments: [],
                report: null,
                resultModalIsOpen: false,
            };
        case actionCreators.downloadFinish.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.clearState.type:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
