import React, { useCallback, useMemo } from 'react';
import { Messages } from '@alfa-bank/corp-sign-ui-react/consts/messages';
import { Certificate } from '@alfa-bank/corp-sign-ui-react/types';

import { Plate } from '@alfalab/core-components/plate';
import { Radio } from '@alfalab/core-components/radio';
import { Typography } from '@alfalab/core-components/typography';

import { getPersonName } from './get-person-name';

const OGRN_KEYS = ['OGRN', 'OGRNIP', 'ОГРН', 'ОГРНИП', '1.2.643.100.1', '1.2.643.100.5'];
const SNILS_KEYS = ['СНИЛС', 'SNILS', '1.2.643.100.3'];

type CertificateRadioProps = {
    certificate: Certificate;
    selectedCertificate: string | null;
    fields: Messages['common']['chooseCertificateModal']['default']['fields'];
    onChangeCertificate: (value: string | null) => void;
};

export function CertificateRadio({
    certificate,
    selectedCertificate,
    fields,
    onChangeCertificate,
}: CertificateRadioProps) {
    const {
        SubjectFields: { O, SN, G, ...restFields },
        Thumbprint,
        ValidFromDate,
        ValidToDate,
    } = certificate;

    const {
        individualCert,
        organization,
        person,
        snils,
        validityPeriod,
        validFrom,
        validTo,
        emptyValue,
    } = fields;

    const handleChangeCertificate = useCallback(() => {
        onChangeCertificate(Thumbprint);
    }, [Thumbprint, onChangeCertificate]);

    const { isIndividualCertificate, snilsKey } = useMemo(
        () => ({
            isIndividualCertificate: !OGRN_KEYS.some((key) => !!restFields[key]),
            snilsKey: SNILS_KEYS.find((key) => key in restFields),
        }),
        [restFields],
    );

    return (
        <Plate
            border={selectedCertificate === Thumbprint}
            rounded={true}
            view='custom'
            onClick={handleChangeCertificate}
        >
            <Radio
                label={
                    <Typography.Text view='primary-small'>
                        {isIndividualCertificate
                            ? individualCert
                            : `${organization}: ${O || emptyValue}`}
                    </Typography.Text>
                }
                hint={
                    <Typography.Text view='primary-small'>
                        <div>
                            {person}: {getPersonName(SN, G)}
                        </div>
                        {isIndividualCertificate && (
                            <div>
                                {`${snils}: ${(snilsKey && restFields[snilsKey]) || emptyValue}`}
                            </div>
                        )}
                        <div>
                            {validityPeriod}:{' '}
                            {`${validFrom} ${ValidFromDate} ${validTo} ${ValidToDate}`}
                        </div>
                    </Typography.Text>
                }
                key={Thumbprint}
                value={Thumbprint}
                checked={selectedCertificate === Thumbprint}
            />
        </Plate>
    );
}
