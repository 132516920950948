import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '#/src/store/reducer';
import { availableCellsSelector } from '#/src/store/supplies/table-cells-settings/selectors';

import * as actionCreators from './action-creators';
import { CellNames, TableCellsSettingsType } from './types';

const AVAILABLE_CELLS_LOCALSTORAGE_KEY = 'available-cells';

export function updateCellState(
    cell: CellNames,
): ThunkAction<void, ApplicationState, void, TableCellsSettingsType> {
    return async (dispatch, getState) => {
        const state = getState();
        const availableCells = availableCellsSelector(state);
        let newAvailableCells: CellNames[] = [];

        if (availableCells.includes(cell)) {
            newAvailableCells = availableCells.filter((c) => c !== cell);
        } else {
            newAvailableCells = availableCells.concat(cell);
        }

        dispatch(actionCreators.updateAvailableTableCells(newAvailableCells));
        updateCellsInLocalStorage(availableCells);
    };
}

export function restoreAvailableCells(): ThunkAction<
    void,
    ApplicationState,
    void,
    TableCellsSettingsType
> {
    return async (dispatch) => {
        const cells = getCellsFromLocalStorage();

        if (Array.isArray(cells)) {
            dispatch(actionCreators.updateAvailableTableCells(cells));
        }
    };
}

function updateCellsInLocalStorage(cells: CellNames[]) {
    try {
        localStorage.setItem(AVAILABLE_CELLS_LOCALSTORAGE_KEY, JSON.stringify(cells));
    } catch (e) {
        // ignore it
    }
}

function getCellsFromLocalStorage() {
    const storedString = localStorage.getItem(AVAILABLE_CELLS_LOCALSTORAGE_KEY);

    if (!storedString) {
        return null;
    }

    try {
        return JSON.parse(storedString);
    } catch (e) {
        return null;
    }
}
