import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { fetchRegisterInfo } from '#/src/store/registers/register-info/actions';
import {
    idSelector,
    registerInfoIsErrorSelector,
    registerInfoIsSuccessSelector,
} from '#/src/store/registers/register-info/selectors';

import { RegisterErrorSection } from './register-error-section';
import { RegisterInfoSection } from './register-info-section';
import { RegisterInfoSpinner } from './register-info-spinner';

type RegisterInfoPageProps = RouteComponentProps<{ id: string }>;

export function RegisterInfoPage({ match: { params } }: RegisterInfoPageProps) {
    const dispatch = useDispatch();
    const isSuccess = useSelector(registerInfoIsSuccessSelector);
    const isError = useSelector(registerInfoIsErrorSelector);
    const registerId = useSelector(idSelector);
    const organizationId = useSelector(currentOrganizationIdSelector);

    useEffect(() => {
        dispatch(fetchRegisterInfo(+params.id, organizationId as string));
    }, [dispatch, organizationId, params.id]);

    let content = <RegisterInfoSpinner />;

    if (isError) {
        content = <RegisterErrorSection />;
    } else if (isSuccess && registerId === +params.id && organizationId) {
        content = <RegisterInfoSection partyTin={organizationId} />;
    }

    return <div>{content}</div>;
}
