import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Gap } from '@alfalab/core-components/gap';
import { RadioGroup, RadioGroupProps } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';

import { changeFinancingType } from '#/src/store/registers/send-register/action-creators';
import {
    fileSelector,
    financingTypeSelector,
    isRegisterSelector,
    sendRegisterIsLoadingSelector,
    typeSelector,
} from '#/src/store/registers/send-register/selectors';
import { FinancingType } from '#/src/types/sign';

export function FinancingTypeField() {
    const dispatch = useDispatch();
    const isRegister = useSelector(isRegisterSelector);
    const file = useSelector(fileSelector);
    const type = useSelector(typeSelector);
    const financingType = useSelector(financingTypeSelector);
    const isLoading = useSelector(sendRegisterIsLoadingSelector);
    const handleChange = useCallback<NonNullable<RadioGroupProps['onChange']>>(
        (event, payload) => {
            dispatch(changeFinancingType(payload.value as FinancingType));
        },
        [dispatch],
    );

    useEffect(() => {
        if (!isRegister || type !== 'supplier') {
            dispatch(changeFinancingType(null));
        } else if (isRegister && type === 'supplier') {
            dispatch(changeFinancingType('FINANCE'));
        }
    }, [dispatch, type, isRegister]);

    let fieldComponent = null;

    if (file && isRegister && type === 'supplier') {
        fieldComponent = (
            <React.Fragment>
                <RadioGroup
                    label='Тип финансирования'
                    direction='horizontal'
                    type='tag'
                    disabled={isLoading}
                    onChange={handleChange}
                    value={financingType || ''}
                >
                    <Tag value='FINANCE' size='xxs' disabled={isLoading}>
                        С финансированием
                    </Tag>
                    <Tag value='NOT_FINANCE' size='xxs' disabled={isLoading}>
                        Без финансирования
                    </Tag>
                </RadioGroup>
                <Gap size='2xl' />
            </React.Fragment>
        );
    }

    return fieldComponent;
}
