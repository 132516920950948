import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FileUpload from 'arui-private/core/file-upload';
import { FileUploadProps } from 'arui-private/core/file-upload/file-upload';

import { changeStateFile } from '#/src/store/registers/send-register/action-creators';
import {
    fileSelector,
    sendRegisterIsLoadingSelector,
} from '#/src/store/registers/send-register/selectors';

const availableFileTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain',
];

export function RegisterFileAttach() {
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const file = useSelector(fileSelector);
    const isLoading = useSelector(sendRegisterIsLoadingSelector);

    const handleFileUpload = useCallback<NonNullable<FileUploadProps['onFileUpload']>>(
        (files) => {
            if (availableFileTypes.includes(files[0].type)) {
                dispatch(changeStateFile(files[0]));
                setError('');
            } else {
                setError('Файл должен быть в формате .xls, .xlsx или .txt.');
            }
        },
        [dispatch],
    );

    const handleResetFile = useCallback<NonNullable<FileUploadProps['onFileDelete']>>(() => {
        dispatch(changeStateFile(null));
        setError('');
    }, [dispatch]);

    const files = useMemo(() => {
        if (file) {
            return [
                {
                    name: file.name,
                    size: file.size,
                    id: file.name,
                },
            ];
        }

        return [];
    }, [file]);

    return (
        <FileUpload
            disabled={isLoading}
            data-test-id='send-register-file-attach'
            attachHint='.xls, .xlsx, .txt'
            onFileUpload={handleFileUpload}
            error={error}
            fileUploadItems={files}
            accept='.xls,.xlsx,.txt'
            block={true}
            multiple={false}
            onFileDelete={handleResetFile}
        />
    );
}
