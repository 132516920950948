import React from 'react';
import { createCn } from 'bem-react-classname';

import { Spinner } from '@alfalab/core-components/spinner';

import './register-info-spinner.css';

const cn = createCn('register-info-spinner');

export function RegisterInfoSpinner() {
    return (
        <div className={cn()}>
            <Spinner size='m' visible={true} />
        </div>
    );
}
