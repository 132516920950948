import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Divider } from '@alfalab/core-components/divider';
import { Input, InputProps } from '@alfalab/core-components/input';
import { Scrollbar } from '@alfalab/core-components/scrollbar';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { AppOrganizationListItem } from '#/src/containers/app/app-organization-select/app-organization-list-item';
import { changeOrganizationForPage } from '#/src/store/organizations/actions';
import {
    currentOrganizationIdSelector,
    organizationsListByPageSelector,
} from '#/src/store/organizations/selectors';

import './app-organization-list.css';

const cn = createCn('app-organization-list');

type AppOrganizationListProps = {
    onClosePopover: () => void;
};

export function AppOrganizationList({ onClosePopover }: AppOrganizationListProps) {
    const [value, changeValue] = useState('');
    const dispatch = useDispatch();
    const organizations = useSelector(organizationsListByPageSelector);
    const currentOrganizationId = useSelector(currentOrganizationIdSelector);

    const handleSelectOrganization = useCallback(
        (tin: string) => {
            dispatch(changeOrganizationForPage(tin));
            onClosePopover();
            changeValue('');
        },
        [dispatch, onClosePopover],
    );

    const handleChangeValue = useCallback<NonNullable<InputProps['onChange']>>((event, payload) => {
        changeValue(payload.value);
    }, []);

    const valueFormatted = value.toLowerCase().trim();

    let filteredOrganizations = organizations;

    if (valueFormatted) {
        filteredOrganizations = organizations.filter(
            ({ name, tin }) =>
                name?.toLowerCase().trim().includes(valueFormatted) ||
                tin?.includes(valueFormatted),
        );
    }

    return (
        <div className={cn()}>
            <div className={cn('input-container')}>
                <Input
                    dataTestId='app-organization-input'
                    block={true}
                    onChange={handleChangeValue}
                    value={value}
                    rightAddons={<MagnifierMIcon />}
                    size='s'
                    placeholder='Введите название компании или ИНН'
                />
            </div>
            {Boolean(filteredOrganizations.length) && <Divider />}
            <div
                className={cn('list-container', { fixedHeight: filteredOrganizations.length > 5 })}
            >
                <Scrollbar className={cn('scrollbar')} autoHide={false}>
                    {filteredOrganizations.map((organization) => (
                        <AppOrganizationListItem
                            key={organization.tin}
                            name={organization.name}
                            tin={organization.tin}
                            isSelected={currentOrganizationId === organization.tin}
                            onSelectOrganization={handleSelectOrganization}
                        />
                    ))}
                </Scrollbar>
            </div>
        </div>
    );
}
