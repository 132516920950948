import { createAsyncThunk } from '@reduxjs/toolkit';

import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { AsyncThunkConfig } from '#/src/types/redux-toolkit';
import { RegisterErf } from '#/src/types/registers';
import { fetchers } from '#/src/utils/client-api';

export const fetchRegisterErf = createAsyncThunk<RegisterErf[], number, AsyncThunkConfig>(
    'registerErf/fetchRegisterErf',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            return await fetchers.fetchRegisterErf(id);
        } catch (error) {
            dispatch(dispatchErrorNotification(error));

            return rejectWithValue(error);
        }
    },
);
