import React from 'react';
import { createCn } from 'bem-react-classname';

import { Divider } from '@alfalab/core-components/divider';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { CombinedRegisterErf } from '#/src/types/registers';

import './register-erf-row.css';

type RegisterErfRowProps = {
    erf: CombinedRegisterErf;
};

const cn = createCn('register-erf-row');

export function RegisterErfRow({ erf }: RegisterErfRowProps) {
    return (
        <React.Fragment>
            <Gap size='s' />
            <Grid.Row justify='between'>
                <Grid.Col width='5'>{erf.debtorName}</Grid.Col>
                <Grid.Col width='available'>
                    {erf.supplyAgreementNumber.map((value) => (
                        <Typography.Text tag='div'>{value}</Typography.Text>
                    ))}
                </Grid.Col>
                <Grid.Col width='auto' className={cn('last-cell')}>
                    {erf.erfValue.map((value) => (
                        <Typography.Text tag='div'>{value}%</Typography.Text>
                    ))}
                </Grid.Col>
            </Grid.Row>
            <Gap size='xs' />
            <Divider />
        </React.Fragment>
    );
}
