import React, { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Amount } from '@alfalab/core-components/amount';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Status } from '@alfalab/core-components/status';
import { Typography } from '@alfalab/core-components/typography';

import { currencyCodes } from '#/src/dictionaries/currency';
import { docTypeDictionary } from '#/src/dictionaries/document-type';
import { statusColors, statusDictionary } from '#/src/dictionaries/register-row-status';
import { setSelectedRowId } from '#/src/store/registers/register-info/action-creators';
import { fetchRepayments } from '#/src/store/registers/repayments/actions';
import { RegisterRow } from '#/src/types/registers';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import './register-info-table-row.css';

const cn = createCn('register-info-table-row');

type RegisterInfoTableRowProps = {
    registerRow: RegisterRow;
};

export function RegisterInfoTableRow({ registerRow }: RegisterInfoTableRowProps) {
    const dispatch = useDispatch();

    const handleOpenRegister = useCallback(() => {
        batch(() => {
            dispatch(setSelectedRowId(registerRow.id));
            dispatch(fetchRepayments(registerRow.id));
        });
    }, [dispatch, registerRow.id]);

    return (
        <div
            className={cn()}
            data-test-id='register-info-table-row'
            onClick={handleOpenRegister}
            aria-hidden='true'
        >
            <Grid.Col width='2' dataTestId='table-cell-seller' className={cn('col')}>
                {registerRow.sellerName || 'Отсутствует'}
                <Gap size='2xs' />
                <Typography.Text
                    tag='div'
                    view='secondary-large'
                    color='secondary'
                    dataTestId='table-cell-seller-tin'
                >
                    ИНН: {registerRow.sellerTin || 'Отсутствует'}
                </Typography.Text>
            </Grid.Col>
            <Grid.Col width='2' dataTestId='table-cell-debtor' className={cn('col')}>
                {registerRow.debtorName || 'Отсутствует'}
                <Gap size='2xs' />
                <Typography.Text
                    tag='div'
                    view='secondary-large'
                    color='secondary'
                    dataTestId='table-cell-debtor-tin'
                >
                    ИНН: {registerRow.debtorTin || 'Отсутствует'}
                </Typography.Text>
            </Grid.Col>
            <Grid.Col width='2' dataTestId='table-cell-amount' className={cn('col')}>
                {registerRow.supplyAmount ? (
                    <Amount
                        view='withZeroMinorPart'
                        value={registerRow.supplyAmount * 100}
                        currency={currencyCodes[registerRow.currencyNumber]}
                        minority={100}
                        className={cn('amount')}
                        bold='none'
                    />
                ) : (
                    'Отсутствует'
                )}
                {!!registerRow.confirmationAmount && (
                    <React.Fragment>
                        <Gap size='3xs' />
                        <Amount
                            view='withZeroMinorPart'
                            value={registerRow.confirmationAmount * 100}
                            currency={currencyCodes[registerRow.currencyNumber]}
                            minority={100}
                            className={cn('amount')}
                            bold='none'
                        />
                    </React.Fragment>
                )}
            </Grid.Col>
            <Grid.Col width='1' dataTestId='table-cell-date' className={cn('col')}>
                {registerRow.supplyDate
                    ? formatDateForCalendar(registerRow.supplyDate)
                    : 'Отсутствует'}
            </Grid.Col>
            <Grid.Col width='2' dataTestId='table-cell-doc' className={cn('col')}>
                {registerRow.documentNumber || 'Отсутствует'}
                <Gap size='2xs' />
                <Typography.Text
                    tag='div'
                    view='secondary-large'
                    color='secondary'
                    dataTestId='table-cell-doc-type'
                >
                    {docTypeDictionary[registerRow.documentType] || 'Отсутствует'}
                    <br />
                    {registerRow.linkForSupplyDocument
                        ? `УПД ${String(registerRow.linkForSupplyDocument).replace(
                              /([a-z0-9-а-яё])([\\/,])([a-z0-9-а-яё])/gi,
                              (s, beforeS, sym, afterS) => `${beforeS}${sym} ${afterS}`,
                          )}`
                        : ''}
                </Typography.Text>
            </Grid.Col>
            <Grid.Col width='1' dataTestId='table-cell-agreement-number' className={cn('col')}>
                {registerRow.agreementNumber
                    ? String(registerRow.agreementNumber).replace(
                          /([a-z0-9-а-яё])([\\/,])([a-z0-9-а-яё])/gi,
                          (s, beforeS, sym, afterS) => `${beforeS}${sym} ${afterS}`,
                      )
                    : 'Отсутствует'}
            </Grid.Col>
            <Grid.Col width='2' dataTestId='table-cell-status' className={cn('col')}>
                {statusDictionary[registerRow.status] ? (
                    <Status
                        className={cn('status', {
                            partially: registerRow.status === 'PARTIALLY_FINANCED',
                        })}
                        color={statusColors[registerRow.status] || 'grey'}
                    >
                        {statusDictionary[registerRow.status]}
                    </Status>
                ) : (
                    'Отсутствует'
                )}
            </Grid.Col>
        </div>
    );
}
