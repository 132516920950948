import { CheckPermissionPayload, CheckPermissionResponse } from '#/src/server/services/api/sign';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        checkSignPermission: fetchFactory<CheckPermissionPayload, CheckPermissionResponse>(
            `${contextRoot}/secure/nodeapi/checkSignPermission`,
            'POST',
            { csrfCookieName },
        ),
    };

    return {
        fetchCheckSignPermission(params: CheckPermissionPayload) {
            return fetch.checkSignPermission(params);
        },
    };
}
