import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { registersModelsSelector } from '#/src/store/registers/selectors';

export const signRegistersStateSelector = (state: ApplicationState) =>
    registersModelsSelector(state).signRegisters;

export const additionalInfoModalIsOpenSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).additionalInfoModalIsOpen;

export const successResultModalIsOpenSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).successResultModalIsOpen;

export const processedResultModalIsOpenSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).processedResultModalIsOpen;

export const warningModalIsOpenSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).warningModalIsOpen;

export const resultSelector = (state: ApplicationState) => signRegistersStateSelector(state).result;

export const currencyRegisterCountsSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).counts;

export const registerIdsSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).registerIds;

export const filteredRegisterIdsSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).filteredRegisterIds;

export const permissionsSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).permissions;

export const documentsSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).documents;

export const userSelector = (state: ApplicationState) => signRegistersStateSelector(state).user;

export const signButtonCallbackSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).callback;

export const createdSignDataSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).createdSignData;

export const loadingStateSelector = (state: ApplicationState) =>
    signRegistersStateSelector(state).loadingState;

export const signRegistersIsLoadingSelector = (state: ApplicationState) =>
    loadingStateSelector(state) === LOADING_STATE.LOADING;
