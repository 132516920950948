import _orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { Customer } from 'thrift-services/entities';

import { OptionShape } from '@alfalab/core-components/select/typings';

import { ApplicationState } from '#/src/store/reducer';
import { routeInfoSelector } from '#/src/store/router/selectors';
import { stateSelector } from '#/src/store/selectors';
import { Organization } from '#/src/types/orgatiozations';
import filterOrganizationListByPage from '#/src/utils/filter-organization-list-by-page';

export const organizationsStateSelector = (state: ApplicationState) =>
    stateSelector(state).organizations;

export const currentOrganizationIdSelector = (state: ApplicationState) =>
    organizationsStateSelector(state).current;

export const organizationsListSelector = (state: ApplicationState) =>
    organizationsStateSelector(state).list;

export const currentOrganizationTypeSelector = (state: ApplicationState) =>
    organizationsStateSelector(state).type;

export const currentOrganizationSelector = createSelector(
    currentOrganizationIdSelector,
    organizationsListSelector,
    (id, organizations) => (id ? organizations.find(({ tin }) => id === tin) : null),
);

export const organizationsListSortedSelector = createSelector(
    organizationsListSelector,
    (organizations) =>
        _orderBy(organizations, [(org: Organization) => org.name.toLowerCase()], ['asc']),
);

export const organizationsListByPageSelector = createSelector(
    organizationsListSortedSelector,
    routeInfoSelector,
    filterOrganizationListByPage,
);

export const hasAvailableOrganizationsByPageSelector = createSelector(
    organizationsListByPageSelector,
    (organizations) => organizations.length,
);

export const customersSelector = createSelector(
    organizationsListByPageSelector,
    (organizations): Customer[] => organizations.map((org) => ({ eqId: org.tin, name: org.name })),
);

export const currentOrganizationIsDebtorSelector = createSelector(
    currentOrganizationTypeSelector,
    (type) => type === 'debtor',
);

export const currentOrganizationInfoSelector = createSelector(
    currentOrganizationIdSelector,
    organizationsListSelector,
    (id, list) => list.find(({ tin }) => tin === id),
);

export const organizationsListByOptionsSelector = createSelector(
    organizationsListSortedSelector,
    (organizations) =>
        organizations.map<OptionShape>((item) => ({
            key: item.tin,
            value: item.tin,
            content: item.name,
        })),
);
