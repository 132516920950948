import { LoadingState } from '#/src/constants/loading-state';
import { ReportNames } from '#/src/types/reports';

import * as actionCreators from './action-creators';
import { ReportNamesActionsType } from './types';

export type ReportNamesState = {
    loadingState: LoadingState;
    list: ReportNames[];
};

const initialState: ReportNamesState = {
    list: [],
    loadingState: 'unknown',
};

export function reportNamesReducer(
    state: ReportNamesState = initialState,
    action: ReportNamesActionsType,
): ReportNamesState {
    switch (action.type) {
        case actionCreators.getReportNamesStart.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.getReportNamesSuccess.type:
            return {
                ...state,
                list: action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.getReportNamesError.type:
            return {
                ...state,
                list: [],
                loadingState: action.loadingState,
            };
        default:
            return state;
    }
}
