import React, { useCallback } from 'react';
import { SignChooseCertificateModalProps } from '@alfa-bank/corp-sign-ui-react/components/SignChooseCertificateModal';

import {
    ChooseCertificateSignModal,
    ChooseCertificateSignModalProps,
} from '#/src/components/choose-certificate-sign-modal';
import { REGISTERS_DECLENSIONS } from '#/src/constants/declensions';

type ChooseCertificateRegistersSignModalProps = { isDebtor: boolean } & Pick<
    ChooseCertificateSignModalProps,
    'permissions' | 'documents' | 'certificatesAreHidden' | 'onUnmount'
> &
    Partial<SignChooseCertificateModalProps>;

export function ChooseCertificateRegistersSignModal({
    isDebtor,
    onClose,
    ...restProps
}: ChooseCertificateRegistersSignModalProps) {
    const handleCloseModal = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <ChooseCertificateSignModal
            declensions={REGISTERS_DECLENSIONS}
            title={isDebtor ? 'Отправить на сверку' : 'Подписать и отправить'}
            onCloseModal={handleCloseModal}
            {...restProps}
        />
    );
}
