export async function downloadFile(blobPart: BlobPart, type: string, filename: string) {
    const blob = new Blob([blobPart], { type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE 11 is still relevant(
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        // other browsers
        const anchorElement = document.createElement('a');

        document.body.appendChild(anchorElement);
        anchorElement.style.display = 'none';
        anchorElement.href = URL.createObjectURL(blob);
        anchorElement.download = filename;

        anchorElement.click();
        document.body.removeChild(anchorElement);
    }
}
