import React from 'react';
import { createCn } from 'bem-react-classname';

import { CountFilter } from '#/src/dictionaries/reports-dashboard';

import { TotalInfoProps } from './chart-report-total-info/get-total-info-items';
import { OptionsProps } from './high-charts-report/get-options';
import ChartReportTotalInfo from './chart-report-total-info';
import HighChartsReport from './high-charts-report';

import './chart-reports.css';

type HighChartReportsProps = OptionsProps &
    TotalInfoProps & {
        byCount: CountFilter;
        isLoading: boolean;
    };
const cn = createCn('chart-reports');

export default function ChartReports({
    tab,
    receivables,
    receivableTotal,
    commissions,
    commissionTotal,
    payments,
    paymentTotal,
    refunds,
    refundTotal,
    filters,
    byCount,
    isLoading,
}: HighChartReportsProps) {
    return (
        <div className={cn()}>
            <HighChartsReport
                tab={tab}
                receivables={receivables}
                commissions={commissions}
                payments={payments}
                refunds={refunds}
                filters={filters}
                isLoading={isLoading}
            />
            <ChartReportTotalInfo
                tab={tab}
                receivableTotal={receivableTotal}
                commissionTotal={commissionTotal}
                paymentTotal={paymentTotal}
                refundTotal={refundTotal}
                filters={filters}
                byCount={byCount}
                isLoading={isLoading}
            />
        </div>
    );
}
