import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { updateAllFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { getDefaultSuppliesFiltersState } from '#/src/store/supplies/supplies-filters/utils';
import { useOnUpdate } from '#/src/utils/use-on-update';

import { SuppliesFilters } from './supplies-filters';
import { SuppliesNeedUpdateNotification } from './supplies-need-update-notification';
import { SuppliesTable } from './supplies-table';

export function SuppliesListPage() {
    const dispatch = useDispatch();
    const currentOrganizationId = useSelector(currentOrganizationIdSelector);

    useOnUpdate(() => {
        if (currentOrganizationId) {
            dispatch(updateAllFilters(getDefaultSuppliesFiltersState()));
        }
    }, [dispatch, currentOrganizationId]);

    return (
        <div>
            <SuppliesFilters />
            <SuppliesNeedUpdateNotification />
            <SuppliesTable />
        </div>
    );
}
