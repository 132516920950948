import React from 'react';
import { createCn } from 'bem-react-classname';

import { Amount } from '@alfalab/core-components/amount';
import { Divider } from '@alfalab/core-components/divider';
import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';

import './debtor-limits-slider-item.css';

type DebtorLimitsSliderItemProps = {
    name: string;
    amount: number;
    isLoading?: boolean;
};

const cn = createCn('debtor-limits-slider-item');

export default function DebtorLimitsSliderItem({
    name,
    amount,
    isLoading,
}: DebtorLimitsSliderItemProps) {
    return (
        <div className={cn()}>
            <Skeleton visible={isLoading}>
                <Typography.Text tag='div' view='primary-large' weight='bold'>
                    {name}
                </Typography.Text>
            </Skeleton>
            <Divider className={cn('divider')} />
            <Skeleton visible={isLoading}>
                <div className={cn('amount')}>
                    <Typography.Text
                        tag='div'
                        view='secondary-large'
                        color='secondary'
                        className={cn('amount-title')}
                    >
                        Используемый
                    </Typography.Text>
                    <Amount value={amount * 100} currency='RUR' minority={100} />
                </div>
            </Skeleton>
        </div>
    );
}
