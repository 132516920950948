import { createCachedSelector } from 're-reselect';

import { ApplicationState } from '#/src/store/reducer';
import { stateSelector } from '#/src/store/selectors';
import { convertAgreementsToOptions } from '#/src/utils/convert-agreements-to-options';

import { entityDefaultState } from './reducer';

export const agreementsStateSelector = (state: ApplicationState) => stateSelector(state).agreements;

const idSelector = (state: ApplicationState, companyType: string) => companyType;

export const agreementsStateByIdSelector = createCachedSelector(
    idSelector,
    agreementsStateSelector,
    (id, agreements) => agreements[id] || entityDefaultState,
)(idSelector);

export const agreementsIsLoadingByIdSelector = createCachedSelector(
    agreementsStateByIdSelector,
    (agreements) => agreements.loadingState === 'loading',
)(idSelector);

export const agreementsIsErrorByIdSelector = createCachedSelector(
    agreementsStateByIdSelector,
    (agreements) => agreements.loadingState === 'error',
)(idSelector);

export const agreementsListByIdSelector = createCachedSelector(
    agreementsStateByIdSelector,
    (agreements) => agreements.entities,
)(idSelector);

export const optionsByIdSelector = createCachedSelector(agreementsListByIdSelector, (entities) =>
    convertAgreementsToOptions(entities),
)(idSelector);
