import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Alert } from '@alfalab/core-components/alert';
import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Modal } from '@alfalab/core-components/modal';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { pluralize } from 'arui-private/lib/formatters';

import { REGISTERS_DECLENSIONS } from '#/src/constants/declensions';
import { changeAdditionalModalState } from '#/src/store/registers/sign-registers/action-creators';
import { createSignRegistersData } from '#/src/store/registers/sign-registers/actions';
import {
    additionalInfoModalIsOpenSelector,
    currencyRegisterCountsSelector,
    registerIdsSelector,
} from '#/src/store/registers/sign-registers/selectors';
import { RegisterData } from '#/src/types/registers';

import { getAdditionalInfoText } from './utils/get-additional-info-text';
import { ItemOfCountsRow } from './item-of-counts-row';

type AdditionalInfoModalProps = {
    isDebtor: boolean;
    register?: RegisterData;
    selectedIds: number[];
};

export function AdditionalInfoModal({ isDebtor, register, selectedIds }: AdditionalInfoModalProps) {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(additionalInfoModalIsOpenSelector);
    const counts = useSelector(currencyRegisterCountsSelector);
    const registerIds = useSelector(registerIdsSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(changeAdditionalModalState(false));
    }, [dispatch]);

    const handleCreateSignRegistersData = useCallback(() => {
        batch(() => {
            dispatch(changeAdditionalModalState(false));
            dispatch(createSignRegistersData());
        });
    }, [dispatch]);

    return (
        <Modal open={modalIsOpen} hasCloser={true} onClose={handleCloseModal} size='l'>
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    Дополнительная информация
                </Typography.Title>
                <Gap size='2xl' />
                <Alert view='attention'>
                    <Typography.Text view='primary-small'>
                        {getAdditionalInfoText(isDebtor, register)}
                    </Typography.Text>
                </Alert>
                {!register && (
                    <React.Fragment>
                        <Gap size='l' />
                        <Typography.Text tag='p'>
                            Всего {selectedIds.length}{' '}
                            {pluralize(selectedIds.length, REGISTERS_DECLENSIONS)}
                        </Typography.Text>
                        {!isDebtor &&
                            counts.map((item) => <ItemOfCountsRow key={item.type} item={item} />)}
                    </React.Fragment>
                )}
                <Gap size='3xl' />
                <Space size={12} direction='horizontal'>
                    <Button
                        disabled={Boolean(!registerIds.length)}
                        view='primary'
                        size='s'
                        onClick={handleCreateSignRegistersData}
                    >
                        Продолжить
                    </Button>
                    <Button view='tertiary' size='s' onClick={handleCloseModal}>
                        Отменить
                    </Button>
                </Space>
            </Modal.Content>
        </Modal>
    );
}
