import { LoadingState } from '#/src/constants/loading-state';
import { CurrencyCount, RegisterData, RegisterRow } from '#/src/types/registers';
import { SortField, SortOrder } from '#/src/types/sort';

import * as actionCreators from './action-creators';
import { RegisterInfoActionsType } from './types';

export type RegisterInfoState = {
    id: string | number;
    entities: RegisterRow[];
    info: RegisterData | null;
    loadingState: LoadingState;
    count: number;
    currencyCounts: CurrencyCount[];
    pagination: {
        page: number;
        pageSize: number;
    };
    sort: {
        field: SortField;
        order: SortOrder;
    };
    selectedRowId: number | null;
};

const initialState: RegisterInfoState = {
    id: '',
    entities: [],
    count: 0,
    info: null,
    pagination: {
        page: 1,
        pageSize: 20,
    },
    sort: {
        field: SortField.DATE,
        order: SortOrder.DESC,
    },
    loadingState: 'unknown',
    currencyCounts: [],
    selectedRowId: null,
};

export function registerInfoReducer(
    state: RegisterInfoState = initialState,
    action: RegisterInfoActionsType,
): RegisterInfoState {
    switch (action.type) {
        case actionCreators.changeRegisterInfoLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.setRegisterInfo.type:
            return {
                ...state,
                pagination: { ...initialState.pagination },
                sort: { ...initialState.sort },
                ...action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.setSelectedRowId.type:
            return {
                ...state,
                selectedRowId: action.id,
            };
        case actionCreators.setSort.type:
            return {
                ...state,
                sort: {
                    ...action.data,
                },
            };
        case actionCreators.setPagination.type:
            return {
                ...state,
                pagination: {
                    ...action.data,
                },
            };
        case actionCreators.clearRegisterInfo.type:
            return initialState;
        default:
            return state;
    }
}
