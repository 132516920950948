import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateReportFilters } from '#/src/store/reports-xls/report-filters/action-creators';
import { reportFiltersSelector } from '#/src/store/reports-xls/report-filters/selectors';

const SupplyNumFilter: FC = () => {
    const dispatch = useDispatch();

    const { supplyNum } = useSelector(reportFiltersSelector);

    const handleChangeSupplyNum = useCallback<NonNullable<InputProps['onChange']>>(
        (_, { value }) => {
            dispatch(updateReportFilters({ supplyNum: value }));
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='supply-number-input'
            label='Поставка'
            block={true}
            value={supplyNum}
            onChange={handleChangeSupplyNum}
        />
    );
};

export default SupplyNumFilter;
