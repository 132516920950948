import { CurrencyCodes } from '@alfalab/core-components/amount';

export type Currency = '810' | '643' | '978' | '840' | '392';

export const currencyCodes: Record<Currency, CurrencyCodes> = {
    810: 'RUR',
    643: 'RUR',
    978: 'EUR',
    840: 'USD',
    392: 'JPY',
};
