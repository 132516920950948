export enum FileType {
    REGISTER_SOURCE_FILE = 'REGISTER_SOURCE_FILE',
    SIGN = 'SIGN',
}

export type CheckOrDownloadDocumentPayload = {
    ids: number[];
    fileTypes: FileType[];
};

export type CheckDocumentResponse = {
    successDocuments: number[];
    report: string;
};
