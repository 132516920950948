import { SupplyCorrectionType, SupplySource, SupplyStatus } from '#/src/types/supply';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import * as actionCreators from './action-creators';
import { SuppliesFiltersActionsType } from './types';

export type FiltersState = {
    debtorsTin: string[];
    factoringAgreementNumber: string;
    supplyAgreementNumber: string;
    amountCertificationDocumentNumber: string;
    fromAmount: string;
    toAmount: string;
    statuses: SupplyStatus[];
    dateFrom: string;
    dateTo: string;
    supplySource: SupplySource;
    registerNumber: string;
    correction: SupplyCorrectionType | null;
};

export type SuppliesFiltersState = {
    applied: FiltersState;
    selected: FiltersState;
    errors: {
        amountRange: string | null;
        dateFrom: string | null;
        dateTo: string | null;
    };
};

export const initialFiltersState: FiltersState = {
    correction: null,
    statuses: [],
    dateFrom: formatDateForCalendar(Date.now() - 604800000),
    dateTo: formatDateForCalendar(Date.now()),
    registerNumber: '',
    supplySource: 'ALL',
    fromAmount: '',
    toAmount: '',
    debtorsTin: [],
    factoringAgreementNumber: '',
    supplyAgreementNumber: '',
    amountCertificationDocumentNumber: '',
};

const initialSuppliesFiltersState: SuppliesFiltersState = {
    applied: { ...initialFiltersState },
    selected: { ...initialFiltersState },
    errors: {
        amountRange: null,
        dateFrom: null,
        dateTo: null,
    },
};

export function suppliesFiltersReducer(
    state: SuppliesFiltersState = initialSuppliesFiltersState,
    action: SuppliesFiltersActionsType,
): SuppliesFiltersState {
    switch (action.type) {
        case actionCreators.updateSelectedFilters.type:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.filters,
                },
            };
        case actionCreators.updateAppliedFilters.type:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    ...action.filters,
                },
            };
        case actionCreators.updateErrorsFilters.type:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.errors,
                },
            };
        case actionCreators.updateAllFilters.type:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.filters,
                },
                applied: {
                    ...state.applied,
                    ...action.filters,
                },
            };
        default:
            return state;
    }
}
