import React from 'react';

import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { SupplyDetails } from '#/src/types/supply';
import { formatDateForList } from '#/src/utils/date-converter';

import { SupplyEventSection } from './supply-event-section';

type SupplyHistoryListProps = Pick<
    SupplyDetails,
    'financingAmount' | 'eventList' | 'lastOrderAcceptanceDate'
>;

export function SupplyHistoryList({
    eventList,
    financingAmount,
    lastOrderAcceptanceDate,
}: SupplyHistoryListProps) {
    return (
        <div>
            {!!financingAmount && (
                <Typography.Text tag='p' view='secondary-large'>
                    Сумма финансирования:{' '}
                    <Amount
                        value={financingAmount * 100}
                        minority={100}
                        view='withZeroMinorPart'
                        currency='RUR'
                        bold='none'
                    />
                </Typography.Text>
            )}
            {!!lastOrderAcceptanceDate && (
                <Typography.Text tag='p' view='secondary-large'>
                    Отсрочка до {formatDateForList(new Date(lastOrderAcceptanceDate), true)}
                </Typography.Text>
            )}
            {!!eventList?.length && <SupplyEventSection eventList={eventList} />}
        </div>
    );
}
