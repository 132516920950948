import React from 'react';
import { createCn } from 'bem-react-classname';

import { Badge } from '@alfalab/core-components/badge';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';

import { SystemNotification } from '#/src/types/communication';

import SystemNotificationPlate from './system-notification-plate';

import './multiple-system-notification.css';

const cn = createCn('multiple-system-notification');

type MultipleSystemNotificationProps = {
    notifications: SystemNotification[];
};

export default function MultipleSystemNotification({
    notifications,
}: MultipleSystemNotificationProps) {
    return (
        <div className={cn()} data-test-id='multiple-system-notification'>
            <Badge size='xl' view='icon' iconColor='attention' content={<AlertCircleMIcon />} />
            <Typography.Title weight='bold' tag='div' view='medium' className={cn('title')}>
                Для вас есть новости
            </Typography.Title>
            <Typography.Text tag='p'>
                Пожалуйста, ознакомьтесь с новыми уведомлениями.
            </Typography.Text>
            <Space className={cn('plates')} fullWidth={true}>
                {notifications.map((notification) => (
                    <SystemNotificationPlate key={notification.id} notification={notification} />
                ))}
            </Space>
        </div>
    );
}
