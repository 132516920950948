import {
    CreateSupplySignDataPayload,
    CreateSupplySignDataResponse,
    DeleteRegisterAndSupplyLinksPayload,
    DeleteRegisterAndSupplyLinksResponse,
    GetExcelReportPayload,
    GetSuppliesAmountPayload,
    GetSuppliesAmountResponse,
    GetSuppliesPayload,
    GetSuppliesResponse,
    GetSupplyCertificatesQuery,
    GetSupplyCertificatesResponse,
    GetSupplyCorrectionHistoryQuery,
    GetSupplyCorrectionHistoryResponse,
    GetSupplyEditingPayload,
    GetSupplyEditingResponse,
    GetSupplyErrorsQuery,
    GetSupplyErrorsResponse,
    GetSupplyRepaymentsQuery,
    GetSupplyRepaymentsResponse,
    NeedUpdatePayload,
    NeedUpdateResponse,
    SendSignSuppliesToFactorPayload,
    SendSignSuppliesToFactorResponse,
    UpdateSupplyPayload,
    UpdateSupplyResponse,
} from '#/src/server/services/api/supply';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        getSupplies: fetchFactory<GetSuppliesPayload, GetSuppliesResponse>(
            `${contextRoot}/secure/nodeapi/getSupplies`,
            'POST',
            { allowMultiRequest: true, csrfCookieName },
        ),
        needUpdate: fetchFactory<NeedUpdatePayload, NeedUpdateResponse>(
            `${contextRoot}/secure/nodeapi/needUpdate`,
            'POST',
            { allowMultiRequest: true, csrfCookieName },
        ),
        getSupplyErrors: fetchFactory<GetSupplyErrorsQuery, GetSupplyErrorsResponse>(
            `${contextRoot}/secure/nodeapi/getSupplyErrors`,
            'GET',
            { allowMultiRequest: true },
        ),
        getSupplyCertificates: fetchFactory<
            GetSupplyCertificatesQuery,
            GetSupplyCertificatesResponse
        >(`${contextRoot}/secure/nodeapi/getSupplyCertificates`, 'GET', {
            allowMultiRequest: true,
        }),
        getSupplyRepayments: fetchFactory<GetSupplyRepaymentsQuery, GetSupplyRepaymentsResponse>(
            `${contextRoot}/secure/nodeapi/getSupplyRepayments`,
            'GET',
            { allowMultiRequest: true },
        ),
        createSupplySignData: fetchFactory<
            CreateSupplySignDataPayload,
            CreateSupplySignDataResponse
        >(`${contextRoot}/secure/nodeapi/createSupplySignData`, 'POST', {
            allowMultiRequest: true,
            csrfCookieName,
        }),
        sendSignSuppliesToFactor: fetchFactory<
            SendSignSuppliesToFactorPayload,
            SendSignSuppliesToFactorResponse
        >(`${contextRoot}/secure/nodeapi/sendSignSuppliesToFactor`, 'POST', {
            allowMultiRequest: true,
            csrfCookieName,
        }),
        getExcelReport: fetchFactory<GetExcelReportPayload, Blob>(
            `${contextRoot}/secure/nodeapi/getExcelReport`,
            'POST',
            { allowMultiRequest: true, csrfCookieName, responseType: 'blob' },
        ),
        deleteRegisterAndSupplyLinks: fetchFactory<
            DeleteRegisterAndSupplyLinksPayload,
            DeleteRegisterAndSupplyLinksResponse
        >(`${contextRoot}/secure/nodeapi/deleteRegisterAndSupplyLinks`, 'POST', {
            allowMultiRequest: true,
            csrfCookieName,
        }),
        getSuppliesAmount: fetchFactory<GetSuppliesAmountPayload, GetSuppliesAmountResponse>(
            `${contextRoot}/secure/nodeapi/getSuppliesAmount`,
            'POST',
            { allowMultiRequest: true, csrfCookieName },
        ),
        getSupplyCorrectionHistory: fetchFactory<
            GetSupplyCorrectionHistoryQuery,
            GetSupplyCorrectionHistoryResponse
        >(`${contextRoot}/secure/nodeapi/getSupplyCorrectionHistory`, 'GET', {
            allowMultiRequest: true,
        }),
        getSupplyEditing: fetchFactory<GetSupplyEditingPayload, GetSupplyEditingResponse>(
            `${contextRoot}/secure/nodeapi/getSupplyEditing`,
            'POST',
            { allowMultiRequest: true, csrfCookieName },
        ),
        updateSupply: fetchFactory<UpdateSupplyPayload, UpdateSupplyResponse>(
            `${contextRoot}/secure/nodeapi/updateSupply`,
            'PUT',
            { allowMultiRequest: true, csrfCookieName },
        ),
    };

    return {
        fetchSupplies(params: GetSuppliesPayload) {
            return fetch.getSupplies(params);
        },
        fetchNeedUpdate(params: NeedUpdatePayload) {
            return fetch.needUpdate(params);
        },
        fetchSupplyErrors(id: number) {
            return fetch.getSupplyErrors({ id });
        },
        fetchSupplyCertificates(supplyId: number) {
            return fetch.getSupplyCertificates({ supplyId });
        },
        fetchSupplyRepayments(supplyId: number) {
            return fetch.getSupplyRepayments({ supplyId });
        },
        fetchCreateSupplySignData(params: CreateSupplySignDataPayload) {
            return fetch.createSupplySignData(params);
        },
        fetchSendSignSuppliesToFactor(params: SendSignSuppliesToFactorPayload) {
            return fetch.sendSignSuppliesToFactor(params);
        },
        fetchExcelReport(params: GetExcelReportPayload) {
            return fetch.getExcelReport(params);
        },
        fetchDeleteRegisterAndSupplyLinks(registerId: number) {
            return fetch.deleteRegisterAndSupplyLinks({ registerId });
        },
        fetchSuppliesAmount(ids: number[]) {
            return fetch.getSuppliesAmount(ids);
        },
        fetchSupplyCorrectionHistory(supplyId: number) {
            return fetch.getSupplyCorrectionHistory({ supplyId });
        },
        fetchSupplyEditing(ids: number[]) {
            return fetch.getSupplyEditing(ids);
        },
        fetchUpdateSupply(params: UpdateSupplyPayload) {
            return fetch.updateSupply(params);
        },
    };
}
