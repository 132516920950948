import { ThunkAction } from 'redux-thunk';
import Cookie from 'js-cookie';

import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { organizationsListSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { pathnameSelector } from '#/src/store/router/selectors';
import { Permission } from '#/src/types/orgatiozations';

import { changeOrganization } from './action-creators';
import { OrganizationsActionsType } from './types';

export function changeOrganizationForPage(
    id: string,
    roleType: 'supplier' | 'debtor' | null = null,
): ThunkAction<void, ApplicationState, void, OrganizationsActionsType> {
    return (dispatch, getState) => {
        const state = getState();
        const path = pathnameSelector(state);
        const organizations = organizationsListSelector(state);
        const organizationInfo = organizations.find(({ tin }) => id === tin);
        let availableRoleType = roleType;

        if (organizationInfo && !availableRoleType) {
            if (routesDictionary.reportsXlsPage.pathRouteRegex.test(path)) {
                if (organizationInfo?.actions.includes(Permission.FACT_SHOW_SELLER_REPORTS_NEW)) {
                    availableRoleType = 'supplier';
                } else {
                    availableRoleType = 'debtor';
                }
            } else if (routesDictionary.registersPage.pathRouteRegex.test(path)) {
                if (organizationInfo?.actions.includes(Permission.FACT_SHOW_SELLER_REGISTERS)) {
                    availableRoleType = 'supplier';
                } else {
                    availableRoleType = 'debtor';
                }
            }
        }

        dispatch(changeOrganization(id, availableRoleType));
        Cookie.set('organizationId', id, { sameSite: 'Lax' });
    };
}
