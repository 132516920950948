import { useCallback, useEffect, useRef, useState } from 'react';

const useIsFixed = (): [boolean, React.RefObject<HTMLDivElement>, number] => {
    const [isFixed, setFixed] = useState(false);
    const [width, setWidth] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const handleScroll = useCallback(() => {
        let isFixedNewState = false;

        if (containerRef && containerRef?.current?.getBoundingClientRect) {
            setWidth(containerRef.current?.getBoundingClientRect().width);

            if (isFixed) {
                isFixedNewState = containerRef.current?.getBoundingClientRect().top <= 0;
            } else {
                isFixedNewState = containerRef.current?.getBoundingClientRect().top <= 10;
            }
            if (isFixedNewState !== isFixed) {
                setFixed(isFixedNewState);
            }
        }
    }, [containerRef, isFixed]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return [isFixed, containerRef, width];
};

export default useIsFixed;
