import {
    GetSuppliesDetailsQuery,
    GetSuppliesDetailsResponse,
} from '#/src/server/services/api/supplies';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '') {
    const fetch = {
        getSuppliesDetails: fetchFactory<GetSuppliesDetailsQuery, GetSuppliesDetailsResponse>(
            `${contextRoot}/secure/nodeapi/getSuppliesDetails`,
            'GET',
            { allowMultiRequest: true },
        ),
    };

    return {
        fetchSuppliesDetails(id: number, serverRequestTime: string) {
            return fetch.getSuppliesDetails({ id, serverRequestTime });
        },
    };
}
