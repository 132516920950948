import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { ConnectedRouter } from 'connected-react-router';

import { ApplicationState } from '#/src/store/reducer';
import { History } from '#/src/types/routing';

import { Root } from './root';

type BrowserRootProps = {
    store: Store<ApplicationState>;
    history: History;
};

export const BrowserRoot: React.FC<BrowserRootProps> = ({ store, history }) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Root history={history} />
        </ConnectedRouter>
    </Provider>
);
