import {
    FiltersSourceType,
    RegisterRowStatusType,
    RegisterSourceAllType,
} from '#/src/types/registers';

import * as actionCreators from './action-creators';
import { RegisterInfoFiltersActionsType } from './types';

export type FiltersState = {
    status: RegisterRowStatusType[];
    dateFrom: string;
    dateTo: string;
    amountFrom: string;
    amountTo: string;
    documentNumber: string;
    registerSource: FiltersSourceType;
    agreementNumber: string;
    sellersTin: string[];
    debtorsTin: string[];
};

export type RegisterInfoFiltersState = {
    applied: FiltersState;
    selected: FiltersState;
    errors: Record<string, string | null>;
};

export const initialFiltersState: FiltersState = {
    status: [],
    dateFrom: '',
    dateTo: '',
    documentNumber: '',
    registerSource: RegisterSourceAllType.ALL,
    agreementNumber: '',
    sellersTin: [],
    debtorsTin: [],
    amountFrom: '',
    amountTo: '',
};

const initialRegisterInfoFiltersState: RegisterInfoFiltersState = {
    applied: { ...initialFiltersState },
    selected: { ...initialFiltersState },
    errors: {},
};

export function registerInfoFiltersReducer(
    state: RegisterInfoFiltersState = initialRegisterInfoFiltersState,
    action: RegisterInfoFiltersActionsType,
): RegisterInfoFiltersState {
    switch (action.type) {
        case actionCreators.updateSelectedFilters.type:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.filters,
                },
            };
        case actionCreators.updateAppliedFilters.type:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    ...action.filters,
                },
            };
        case actionCreators.setFiltersErrors.type:
            return {
                ...state,
                errors: {
                    ...action.errors,
                },
            };
        case actionCreators.resetAllFilters.type:
            return {
                errors: {},
                applied: { ...initialFiltersState },
                selected: { ...initialFiltersState },
            };
        default:
            return state;
    }
}
