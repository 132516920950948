import React, { ReactNode } from 'react';
import { createCn } from 'bem-react-classname';

import './reports-xls-table-cell.css';

const cn = createCn('reports-xls-table-cell');

type ReportsXlsTableCellProps = {
    children: ReactNode;
    type?:
        | 'default'
        | 'status'
        | 'controls'
        | 'name'
        | 'date'
        | 'document'
        | 'agreement'
        | 'debtor'
        | 'supply';
};

export default function ReportsXlsTableCell({
    children,
    type = 'default',
}: ReportsXlsTableCellProps) {
    return <div className={cn({ type })}>{children}</div>;
}
