import createAction from '#/src/utils/create-action';

import { FiltersState } from './reducer';

export const updateSelectedFilters = createAction(
    'REGISTERS / FILTERS / updateSelectedFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const updateAppliedFilters = createAction(
    'REGISTERS / FILTERS / updateAppliedFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const updateAllFilters = createAction(
    'REGISTERS / FILTERS / updateAllFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const resetAllFilters = createAction(
    'REGISTERS / FILTERS / resetAllFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);
