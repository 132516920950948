import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputAutocompleteProps } from '@alfalab/core-components/input-autocomplete';
import { OptionShape } from '@alfalab/core-components/select/typings';

import { CustomInputAutocomplete } from '#/src/components/custom-input-autocomplete';
import {
    entitiesSelector,
    registerInfoSelector,
} from '#/src/store/registers/register-info/selectors';
import { updateSelectedFilters } from '#/src/store/registers/register-info-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/register-info-filters/selectors';

export function DebtorsFilter() {
    const dispatch = useDispatch();

    const { debtorsTin, sellersTin } = useSelector(selectedFiltersSelector);

    const registerInfo = useSelector(registerInfoSelector);
    const entities = useSelector(entitiesSelector);
    const props = useMemo(() => {
        let tin: 'debtorTin' | 'sellerTin' = 'debtorTin';
        let filterName: 'sellersTin' | 'debtorsTin' = 'debtorsTin';
        let name: 'sellerName' | 'debtorName' = 'debtorName';
        const debtorsMap: Record<string, string> = {};
        const options: OptionShape[] = [];
        let selected = debtorsTin;
        let label = 'Дебитор';

        if (registerInfo) {
            if (registerInfo.debtor) {
                tin = 'sellerTin';
                name = 'sellerName';
                label = 'Поставщик';
                selected = sellersTin;
                filterName = 'sellersTin';
            }

            entities.forEach((row) => {
                if (row[tin] && !debtorsMap[row[tin]]) {
                    debtorsMap[row[tin]] = row[name] || row[tin];
                }
            });

            Object.keys(debtorsMap).forEach((key) => {
                options.push({
                    key,
                    value: key,
                    content: debtorsMap[key],
                });
            });
        }

        return {
            options,
            label,
            selected,
            filterName,
        };
    }, [registerInfo, entities, debtorsTin, sellersTin]);

    const handleFilterDebtorsChange = useCallback<NonNullable<InputAutocompleteProps['onChange']>>(
        ({ selectedMultiple, initiator }) => {
            dispatch(
                updateSelectedFilters({
                    [props.filterName]: initiator
                        ? (selectedMultiple.map((option) => option?.key || option) as string[])
                        : [],
                }),
            );
        },
        [dispatch, props.filterName],
    );

    return (
        <CustomInputAutocomplete
            dataTestId='debtors-select'
            multiple={true}
            label={props.label}
            closeOnSelect={false}
            placeholder='Введите название компании или ИНН'
            selected={props.selected}
            options={props.options}
            onChange={handleFilterDebtorsChange}
        />
    );
}
