import createAction from '#/src/utils/create-action';

import { DashboardFiltersState } from './reducer';

export const updateFilters = createAction(
    'DASHBOARD / FILTERS / updateFilters',
    (filters: Partial<DashboardFiltersState>) => ({ filters }),
);

export const resetFilters = createAction(
    'DASHBOARD / FILTERS / resetAllFilters',
    (filters: Partial<DashboardFiltersState>) => ({ filters }),
);
