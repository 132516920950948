import { LoadingState } from '#/src/constants/loading-state';
import { SupplyDetails } from '#/src/types/supply';

import * as actionCreators from './action-creators';
import { SupplyDetailsActionsType } from './types';

export type SupplyDetailsById = {
    info: SupplyDetails | null;
    loadingState: LoadingState;
};

export type SupplyDetailsState = Record<number, SupplyDetailsById>;

export const supplyDetailsByIdDefault: SupplyDetailsById = {
    info: null,
    loadingState: 'unknown',
};

const initialState: SupplyDetailsState = {};

export function supplyDetailsReducer(
    state: SupplyDetailsState = initialState,
    action: SupplyDetailsActionsType,
): SupplyDetailsState {
    switch (action.type) {
        case actionCreators.getSupplyDetailsStart.type:
            return {
                ...state,
                [action.id]: {
                    ...supplyDetailsByIdDefault,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyDetailsSuccess.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    info: action.data,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyDetailsError.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.removeSupplyDetails.type: {
            const { [action.id]: removeItem, ...items } = state;

            return {
                ...items,
            };
        }
        default:
            return state;
    }
}
