export enum ReportNames {
    MONGER_DEBTOR_LIST = 'MONGER_DEBTOR_LIST',
    MONGER_SUPPLY_LIST = 'MONGER_SUPPLY_LIST',
    MONGER_DEBTOR_PAYMENTS = 'MONGER_DEBTOR_PAYMENTS',
    MONGER_REGISTERED_SUPPLIES = 'MONGER_REGISTERED_SUPPLIES',
    MONGER_FINANCING_TRANSFER = 'MONGER_FINANCING_TRANSFER',
    MONGER_RESERVES_TRANSFER = 'MONGER_RESERVES_TRANSFER',
    MONGER_PAYMENTS_COMMISIONS_RESTS = 'MONGER_PAYMENTS_COMMISIONS_RESTS',
    MONGER_DETAILS_OF_FINANCING_COMMISSION = 'MONGER_DETAILS_OF_FINANCING_COMMISSION',
    MONGER_INCOMING_DEBTOR_PAYMENTS = 'MONGER_INCOMING_DEBTOR_PAYMENTS',
    MONGER_DEBTS_OF_DEBTORS = 'MONGER_DEBTS_OF_DEBTORS',
    MONGER_DETAILS_FOR_DEBTS_OF_DEBTORS = 'MONGER_DETAILS_FOR_DEBTS_OF_DEBTORS',
    MONGER_LIMITS = 'MONGER_LIMITS',
    MONGER_FINANCING_TAXES = 'MONGER_FINANCING_TAXES',
    MONGER_REPAYMENTS = 'MONGER_REPAYMENTS',
    MONGER_TURNOVER = 'MONGER_TURNOVER',
    MONGER_STATISTICS = 'MONGER_STATISTICS',
    MONGER_DETAILS_FOR_INVOICE = 'MONGER_DETAILS_FOR_INVOICE',
    DEBTOR_MONGER_LIST = 'DEBTOR_MONGER_LIST',
    DEBTOR_SUPPLY_LIST = 'DEBTOR_SUPPLY_LIST',
    DEBTOR_REGISRERED_SUPPLIES = 'DEBTOR_REGISRERED_SUPPLIES',
    DEBTOR_INCOMING_PAYMENTS = 'DEBTOR_INCOMING_PAYMENTS',
    DEBTOR_PAYMENTS = 'DEBTOR_PAYMENTS',
    DEBTOR_ACCRUED_COMMISSION = 'DEBTOR_ACCRUED_COMMISSION',
    DEBTOR_DEBT_DEBTOR_TO_SUPPLY = 'DEBTOR_DEBT_DEBTOR_TO_SUPPLY',
    DEBTOR_PAYMENT_REFUNDED_DEBTOR = 'DEBTOR_PAYMENT_REFUNDED_DEBTOR',
    DEBTOR_TARIFFS_DEBTOR = 'DEBTOR_TARIFFS_DEBTOR',
    DEBTOR_TARIFFS_DEBTOR_FOR_SELLER = 'DEBTOR_TARIFFS_DEBTOR_FOR_SELLER',
}

export type ReportFilterKeys =
    | 'dateFrom'
    | 'dateTo'
    | 'debtorId'
    | 'documentNumber'
    | 'supplyNum'
    | 'generalAgreementId';

export enum CachedReportStatus {
    SUCCESS = 'SUCCESS',
    PROCESS = 'PROCESS',
    ERROR = 'ERROR',
}

export type CachedReportErrorCode = 'TECH_ERROR' | 'QUEUE_IS_FULL';

export type CachedReport = {
    id: string;
    status: CachedReportStatus;
    dateTime: string;
    isDebtor: boolean;
    errorCode: CachedReportErrorCode | null;
    fieldValuesMap: {
        date?: string | null;
        reportName: ReportNames;
        dateFrom?: string | null;
        dateTo?: string;
        numDoc?: string;
        debtorId?: string;
        supplyNum?: string;
        generalAgreementId?: string;
    };
};

export type GetByteReportQuery = {
    reportName: ReportNames;
    isDebtor: boolean;
    partyTin: string;
    from?: string;
    to?: string;
    debtorId?: string;
    documentNumber?: string;
    supplyNum?: string;
    generalAgreementId?: string;
};
