import { batch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { SignButtonProps } from '@alfa-bank/corp-sign-ui-react/components/SignButton';
import { DocumentWithContent, DocumentWithSign } from '@alfa-bank/corp-sign-ui-react/types';

import { LOADING_STATE } from '#/src/constants/loading-state';
import {
    dispatchErrorNotification,
    dispatchSuccessNotification,
} from '#/src/store/notifications/actions';
import {
    currentOrganizationIdSelector,
    currentOrganizationSelector,
} from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import {
    createdRegisterSignDataSelector,
    createdSuppliesSignDataSelector,
    registerIdSelector,
    signButtonCallbackSelector,
    suppliesIdsBySourceSelector,
} from '#/src/store/supplies/sign-supplies/selectors';
import combineActiveErf from '#/src/store/supplies/sign-supplies/utils/combine-active-erf';
import combineErfRequestParams from '#/src/store/supplies/sign-supplies/utils/combine-erf-request-params';
import combineSendToFactorPromises from '#/src/store/supplies/sign-supplies/utils/combine-send-to-factor-promises';
import { getSupplies } from '#/src/store/supplies/supplies-list/action-creators';
import {
    availableSuppliesInfoSelector,
    suppliesPaginationSelector,
} from '#/src/store/supplies/supplies-list/selectors';
import { SuppliesListActionsType } from '#/src/store/supplies/supplies-list/types';
import { userNameSelector } from '#/src/store/user/selectors';
import { Organization } from '#/src/types/orgatiozations';
import { CheckPermissionSOResult, CreateSignDataResponse } from '#/src/types/sign';
import { UserInfo } from '#/src/types/user';
import { fetchers } from '#/src/utils/client-api';
import combinePermissions from '#/src/utils/combine-permissions';
import convertSuppliesFiltersToRequestPayload from '#/src/utils/convert-supplies-filters-to-request-payload';

import * as actionCreators from './action-creators';
import { SignSuppliesActionsType } from './types';

export function prepareSignSuppliesData(
    ids: number[],
    props: Partial<SignButtonProps>,
): ThunkAction<void, ApplicationState, void, SignSuppliesActionsType> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.clearState());
        dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.LOADING));

        const state = getState();
        const availableSuppliesInfo = availableSuppliesInfoSelector(state);
        const currentOrganizationId = currentOrganizationIdSelector(state) as string;

        if (availableSuppliesInfo.ids.length) {
            try {
                const { supplies } = await fetchers.fetchSupplies({
                    sellerTin: currentOrganizationId,
                    needCompressedAnswer: false,
                    supplyIds: availableSuppliesInfo.ids,
                    needFields: ['supplyAgreementNumber', 'debtorTin'],
                });

                if (supplies) {
                    if (supplies.some(({ supplyAgreementNumber }) => !supplyAgreementNumber)) {
                        dispatch(actionCreators.changeEmptyFieldsWarningModalState(true));
                        dispatch(
                            actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.ERROR),
                        );

                        return;
                    }

                    const erfRequestParams = combineErfRequestParams(
                        supplies,
                        currentOrganizationId,
                    );
                    const activeErf = await fetchers.fetchActiveErf(erfRequestParams);
                    const suppliesAmount = await fetchers.fetchSuppliesAmount(
                        availableSuppliesInfo.ids,
                    );

                    dispatch(
                        actionCreators.setPreparedSignData({
                            availableAmount: suppliesAmount,
                            activeErf: combineActiveErf(activeErf),
                            suppliesIds: availableSuppliesInfo.ids,
                            callback: props.onClick,
                        }),
                    );
                }
            } catch (e) {
                dispatch(actionCreators.changeErfErrorModalState(true));
                dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.ERROR));
            }
        } else {
            dispatch(
                actionCreators.setPreparedSignData({
                    availableAmount: availableSuppliesInfo.amount,
                    activeErf: [],
                    suppliesIds: availableSuppliesInfo.ids,
                    callback: props.onClick,
                }),
            );
        }
    };
}

export function createSignSuppliesData(): ThunkAction<
    void,
    ApplicationState,
    void,
    SignSuppliesActionsType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.LOADING));

        const state = getState();
        const username = userNameSelector(state);
        const signButtonCallback = signButtonCallbackSelector(state);
        const currentOrganization = currentOrganizationSelector(state) as Organization;
        const { idsByEDI, idsByRPD } = suppliesIdsBySourceSelector(state);

        try {
            if (idsByEDI.length || idsByRPD.length) {
                const user = await fetchers.fetchUsernameInfo(username);
                const supplySignDataResult = await createSupplySignDataFlow(
                    currentOrganization.upin,
                    user,
                    idsByEDI,
                );
                const registerSignDataResult = await createRegisterSignDataFlow(
                    currentOrganization,
                    user,
                    idsByRPD,
                    username,
                );

                const error =
                    'error' in (supplySignDataResult || {}) ||
                    'error' in (registerSignDataResult || {});

                if (error) {
                    dispatch(dispatchErrorNotification(error));
                    dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.UNKNOWN));

                    return;
                }

                let documents: DocumentWithContent[] = [];
                let filteredSuppliesIds: number[] = [];
                let createdSuppliesSignData: CreateSignDataResponse = [];
                let createdRegisterSignData: CreateSignDataResponse = [];
                let permissions: CheckPermissionSOResult[] = [];

                if (supplySignDataResult && !('error' in supplySignDataResult)) {
                    documents = documents.concat(supplySignDataResult.documents);
                    filteredSuppliesIds = filteredSuppliesIds.concat(
                        supplySignDataResult.filteredSuppliesIds,
                    );
                    createdSuppliesSignData = supplySignDataResult.createdSignData;
                    permissions = permissions.concat(supplySignDataResult.permissions);
                }
                if (registerSignDataResult && !('error' in registerSignDataResult)) {
                    documents = documents.concat(registerSignDataResult.documents);
                    filteredSuppliesIds = filteredSuppliesIds.concat(
                        registerSignDataResult.filteredSuppliesIds,
                    );
                    createdRegisterSignData = registerSignDataResult.createdSignData;
                    permissions = permissions.concat(registerSignDataResult.permissions);
                }

                const warningModalIsOpen = Boolean(!documents.length && permissions.length);

                dispatch(
                    actionCreators.setCreatedSignData({
                        documents,
                        filteredSuppliesIds,
                        createdSuppliesSignData,
                        createdRegisterSignData,
                        user,
                        permissions,
                        warningModalIsOpen,
                        registerId: registerSignDataResult?.registerId || null,
                    }),
                );

                if (signButtonCallback && !warningModalIsOpen) {
                    signButtonCallback();
                }
            } else {
                dispatch(
                    actionCreators.setCreatedSignData({
                        documents: [],
                        filteredSuppliesIds: [],
                        createdSuppliesSignData: [],
                        createdRegisterSignData: [],
                        user: null,
                        permissions: [],
                        warningModalIsOpen: true,
                        registerId: null,
                    }),
                );
            }
        } catch (e) {
            dispatch(actionCreators.clearState());
            dispatch(dispatchErrorNotification(e));
        }
    };
}

export function sendSignSuppliesToFactor(
    documents: DocumentWithSign[],
): ThunkAction<void, ApplicationState, void, SignSuppliesActionsType> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.LOADING));

        const state = getState();
        const registerId = registerIdSelector(state);
        const currentOrganizationId = currentOrganizationIdSelector(state) as string;
        const createdSuppliesSignData = createdSuppliesSignDataSelector(state);
        const createdRegisterSignData = createdRegisterSignDataSelector(state);

        if (registerId) {
            try {
                await fetchers.fetchRegisterInfo({ registerId, partyTin: currentOrganizationId });
            } catch (e) {
                batch(() => {
                    dispatch(actionCreators.changeWaitingErrorModalState(true));
                    dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.ERROR));
                });

                return;
            }
        }

        try {
            const promises = combineSendToFactorPromises(
                documents,
                createdSuppliesSignData,
                createdRegisterSignData,
            );

            const listResults = await Promise.all(promises);

            const statuses = Object.values(
                listResults.reduce((list, item) => ({ ...list, ...item }), {}),
            );

            if (statuses.every((status) => status === 'SUCCESS')) {
                dispatch(signSupplyResolve(statuses.length));
            } else if (statuses.every((status) => status !== 'SUCCESS')) {
                const error =
                    statuses.length > 1
                        ? 'Распоряжения не отправлены'
                        : 'Распоряжение не отправлено';

                dispatch(signSupplyRejectSO(error));
            } else {
                dispatch(signSupplyRejectSO('Часть распоряжений не была отправлена'));
            }
            dispatch(actionCreators.clearState());
        } catch (e) {
            dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.ERROR));
            if (e) {
                dispatch(dispatchErrorNotification(e));
            }
        }
    };
}

export function deleteRegisterAndSupplyLinks(
    registerId: number,
): ThunkAction<void, ApplicationState, void, SignSuppliesActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.LOADING));

        try {
            await fetchers.fetchDeleteRegisterAndSupplyLinks(registerId);
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
        } finally {
            dispatch(actionCreators.changeSignSuppliesLoadingState(LOADING_STATE.UNKNOWN));
        }
    };
}

export async function createSupplySignDataFlow(
    upin: string,
    user: UserInfo,
    suppliesIds: number[],
) {
    if (!suppliesIds.length) {
        return null;
    }

    try {
        const commonUserData = { xpin: user.xpin, upin };

        const createdSignData = await fetchers.fetchCreateSupplySignData({
            financingType: 'FINANCE',
            signDocuments: suppliesIds.map((id) => ({ id: id.toString(), ...commonUserData })),
        });
        const permissions = await fetchers.fetchCheckSignPermission(
            createdSignData.map((item) => ({
                uid: `${item.signId}:financingOrder`,
                ...commonUserData,
            })),
        );
        const documents: DocumentWithContent[] = [];
        const filteredSuppliesIds: number[] = [];
        const result = combinePermissions(permissions);

        createdSignData.forEach((item) => {
            if (result[item.signId]) {
                documents.push({ documentId: item.signId, content: item.data });
                filteredSuppliesIds.push(+item.id);
            }
        });

        return {
            documents,
            filteredSuppliesIds,
            createdSignData,
            permissions,
        };
    } catch (error) {
        return { error };
    }
}

export async function createRegisterSignDataFlow(
    organization: Organization,
    user: UserInfo,
    suppliesIds: number[],
    username: string,
) {
    if (!suppliesIds.length) {
        return null;
    }

    try {
        const requestData = convertSuppliesFiltersToRequestPayload(organization.tin);
        const { supplies } = await fetchers.fetchSupplies({
            ...requestData,
            supplyIds: suppliesIds,
            needFields: [
                'rpdRowId',
                'supplyAmount',
                'supplyAgreementNumber',
                'factoringAgreementNumber',
                'afinId',
                'delayPeriod',
                'receiptDate',
            ],
        });

        if (supplies) {
            const register = await fetchers.fetchCreateRegister(
                supplies.map(
                    ({
                        rpdRowId,
                        supplyAmount,
                        factoringAgreementNumber,
                        supplyAgreementNumber,
                        afinId,
                        delayPeriod,
                        receiptDate,
                    }) => ({
                        debtorRegisterRowId: rpdRowId as number,
                        supplyAmount: supplyAmount as number,
                        agreementNumber: supplyAgreementNumber as string,
                        factoringAgreementNumber: factoringAgreementNumber as string,
                        afinId: afinId as string,
                        ...(delayPeriod && { delayPeriod }),
                        ...(receiptDate && { receiptDate }),
                    }),
                ),
                { partyTin: organization.tin, username },
            );
            const commonUserData = { xpin: user.xpin, upin: organization.upin };

            const createdSignData = await fetchers.fetchCreateRegisterSignData({
                financingType: 'FINANCE',
                signDocuments: [{ id: register.id.toString(), ...commonUserData }],
            });
            const permissions = await fetchers.fetchCheckSignPermission(
                createdSignData.map((item) => ({
                    uid: `${item.signId}:register`,
                    ...commonUserData,
                })),
            );
            const documents: DocumentWithContent[] = [];
            const result = combinePermissions(permissions);

            createdSignData.forEach((item) => {
                if (result[item.signId]) {
                    documents.push({ documentId: item.signId, content: item.data });
                }
            });

            return {
                registerId: register.id,
                documents,
                filteredSuppliesIds: documents.length ? suppliesIds : [],
                createdSignData,
                permissions: suppliesIds.map(() => permissions[0]),
            };
        }

        return { error: true };
    } catch (error) {
        return { error };
    }
}

const DEFAULT_ERROR = 'Произошла ошибка';

export function signSupplyResolve(
    counts: number,
): ThunkAction<void, ApplicationState, void, SignSuppliesActionsType | SuppliesListActionsType> {
    return (dispatch, getState) => {
        dispatch(
            dispatchSuccessNotification(
                `Распоряжени${counts > 1 ? 'я' : 'e'}
            на финансирование отправлен${counts > 1 ? 'ы' : 'о'}`,
            ),
        );

        const state = getState();
        const { page, pageSize } = suppliesPaginationSelector(state);

        dispatch(getSupplies({ page, pageSize, needMergeSelected: false, reloadPage: true }));
    };
}

export function signSupplyRejectSO(
    err: any,
): ThunkAction<void, ApplicationState, void, SignSuppliesActionsType | SuppliesListActionsType> {
    return (dispatch, getState) => {
        const error = {
            title: err.title || null,
            message:
                (err && err.message !== 'object' && err.message) ||
                (err && !!err.message && err.message.body) ||
                err ||
                DEFAULT_ERROR,
        };
        const state = getState();
        const { page, pageSize } = suppliesPaginationSelector(state);

        dispatch(getSupplies({ page, pageSize, needMergeSelected: false, reloadPage: true }));
        dispatch(dispatchErrorNotification(error));
    };
}
