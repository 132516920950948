export type SettingsState = {
    pageTitle: string;
    alfaMetricsId: string;
    projectName: string;
    authPage: string;
    contextRoot: string;
    version: string;
    traceId?: string;
};

export const initialState: SettingsState = {
    pageTitle: '',
    alfaMetricsId: '',
    projectName: '',
    authPage: '',
    contextRoot: '',
    version: '',
    traceId: undefined,
};

export function settingsReducer(state: SettingsState = initialState): SettingsState {
    return state;
}
