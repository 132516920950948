import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Modal } from '@alfalab/core-components/modal';

import { redirectUserAfterAuthFlow } from '#/src/store/auth/actions';
import { hideNotifications } from '#/src/store/system-notify/action-creators';
import {
    notifyModalIsOpenSelector,
    systemNotificationsSelector,
} from '#/src/store/system-notify/selectors';

import MultipleSystemNotification from '../multiple-system-notification';
import SingleSystemNotification from '../single-system-notification';

import './system-notification-modal.css';

const cn = createCn('system-notification-modal');

export default function SystemNotificationModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(notifyModalIsOpenSelector);
    const systemNotifications = useSelector(systemNotificationsSelector);
    const multipleNotifications = systemNotifications.length > 1;
    const handleCloseModal = useCallback(() => {
        dispatch(hideNotifications());
        dispatch(redirectUserAfterAuthFlow());
    }, [dispatch]);

    return (
        <Modal
            open={modalIsOpen}
            hasCloser={true}
            onClose={handleCloseModal}
            size='m'
            dataTestId='system-notification-modal'
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <div className={cn('container')}>
                    {multipleNotifications ? (
                        <MultipleSystemNotification notifications={systemNotifications} />
                    ) : (
                        <SingleSystemNotification notification={systemNotifications[0]} />
                    )}
                    <Button
                        dataTestId='success-button'
                        onClick={handleCloseModal}
                        className={cn('button')}
                        size='m'
                        view={multipleNotifications ? 'primary' : 'tertiary'}
                    >
                        {multipleNotifications ? 'Понятно' : 'Хорошо'}
                    </Button>
                </div>
            </Modal.Content>
        </Modal>
    );
}
