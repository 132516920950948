import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, LinkProps } from '@alfalab/core-components/link';
import { Spinner, SpinnerProps } from '@alfalab/core-components/spinner';

import { downloadFile } from '#/src/store/files/actions';
import { fileIsLoadingSelector } from '#/src/store/files/selectors';

type DownloadFileLinkProps = {
    fileType: string;
    fileName: string;
    spinnerSize?: SpinnerProps['size'];
} & LinkProps;

export function DownloadFileLink({
    fileName,
    fileType,
    children,
    spinnerSize = 'xs',
    ...linkProps
}: DownloadFileLinkProps) {
    const dispatch = useDispatch();
    const isLoading = useSelector(fileIsLoadingSelector);

    const handleDownloadFile = useCallback(() => {
        if (!isLoading) {
            dispatch(downloadFile({ fileType, fileName }));
        }
    }, [dispatch, isLoading, fileName, fileType]);

    return (
        <Link
            {...linkProps}
            rightAddons={<Spinner size={spinnerSize} visible={isLoading} />}
            onClick={handleDownloadFile}
            pseudo={true}
        >
            {children}
        </Link>
    );
}
