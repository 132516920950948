import React from 'react';

import { Typography } from '@alfalab/core-components/typography';

import { RegisterData } from '#/src/types/registers';
import { formatDateForCalendar } from '#/src/utils/date-converter';

type RegisterFileNumberProps = {
    register: RegisterData;
};
export function RegisterFileNumber({ register }: RegisterFileNumberProps) {
    return (
        <Typography.Text
            tag='div'
            view='primary-medium'
            color='secondary'
            weight='bold'
            dataTestId='register-info-file-number'
        >
            №
            {register.fileNumber || register.fileNumber === 0
                ? register.fileNumber
                : ' Отсутствует'}{' '}
            от {register.createDate && formatDateForCalendar(register.createDate)}
        </Typography.Text>
    );
}
