import { v4 as generateId } from 'uuid';

import {
    CertificateInfoSignedSupply,
    CertificateInfoSignedSupplyData,
    Document,
} from '#/src/types/certificates';

export default function convertCertificateDataListToCertificateInfoSignedSupplyList(
    certificates: CertificateInfoSignedSupplyData[],
): CertificateInfoSignedSupply[] {
    return Object.values(
        certificates.reduce<Record<string, CertificateInfoSignedSupply>>((acc, certificate) => {
            const { docName, signingDate, ...info } = certificate;
            const document: Document = {
                id: generateId(),
                docName,
                signingDate,
            };
            const key = Object.values(info).join();

            const certificateInfo: CertificateInfoSignedSupply = (key &&
                acc[key] && {
                    ...acc[key],
                    signedDocuments: acc[key].signedDocuments.concat(document),
                }) || {
                ...info,
                id: generateId(),
                signedDocuments: [document],
            };

            return {
                ...acc,
                [key || certificateInfo.id]: certificateInfo,
            };
        }, {}),
    );
}
