import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { IconButton } from '@alfalab/core-components/icon-button';
import { ArrowLeftMIcon } from '@alfalab/icons-classic/ArrowLeftMIcon';
import { ArrowRightMIcon } from '@alfalab/icons-classic/ArrowRightMIcon';

import { companiesIsLoadingByTypeSelector } from '#/src/store/companies/selectors';
import { dashboardChartsIsLoadingSelector } from '#/src/store/dashboard/dashboard-charts/selectors';
import { updateFilters } from '#/src/store/dashboard/dashboard-filters/action-creators';
import { filtersSelector } from '#/src/store/dashboard/dashboard-filters/selectors';
import { ApplicationState } from '#/src/store/reducer';
import getDateForDashboardByStep from '#/src/utils/get-date-for-dashboard-by-step';

import getPeriodValue from './get-period-value';

import './dashboard-data-switcher-filter.css';

const cn = createCn('dashboard-data-switcher-filter');

export function DataSwitcherFilter() {
    const dispatch = useDispatch();
    const filters = useSelector(filtersSelector);
    const debtorsIsLoading = useSelector((state: ApplicationState) =>
        companiesIsLoadingByTypeSelector(state, 'debtors'),
    );
    const isLoading = useSelector(dashboardChartsIsLoadingSelector);

    const handlePrevStep = useCallback(() => {
        const newStep = filters.stepDate - 1;
        const { dateFrom, dateTo } = getDateForDashboardByStep(
            newStep,
            filters.stepDate,
            filters.dateFrom,
            filters.period,
        );

        dispatch(updateFilters({ dateFrom, dateTo, stepDate: newStep }));
    }, [dispatch, filters.stepDate, filters.dateFrom, filters.period]);

    const handleNextStep = useCallback(() => {
        const newStep = filters.stepDate + 1;
        const { dateFrom, dateTo } = getDateForDashboardByStep(
            newStep,
            filters.stepDate,
            filters.dateFrom,
            filters.period,
        );

        dispatch(updateFilters({ dateFrom, dateTo, stepDate: newStep }));
    }, [dispatch, filters.stepDate, filters.dateFrom, filters.period]);

    return (
        <div className={cn()}>
            <IconButton
                size='xs'
                view='primary'
                icon={ArrowLeftMIcon}
                className={cn('btn-left')}
                disabled={isLoading || debtorsIsLoading}
                onClick={handlePrevStep}
            />
            <span className={cn('value')}>
                {getPeriodValue(filters.dateFrom, filters.dateTo, filters.period)}
            </span>
            <IconButton
                size='xs'
                view='primary'
                icon={ArrowRightMIcon}
                disabled={!filters.stepDate || isLoading || debtorsIsLoading}
                className={cn('btn-right')}
                onClick={handleNextStep}
            />
        </div>
    );
}
