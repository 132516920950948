import { combineReducers } from 'redux';

import { supplyCertificatesReducer, SupplyCertificatesState } from './supply-certificates/reducer';
import {
    supplyCorrectionHistoryReducer,
    SupplyCorrectionHistoryState,
} from './supply-correction-history/reducer';
import { supplyDetailsReducer, SupplyDetailsState } from './supply-details/reducer';
import { supplyErrorsReducer, SupplyErrorsState } from './supply-errors/reducer';
import { supplyRepaymentsReducer, SupplyRepaymentsState } from './supply-repayments/reducer';

export type SupplyState = {
    errors: SupplyErrorsState;
    certificates: SupplyCertificatesState;
    correctionHistory: SupplyCorrectionHistoryState;
    details: SupplyDetailsState;
    repayments: SupplyRepaymentsState;
};

export const supplyReducer = combineReducers<SupplyState>({
    errors: supplyErrorsReducer,
    certificates: supplyCertificatesReducer,
    correctionHistory: supplyCorrectionHistoryReducer,
    details: supplyDetailsReducer,
    repayments: supplyRepaymentsReducer,
});
