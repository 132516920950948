import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Gap } from '@alfalab/core-components/gap';
import { RadioGroup, RadioGroupProps } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';

import { changeRegisterType } from '#/src/store/registers/send-register/action-creators';
import {
    fileSelector,
    isRegisterSelector,
    sendRegisterIsLoadingSelector,
} from '#/src/store/registers/send-register/selectors';

export function RegisterTypeField() {
    const dispatch = useDispatch();
    const isRegister = useSelector(isRegisterSelector);
    const file = useSelector(fileSelector);
    const isLoading = useSelector(sendRegisterIsLoadingSelector);
    const handleChange = useCallback<NonNullable<RadioGroupProps['onChange']>>(
        (event, payload) => {
            dispatch(changeRegisterType(payload.value === 'register'));
        },
        [dispatch],
    );

    return file ? (
        <React.Fragment>
            <RadioGroup
                label='Тип реестра'
                direction='horizontal'
                type='tag'
                disabled={!file || isLoading}
                onChange={handleChange}
                value={isRegister ? 'register' : 'corrective'}
            >
                <Tag key='register' value='register' size='xxs' disabled={!file || isLoading}>
                    Реестр
                </Tag>
                <Tag key='corrective' value='corrective' size='xxs' disabled={!file || isLoading}>
                    Коррект
                </Tag>
            </RadioGroup>
            <Gap size='2xl' />
        </React.Fragment>
    ) : null;
}
