import React from 'react';
import { createCn } from 'bem-react-classname';

import { Modal, ModalProps } from '@alfalab/core-components/modal';

import './blank-result-modal.css';

export type BlankResultModalProps = {
    open: boolean;
    onClose: ModalProps['onClose'];
    children: React.ReactNode;
    size?: ModalProps['size'];
    onUnmount?: ModalProps['onUnmount'];
    dataTestId?: string;
};

const cn = createCn('blank-result-modal');

export function BlankResultModal({
    onClose,
    open,
    children,
    size = 'm',
    onUnmount,
    dataTestId = 'blank-result-modal',
}: BlankResultModalProps) {
    return (
        <Modal
            open={open}
            hasCloser={true}
            onClose={onClose}
            size={size}
            onUnmount={onUnmount}
            dataTestId={dataTestId}
        >
            <Modal.Header hasCloser={true} dataTestId={`${dataTestId}-header`} />
            <Modal.Content className={cn()}>{children}</Modal.Content>
        </Modal>
    );
}
