import React from 'react';

import { Status } from '@alfalab/core-components/status';
import { Typography } from '@alfalab/core-components/typography';

import { statusColors, statusDictionary } from '#/src/dictionaries/registers-status';
import { RegisterData } from '#/src/types/registers';

type RegisterStatusProps = {
    register: RegisterData;
};
export function RegisterStatus({ register }: RegisterStatusProps) {
    return statusDictionary[register.status] ? (
        <Status
            dataTestId='register-info-status'
            color={register.deleted ? 'grey' : statusColors[register.status] || 'grey'}
        >
            {statusDictionary[register.status]}
        </Status>
    ) : (
        <Typography.Text tag='div' view='primary-small' color='primary'>
            Отсутствует
        </Typography.Text>
    );
}
