import { createCachedSelector } from 're-reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { supplySelector } from '../selectors';

import { supplyCertificatesByIdDefault } from './reducer';

const supplyIdSelector = (state: ApplicationState, supplyId: number) => supplyId;

export const supplyCertificatesStateSelector = (state: ApplicationState) =>
    supplySelector(state).certificates;

export const supplyCertificatesStateByIdSelector = createCachedSelector(
    supplyCertificatesStateSelector,
    supplyIdSelector,
    (supplyCertificatesMap, supplyId) =>
        supplyCertificatesMap[supplyId] || supplyCertificatesByIdDefault,
)(supplyIdSelector);

export const supplyCertificatesIsLoadingSelector = createCachedSelector(
    supplyCertificatesStateByIdSelector,
    (supplyCertificatesById) => supplyCertificatesById.loadingState === LOADING_STATE.LOADING,
)(supplyIdSelector);

export const supplyCertificatesIsUnknownSelector = createCachedSelector(
    supplyCertificatesStateByIdSelector,
    (supplyCertificatesById) => supplyCertificatesById.loadingState === LOADING_STATE.UNKNOWN,
)(supplyIdSelector);

export const supplyCertificatesSelector = createCachedSelector(
    supplyCertificatesStateByIdSelector,
    (supplyCertificatesById) => supplyCertificatesById.certificates,
)(supplyIdSelector);
