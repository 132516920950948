import { createCachedSelector } from 're-reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { supplySelector } from '../selectors';

import { supplyErrorsByIdDefault } from './reducer';

const supplyIdSelector = (state: ApplicationState, supplyId: number) => supplyId;

export const supplyErrorsStateSelector = (state: ApplicationState) => supplySelector(state).errors;

export const supplyErrorsStateByIdSelector = createCachedSelector(
    supplyErrorsStateSelector,
    supplyIdSelector,
    (supplyErrorsMap, supplyId) => supplyErrorsMap[supplyId] || supplyErrorsByIdDefault,
)(supplyIdSelector);

export const supplyErrorsIsLoadingSelector = createCachedSelector(
    supplyErrorsStateByIdSelector,
    (supplyErrorsById) => supplyErrorsById.loadingState === LOADING_STATE.LOADING,
)(supplyIdSelector);

export const supplyErrorsIsUnknownSelector = createCachedSelector(
    supplyErrorsStateByIdSelector,
    (supplyErrorsById) => supplyErrorsById.loadingState === LOADING_STATE.UNKNOWN,
)(supplyIdSelector);

export const supplyErrorsSelector = createCachedSelector(
    supplyErrorsStateByIdSelector,
    (supplyErrorsById) => supplyErrorsById.errors,
)(supplyIdSelector);
