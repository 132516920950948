import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Select, SelectProps } from '@alfalab/core-components/select';

import {
    companiesIsLoadingByTypeSelector,
    optionsByTypeSelector,
} from '#/src/store/companies/selectors';
import { dashboardChartsIsLoadingSelector } from '#/src/store/dashboard/dashboard-charts/selectors';
import { updateFilters } from '#/src/store/dashboard/dashboard-filters/action-creators';
import { filtersSelector } from '#/src/store/dashboard/dashboard-filters/selectors';
import { ApplicationState } from '#/src/store/reducer';

import './dashboard-debtors-filter.css';

const cn = createCn('dashboard-debtors-filter');

export function DebtorsFilter() {
    const dispatch = useDispatch();
    const { debtorTin } = useSelector(filtersSelector);
    const debtorsIsLoading = useSelector((state: ApplicationState) =>
        companiesIsLoadingByTypeSelector(state, 'debtors'),
    );
    const isLoading = useSelector(dashboardChartsIsLoadingSelector);
    const options = useSelector((state: ApplicationState) =>
        optionsByTypeSelector(state, 'debtors'),
    );

    const handleChangeDebtorTin = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selectedMultiple }) => {
            dispatch(updateFilters({ debtorTin: selectedMultiple.map(({ key }) => key) || [] }));
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='debtors-select'
            className={cn()}
            allowUnselect={true}
            multiple={true}
            selected={debtorTin}
            block={true}
            optionsListWidth='field'
            options={options}
            disabled={isLoading || debtorsIsLoading}
            label='По дебитору'
            onChange={handleChangeDebtorTin}
            size='m'
        />
    );
}
