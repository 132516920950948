import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Badge } from '@alfalab/core-components/badge';
import { Collapse } from '@alfalab/core-components/collapse';
import { Grid } from '@alfalab/core-components/grid';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';
import { FormatXlsMBlackIcon } from '@alfalab/icons-classic/FormatXlsMBlackIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { ChevronUpMIcon } from '@alfalab/icons-glyph/ChevronUpMIcon';
import { PointerDownMIcon } from '@alfalab/icons-glyph/PointerDownMIcon';
import SkeletonLabel from 'arui-private/skeleton-label';

import ReportXlsTable from '#/src/containers/pages/reports-xls-page/reports-xls-table';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import {
    downloadCachedReports,
    fetchCachedReports,
} from '#/src/store/reports-xls/cached-reports/actions';
import {
    cachedReportsSortedByDateSelector,
    countOfProcessingCachedReportsSelector,
    generatedCachedReportsSelector,
    isLoadingDownloadCachedReportSelector,
    isLoadingGenerateCachedReportSelector,
} from '#/src/store/reports-xls/cached-reports/selectors';
import { useConnectedAction } from '#/src/utils/use-connected-action';
import { usePolling } from '#/src/utils/use-polling';

import './cached-reports-section.css';

const cn = createCn('cached-reports-section');

export default function CachedReportsSection() {
    const dispatch = useDispatch();
    const organizationId = useSelector(currentOrganizationIdSelector);
    const dispatchFetchCachedReports = useConnectedAction(fetchCachedReports);
    const countOfProcessingCachedReports = useSelector(countOfProcessingCachedReportsSelector);
    const cachedReports = useSelector(cachedReportsSortedByDateSelector);
    const generatedCachedReports = useSelector(generatedCachedReportsSelector);
    const isLoadingGenerateCachedReport = useSelector(isLoadingGenerateCachedReportSelector);
    const isLoadingDownloadCachedReport = useSelector(isLoadingDownloadCachedReportSelector);
    const [expanded, setExpanded] = useState(false);

    const handleToggleExpanded = useCallback(() => {
        setExpanded((prevState) => !prevState);
    }, []);
    const handleDownloadCachedReports = useCallback(() => {
        if (organizationId && generatedCachedReports?.length) {
            dispatch(downloadCachedReports(organizationId, generatedCachedReports));
        }
    }, [dispatch, organizationId, generatedCachedReports]);

    let iconStatus = <FormatXlsMBlackIcon className={cn('status')} />;
    let titleStatus = 'Список сформированных отчетов';
    let controls = (
        <IconButton
            disabled={isLoadingDownloadCachedReport || !generatedCachedReports?.length}
            size='xs'
            view='primary'
            icon={PointerDownMIcon}
            onClick={handleDownloadCachedReports}
        />
    );

    if (countOfProcessingCachedReports) {
        controls = <Badge view='count' content={countOfProcessingCachedReports} />;
        iconStatus = <Spinner size='s' visible={true} className={cn('status')} />;
        titleStatus = 'Отчет формируется...';
    }

    usePolling(dispatchFetchCachedReports, !!countOfProcessingCachedReports);

    return isLoadingGenerateCachedReport ? (
        <SkeletonLabel size='4xl' className={cn('loader')} />
    ) : (
        <div className={cn()}>
            <Grid.Row align='middle' className={cn('row')} gutter='8'>
                <Grid.Col width='auto'>{iconStatus}</Grid.Col>
                <Grid.Col width='available'>
                    <Typography.Text tag='span' view='primary-large' weight='bold'>
                        {titleStatus}
                    </Typography.Text>
                </Grid.Col>
                <Grid.Col width='auto'>
                    {controls}
                    <IconButton
                        size='s'
                        onClick={handleToggleExpanded}
                        view='primary'
                        icon={expanded ? ChevronUpMIcon : ChevronDownMIcon}
                    />
                </Grid.Col>
            </Grid.Row>
            {cachedReports && cachedReports.length && (
                <Collapse expanded={expanded}>
                    <ReportXlsTable cachedReports={cachedReports} />
                </Collapse>
            )}
        </div>
    );
}
