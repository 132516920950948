import { createSelector } from 'reselect';

import { reportNames, reportSortIndex } from '#/src/constants/reports';
import { ApplicationState } from '#/src/store/reducer';

import { reportsXlsSelector } from '../selectors';

export const reportNamesStateSelector = (state: ApplicationState) =>
    reportsXlsSelector(state).reportNames;

export const reportNamesSelector = (state: ApplicationState) =>
    reportNamesStateSelector(state).list;

export const reportNamesSortedSelector = createSelector(reportNamesSelector, (names) =>
    names.slice().sort((reportA, reportB) => reportSortIndex[reportA] - reportSortIndex[reportB]),
);

export const reportNameOptionsSortedSelector = createSelector(reportNamesSortedSelector, (names) =>
    names
        .filter((value) => !!reportNames[value])
        .map((value) => ({ key: value, value, content: reportNames[value] })),
);

export const reportNamesLoadingStateSelector = createSelector(
    reportNamesStateSelector,
    (state) => state.loadingState,
);
