import React, { useCallback, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';
import { Modal } from '@alfalab/core-components/modal';
import { useMatchMedia } from '@alfalab/core-components/mq';

import PlaySvg from './images/play.svg';

import './banner-manual-video.css';

const cn = createCn('banner-manual-video');

export default function BannerManualVideo() {
    const [isDesktop] = useMatchMedia('--desktop-s');

    const [modalIsOpen, setModalState] = useState(false);
    const handleCloseModal = useCallback(() => setModalState(false), []);
    const handleOpenModal = useCallback((event?: React.MouseEvent) => {
        event?.preventDefault();
        setModalState(true);
    }, []);

    return isDesktop ? (
        <React.Fragment>
            <div className={cn()}>
                <div className={cn('icon')}>
                    <img src={PlaySvg} alt='Воспроизвести видео' />
                </div>
                <div className={cn('content')}>
                    <div className={cn('content-title')}>Все просто</div>
                    <div className={cn('content-text')}>
                        <Link
                            dataTestId='show-video-link'
                            pseudo={true}
                            onClick={handleOpenModal}
                            className={cn('content-link')}
                        >
                            Посмотрите наше видео
                        </Link>
                        , в котором показана работа с сервисом
                    </div>
                </div>
            </div>
            <Modal open={modalIsOpen} hasCloser={true} onClose={handleCloseModal} fullscreen={true}>
                <Modal.Header hasCloser={true} />
                <Modal.Content>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video controls={true} autoPlay={true} className={cn('player')}>
                        <source src='/media/Alfa-Finance-onboard.mp4' />
                    </video>
                </Modal.Content>
            </Modal>
        </React.Fragment>
    ) : null;
}
