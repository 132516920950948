import { combineReducers } from 'redux';

import { registerErfReducer, RegisterErfState } from './register-erf/reducer';
import { registerInfoReducer, RegisterInfoState } from './register-info/reducer';
import {
    registerInfoFiltersReducer,
    RegisterInfoFiltersState,
} from './register-info-filters/reducer';
import { registersFiltersReducer, RegistersFiltersState } from './registers-filters/reducer';
import { registersListReducer, RegistersListState } from './registers-list/reducer';
import { repaymentsReducer, RepaymentsState } from './repayments/reducer';
import { sendRegisterReducer, SendRegisterState } from './send-register/reducer';
import { signRegistersReducer, SignRegistersState } from './sign-registers/reducer';

export type RegistersState = {
    registersFilters: RegistersFiltersState;
    registersList: RegistersListState;
    registerInfo: RegisterInfoState;
    registerInfoFilters: RegisterInfoFiltersState;
    repayments: RepaymentsState;
    sendRegister: SendRegisterState;
    registerErf: RegisterErfState;
    signRegisters: SignRegistersState;
};

export const registersReducer = combineReducers<RegistersState>({
    registersList: registersListReducer,
    registersFilters: registersFiltersReducer,
    registerInfo: registerInfoReducer,
    registerInfoFilters: registerInfoFiltersReducer,
    repayments: repaymentsReducer,
    sendRegister: sendRegisterReducer,
    registerErf: registerErfReducer,
    signRegisters: signRegistersReducer,
});
