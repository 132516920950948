import { SignButtonProps } from '@alfa-bank/corp-sign-ui-react/components/SignButton';
import { DocumentWithContent } from '@alfa-bank/corp-sign-ui-react/types';

import { LoadingState } from '#/src/constants/loading-state';
import { CurrencyRegisterCount } from '#/src/types/registers';
import { CheckPermissionSOResult, CreateSignDataResponse } from '#/src/types/sign';
import { UserInfo } from '#/src/types/user';

import * as actionCreators from './action-creators';
import { SignRegistersActionsType } from './types';

export type SignRegistersState = {
    loadingState: LoadingState;
    counts: CurrencyRegisterCount[];
    registerIds: number[];
    callback: SignButtonProps['onClick'] | undefined;
    additionalInfoModalIsOpen: boolean;
    documents: DocumentWithContent[];
    filteredRegisterIds: number[];
    createdSignData: CreateSignDataResponse;
    user: UserInfo | null;
    permissions: CheckPermissionSOResult[];
    warningModalIsOpen: boolean;
    successResultModalIsOpen: boolean;
    processedResultModalIsOpen: boolean;
    result: Record<string, string> | null;
};

const initialState: SignRegistersState = {
    loadingState: 'unknown',
    additionalInfoModalIsOpen: false,
    counts: [],
    registerIds: [],
    callback: undefined,
    documents: [],
    filteredRegisterIds: [],
    createdSignData: [],
    user: null,
    permissions: [],
    warningModalIsOpen: false,
    successResultModalIsOpen: false,
    processedResultModalIsOpen: false,
    result: null,
};

export function signRegistersReducer(
    state: SignRegistersState = initialState,
    action: SignRegistersActionsType,
): SignRegistersState {
    switch (action.type) {
        case actionCreators.changeAdditionalModalState.type:
            return {
                ...state,
                additionalInfoModalIsOpen: action.isOpen,
            };
        case actionCreators.changeSuccessResultModalState.type:
            return {
                ...state,
                successResultModalIsOpen: action.isOpen,
            };
        case actionCreators.changeProcessedResultModalState.type:
            return {
                ...state,
                processedResultModalIsOpen: action.isOpen,
            };
        case actionCreators.changeWarningModalState.type:
            return {
                ...state,
                warningModalIsOpen: action.isOpen,
            };
        case actionCreators.changeSignRegistersLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.setPreparedSignData.type:
            return {
                ...state,
                loadingState: 'unknown',
                additionalInfoModalIsOpen: true,
                counts: action.counts,
                registerIds: action.registerIds,
                callback: action.callback,
            };
        case actionCreators.setCreatedSignData.type:
            return {
                ...state,
                loadingState: 'unknown',
                warningModalIsOpen: action.warningModalIsOpen,
                filteredRegisterIds: action.filteredRegisterIds,
                permissions: action.permissions,
                createdSignData: action.createdSignData,
                documents: action.documents,
                user: action.user,
            };
        case actionCreators.setResult.type:
            return {
                ...state,
                loadingState: 'unknown',
                result: action.result,
                successResultModalIsOpen: !!action.result,
                processedResultModalIsOpen: !action.result,
            };
        case actionCreators.clearState.type:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
