import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Space } from '@alfalab/core-components/space';
import { Color, Typography } from '@alfalab/core-components/typography';
import { ArrowDownLineDownMIcon } from '@alfalab/icons-glyph/ArrowDownLineDownMIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { BlankResultModal } from '#/src/components/blank-result-modal';
import { clearState } from '#/src/store/documents/action-creators';
import { downloadReport } from '#/src/store/documents/actions';
import {
    reportSelector,
    resultModalIsOpenSelector,
    successDocumentsSelector,
} from '#/src/store/documents/selectors';

type DownloadDocumentsResultModalProps = {
    title: React.ReactNode;
    text: React.ReactNode;
};

export default function DownloadDocumentsResultModal({
    text,
    title,
}: DownloadDocumentsResultModalProps) {
    const dispatch = useDispatch();
    const successDocuments = useSelector(successDocumentsSelector);
    const report = useSelector(reportSelector);
    const resultModalIsOpen = useSelector(resultModalIsOpenSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);
    const handleDownloadReport = useCallback(() => {
        dispatch(downloadReport());
    }, [dispatch]);

    let IconComponent = ExclamationCircleMIcon;
    let iconColor: Color = 'attention';

    if (report && !successDocuments.length) {
        IconComponent = CrossCircleMIcon;
        iconColor = 'accent';
    }

    return (
        <BlankResultModal
            dataTestId='modal-win'
            open={resultModalIsOpen}
            onClose={handleCloseModal}
        >
            <Space size={12} direction='vertical' fullWidth={true} align='center'>
                <Typography.Text tag='div' color={iconColor}>
                    <IconComponent width='52px' height='52px' />
                </Typography.Text>
                <Typography.Title dataTestId='modal-win-title' view='small' tag='div' weight='bold'>
                    {title}
                </Typography.Title>
                <Typography.Text dataTestId='modal-win-description' tag='p'>
                    {text}
                </Typography.Text>
                <Button
                    dataTestId='modal-win-submit-button'
                    view='tertiary'
                    onClick={handleDownloadReport}
                    size='s'
                    leftAddons={<ArrowDownLineDownMIcon />}
                >
                    Скачать отчёт
                </Button>
            </Space>
        </BlankResultModal>
    );
}
