import { Assign } from 'utility-types';

import { RegistersErrorsKeys } from '#/src/dictionaries/registers-errors';
import { FinancingType } from '#/src/types/sign';

import { Currency } from './currency';

export enum RegisterSourceType {
    REGISTER_USER = 'REGISTER_USER',
    REGISTER_EDI = 'REGISTER_EDI',
    REGISTER_FROM_RPD = 'REGISTER_FROM_RPD',
}

export enum RegisterSourceAllType {
    ALL = 'ALL',
}

export type FiltersSourceType =
    | RegisterSourceType.REGISTER_EDI
    | RegisterSourceType.REGISTER_USER
    | RegisterSourceAllType;

export type RegisterStatusType =
    | 'DUPLICATE'
    | 'SUCCESS_PROCESSED'
    | 'PARTIALLY_PROCESSED'
    | 'ERROR_PROCESSED'
    | 'FINANCING_REQUESTED'
    | 'PROCESSING'
    | 'ERROR_PROCESSING'
    | 'AF_RECEIVED'
    | 'AF_PARTIALLY_RECEIVED'
    | 'PARTIALLY_VERIFIED'
    | 'VERIFIED'
    | 'PARTIALLY_FINANCED'
    | 'FINANCED'
    | 'PARTIALLY_PAID'
    | 'CLOSED'
    | 'REJECTED'
    | 'PARTIALLY_APPROVED'
    | 'APPROVED'
    | 'ON_PROCESSING';

export type RegisterShortDto = Partial<{
    createDate: number;
    debtor: boolean;
    deleted: boolean;
    fileNumber: number;
    filename: string;
    id: number;
    partyTin: string;
    registerSource: RegisterSourceType;
    registerType: boolean;
    rowCount: number;
    status: RegisterStatusType;
    error: string;
    financingType: FinancingType;
}>;

export type GetRegistersResponse = {
    registerDataList?: RegisterShortDto[];
    compressedInfoList?: string[];
};

export type GetRegistersPayload = Partial<{
    debtor: boolean;
    descOrder: boolean;
    fileNumber: string;
    from: string;
    needCompress: boolean;
    needFields: string[];
    partyTin: string;
    registerRowFilter: {
        documentNumber: string;
        sellersTin: string[];
        debtorsTin: string[];
    };
    registerSource: RegisterSourceType[];
    statuses: RegisterStatusType[];
    to: string;
    ids: number[];
}>;

export type DownloadXlsPayload = Partial<{
    ids: number[];
    registerRowFilter: {
        documentNumber: string;
        sellersTin: string[];
        debtorsTin: string[];
    };
}>;

export type GetRegisterQuery = {
    registerId: number | string;
    partyTin: number | string;
};

export type CurrencyCount = {
    amount: number;
    rowCount: number;
    type: Currency;
};

export type RegisterRowStatusType =
    | 'DUPLICATED'
    | 'RECEIVED'
    | 'ERROR_PROCESSED'
    | 'FINANCING_REQUESTED'
    | 'PROCESSING'
    | 'ERROR'
    | 'AF_RECEIVED'
    | 'VERIFIED'
    | 'PARTIALLY_FINANCED'
    | 'FINANCED'
    | 'PARTIALLY_PAID'
    | 'CLOSED'
    | 'REJECTED'
    | 'APPROVED';

export type DocumentType =
    | '27'
    | '829'
    | '40'
    | '841'
    | '41'
    | '42'
    | '30'
    | '551'
    | '35'
    | '36'
    | '37'
    | '38';

export type RegisterRow = {
    agreementNumber: string;
    currencyNumber: Currency;
    debtorName: string;
    debtorTin: string;
    documentNumber: string;
    documentType: DocumentType;
    errors: string[];
    id: number;
    sellerName: string;
    sellerTin: string;
    status: RegisterRowStatusType;
    supplyAmount: number;
    supplyDate: number;
    linkForSupplyDocument?: string;
    confirmationAmount?: number;
    warning?: string;
};

export type RegisterInfo = {
    createDate: number;
    debtor: boolean;
    deleted: boolean;
    fileNumber: number;
    id: number;
    registerRows: RegisterRow[];
    registerSource: string;
    status: RegisterStatusType;
    errors: string[];
};

export type RegisterData = Omit<RegisterInfo, 'registerRows'>;

export type GetRegisterInfoResponse = {
    currencyCounts?: CurrencyCount[];
    registerData?: RegisterInfo;
};

export type RegisterRepayment = {
    repaymentDate: number;
    repaymentAmount: number;
};

export type HashesInfo = {
    registerId: number;
    hash64bit: string;
    hash256Bit: string;
    hash512bit: string;
};

export type GetFullCurrencyPayload = {
    registerIds: number[];
    statuses: string[];
};

export type CurrencyRegisterCount = {
    type: Currency;
    amount: number;
    registerIdList: number[];
    registerCount: number;
};

export type GetFullCurrencyResponse = {
    registerIds: number[];
    counts: CurrencyRegisterCount[];
};

export type SendSignRegistersToFactorResult = {
    id: string;
    signId: string;
    signature: string;
};

export type CreateRegisterQuery = {
    username: string;
    partyTin: string;
};

export type CreateRegisterResponse = {
    id: number;
    status: string;
    errorCode: string;
};

export enum RegisterStatus {
    AF_CREATED = 'AF_CREATED',
    AF_ERROR_PROCESSED = 'AF_ERROR_PROCESSED',
    AF_SUCCESS_PROCESSED = 'AF_SUCCESS_PROCESSED',
    DUPLICATE = 'DUPLICATE',
    ERROR_PROCESSED = 'ERROR_PROCESSED',
    ERROR_PROCESSING = 'ERROR_PROCESSING',
    FINANCING_REQUESTED = 'FINANCING_REQUESTED',
    PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
    PROCESSING = 'PROCESSING',
    SUCCESS_PROCESSED = 'SUCCESS_PROCESSED',
}
export type Register = {
    id: number;
    filename: string | null;
    partyTin: string;
    debtor: boolean;
    fileNumber: number | null;
    createDate: string;
    rowCount: number;
    registerType: string;
    error: RegistersErrorsKeys | null;
    status: RegisterStatus | null;
};

export type RegisterListItem = Register;

export type RequestParamsRegistersList = {
    partyTin: string;
    debtor: boolean;
    descOrder: boolean;
    status: RegisterStatus[];
    from: string;
    to: string;
    needCompress: boolean;
    needFields: Array<keyof Register>;
    ids?: number[];
};

export type RegisterErf = {
    id: number;
    registerId: number;
    debtorName: string | null;
    debtorTin: string;
    supplyAgreementNumber: string;
    erfValue: number;
};

export type CombinedRegisterErf = Assign<
    RegisterErf,
    {
        supplyAgreementNumber: string[];
        erfValue: number[];
    }
>;

export type CreateRegisterFromFilePayload = {
    file: string;
    filename: string;
    username: string;
    tin: string;
    isDebtor: boolean;
    isRegister?: boolean;
    source?: number;
    financingType?: FinancingType;
};

export type CreateRegisterCommonErrorCode = 'QUEUE_IS_UNAVAILABLE' | 'FILE_CANNOT_BE_SAVED';

export type CreateCorrectionErrorCode = CreateRegisterCommonErrorCode | 'CORRECT_CANNOT_BE_SAVED';

export type CreateCorrectionFromFilePayload = Pick<
    CreateRegisterFromFilePayload,
    'file' | 'filename' | 'username' | 'tin' | 'source'
>;

export type CreateRegisterErrorCode =
    | 'REGISTER_CANNOT_BE_SAVED'
    | 'FILE_CANNOT_BE_SAVED'
    | 'QUEUE_IS_UNAVAILABLE';

export type CreateRegisterFromFileResponse = {
    id: number;
    status: RegisterStatusType;
    errorCode: CreateRegisterErrorCode;
};

export type CreateCorrectionFromFileResponse = Pick<
    CreateRegisterFromFileResponse,
    'id' | 'status'
> & {
    errorCode: CreateCorrectionErrorCode;
};

export type CreateRegisterByDebtorItem = {
    debtorRegisterRowId: number;
    supplyAmount: number;
};
