import { ThunkAction } from 'redux-thunk';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { NotificationsActionsType } from '#/src/store/notifications/types';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import {
    updateAllFilters,
    updateAppliedFilters,
} from '#/src/store/registers/registers-filters/action-creators';
import {
    appliedFiltersSelector,
    selectedFiltersSelector,
} from '#/src/store/registers/registers-filters/selectors';
import { RegistersFiltersActionsType } from '#/src/store/registers/registers-filters/types';
import { getDefaultRegistersFiltersState } from '#/src/store/registers/registers-filters/utils';
import { RegistersListState } from '#/src/store/registers/registers-list/reducer';
import {
    idsSelector,
    paginationSelector,
    selectedSelector,
    sortSelector,
} from '#/src/store/registers/registers-list/selectors';
import { fetchers } from '#/src/utils/client-api';
import { convertFiltersToRequestParams } from '#/src/utils/registers-filters-converter';

import * as actionCreators from './action-creators';
import { setSort } from './action-creators';
import { RegistersListActionsType } from './types';

const needFieldsForRegisterRow = [
    'id',
    'filename',
    'partyTin',
    'debtor',
    'fileNumber',
    'createDate',
    'rowCount',
    'registerType',
    'error',
    'status',
    'registerSource',
    'deleted',
    'financingType',
];

export function fetchRegistersFlow({
    page = 1,
    pageSize = 20,
    resetSelected = false,
    partyTin,
}: Partial<{
    page: number;
    pageSize: number;
    resetSelected: boolean;
    partyTin?: string;
}>): ThunkAction<
    void,
    ApplicationState,
    void,
    RegistersListActionsType | NotificationsActionsType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeRegistersListLoadingState(LOADING_STATE.LOADING));

        try {
            const state = getState();
            const filters = appliedFiltersSelector(state);
            const sort = sortSelector(state);
            const selected = selectedSelector(state);
            const selectedOrganizationId = partyTin || currentOrganizationIdSelector(state);
            const requestParams = convertFiltersToRequestParams(
                filters,
                sort,
                selectedOrganizationId,
            );
            const requestPayload = {
                ...requestParams,
                needFields: ['id'],
                needCompress: true,
            };

            const { compressedInfoList } = await fetchers.fetchRegisters(requestPayload);

            if (compressedInfoList && compressedInfoList.length) {
                const requestPayloadForPage = {
                    ...requestParams,
                    ids: compressedInfoList
                        .slice((page - 1) * pageSize, pageSize * page)
                        .map((id) => +id),
                    needFields: needFieldsForRegisterRow,
                    needCompress: false,
                };

                const { registerDataList } = await fetchers.fetchRegisters(requestPayloadForPage);

                dispatch(
                    actionCreators.setRegistersList({
                        ids: compressedInfoList.map((id) => +id),
                        entities: registerDataList,
                        count: compressedInfoList.length,
                        selected: resetSelected ? [] : selected,
                        pagination: {
                            page,
                            pageSize,
                        },
                    }),
                );
            } else {
                dispatch(
                    actionCreators.setRegistersList({
                        ids: [],
                        entities: [],
                        count: 0,
                        selected: resetSelected ? [] : selected,
                        pagination: {
                            page,
                            pageSize,
                        },
                    }),
                );
            }
        } catch (e) {
            if (e?.message !== 'aborted') {
                dispatch(
                    dispatchErrorNotification(
                        'В процессе загрузки списка реестров произошла ошибка',
                    ),
                );
                dispatch(actionCreators.changeRegistersListLoadingState(LOADING_STATE.ERROR));
            }
        }
    };
}

export function fetchRegisters(
    reload?: boolean,
): ThunkAction<
    void,
    ApplicationState,
    void,
    RegistersListActionsType | NotificationsActionsType | RegistersFiltersActionsType
> {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const filters = reload ? appliedFiltersSelector(state) : selectedFiltersSelector(state);
            const pagination = paginationSelector(state);

            if (!reload) {
                dispatch(updateAppliedFilters(filters));
            }
            dispatch(fetchRegistersFlow({ ...pagination, resetSelected: true }));
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
        }
    };
}

export function fetchRegistersByRole(
    role: 'supplier' | 'debtor',
): ThunkAction<
    void,
    ApplicationState,
    void,
    RegistersListActionsType | NotificationsActionsType | RegistersFiltersActionsType
> {
    return async (dispatch) => {
        try {
            const initialFilters = getDefaultRegistersFiltersState();
            const filters = { ...initialFilters, role };

            dispatch(updateAllFilters(filters));
            dispatch(fetchRegistersFlow({ resetSelected: true }));
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
        }
    };
}

export function fetchRegistersBySort(
    sort: RegistersListState['sort'],
): ThunkAction<
    void,
    ApplicationState,
    void,
    RegistersListActionsType | NotificationsActionsType | RegistersFiltersActionsType
> {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const pagination = paginationSelector(state);

            dispatch(setSort(sort));
            dispatch(fetchRegistersFlow({ ...pagination, resetSelected: true }));
        } catch (e) {
            dispatch(dispatchErrorNotification(e));
        }
    };
}

export function fetchRegistersByPage({
    page = 1,
    pageSize = 20,
}: Partial<{ page: number; pageSize: number }>): ThunkAction<
    void,
    ApplicationState,
    void,
    RegistersListActionsType | NotificationsActionsType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeRegistersListLoadingState(LOADING_STATE.LOADING));

        try {
            const state = getState();
            const filters = appliedFiltersSelector(state);
            const sort = sortSelector(state);
            const ids = idsSelector(state);
            const selectedOrganizationId = currentOrganizationIdSelector(state);
            const requestParams = convertFiltersToRequestParams(
                filters,
                sort,
                selectedOrganizationId,
            );

            const requestPayloadForPage = {
                ...requestParams,
                ids: ids.slice((page - 1) * pageSize, pageSize * page).map((id) => +id),
                needFields: needFieldsForRegisterRow,
                needCompress: false,
            };

            const { registerDataList } = await fetchers.fetchRegisters(requestPayloadForPage);

            dispatch(
                actionCreators.setRegistersList({
                    entities: registerDataList,
                    pagination: {
                        page,
                        pageSize,
                    },
                }),
            );
        } catch (e) {
            dispatch(
                dispatchErrorNotification('В процессе загрузки списка реестров произошла ошибка'),
            );
            dispatch(actionCreators.changeRegistersListLoadingState(LOADING_STATE.ERROR));
        }
    };
}
