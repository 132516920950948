import React from 'react';

import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Divider } from '@alfalab/core-components/divider';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { pluralize } from 'arui-private/lib/formatters';

import { AlignRightGridCol } from '#/src/components/align-right-grid-col';
import { REGISTERS_DECLENSIONS } from '#/src/constants/declensions';
import { currencyCodes } from '#/src/dictionaries/currency';
import { CurrencyRegisterCount } from '#/src/types/registers';

type ItemOfCountsRowProps = {
    item: CurrencyRegisterCount;
};

export function ItemOfCountsRow({ item }: ItemOfCountsRowProps) {
    return (
        <React.Fragment>
            <Grid.Row justify='between' align='top'>
                <Grid.Col width='6'>
                    {item.registerIdList.length}{' '}
                    {pluralize(item.registerIdList.length, REGISTERS_DECLENSIONS)}
                </Grid.Col>
                <AlignRightGridCol width='6'>
                    <Amount
                        view='withZeroMinorPart'
                        value={item.amount * 100}
                        minority={100}
                        currency={currencyCodes[item.type] as CurrencyCodes}
                        bold='none'
                    />
                </AlignRightGridCol>
            </Grid.Row>
            <Gap size='l' />
            <Divider />
        </React.Fragment>
    );
}
