import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputAutocompleteProps } from '@alfalab/core-components/input-autocomplete';

import { CustomInputAutocomplete } from '#/src/components/custom-input-autocomplete';
import { currentOrganizationTypeSelector } from '#/src/store/organizations/selectors';
import { debtorOptionsSelector } from '#/src/store/reports-xls/debtors/selectors';
import { updateReportFilters } from '#/src/store/reports-xls/report-filters/action-creators';
import { reportFiltersSelector } from '#/src/store/reports-xls/report-filters/selectors';

const DebtorFilter: FC = () => {
    const dispatch = useDispatch();

    const { debtorId } = useSelector(reportFiltersSelector);
    const options = useSelector(debtorOptionsSelector);
    const organizationType = useSelector(currentOrganizationTypeSelector);

    const handleChangeDebtorId = useCallback<NonNullable<InputAutocompleteProps['onChange']>>(
        ({ selected }) => {
            dispatch(updateReportFilters({ debtorId: selected?.value || '' }));
        },
        [dispatch],
    );

    return (
        <CustomInputAutocomplete
            dataTestId='debtors-select'
            placeholder={
                organizationType === 'debtor' ? 'Наименование продавца' : 'Наименование дебитора'
            }
            selected={debtorId}
            onChange={handleChangeDebtorId}
            options={options}
        />
    );
};

export default DebtorFilter;
