import {
    GetActiveErfPayload,
    GetActiveErfResponse,
    GetSupplyAgreementsBaseQuery,
    GetSupplyAgreementsBaseResponse,
} from '#/src/server/services/api/agreement-cache';
import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        getActiveErf: fetchFactory<GetActiveErfPayload, GetActiveErfResponse>(
            `${contextRoot}/secure/nodeapi/getActiveErf`,
            'POST',
            { allowMultiRequest: true, csrfCookieName },
        ),
        getSupplyAgreementsBase: fetchFactory<
            GetSupplyAgreementsBaseQuery,
            GetSupplyAgreementsBaseResponse
        >(`${contextRoot}/secure/nodeapi/getSupplyAgreementsBase`, 'GET', {
            allowMultiRequest: true,
        }),
    };

    return {
        fetchActiveErf(params: GetActiveErfPayload) {
            return fetch.getActiveErf(params);
        },
        fetchSupplyAgreementsBase(params: GetSupplyAgreementsBaseQuery) {
            return fetch.getSupplyAgreementsBase(params);
        },
    };
}
