import React from 'react';

import { Pagination } from '#/src/components/pagination';
import { setPagination } from '#/src/store/registers/register-info/action-creators';
import {
    countsOfFilteredEntitiesSelector,
    paginationSelector,
} from '#/src/store/registers/register-info/selectors';

export function RegisterInfoPagination() {
    return (
        <Pagination
            paginationSelector={paginationSelector}
            totalItemsSelector={countsOfFilteredEntitiesSelector}
            onChangePageAction={setPagination}
        />
    );
}
