import { createSelector } from 'reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { dashboardModelsSelector } from '../selectors';

export const dashboardLimitsStateSelector = (state: ApplicationState) =>
    dashboardModelsSelector(state).dashboardLimits;

export const dashboardLoadingStateSelector = (state: ApplicationState) =>
    dashboardLimitsStateSelector(state).loadingState;

export const limitsListSelector = (state: ApplicationState) =>
    dashboardLimitsStateSelector(state).list;

export const limitsInfoSelector = (state: ApplicationState) =>
    dashboardLimitsStateSelector(state).info;

export const dashboardLimitsIsLoadingSelector = createSelector(
    dashboardLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.LOADING,
);

export const dashboardLimitsIsErrorSelector = createSelector(
    dashboardLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.ERROR,
);
