import * as actionCreators from './action-creators';
import { Notification, NotificationsActionsType } from './types';

export type NotificationsState = {
    entities: Notification[];
};

export const initialState: NotificationsState = {
    entities: [],
};

export function notificationsReducer(
    state: NotificationsState = initialState,
    action: NotificationsActionsType,
): NotificationsState {
    switch (action.type) {
        case actionCreators.addNotification.type:
            return {
                ...state,
                entities: state.entities.concat(action.notification),
            };
        case actionCreators.removeNotification.type:
            return {
                ...state,
                entities: state.entities.filter((notification) => notification.id !== action.id),
            };
        default:
            return state;
    }
}
