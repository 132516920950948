import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps } from '@alfalab/core-components/input';

import { updateSelectedFilters } from '#/src/store/registers/register-info-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/register-info-filters/selectors';

export function AgreementNumberFilter() {
    const dispatch = useDispatch();
    const { agreementNumber } = useSelector(selectedFiltersSelector);

    const handleChangeSupplyNumber = useCallback<NonNullable<InputProps['onChange']>>(
        (_, payload) => {
            dispatch(updateSelectedFilters({ agreementNumber: payload.value }));
        },
        [dispatch],
    );

    return (
        <Input
            dataTestId='contract-number-input'
            value={agreementNumber || ''}
            block={true}
            label='Номер контракта'
            name='input_agreement-number'
            onChange={handleChangeSupplyNumber}
        />
    );
}
