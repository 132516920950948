export default function convertFileToBase64String(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            const result = reader.result?.toString() || '';

            resolve(result.split(',')[1]);
        };
        reader.onerror = reject;
    });
}
