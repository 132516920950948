import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectProps } from '@alfalab/core-components/select';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { SelectWithTags } from '@alfalab/core-components/select-with-tags';
import { Typography } from '@alfalab/core-components/typography';

import {
    companiesIsLoadingByTypeSelector,
    optionsByTypeSelector,
} from '#/src/store/companies/selectors';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { updateSelectedFilters } from '#/src/store/registers/registers-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';

export function DebtorsFilter() {
    const dispatch = useDispatch();
    const [value, changeValue] = useState('');

    const { role, debtorsTin, sellersTin } = useSelector(selectedFiltersSelector);

    const debtorOptions = useSelector((state: ApplicationState) =>
        optionsByTypeSelector(state, 'debtors'),
    );
    const organizationId = useSelector(currentOrganizationIdSelector);
    const sellerOptions = useSelector((state: ApplicationState) =>
        optionsByTypeSelector(state, 'sellers'),
    );
    const debtorsIsLoading = useSelector((state: ApplicationState) =>
        companiesIsLoadingByTypeSelector(state, 'debtors'),
    );
    const sellersIsLoading = useSelector((state: ApplicationState) =>
        companiesIsLoadingByTypeSelector(state, 'sellers'),
    );
    let options: OptionShape[] = [];
    let selected;
    let placeholder;

    if (role === 'debtor') {
        options = sellerOptions;
        selected = sellersTin;
        placeholder = 'Поставщик';
    }

    if (role === 'supplier') {
        options = debtorOptions;
        selected = debtorsTin;
        placeholder = 'Дебитор';
    }

    const handleChangeDebtors = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selectedMultiple }) => {
            dispatch(
                updateSelectedFilters({
                    debtorsTin: selectedMultiple.map((option) => option?.key || option) as string[],
                }),
            );
        },
        [dispatch],
    );

    const handleChangeSellers = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selectedMultiple }) => {
            dispatch(
                updateSelectedFilters({
                    sellersTin: selectedMultiple.map((option) => option?.key || option) as string[],
                }),
            );
        },
        [dispatch],
    );

    return (
        <SelectWithTags
            value={value}
            onInput={(event) => changeValue(event.target.value)}
            autocomplete={true}
            dataTestId={
                role === 'debtor' ? 'register-sellers-tin-select' : 'register-debtors-tin-select'
            }
            allowUnselect={true}
            block={true}
            placeholder={placeholder}
            optionsListWidth='field'
            selected={selected}
            options={options.map(({ key, content }) => ({
                key,
                value: content,
                content: (
                    <div>
                        <Typography.Text tag='div' view='secondary-large'>
                            ИНН {key}
                        </Typography.Text>
                        <Typography.Text tag='div' view='secondary-large' color='secondary'>
                            {content}
                        </Typography.Text>
                    </div>
                ),
            }))}
            onChange={role === 'debtor' ? handleChangeSellers : handleChangeDebtors}
            size='s'
            transformTagText={(content: React.ReactElement) =>
                content?.props?.children?.[1]?.props?.children
            }
            moveInputToNewLine={false}
            collapseTagList={true}
            disabled={debtorsIsLoading || sellersIsLoading || !organizationId || !role}
            match={({ key, value: name }, searchString) => {
                const valueFormatted = searchString.toLowerCase().trim();
                const keyFormatted = key?.toLowerCase().trim();
                const nameFormatted = name?.toLowerCase().trim();

                return (
                    !valueFormatted ||
                    keyFormatted?.includes(valueFormatted) ||
                    nameFormatted?.includes(valueFormatted)
                );
            }}
        />
    );
}
