import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import { DashboardTabs } from '#/src/dictionaries/reports-dashboard';
import { Commission, Payment, Receivable, Refund } from '#/src/types/dashboard-reports';

import * as actionCreators from './action-creators';
import { DashboardChartsActionsType } from './types';

export type DashboardChartsState = {
    selectedTab: DashboardTabs;
    loadingState: LoadingState;
    receivables: {
        list: Receivable[];
        info: {
            totalArrive: number;
            totalFinanced: number;
            totalPaidClient: number;
            totalPaidDebtor: number;
        };
    };
    commissions: {
        list: Commission[];
        info: {
            totalComMAmount: number;
            totalComPDAmount: number;
            totalComUdzAmount: number;
            totalAmount: number;
        };
    };
    payments: {
        list: Payment[];
        info: {
            totalAnotherPayments: number;
            totalClientPayments: number;
            totalDebtorPayments: number;
        };
    };
    refunds: {
        list: Refund[];
        info: {
            totalPaidBefore: number;
            totalPaidByClient: number;
        };
    };
};

const initialState: DashboardChartsState = {
    selectedTab: DashboardTabs.RECEIVABLES,
    loadingState: LOADING_STATE.UNKNOWN,
    receivables: {
        list: [],
        info: {
            totalArrive: 0,
            totalFinanced: 0,
            totalPaidClient: 0,
            totalPaidDebtor: 0,
        },
    },
    commissions: {
        list: [],
        info: {
            totalComMAmount: 0,
            totalComPDAmount: 0,
            totalComUdzAmount: 0,
            totalAmount: 0,
        },
    },
    payments: {
        list: [],
        info: {
            totalAnotherPayments: 0,
            totalClientPayments: 0,
            totalDebtorPayments: 0,
        },
    },
    refunds: {
        list: [],
        info: {
            totalPaidBefore: 0,
            totalPaidByClient: 0,
        },
    },
};

export function dashboardChartsReducer(
    state: DashboardChartsState = initialState,
    action: DashboardChartsActionsType,
): DashboardChartsState {
    switch (action.type) {
        case actionCreators.changeDashboardChartsLoadingState.type:
            return {
                ...state,
                loadingState: action.loadingState,
            };
        case actionCreators.setDashboardCharts.type:
            return {
                ...state,
                ...action.data,
                loadingState: action.loadingState,
            };
        case actionCreators.setSelectedTab.type:
            return {
                ...state,
                selectedTab: action.tab,
            };
        case actionCreators.clearCharts.type:
            return {
                ...initialState,
                selectedTab: state.selectedTab,
                loadingState: state.loadingState,
            };
        default:
            return state;
    }
}
