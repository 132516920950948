import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import { agreementsReducer, AgreementsState } from './agreements/reducer';
import { appReducer, AppState } from './app/reducer';
import { authReducer, AuthState } from './auth/reducer';
import { changePasswordReducer, ChangePasswordState } from './change-password/reducer';
import { companiesReducer, CompaniesState } from './companies/reducer';
import { dashboardReducer, DashboardState } from './dashboard/reducer';
import { documentsReducer, DocumentsState } from './documents/reducer';
import { filesReducer, FilesState } from './files/reducer';
import { navigationsReducer, NavigationsState } from './navigations/reducer';
import { notificationsReducer, NotificationsState } from './notifications/reducer';
import { organizationsReducer, OrganizationsState } from './organizations/reducer';
import { registersReducer, RegistersState } from './registers/reducer';
import { reportsXlsReducer, ReportsXlsState } from './reports-xls/reducer';
import { restorePasswordReducer, RestorePasswordState } from './restore-password/reducer';
import { settingsReducer, SettingsState } from './settings/reducer';
import { suppliesReducer, SuppliesState } from './supplies/reducer';
import { supplyReducer, SupplyState } from './supply/reducer';
import { systemNotifyReducer, SystemNotifyState } from './system-notify/reducer';
import { userReducer, UserState } from './user/reducer';

export type ApplicationState = {
    router: RouterState;
    settings: SettingsState;
    organizations: OrganizationsState;
    app: AppState;
    notifications: NotificationsState;
    auth: AuthState;
    restorePassword: RestorePasswordState;
    navigations: NavigationsState;
    changePassword: ChangePasswordState;
    supply: SupplyState;
    user: UserState;
    dashboard: DashboardState;
    registers: RegistersState;
    reportsXls: ReportsXlsState;
    systemNotify: SystemNotifyState;
    documents: DocumentsState;
    supplies: SuppliesState;
    companies: CompaniesState;
    files: FilesState;
    agreements: AgreementsState;
};

export default (history: History) =>
    combineReducers<ApplicationState>({
        router: connectRouter(history),
        settings: settingsReducer,
        organizations: organizationsReducer,
        app: appReducer,
        notifications: notificationsReducer,
        auth: authReducer,
        restorePassword: restorePasswordReducer,
        navigations: navigationsReducer,
        user: userReducer,
        changePassword: changePasswordReducer,
        supply: supplyReducer,
        supplies: suppliesReducer,
        dashboard: dashboardReducer,
        registers: registersReducer,
        reportsXls: reportsXlsReducer,
        systemNotify: systemNotifyReducer,
        documents: documentsReducer,
        companies: companiesReducer,
        files: filesReducer,
        agreements: agreementsReducer,
    });
