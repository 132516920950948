import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';

import createReducers, { ApplicationState } from '#/src/store/reducer';
import rootSaga from '#/src/store/sagas';
import { LocationState } from '#/src/types/routing';

const IS_BROWSER = typeof window !== 'undefined';

const composeEnhancers = (IS_BROWSER && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export function configureStore(contextRoot: string, currentPath?: string) {
    return (initState: ApplicationState) => {
        const history = IS_BROWSER
            ? createBrowserHistory<LocationState>({ basename: contextRoot || '/' })
            : createMemoryHistory<LocationState>({ initialEntries: [currentPath || contextRoot] });

        const sagaMiddleware = createSagaMiddleware();

        const middlewares = [routerMiddleware(history), thunk];

        if (IS_BROWSER) {
            middlewares.push(sagaMiddleware);
        }

        const store = createStore(
            createReducers(history),
            initState,
            composeEnhancers(applyMiddleware(...middlewares)),
        );

        if (IS_BROWSER) {
            sagaMiddleware.run(rootSaga);
        }

        if (process.env.NODE_ENV !== 'production' && module.hot) {
            module.hot.accept('./store/reducer', () => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
                store.replaceReducer(require('./store/reducer').default);
            });
        }

        return { store, history };
    };
}

export default configureStore;
