import endOfWeek from 'date-fns/end_of_iso_week';
import endOfQuarter from 'date-fns/end_of_quarter';
import startOfWeek from 'date-fns/start_of_iso_week';
import startOfISOYear from 'date-fns/start_of_iso_year';
import startOfQuarter from 'date-fns/start_of_quarter';

import { DATE_ISO_FORMAT } from '#/src/constants/date';
import { PeriodFilter } from '#/src/dictionaries/reports-dashboard';
import { convertUnixTimeToDateString } from '#/src/utils/date-converter';

export default function getDateForDashboardByPeriod(period: PeriodFilter) {
    const today = Date.now();
    let dateFrom = convertUnixTimeToDateString(today, DATE_ISO_FORMAT);
    let dateTo = convertUnixTimeToDateString(today, DATE_ISO_FORMAT);

    if (PeriodFilter.WEEK === period) {
        dateFrom = convertUnixTimeToDateString(startOfWeek(today), DATE_ISO_FORMAT);
        dateTo = convertUnixTimeToDateString(endOfWeek(today), DATE_ISO_FORMAT);
    } else if (PeriodFilter.QUARTER === period) {
        dateFrom = convertUnixTimeToDateString(startOfQuarter(today), DATE_ISO_FORMAT);
        dateTo = convertUnixTimeToDateString(endOfQuarter(today), DATE_ISO_FORMAT);
    } else if (PeriodFilter.YEAR === period) {
        dateFrom = convertUnixTimeToDateString(startOfISOYear(today), DATE_ISO_FORMAT);
    }

    return { dateFrom, dateTo };
}
