import { useEffect } from 'react';

type IntervalPolicy = (iterationNumber: number) => number;

const defaultIntervalPolicy: IntervalPolicy = (iterationNumber) => {
    if (iterationNumber > 5) {
        // eslint-disable-next-line no-param-reassign
        iterationNumber = 5;
    }

    return 1000 * iterationNumber;
};

/**
 * Позволяет пулить переданный метод с заданной переодичностью.
 * Первое выполнение функции произойдет через минимальный интервал (не при старте пулинга)
 *
 * Можно задать свою функцию, которая будет изменять ожидание перед следующей попыткой в зависимости
 * от номера итерации, или использовать стандартную (1s-2s-3s-4s-5s-5s-5s-5s...).
 * Важно чтобы передаваемые параметры были мемоизированы, чтобы полинг не перезапускался с изменением функций
 * @param pollFn метод, который будет полится
 * @param shouldPoll флаг для запуска/остановки полинга
 * @param intervalPolicy функция, которая возвращает таймаут перед следующим вызовом
 */
export function usePolling(
    pollFn: () => unknown,
    shouldPoll: boolean,
    intervalPolicy = defaultIntervalPolicy,
) {
    useEffect(() => {
        let timoutRef: ReturnType<typeof setTimeout> | undefined;
        let pollCount = 1;

        if (shouldPoll) {
            timoutRef = setTimeout(iterate, intervalPolicy(pollCount));
        }

        async function iterate() {
            await pollFn();
            pollCount += 1;
            timoutRef = setTimeout(iterate, intervalPolicy(pollCount));
        }

        return () => {
            if (timoutRef) {
                clearTimeout(timoutRef);
            }
        };
    }, [pollFn, shouldPoll, intervalPolicy]);
}
