import React, { useMemo } from 'react';
import { createCn } from 'bem-react-classname';

import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import InfoField from '#/src/components/info-field';
import { CertificateInfoSignedSupply } from '#/src/types/certificates';

import { CertificateDocumentsFields } from './certificate-documents-fields';
import { getCertificateFields } from './get-certificate-fields';

import './supply-certificates-item.css';

type SupplyCertificatesItemProps = {
    index: number;
    certificate: CertificateInfoSignedSupply;
};

const cn = createCn('supply-certificates-item');
const widthDateFields = { desktop: 2, tablet: 2, mobile: 12 };

export function SupplyCertificatesItem({ certificate, index }: SupplyCertificatesItemProps) {
    const [infoFields, dateFields] = useMemo(
        () => getCertificateFields(certificate),
        [certificate],
    );

    return (
        <div className={cn()}>
            <Typography.Text tag='div' weight='bold' view='secondary-large'>
                Сертификат №{index}
            </Typography.Text>
            {!!infoFields.length && (
                <div>
                    {infoFields.map((item) => (
                        <InfoField key={item.id} title={item.title} value={item.value} />
                    ))}
                </div>
            )}
            {!!dateFields.length && (
                <Grid.Row justify='left' align='top'>
                    {dateFields.map((item) => (
                        <Grid.Col width={widthDateFields} key={item.id}>
                            <InfoField key={item.id} title={item.title} value={item.value} />
                        </Grid.Col>
                    ))}
                </Grid.Row>
            )}
            {!!certificate.signedDocuments.length && (
                <CertificateDocumentsFields signedDocuments={certificate.signedDocuments} />
            )}
        </div>
    );
}
