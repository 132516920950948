import { OptionShape } from '@alfalab/core-components/select/typings';

import { FiltersSourceType } from '#/src/types/registers';

export const registersFiltersSourceTypeDictionary: Record<FiltersSourceType, string> = {
    ALL: 'Все',
    REGISTER_USER: 'Реестр',
    REGISTER_EDI: 'ЭДО',
};

export const registersFiltersSourceTypeOptions: OptionShape[] = Object.keys(
    registersFiltersSourceTypeDictionary,
).map((sourceKey: FiltersSourceType) => ({
    value: sourceKey,
    key: sourceKey,
    content: registersFiltersSourceTypeDictionary[sourceKey],
}));
