import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetSuppliesPayload } from '#/src/server/services/api/supply';
import {
    dispatchErrorNotification,
    dispatchSuccessNotification,
} from '#/src/store/notifications/actions';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { SuppliesEditingState } from '#/src/store/supplies/supplies-editing/reducer';
import { editedSuppliesListSelector } from '#/src/store/supplies/supplies-editing/selectors';
import { getSupplies } from '#/src/store/supplies/supplies-list/action-creators';
import {
    serverRequestTimeSelector,
    suppliesPaginationSelector,
} from '#/src/store/supplies/supplies-list/selectors';
import { AsyncThunkConfig } from '#/src/types/redux-toolkit';
import { fetchers } from '#/src/utils/client-api';
import { getSuppliesEditingData } from '#/src/utils/get-supplies-editing-data';

const queryForFetchSupplies: Omit<GetSuppliesPayload, 'sellerTin'> = {
    supplySources: ['EDI', 'RPD'],
    statuses: ['RECEIVED', 'ERROR', 'APPROVED'],
    needCompressedAnswer: false,
    needToGetShortSupplyAnswer: false,
    needFields: ['id', 'debtorTin', 'debtorName'],
};

export const fetchSuppliesEditing = createAsyncThunk<
    Omit<SuppliesEditingState, 'loadingState'>,
    number[],
    AsyncThunkConfig
>(
    'suppliesEditing/fetchSuppliesEditing',
    async (supplyIds, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const serverRequestTime = serverRequestTimeSelector(state);
            const sellerTin = currentOrganizationIdSelector(state) as string;

            const availableSupplies = await fetchers.fetchSupplies({
                ...queryForFetchSupplies,
                serverRequestTime,
                supplyIds,
                sellerTin,
            });

            if (availableSupplies.supplies?.length) {
                const suppliesEditingList = await fetchers.fetchSupplyEditing(
                    availableSupplies.supplies.map(({ id }) => id as number),
                );

                const availableSuppliesForEditing = suppliesEditingList.filter(
                    ({
                        supplyAgreementEditable,
                        supplyDelayPeriodEditable,
                        supplyReceiptDateEditable,
                    }) =>
                        supplyAgreementEditable ||
                        supplyDelayPeriodEditable ||
                        supplyReceiptDateEditable,
                );

                if (availableSuppliesForEditing.length) {
                    const { editingSuppliesList, editedDebtors, debtorsDictionary } =
                        getSuppliesEditingData(
                            availableSupplies.supplies,
                            availableSuppliesForEditing,
                        );

                    return {
                        editingSuppliesList,
                        editedDebtors,
                        debtorsDictionary,
                        editModalIsOpen: true,
                        warningModalIsOpen: false,
                    };
                }
            }

            return {
                debtorsDictionary: {},
                editingSuppliesList: [],
                editedDebtors: {},
                editModalIsOpen: false,
                warningModalIsOpen: true,
            };
        } catch (error) {
            dispatch(dispatchErrorNotification(error));

            return rejectWithValue(error);
        }
    },
);

export const updateSupplies = createAsyncThunk<string, never, AsyncThunkConfig>(
    'suppliesEditing/updateSupplies',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const editedSuppliesList = editedSuppliesListSelector(state);
            const { page, pageSize } = suppliesPaginationSelector(state);

            const response = await fetchers.fetchUpdateSupply(editedSuppliesList);

            dispatch(dispatchSuccessNotification('Поставки успешно сохранены'));
            dispatch(getSupplies({ page, pageSize, needMergeSelected: false, reloadPage: true }));

            return response;
        } catch (error) {
            dispatch(dispatchErrorNotification(error));

            return rejectWithValue(error);
        }
    },
);
