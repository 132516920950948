import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    changeWarningModalState,
    clearState,
} from '#/src/store/supplies/sign-supplies/action-creators';
import {
    documentsSelector,
    filteredSuppliesIdsSelector,
    permissionsSelector,
    warningModalIsOpenSelector,
} from '#/src/store/supplies/sign-supplies/selectors';
import { availableSuppliesSelector } from '#/src/store/supplies/supplies-list/selectors';

import { ChooseCertificateSuppliesSignModal } from './choose-certificate-supplies-sign-modal';

export function WarningModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(warningModalIsOpenSelector);
    const permissions = useSelector(permissionsSelector);
    const documents = useSelector(documentsSelector);
    const suppliesIds = useSelector(filteredSuppliesIdsSelector);
    const availableSupplies = useSelector(availableSuppliesSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(changeWarningModalState(false));
    }, [dispatch]);

    const handleClearState = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    return (
        <ChooseCertificateSuppliesSignModal
            onClose={handleCloseModal}
            onUnmount={handleClearState}
            open={modalIsOpen}
            suppliesIds={suppliesIds}
            availableSupplies={availableSupplies.available}
            permissions={permissions}
            documents={documents}
            certificatesAreHidden={true}
        />
    );
}
