import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { routesDictionary } from '#/src/dictionaries/routes-dictionary';

export default function getHeaderPropsByPathname(dispatch: Dispatch, pathname: string) {
    if (routesDictionary.registerInfoPage.pathRouteRegex.test(pathname)) {
        return {
            hasNavigationPane: true,
            navigationPaneSettings: {
                linkText: 'Назад к списку реестров',
                label: 'Назад к списку реестров',
            },
            onNavigationLinkClick: () => {
                dispatch(push(routesDictionary.registersPage.path));
            },
            customerSelectDisabled: true,
        };
    }

    return {};
}
