import React from 'react';
import { createCn } from 'bem-react-classname';

import { CollapseToggleSection } from '#/src/components/collapse-toggle-section';
import { SupplyEventInfo } from '#/src/types/supply';

import { SupplyEventRow } from './supply-event-row';

import './supply-event-section.css';

type SupplyEventSectionProps = {
    eventList: SupplyEventInfo[];
};

const cn = createCn('supply-event-section');

export function SupplyEventSection({ eventList }: SupplyEventSectionProps) {
    return (
        <div className={cn()}>
            <CollapseToggleSection
                linkTextExpanded='История статусов'
                linkTextCollapsed='История статусов'
                linkDataTestId='toggle-expended-state-history-link'
            >
                {eventList.map((eventInfo) => (
                    <SupplyEventRow key={eventInfo.eventTimestamp} eventInfo={eventInfo} />
                ))}
            </CollapseToggleSection>
        </div>
    );
}
