import React from 'react';
import { createCn } from 'bem-react-classname';

import { Button, ButtonProps } from '@alfalab/core-components/button';

import { FormType } from '#/src/components/external-page-form/types';

import './form-button.css';

const cn = createCn('form-button');

type FormButtonProps = ButtonProps & {
    text: string;
    form: FormType;
};

export default function FormButton({
    view = 'primary',
    size = 'm',
    text,
    form,
    ...props
}: FormButtonProps) {
    return (
        <Button
            dataTestId={`${form}-form-${props.type || 'button'}`}
            {...props}
            className={cn()}
            view={view}
            size={size}
        >
            {text}
        </Button>
    );
}
