import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '#/src/constants/loading-state';
import { SupplyAgreementData } from '#/src/types/agreements';

import { getAgreementsByDebtor } from './actions';

export type EntityState = {
    entities: SupplyAgreementData[];
    loadingState: LoadingState;
};

export const entityDefaultState: EntityState = {
    entities: [],
    loadingState: 'unknown',
};

export type AgreementsState = Record<string, EntityState>;

const initialState: AgreementsState = {};

export const {
    reducer: agreementsReducer,
    name,
    actions,
} = createSlice({
    name: 'agreementsReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAgreementsByDebtor.pending, (state, action) => {
            state[action.meta.arg.id] = { loadingState: 'loading', entities: [] };
        });
        builder.addCase(getAgreementsByDebtor.fulfilled, (state, action) => {
            state[action.meta.arg.id].loadingState = 'success';
            state[action.meta.arg.id].entities = action.payload;
        });
        builder.addCase(getAgreementsByDebtor.rejected, (state, action) => {
            state[action.meta.arg.id].loadingState = 'error';
        });
    },
});
