import React from 'react';

import { Grid } from '@alfalab/core-components/grid';
import SkeletonLabel from 'arui-private/skeleton-label';

export default function ReportXlsSkeleton() {
    return (
        <Grid.Row>
            <Grid.Col width={{ desktop: 9, tablet: 9, mobile: 12 }}>
                <SkeletonLabel size='2xl' />
                <SkeletonLabel size='2xl' />
                <Grid.Row justify='left'>
                    <Grid.Col width='6'>
                        <SkeletonLabel size='2xl' />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <SkeletonLabel size='2xl' />
                    </Grid.Col>
                </Grid.Row>
                <SkeletonLabel size='2xl' />
            </Grid.Col>
        </Grid.Row>
    );
}
