import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SignChooseCertificateModalProps } from '@alfa-bank/corp-sign-ui-react/components/SignChooseCertificateModal';

import {
    ChooseCertificateSignModal,
    ChooseCertificateSignModalProps,
} from '#/src/components/choose-certificate-sign-modal';
import { SUPPLIES_DECLENSIONS } from '#/src/constants/declensions';
import { deleteRegisterAndSupplyLinks } from '#/src/store/supplies/sign-supplies/actions';

type ChooseCertificateSuppliesSignModalProps = {
    registerId?: number | null;
    availableSupplies: Record<number, number>;
    suppliesIds: number[];
} & Pick<
    ChooseCertificateSignModalProps,
    'permissions' | 'documents' | 'certificatesAreHidden' | 'onUnmount'
> &
    Partial<SignChooseCertificateModalProps>;

export function ChooseCertificateSuppliesSignModal({
    registerId,
    availableSupplies,
    suppliesIds,
    onClose,
    ...restProps
}: ChooseCertificateSuppliesSignModalProps) {
    const dispatch = useDispatch();

    const handleCloseModal = useCallback(() => {
        if (onClose) {
            if (registerId) {
                dispatch(deleteRegisterAndSupplyLinks(registerId));
            }
            onClose();
        }
    }, [dispatch, onClose, registerId]);

    const signButtonIsDisabled = useMemo(
        () => suppliesIds.every((id) => !availableSupplies[id]),
        [availableSupplies, suppliesIds],
    );

    return (
        <ChooseCertificateSignModal
            declensions={SUPPLIES_DECLENSIONS}
            title='Подписать и отправить'
            onCloseModal={handleCloseModal}
            signButtonIsDisabled={signButtonIsDisabled}
            {...restProps}
        />
    );
}
