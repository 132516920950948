import React from 'react';

import ConnectedSvg from 'arui-private/icons/connected.svg';
import FatalErrorSvg from 'arui-private/icons/error.svg';

import { RegisterData } from '#/src/types/registers';

export function getSuccessModalImg(
    result: Record<string, string> | null,
    registersIds: number[],
    register?: RegisterData,
) {
    if (!result) return null;

    const success = Object.keys(result).filter((key) => result[key] === 'SUCCESS');

    if ((!!register && success.length === registersIds.length) || (!register && !!success.length)) {
        return <img src={ConnectedSvg} alt='Успех' />;
    }

    return <img src={FatalErrorSvg} alt='Ошибка' />;
}
