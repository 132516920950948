import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from '@alfalab/core-components/divider';

import {
    dashboardChartsIsLoadingSelector,
    dashboardChartsStateSelector,
    selectedTabSelector,
} from '#/src/store/dashboard/dashboard-charts/selectors';
import { filtersSelector } from '#/src/store/dashboard/dashboard-filters/selectors';

import ChartReports from './chart-reports';
import { DashboardFilters } from './dashboard-filters';

export function DashboardTabContent() {
    const selectedTab = useSelector(selectedTabSelector);
    const isLoading = useSelector(dashboardChartsIsLoadingSelector);
    const data = useSelector(dashboardChartsStateSelector);
    const filters = useSelector(filtersSelector);

    return (
        <React.Fragment>
            <DashboardFilters />
            <Divider />
            <div>
                <ChartReports
                    tab={selectedTab}
                    receivables={data.receivables.list}
                    commissions={data.commissions.list}
                    payments={data.payments.list}
                    refunds={data.refunds.list}
                    filters={filters}
                    receivableTotal={data.receivables.info}
                    commissionTotal={data.commissions.info}
                    paymentTotal={data.payments.info}
                    refundTotal={data.refunds.info}
                    byCount={filters.byCount}
                    isLoading={isLoading}
                />
            </div>
        </React.Fragment>
    );
}
