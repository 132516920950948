import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';
import { Popover } from '@alfalab/core-components/popover';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { useClickOutside } from '@alfalab/hooks';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';

import { allCells } from '#/src/dictionaries/available-supplies-table-cells';
import { restoreAvailableCells } from '#/src/store/supplies/table-cells-settings/actions';

import { SwitchColumnItem } from './switch-column-item';

import './available-column-control.css';

export const cellNames = {
    date: 'Дата',
    debtor: 'Дебитор',
    contract: 'Контракт',
    supplySource: 'Источник',
    status: 'Статус',
    type: 'Документ',
    amount: 'Сумма',
};

const cn = createCn('available-column-control');

export function AvailableColumnControl() {
    const dispatch = useDispatch();
    const [isOpen, setOpenState] = useState(false);
    const [containerElement, setContainerElement] = React.useState<HTMLAnchorElement | null>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    const handleClosePopover = useCallback(() => {
        setOpenState(false);
    }, []);

    const handleTogglePopoverState = useCallback(() => {
        setOpenState((prevState) => !prevState);
    }, []);

    const handleContainerRef = (node: HTMLAnchorElement) => setContainerElement(node);

    useClickOutside(popoverRef, handleClosePopover);

    useEffect(() => {
        dispatch(restoreAvailableCells());
    }, [dispatch]);

    return (
        <div className={cn()}>
            <Typography.Text view='secondary-large'>
                <Link
                    pseudo={true}
                    dataTestId='column-settings'
                    onClick={handleTogglePopoverState}
                    ref={handleContainerRef}
                    rightAddons={<ChevronDownSIcon />}
                >
                    Настроить колонки
                </Link>
            </Typography.Text>

            <Popover
                anchorElement={containerElement}
                open={isOpen}
                preventFlip={true}
                position='bottom-end'
                offset={[0, 8]}
            >
                <div ref={popoverRef}>
                    <Space direction='vertical' fullWidth={true} size={12} className={cn('list')}>
                        {allCells.map((cellName) => (
                            <SwitchColumnItem cellName={cellName} />
                        ))}
                    </Space>
                </div>
            </Popover>
        </div>
    );
}
