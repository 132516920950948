import { LOADING_STATE } from '#/src/constants/loading-state';
import { CertificateInfoSignedSupply } from '#/src/types/certificates';
import createAction from '#/src/utils/create-action';

export const getSupplyCertificatesStart = createAction(
    'SUPPLY / getSupplyCertificatesStart',
    (id: number) => ({
        id,
        loadingState: LOADING_STATE.LOADING,
    }),
);

export const getSupplyCertificatesSuccess = createAction(
    'SUPPLY / getSupplyCertificatesSuccess',
    (id: number, data: CertificateInfoSignedSupply[]) => ({
        id,
        data,
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const getSupplyCertificatesError = createAction(
    'SUPPLY / getSupplyCertificatesError',
    (id: number) => ({
        id,
        loadingState: LOADING_STATE.ERROR,
    }),
);

export const removeSupplyCertificates = createAction(
    'SUPPLY / removeSupplyCertificates',
    (id: number) => ({ id }),
);
