import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Popover } from '@alfalab/core-components/popover';
import { useClickOutside } from '@alfalab/hooks';
import CorporateCompanySelectNew from 'arui-private/core/corporate-company-select-new';

import { currentOrganizationSelector } from '#/src/store/organizations/selectors';

import { AppOrganizationList } from './app-organization-list';

import './app-organization-select.css';

type AppOrganizationSelectProps = {
    disabled: boolean;
};

const cn = createCn('app-organization-select');
const popoverOffsetParams: [number, number] = [0, 8];

export function AppOrganizationSelect({ disabled }: AppOrganizationSelectProps) {
    const [isOpen, setOpenState] = useState(false);
    const [containerElement, setContainerElement] = React.useState<HTMLDivElement | null>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    const currentOrganization = useSelector(currentOrganizationSelector);

    const handleClosePopover = useCallback(() => {
        setOpenState(false);
    }, []);

    const handleTogglePopoverState = useCallback(() => {
        setOpenState((prevState) => !prevState);
    }, []);

    const handleContainerRef = (node: HTMLDivElement) => setContainerElement(node);

    useClickOutside(popoverRef, handleClosePopover);

    return (
        <React.Fragment>
            <div ref={handleContainerRef}>
                <CorporateCompanySelectNew
                    data-test-id='corporate-company-select'
                    disabled={disabled}
                    customerValue={currentOrganization?.name}
                    onClickCompanySelect={handleTogglePopoverState}
                />
            </div>
            <Popover
                anchorElement={containerElement}
                open={isOpen}
                preventFlip={true}
                position='bottom-end'
                offset={popoverOffsetParams}
                popperClassName={cn('popover')}
                dataTestId='app-organization-popover'
            >
                <div ref={popoverRef}>
                    <AppOrganizationList onClosePopover={handleClosePopover} />
                </div>
            </Popover>
        </React.Fragment>
    );
}
