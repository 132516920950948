import React from 'react';

import { Grid } from '@alfalab/core-components/grid';

import InfoField from '#/src/components/info-field';
import { DATE_FORMAT } from '#/src/constants/date';
import { Document } from '#/src/types/certificates';
import { formatDateForCalendar } from '#/src/utils/date-converter';

type DocumentsFieldsProps = {
    signedDocuments: Document[];
};

const widthGridCol = { desktop: 4, tablet: 4, mobile: 12 };

export function CertificateDocumentsFields({ signedDocuments }: DocumentsFieldsProps) {
    return (
        <Grid.Row justify='left' align='top'>
            {signedDocuments.map((item) => (
                <Grid.Col key={item.id} width={widthGridCol}>
                    <Grid.Row justify='left' align='top'>
                        <Grid.Col width='6'>
                            <InfoField
                                title='Источник'
                                value={item.docName?.toLocaleUpperCase() || 'Отсутствует'}
                            />
                        </Grid.Col>
                        <Grid.Col width='6'>
                            <InfoField
                                title='Дата подписания'
                                value={
                                    item.signingDate
                                        ? formatDateForCalendar(
                                              new Date(item.signingDate),
                                              DATE_FORMAT,
                                          )
                                        : 'Отсутствует'
                                }
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            ))}
        </Grid.Row>
    );
}
