import React from 'react';
import { Redirect, Route } from 'react-router';

import { History, StaticContext } from '#/src/types/routing';

type NotFoundProps = {
    history: History;
    staticContext?: StaticContext;
};

/**
 * Компонент, который необходимо использовать в Switch роутера, если в случае отсутствия
 * сматчившегося роута нужно показать 404.
 */
export default function NotFound({ staticContext, history }: NotFoundProps) {
    const renderRoute = () => {
        if (staticContext) {
            /* staticContext используется при серверном роутинге */
            staticContext.notFoundError = true;

            return null;
        }
        /* location используется при браузерном роутинге */
        const newLocation = {
            ...history.location,
            state: { notFoundError: true },
        };

        return <Redirect to={newLocation} />;
    };

    return <Route render={renderRoute} />;
}
