import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { suppliesModelsSelector } from '../selectors';

export const suppliesDownloadReportStateSelector = (state: ApplicationState) =>
    suppliesModelsSelector(state).suppliesDownloadReport;

export const suppliesDownloadReportLoadingStateSelector = (state: ApplicationState) =>
    suppliesDownloadReportStateSelector(state).loadingState;
export const suppliesDownloadReportIsLoadingSelector = (state: ApplicationState) =>
    suppliesDownloadReportLoadingStateSelector(state) === LOADING_STATE.LOADING;
