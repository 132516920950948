import React from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { entitiesSelector } from '#/src/store/registers/registers-list/selectors';
import { formatDateForCalendar, formatDateForList } from '#/src/utils/date-converter';

import { RegisterListTableRow } from '../register-list-table-row';

import './register-list-table-body.css';

const cn = createCn('register-list-table-body');

export function RegisterListTableBody() {
    const entities = useSelector(entitiesSelector);
    const rows: React.ReactNode[] = [];
    let groupDate = '';

    entities.forEach((register) => {
        if (register.createDate) {
            const registerDate = formatDateForCalendar(register.createDate);

            if (groupDate !== registerDate) {
                rows.push(
                    <div className={cn('row-group')}>
                        {formatDateForList(register.createDate, true)}
                    </div>,
                );
                groupDate = registerDate;
            }
        }

        rows.push(<RegisterListTableRow register={register} />);
    });

    return <React.Fragment>{rows}</React.Fragment>;
}
