export const registerErrorsDictionary: Record<string, string> = {
    FILE_NOT_CONTAINS_ROWS_ERROR: 'Файл не содержит ни одной строки',
    FILE_NOT_MATCH_TEMPLATE_ERROR: 'Файл не соответствует шаблону',
    FILE_NOT_CONTAINS_MORE_1000_ROWS_ERROR: 'В реестре должно быть не более 1000 строк',
    REGISTER_HAVE_BAD_TYPE_ERROR:
        // eslint-disable-next-line max-len
        'Тип реестра не соответствует выбранному при загрузке. Реестр должен содержать поставки с типами документов, отличными от УКД (42) и ТОРГ-12 на возврат товаров (30)',
    CORRECT_HAVE_BAD_TYPE_ERROR:
        // eslint-disable-next-line max-len
        'Тип реестра не соответствует выбранному при загрузке. Коррект должен содержать только поставки с типами документов УКД (42) и ТОРГ-12 на возврат товаров (30)',
    SELLER_TIN_IS_EMPTY_ERROR: 'Не указан ИНН продавца',
    DEBTOR_TIN_IS_EMPTY_ERROR: 'Не указан ИНН дебитора',
    SUPPLY_AMOUNT_IS_EMPTY_ERROR: 'Не указана сумма поставки',
    DOCUMENT_DATE_IS_EMPTY_ERROR: 'Не указана дата поставки',
    RECEIPT_DATE_IS_EMPTY_ERROR:
        // eslint-disable-next-line max-len
        'Не указана дата приемки. Обязательна для всех типов документов, кроме УКД (42) и ТОРГ-12 на возврат товаров (30)',
    DOCUMENT_NUMBER_IS_EMPTY_ERROR: 'Не указан номер поставки',
    CURRENCY_NUMBER_IS_EMPTY_ERROR: 'Не указана валюта',
    DOCUMENT_TYPE_IS_EMPTY_ERROR: 'Не указан тип документа',
    CONTRACT_NUMBER_IS_EMPTY_ERROR: 'Не указан контракт',
    LINK_FOR_SUPPLY_DOCUMENT_IS_EMPTY_ERROR:
        // eslint-disable-next-line max-len
        'Не указана ссылка на номер первичного документ поставки. Ссылка обязательна при типе документа ТОРГ-12 на возврат товаров (30) и УКД (42)',
    REGISTER_ROW_HAVE_BAD_TYPE_ERROR:
        'Реестр должен содержать поставки с типами документов, отличными от УКД (42) и ТОРГ-12 на возврат товаров (30)',
    CORRECT_ROW_HAVE_BAD_TYPE_ERROR:
        'Коррект должен содержать только поставки с типами документов УКД (42) и ТОРГ-12 на возврат товаров (30)',
    NO_REGISTER_DATA: 'Техническая ошибка №1. Попробуйте отправить реестр еще раз',
    CHECK_SIGNATURE_FAILED:
        'Техническая ошибка №2 при проверке подписи. Попробуйте отправить реестр еще раз',
    CANT_CHECK_SIGNATURE:
        'Техническая ошибка №3 при проверке подписи. Попробуйте отправить реестр еще раз',
    CANT_DECODE_SIGNATURE:
        'Техническая ошибка №4 при проверке подписи. Попробуйте отправить реестр еще раз',
    CANT_SAVE_SIGNATURE:
        'Техническая ошибка №5 при проверке подписи. Попробуйте отправить реестр еще раз',
    CANT_GET_ALFA_FACTOR_ID:
        'Техническая ошибка №6 при отправке реестра на финансирование. Попробуйте отправить реестр еще раз',
    CANT_SEND_REGISTRY_EXCEPTION:
        'Техническая ошибка №7 при отправке реестра на финансирование. Попробуйте отправить реестр еще раз',
    CANT_SEND_REGISTRY:
        'Техническая ошибка №8 при отправке реестра на финансирование. Попробуйте отправить реестр еще раз',
    CURRENCY_NAME_NOT_UNIQUE_ERROR:
        'В одном реестре должны содержаться поставки только в одной валюте',
    CANT_GENERATE_TXT:
        'Техническая ошибка №9 при отправке реестра на финансирование. Попробуйте отправить реестр еще раз',
    TEMPLATE_HAVE_ERROR: 'Неправильно заполнен обработчик шаблона! Обратитесь к менеджеру!',
    INVALID_SIGNATURE:
        'Не идентифицирована связь подписи и документа. Необходима переотправка документа.',
    SO_CONNECTION_ERROR: 'Сервис подписания не доступен. Попробуйте повторить подписание позднее',
    INVALID_SNILS:
        'Внутренная ошибка. Данные СНИЛС не проверены в сервисе ПФР. Обратитетесь к своему менеджеру',
    FACTORING_AGREEMENT_IS_EMPTY_ERROR:
        'Не указан номер договора факторинга. Необходимо переформировать реестр ',
    QUEUE_IS_UNAVAILABLE:
        'РПД не может быть передан на обработку. Загрузите реестр заново или обратитесь в поддержку',
    FILE_CANNOT_BE_SAVED:
        'Файл не может быть сохранен. Загрузите реестр заново или обратитесь в поддержку',
    TEMPLATE_IS_NOT_FOUND: 'Шаблон корректа РПД не настроен',
};
