import React, { useCallback, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { TransitionChildren } from 'react-transition-group/Transition';
import { createCn } from 'bem-react-classname';

import { IconButton } from '@alfalab/core-components/icon-button';
import { ArrowLeftMIcon } from '@alfalab/icons-classic/ArrowLeftMIcon';
import { ArrowRightMIcon } from '@alfalab/icons-classic/ArrowRightMIcon';

import './slider.css';

type SliderProps = {
    elements: React.ReactNode[];
};

const cn = createCn('slider');

export default function Slider({ elements }: SliderProps) {
    const [index, setIndex] = useState(0);

    const handleNextArrowClick = useCallback(() => {
        setIndex((prevIndex) => prevIndex + 1);
    }, [setIndex]);

    const handlePrevArrowClick = useCallback(() => {
        setIndex((prevIndex) => prevIndex - 1);
    }, [setIndex]);

    return (
        <div className={cn()}>
            <div className={cn('container')}>
                <SwitchTransition>
                    <CSSTransition key={index} timeout={500} classNames={cn('elem')}>
                        {elements[index] as TransitionChildren}
                    </CSSTransition>
                </SwitchTransition>
            </div>
            <IconButton
                view='primary'
                size='xs'
                icon={ArrowLeftMIcon}
                disabled={index === 0}
                onClick={handlePrevArrowClick}
                className={cn('arrow-left', { disabled: index === 0 })}
            />
            <IconButton
                view='primary'
                size='xs'
                icon={ArrowRightMIcon}
                disabled={index === elements.length - 1}
                onClick={handleNextArrowClick}
                className={cn('arrow-right', { disabled: index === elements.length - 1 })}
            />
        </div>
    );
}
