import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Для правильной работы strictFunctionTypes тут должен быть именно any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useConnectedAction<T extends (...args: A) => any, A extends any[]>(action: T): T {
    const dispatch = useDispatch();

    return useCallback((...args: A) => dispatch(action(...args)), [dispatch, action]) as T;
}
