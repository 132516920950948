import React from 'react';

import { Grid } from '@alfalab/core-components/grid';

import DebtorLimitsSliderItem from './debtor-limits-slider-item';

export default function DebtorLimitsGroupSkeleton() {
    return (
        <Grid.Row justify='left'>
            <Grid.Col width='4'>
                <DebtorLimitsSliderItem name='Заголовок' amount={0} isLoading={true} />
            </Grid.Col>
            <Grid.Col width='4'>
                <DebtorLimitsSliderItem name='Заголовок' amount={0} isLoading={true} />
            </Grid.Col>
            <Grid.Col width='4'>
                <DebtorLimitsSliderItem name='Заголовок' amount={0} isLoading={true} />
            </Grid.Col>
        </Grid.Row>
    );
}
