export const supplyDocumentTypeDictionary: Record<string, string> = {
    UPD: 'УПД',
    INVOICE: 'Счет-фактура',
    DELIVERY_NOTE: 'ТОРГ-12',
    RECADV: 'Уведомление о приемке',
    FORMAL_NOTE: 'Акт',
    KS2: 'КС-2',
    KS3: 'КС-3',
    KS11: 'КС-11',
    KS14: 'КС-14',
    UNSPECIFIED: 'Иной документ',
    UKD: 'УКД',
    DELIVERY_NOTE_RETURN: 'ТОРГ-12 на возврат товаров',
};
