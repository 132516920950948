import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';
import { goBack } from 'connected-react-router';

import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { ExternalPage } from '#/src/components/external-page';
import { ExternalPageForm } from '#/src/components/external-page-form';
import FormButton from '#/src/components/form-button';
import PlateError from '#/src/components/plate-error';
import { changePassword } from '#/src/store/change-password/actions';
import {
    changePasswordErrorSelector,
    changePasswordIsLoadingSelector,
} from '#/src/store/change-password/selectors';
import { userNameSelector } from '#/src/store/user/selectors';

import './change-password-page.css';

const cn = createCn('change-password-page');

export default function ChangePasswordPage() {
    const dispatch = useDispatch();
    const userName = useSelector(userNameSelector);
    const changePasswordIsLoading = useSelector(changePasswordIsLoadingSelector);
    const changePasswordError = useSelector(changePasswordErrorSelector);
    const handleSubmitLogin = useCallback(
        (formValues: Record<string, string>) => {
            dispatch(
                changePassword(
                    formValues.password,
                    formValues.newPassword,
                    formValues.newPasswordConfirm,
                ),
            );
        },
        [dispatch],
    );
    const handleGoBack = useCallback(
        (event?: React.MouseEvent) => {
            event?.preventDefault();

            dispatch(goBack());
        },
        [dispatch],
    );

    return (
        <ExternalPage title='Изменение пароля'>
            <Typography.Text view='primary-medium' className={cn('username')}>
                Логин: {userName}
            </Typography.Text>
            <ExternalPageForm
                form='changePassword'
                className={cn('form')}
                onSubmit={handleSubmitLogin}
            >
                {!!changePasswordError && <PlateError error={changePasswordError} />}
                <FormButton
                    form='changePassword'
                    type='submit'
                    text='Подтвердить'
                    disabled={changePasswordIsLoading}
                />
                <div className={cn('link')}>
                    <Link dataTestId='cancel-link' view='primary' onClick={handleGoBack}>
                        Отмена
                    </Link>
                </div>
            </ExternalPageForm>
        </ExternalPage>
    );
}
