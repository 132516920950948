import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Modal } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';

import {
    changeWaitingErrorModalState,
    clearState,
} from '#/src/store/supplies/sign-supplies/action-creators';
import { waitingErrorModalIsOpenSelector } from '#/src/store/supplies/sign-supplies/selectors';

export function WaitingErrorModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(waitingErrorModalIsOpenSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(changeWaitingErrorModalState(false));
    }, [dispatch]);

    const handleClearState = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    return (
        <Modal
            open={modalIsOpen}
            hasCloser={true}
            onClose={handleCloseModal}
            size='m'
            onUnmount={handleClearState}
        >
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    Невозможно отправить на финансирование
                </Typography.Title>
                <Gap size='2xl' />
                <Typography.Text view='primary-small' tag='p'>
                    Превышен лимит времени ожидания подписания.
                    <br />
                    Необходимо заново выбрать поставки на отправку.
                </Typography.Text>
                <Button view='primary' size='xs' onClick={handleCloseModal}>
                    Понятно
                </Button>
            </Modal.Content>
        </Modal>
    );
}
