import { ThunkAction } from 'redux-thunk';

import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { ApplicationState } from '#/src/store/reducer';
import { userNameSelector } from '#/src/store/user/selectors';
import { fetchers } from '#/src/utils/client-api';
import convertFileToBase64String from '#/src/utils/convert-file-to-base64-string';
import { parseErrorMessage } from '#/src/utils/parse-error-message';

import * as actionCreators from './action-creators';
import {
    fileSelector,
    financingTypeSelector,
    isRegisterSelector,
    partyTinSelector,
    typeSelector,
} from './selectors';
import { SendRegisterActionsType } from './types';

export function sendRegister(): ThunkAction<void, ApplicationState, void, SendRegisterActionsType> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.sendRegisterStart());
        const state = getState();
        const file = fileSelector(state);
        const isRegister = isRegisterSelector(state);
        const partyTin = partyTinSelector(state);
        const type = typeSelector(state);
        const username = userNameSelector(state);
        const financingType = financingTypeSelector(state);

        try {
            if (file && username && partyTin) {
                const base64String = await convertFileToBase64String(file);

                if (type === 'debtor' && !isRegister) {
                    const response = await fetchers.fetchCreateCorrectionFromFile({
                        file: base64String,
                        filename: file.name,
                        username,
                        tin: partyTin,
                        source: 0,
                    });

                    dispatch(actionCreators.sendRegisterSuccess(response));
                } else {
                    const response = await fetchers.fetchCreateRegisterFromFile({
                        file: base64String,
                        filename: file.name,
                        isRegister,
                        isDebtor: type === 'debtor',
                        username,
                        tin: partyTin,
                        financingType: financingType || undefined,
                        source: 0,
                    });

                    dispatch(actionCreators.sendRegisterSuccess(response));
                }
            } else {
                dispatch(actionCreators.sendRegisterError());
            }
        } catch (e) {
            const error = parseErrorMessage(e);

            dispatch(actionCreators.sendRegisterError());
            dispatch(dispatchErrorNotification(error, true));
        }
    };
}
