import { ThunkAction } from 'redux-thunk';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { appliedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';
import {
    areAllSuppliesSelectedSelector,
    selectedIdsSelector,
} from '#/src/store/supplies/supplies-list/selectors';
import { GetExcelReportPayload } from '#/src/types/supply';
import { fetchers } from '#/src/utils/client-api';
import convertSuppliesFiltersToRequestPayload from '#/src/utils/convert-supplies-filters-to-request-payload';
import { downloadFile } from '#/src/utils/download-file';

import * as actionCreators from './action-creators';
import { SuppliesDownloadReportType } from './types';

export function downloadSuppliesReport(): ThunkAction<
    void,
    ApplicationState,
    void,
    SuppliesDownloadReportType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeDownloadReportLoadingState(LOADING_STATE.LOADING));
        const state = getState();
        const areAllSuppliesSelected = areAllSuppliesSelectedSelector(state);
        const currentOrganizationId = currentOrganizationIdSelector(state);
        const filters = appliedFiltersSelector(state);
        const selectedIds = selectedIdsSelector(state);
        const { supplyIds, ...requestData } = convertSuppliesFiltersToRequestPayload(
            currentOrganizationId as string,
            filters,
        );
        let requestPayload: GetExcelReportPayload = {};

        if (areAllSuppliesSelected) {
            requestPayload = { ...requestData, supplyIds: selectedIds };
        } else {
            requestPayload.supplyIds = selectedIds;
            requestPayload.sellerTin = requestData.sellerTin;
        }

        try {
            const file = await fetchers.fetchExcelReport(requestPayload);

            await downloadFile(file, 'application/vnd.ms-excel', 'supply_new.xlsx');

            dispatch(actionCreators.changeDownloadReportLoadingState(LOADING_STATE.SUCCESS));
        } catch (e) {
            dispatch(actionCreators.changeDownloadReportLoadingState(LOADING_STATE.ERROR));
            dispatch(dispatchErrorNotification('Не удалось скачать отчет.'));
        }
    };
}
