import { ThunkAction } from 'redux-thunk';

import {
    DebtorsForReportsErrorNames,
    debtorsForReportsErrorsDictionary,
} from '#/src/dictionaries/debtors-for-reports-errors';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';
import { parseErrorMessage } from '#/src/utils/parse-error-message';

import * as actionCreators from './action-creators';
import { DebtorsActionsType } from './types';

export function fetchDebtors(
    partyTin: string,
    isDebtor: boolean,
): ThunkAction<void, ApplicationState, void, DebtorsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getDebtorsStart());

        try {
            const response = await fetchers.fetchOrganizationNamesForReports({
                partyTin,
                isDebtor,
            });

            dispatch(actionCreators.getDebtorsSuccess(response));
        } catch (e) {
            const errorMessage = parseErrorMessage(e);

            dispatch(
                dispatchErrorNotification(
                    debtorsForReportsErrorsDictionary[
                        errorMessage as DebtorsForReportsErrorNames
                    ] || debtorsForReportsErrorsDictionary.UNKNOWN_ERROR,
                ),
            );
            dispatch(actionCreators.getDebtorsError());
        }
    };
}
