import { ApplicationState } from '#/src/store/reducer';
import { stateSelector } from '#/src/store/selectors';

export const settingsStateSelector = (state: ApplicationState) => stateSelector(state).settings;

export const contextRootSelector = (state: ApplicationState) =>
    settingsStateSelector(state).contextRoot;
export const projectNameSelector = (state: ApplicationState) =>
    settingsStateSelector(state).projectName;
export const pageTitleSelector = (state: ApplicationState) =>
    settingsStateSelector(state).pageTitle;
