import React from 'react';
import { createCn } from 'bem-react-classname';

import { IconButton } from '@alfalab/core-components/icon-button';
import { BankAlfaXxlWhiteIcon } from '@alfalab/icons-classic/BankAlfaXxlWhiteIcon';

import ExternalsPagePhones from '#/src/components/external-page/phones';

import './external-page-header.css';

const cn = createCn('external-page-header');

export default function ExternalPageHeader() {
    return (
        <div className={cn()}>
            <div className={cn('container')}>
                <IconButton
                    className={cn('logo')}
                    view='primary'
                    icon={BankAlfaXxlWhiteIcon}
                    href='https://alfabank.ru'
                    target='_blank'
                    colors='inverted'
                />
                <ExternalsPagePhones view='header' />
            </div>
        </div>
    );
}
