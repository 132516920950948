import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { SupplyErrorsActionsType } from './types';

export function fetchSupplyErrors(
    supplyId: number,
): ThunkAction<void, ApplicationState, void, SupplyErrorsActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getSupplyErrorsStart(supplyId));
        try {
            const response = await fetchers.fetchSupplyErrors(supplyId);

            dispatch(actionCreators.getSupplyErrorsSuccess(supplyId, response));
        } catch (e) {
            dispatch(actionCreators.getSupplyErrorsError(supplyId));
        }
    };
}
