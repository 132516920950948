import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import {
    organizationsListByOptionsSelector,
    organizationsListSelector,
} from '#/src/store/organizations/selectors';
import {
    changeRegisterType,
    changeStatePartyTin,
    changeStateType,
} from '#/src/store/registers/send-register/action-creators';
import {
    fileSelector,
    partyTinSelector,
    sendRegisterIsLoadingSelector,
} from '#/src/store/registers/send-register/selectors';
import { Permission } from '#/src/types/orgatiozations';

export function OrganizationsField() {
    const dispatch = useDispatch();
    const partyTin = useSelector(partyTinSelector);
    const file = useSelector(fileSelector);
    const isLoading = useSelector(sendRegisterIsLoadingSelector);
    const options = useSelector(organizationsListByOptionsSelector);
    const organizations = useSelector(organizationsListSelector);
    const handleOrganizationChange = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selected }) => {
            if (selected?.key) {
                const organizationInfo = organizations.find((item) => item.tin === selected.key);

                if (organizationInfo) {
                    const type = organizationInfo.actions.includes(
                        Permission.FACT_SHOW_SELLER_REGISTERS,
                    )
                        ? 'supplier'
                        : 'debtor';

                    batch(() => {
                        dispatch(changeStatePartyTin(organizationInfo.tin));
                        dispatch(changeStateType(type));

                        if (type === 'debtor') {
                            dispatch(changeRegisterType(true));
                        }
                    });
                }
            }
        },
        [dispatch, organizations],
    );

    return (
        <Select
            data-test-id='organizations-select'
            label='Выберите организацию'
            size='m'
            block={true}
            disabled={!file || isLoading}
            selected={partyTin ? [partyTin] : []}
            options={options}
            onChange={handleOrganizationChange}
        />
    );
}
