import { DocumentWithSign } from '@alfa-bank/corp-sign-ui-react/types';

import { CreateSignDataResponse } from '#/src/types/sign';
import { FactorValidationStatus, SendSignSuppliesToFactorEntity } from '#/src/types/supply';
import { fetchers } from '#/src/utils/client-api';

export default function combineSendToFactorPromises(
    documents: DocumentWithSign[],
    createdSuppliesSignData: CreateSignDataResponse,
    createdRegisterSignData: CreateSignDataResponse,
) {
    const dataIds = createdSuppliesSignData.reduce<Record<string, string>>(
        (arr, item) => ({ ...arr, [item.signId]: item.id }),
        {},
    );
    const dataRegisterIds = createdRegisterSignData.reduce<Record<string, string>>(
        (arr, item) => ({ ...arr, [item.signId]: item.id }),
        {},
    );

    const dataIdsMap: Record<number, SendSignSuppliesToFactorEntity[]> = {};
    let supplyToSignPromises: Array<Promise<Record<string, FactorValidationStatus>>> = [];

    documents.forEach((item, index: number) => {
        const rootIndex = Math.floor(index / 100);

        if (dataIds[item.documentId as string]) {
            if (dataIdsMap[rootIndex]) {
                dataIdsMap[rootIndex] = [
                    ...dataIdsMap[rootIndex],
                    {
                        id: dataIds[item.documentId as string],
                        signId: item.documentId as string,
                        signature: item.sign,
                    },
                ];
            } else {
                dataIdsMap[rootIndex] = [
                    {
                        id: dataIds[item.documentId as string],
                        signId: item.documentId as string,
                        signature: item.sign,
                    },
                ];
            }
        }

        if (dataRegisterIds[item.documentId as string]) {
            supplyToSignPromises = supplyToSignPromises.concat(
                fetchers.fetchSendSignRegistersToFactor([
                    {
                        id: dataRegisterIds[item.documentId as string],
                        signId: item.documentId as string,
                        signature: item.sign,
                    },
                ]),
            );
        }
    }, {});

    return supplyToSignPromises.concat(
        Object.keys(dataIdsMap).map((key) =>
            fetchers.fetchSendSignSuppliesToFactor(
                dataIdsMap[+key] as SendSignSuppliesToFactorEntity[],
            ),
        ),
    );
}
