import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';

import { registersModelsSelector } from '../selectors';

export const registerErfStateSelector = (state: ApplicationState) =>
    registersModelsSelector(state).registerErf;

export const erfListSelector = (state: ApplicationState) => registerErfStateSelector(state).erfList;

export const modalIsOpenSelector = (state: ApplicationState) =>
    registerErfStateSelector(state).modalIsOpen;

export const registerErfIsLoadingSelector = createSelector(
    registerErfStateSelector,
    (state) => state.loadingState === 'loading',
);
