import React from 'react';
import { createCn } from 'bem-react-classname';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import getOptions, { OptionsProps } from './get-options';
import HighChartsReportSkeleton from './high-charts-report-skeleton';

import './high-charts-report.css';

type HighChartsReportProps = OptionsProps & {
    isLoading: boolean;
};

const cn = createCn('high-charts-report');

export default function HighChartsReport(props: HighChartsReportProps) {
    return (
        <div className={cn({ skeleton: props.isLoading })}>
            {props.isLoading ? (
                <HighChartsReportSkeleton />
            ) : (
                <HighchartsReact highcharts={Highcharts} options={getOptions(props)} />
            )}
        </div>
    );
}
