import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';
import { CachedReportStatus } from '#/src/types/reports';

import { reportsXlsSelector } from '../selectors';

export const cachedReportsStateSelector = (state: ApplicationState) =>
    reportsXlsSelector(state).cachedReports;

export const cachedReportsSelector = (state: ApplicationState) =>
    cachedReportsStateSelector(state).list;

export const cachedReportsLoadingStateSelector = createSelector(
    cachedReportsStateSelector,
    (state) => state.loadingState,
);

export const isLoadingGenerateCachedReportSelector = createSelector(
    cachedReportsStateSelector,
    (state) => state.isLoadingGenerateCachedReport,
);

export const isLoadingDownloadCachedReportSelector = createSelector(
    cachedReportsStateSelector,
    (state) => state.isLoadingDownloadCachedReport,
);

export const countOfProcessingCachedReportsSelector = createSelector(
    cachedReportsSelector,
    (reports) =>
        reports?.reduce(
            (acc, report) => (report.status === CachedReportStatus.PROCESS ? acc + 1 : acc),
            0,
        ) || 0,
);

export const cachedReportsSortedByDateSelector = createSelector(cachedReportsSelector, (reports) =>
    reports?.sort((a, b) => +new Date(b.dateTime) - +new Date(a.dateTime)),
);

export const generatedCachedReportsSelector = createSelector(
    cachedReportsSortedByDateSelector,
    (reports) => reports?.filter((report) => report.status === CachedReportStatus.SUCCESS),
);
