import React, { useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { setPagination } from '#/src/store/registers/register-info/action-creators';
import {
    resetAllFilters,
    updateAppliedFilters,
} from '#/src/store/registers/register-info-filters/action-creators';
import {
    filtersErrorsSelector,
    selectedFiltersSelector,
} from '#/src/store/registers/register-info-filters/selectors';

export function FilterButtons() {
    const dispatch = useDispatch();
    const errors = useSelector(filtersErrorsSelector);
    const filters = useSelector(selectedFiltersSelector);
    const hasErrors = useMemo(
        () => Boolean(Object.keys(errors).filter((key) => errors[key]).length),
        [errors],
    );
    const handleApplyFilters = useCallback(() => {
        batch(() => {
            dispatch(updateAppliedFilters(filters));
            dispatch(setPagination({ page: 1, pageSize: 20 }));
        });
    }, [dispatch, filters]);

    const handleResetFilters = useCallback(() => {
        batch(() => {
            dispatch(resetAllFilters());
        });
    }, [dispatch]);

    return (
        <Space direction='horizontal' size={20} align='center'>
            <Button
                dataTestId='submit-filters-button'
                size='xxs'
                disabled={hasErrors}
                view='tertiary'
                onClick={handleApplyFilters}
            >
                Применить фильтры
            </Button>
            <Typography.Text view='primary-small'>
                <Link dataTestId='reset-filters-link' onClick={handleResetFilters} pseudo={true}>
                    Сбросить фильтры
                </Link>
            </Typography.Text>
        </Space>
    );
}
