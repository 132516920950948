import { LoadingState } from '#/src/constants/loading-state';
import { RegisterRepayment } from '#/src/types/registers';

import * as actionCreators from './action-creators';
import { RepaymentsActionsType } from './types';

export type RepaymentsState = {
    loadingState: LoadingState;
    entities: RegisterRepayment[];
    rowId: number | null;
};

const initialState: RepaymentsState = {
    rowId: null,
    loadingState: 'unknown',
    entities: [],
};

export function repaymentsReducer(
    state: RepaymentsState = initialState,
    action: RepaymentsActionsType,
): RepaymentsState {
    switch (action.type) {
        case actionCreators.getRepaymentsStart.type:
            return {
                ...state,
                entities: [],
                rowId: action.rowId,
                loadingState: action.loadingState,
            };
        case actionCreators.getRepaymentsSuccess.type:
            return {
                ...state,
                loadingState: action.loadingState,
                entities: action.data,
            };
        case actionCreators.getRepaymentsError.type:
            return {
                ...state,
                loadingState: action.loadingState,
                entities: [],
            };
        case actionCreators.clearRepayments.type:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
