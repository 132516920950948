import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';

import {
    currentOrganizationIdSelector,
    currentOrganizationIsDebtorSelector,
} from '#/src/store/organizations/selectors';
import { generateCachedReport } from '#/src/store/reports-xls/cached-reports/actions';
import { isLoadingGenerateCachedReportSelector } from '#/src/store/reports-xls/cached-reports/selectors';
import {
    reportFiltersIsFilledSelector,
    reportFiltersSelector,
} from '#/src/store/reports-xls/report-filters/selectors';

import './generate-report-button.css';

const generateReportButtonClassName = createCn('generate-report-xls-button')();

export const GenerateReportButton: FC = () => {
    const dispatch = useDispatch();
    const organizationId = useSelector(currentOrganizationIdSelector);
    const isDebtor = useSelector(currentOrganizationIsDebtorSelector);
    const reportFilters = useSelector(reportFiltersSelector);
    const reportFiltersIsFilled = useSelector(reportFiltersIsFilledSelector);
    const isLoadingGenerateCachedReport = useSelector(isLoadingGenerateCachedReportSelector);

    const handleClick = useCallback(() => {
        if (organizationId) {
            dispatch(
                generateCachedReport({ ...reportFilters, partyTin: organizationId, isDebtor }),
            );
        }
    }, [dispatch, reportFilters, organizationId, isDebtor]);

    return (
        <Button
            dataTestId='generate-report-button'
            view='primary'
            size='s'
            className={generateReportButtonClassName}
            onClick={handleClick}
            disabled={isLoadingGenerateCachedReport || !reportFiltersIsFilled}
        >
            Сформировать отчёт
        </Button>
    );
};
