import {
    FiltersSourceType,
    RegisterSourceAllType,
    RegisterStatusType,
} from '#/src/types/registers';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import * as actionCreators from './action-creators';
import { RegistersFiltersActionsType } from './types';

export type FiltersState = {
    role: 'supplier' | 'debtor' | null;
    status: RegisterStatusType[];
    dateFrom: string;
    dateTo: string;
    fileNumber: string;
    registerSource: FiltersSourceType;
    documentNumber: string;
    sellersTin: string[];
    debtorsTin: string[];
};

export type RegistersFiltersState = {
    applied: FiltersState;
    selected: FiltersState;
};

export const initialFiltersState: FiltersState = {
    role: null,
    status: [],
    dateFrom: formatDateForCalendar(Date.now() - 604800000),
    dateTo: formatDateForCalendar(Date.now()),
    fileNumber: '',
    registerSource: RegisterSourceAllType.ALL,
    documentNumber: '',
    sellersTin: [],
    debtorsTin: [],
};

const initialRegistersFiltersState: RegistersFiltersState = {
    applied: { ...initialFiltersState },
    selected: { ...initialFiltersState },
};

export function registersFiltersReducer(
    state: RegistersFiltersState = initialRegistersFiltersState,
    action: RegistersFiltersActionsType,
): RegistersFiltersState {
    switch (action.type) {
        case actionCreators.updateSelectedFilters.type:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.filters,
                },
            };
        case actionCreators.updateAppliedFilters.type:
            return {
                ...state,
                applied: {
                    ...state.applied,
                    ...action.filters,
                },
            };
        case actionCreators.updateAllFilters.type:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.filters,
                },
                applied: {
                    ...state.applied,
                    ...action.filters,
                },
            };
        case actionCreators.resetAllFilters.type:
            return {
                applied: { ...initialFiltersState, ...action.filters },
                selected: { ...initialFiltersState, ...action.filters },
            };
        default:
            return state;
    }
}
