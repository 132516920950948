import React from 'react';
import { createCn } from 'bem-react-classname';

import {
    AMOUNT_MAJOR_MINOR_PARTS_SEPARATOR,
    AmountType,
    CurrencyCodes,
    formatAmount,
    THINSP,
} from '@alfalab/utils';

import './amount-info.css';

export type AmountInfoProps = Omit<AmountType, 'currency'> & {
    currency?: CurrencyCodes;
    styled?: boolean;
    info?: 'млн' | 'тыс';
};

const cn = createCn('amount-info');

export default function AmountInfo({
    value,
    minority,
    currency,
    view = 'default',
    styled = true,
    info,
}: AmountInfoProps) {
    const { majorPart, minorPart, currencySymbol } = formatAmount({
        value,
        currency: currency || 'RUR',
        minority,
        view,
    });

    return (
        <span className={cn({ styled })}>
            {majorPart}
            <span className={cn('minor-part', { styled })}>
                {minorPart && AMOUNT_MAJOR_MINOR_PARTS_SEPARATOR}
                {minorPart}
            </span>
            {info && (
                <React.Fragment>
                    {THINSP}
                    {info}
                </React.Fragment>
            )}

            {currency && (
                <React.Fragment>
                    {THINSP}
                    <span className={cn('currency', { styled })}>{currencySymbol}</span>
                </React.Fragment>
            )}
        </span>
    );
}
