import { LoadingState } from '#/src/constants/loading-state';
import { CertificateInfoSignedSupply } from '#/src/types/certificates';

import * as actionCreators from './action-creators';
import { SupplyCertificatesActionsType } from './types';

export type SupplyCertificatesById = {
    certificates: CertificateInfoSignedSupply[];
    loadingState: LoadingState;
};

export type SupplyCertificatesState = Record<number, SupplyCertificatesById>;

export const supplyCertificatesByIdDefault: SupplyCertificatesById = {
    certificates: [],
    loadingState: 'unknown',
};

const initialState: SupplyCertificatesState = {};

export function supplyCertificatesReducer(
    state: SupplyCertificatesState = initialState,
    action: SupplyCertificatesActionsType,
): SupplyCertificatesState {
    switch (action.type) {
        case actionCreators.getSupplyCertificatesStart.type:
            return {
                ...state,
                [action.id]: {
                    ...supplyCertificatesByIdDefault,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyCertificatesSuccess.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    certificates: action.data,
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.getSupplyCertificatesError.type:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    loadingState: action.loadingState,
                },
            };
        case actionCreators.removeSupplyCertificates.type: {
            const { [action.id]: removeItem, ...items } = state;

            return {
                ...items,
            };
        }
        default:
            return state;
    }
}
