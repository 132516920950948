import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { stateSelector } from '#/src/store/selectors';

export const documentsStateSelector = (state: ApplicationState) => stateSelector(state).documents;

export const documentsErrorSelector = (state: ApplicationState) =>
    documentsStateSelector(state).error;
export const documentsLoadingStateSelector = (state: ApplicationState) =>
    documentsStateSelector(state).loadingState;
export const documentsIsLoadingSelector = (state: ApplicationState) =>
    documentsLoadingStateSelector(state) === LOADING_STATE.LOADING;
export const requestedDocumentsSelector = (state: ApplicationState) =>
    documentsStateSelector(state).requestedDocuments;
export const successDocumentsSelector = (state: ApplicationState) =>
    documentsStateSelector(state).successDocuments;
export const reportSelector = (state: ApplicationState) => documentsStateSelector(state).report;
export const resultModalIsOpenSelector = (state: ApplicationState) =>
    documentsStateSelector(state).resultModalIsOpen;
