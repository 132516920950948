import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { SIGN_SUPPLIES_LIMIT_COUNT } from '#/src/constants/limits';
import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';
import { availableSuppliesSelector } from '#/src/store/supplies/supplies-list/selectors';

import { suppliesModelsSelector } from '../selectors';

export const signSuppliesStateSelector = (state: ApplicationState) =>
    suppliesModelsSelector(state).signSupplies;

export const erfErrorModalIsOpenSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).erfErrorModalIsOpen;

export const additionalInfoModalIsOpenSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).additionalInfoModalIsOpen;

export const waitingErrorModalIsOpenSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).waitingErrorModalIsOpen;

export const warningModalIsOpenSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).warningModalIsOpen;

export const emptyFieldsWarningModalIsOpenSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).emptyFieldsWarningModalIsOpen;

export const createdSuppliesSignDataSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).createdSuppliesSignData;

export const createdRegisterSignDataSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).createdRegisterSignData;

export const activeErfSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).activeErf;

export const suppliesIdsSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).suppliesIds;

export const registerIdSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).registerId;

export const filteredSuppliesIdsSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).filteredSuppliesIds;

export const permissionsSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).permissions;

export const documentsSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).documents;

export const userSelector = (state: ApplicationState) => signSuppliesStateSelector(state).user;

export const signButtonCallbackSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).callback;

export const availableAmountSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).availableAmount;

export const loadingStateSelector = (state: ApplicationState) =>
    signSuppliesStateSelector(state).loadingState;

export const signSuppliesIsLoadingSelector = (state: ApplicationState) =>
    loadingStateSelector(state) === LOADING_STATE.LOADING;

export const sortedSuppliesIdsSelector = createSelector(
    suppliesIdsSelector,
    availableSuppliesSelector,
    (ids, availableSupplies) => {
        if (ids.length > SIGN_SUPPLIES_LIMIT_COUNT) {
            return sortBy(
                ids,
                (id: number) => availableSupplies.amountCertificationDocumentDate[id],
            ) as number[];
        }

        return ids;
    },
);

export const countsOfSortedSuppliesIdsSelector = createSelector(suppliesIdsSelector, (ids) => {
    if (ids.length > SIGN_SUPPLIES_LIMIT_COUNT) {
        return SIGN_SUPPLIES_LIMIT_COUNT;
    }

    return ids.length;
});

export const suppliesIdsBySourceSelector = createSelector(
    sortedSuppliesIdsSelector,
    countsOfSortedSuppliesIdsSelector,
    availableSuppliesSelector,
    (ids, counts, availableSupplies) => {
        const idsByRPD = [];
        const idsByEDI = [];

        for (let i = 0; i < counts; i++) {
            const key = ids[i];

            if (availableSupplies.available[key]) {
                const source = availableSupplies.source[key];

                if (source && source.includes('RPD')) {
                    idsByRPD.push(key);
                } else if (source && source.includes('EDI')) {
                    idsByEDI.push(key);
                }
            }
        }

        return { idsByRPD, idsByEDI };
    },
);
