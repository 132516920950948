import React from 'react';
import { createCn } from 'bem-react-classname';

import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { DatesFilter } from './dates-filter';
import { DebtorsFilter } from './debtors-filter';
import { DocumentNumberFilter } from './document-number-filter';
import { FileNumberFilter } from './file-number-filter';
import { FilterButtons } from './filter-buttons';
import { RoleFilter } from './role-filter';
import { SourceTypeFilter } from './source-type-filter';
import { StatusFilter } from './status-filter';

import './registers-list-filters.css';

const cn = createCn('registers-list-filters');

export function RegistersListFilters() {
    return (
        <div className={cn()}>
            <Typography.Title view='xsmall' tag='div' weight='bold'>
                Фильтры
            </Typography.Title>
            <Gap size='xl' />
            <Space direction='vertical' fullWidth={true} size={24}>
                <Grid.Row gutter='24'>
                    <Grid.Col width='6'>
                        <DatesFilter />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <SourceTypeFilter />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter='24'>
                    <Grid.Col width='12'>
                        <StatusFilter />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter='24'>
                    <Grid.Col width='6'>
                        <RoleFilter />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <FileNumberFilter />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter='24'>
                    <Grid.Col width='6'>
                        <DocumentNumberFilter />
                    </Grid.Col>
                    <Grid.Col width='6'>
                        <DebtorsFilter />
                    </Grid.Col>
                </Grid.Row>
            </Space>
            <Gap size='xl' />
            <FilterButtons />
        </div>
    );
}
