import { SystemNotification } from '#/src/types/communication';

import * as actionCreators from './action-creators';
import { SystemNotifyActionsType } from './types';

export type SystemNotifyState = {
    notifyModalIsOpen: boolean;
    list: SystemNotification[];
};

const initialState: SystemNotifyState = {
    notifyModalIsOpen: false,
    list: [],
};

export function systemNotifyReducer(
    state: SystemNotifyState = initialState,
    action: SystemNotifyActionsType,
): SystemNotifyState {
    switch (action.type) {
        case actionCreators.showNotifications.type:
            return {
                ...state,
                list: action.data,
                notifyModalIsOpen: true,
            };
        case actionCreators.hideNotifications.type:
            return {
                ...state,
                notifyModalIsOpen: false,
            };
        default:
            return state;
    }
}
