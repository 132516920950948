import { CellNames } from '#/src/store/supplies/table-cells-settings/types';

export const cellNamesDictionary: Record<CellNames, string> = {
    date: 'Дата',
    debtor: 'Дебитор',
    contract: 'Контракт',
    supplySource: 'Источник',
    status: 'Статус',
    type: 'Документ',
    amount: 'Сумма',
};

export const allCells: CellNames[] = [
    'type',
    'amount',
    'date',
    'debtor',
    'contract',
    'supplySource',
    'status',
];
export const disabledCells: CellNames[] = ['amount', 'status', 'type'];
