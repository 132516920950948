import React from 'react';
import { useSelector } from 'react-redux';

import { Gap } from '@alfalab/core-components/gap';
import { Space } from '@alfalab/core-components/space';

import DownloadRegistersButton from '#/src/containers/pages/registers-pages/download-registers-button';
import { inaccessibleOptionKeysForSingleRegister } from '#/src/dictionaries/registers-download-documents';
import {
    countSelector,
    currencyCountsSelector,
    registerInfoSelector,
} from '#/src/store/registers/register-info/selectors';

import RegisterDeleteToggleButton from '../register-delete-toggle-button';
import { RegisterInfoFilters } from '../register-info-filters';
import { RegisterInfoSidePanel } from '../register-info-side-panel';
import { RegisterInfoSignSection } from '../register-info-sign-section';
import { RegisterInfoTable } from '../register-info-table';

import { RegisterCurrencyCounts } from './register-currency-counts';
import { RegisterErf } from './register-erf';
import { RegisterErrorList } from './register-error-list';
import { RegisterFileNumber } from './register-file-number';
import { RegisterStatus } from './register-status';

type RegisterInfoSectionProps = {
    partyTin: string;
};

export function RegisterInfoSection({ partyTin }: RegisterInfoSectionProps) {
    const register = useSelector(registerInfoSelector);
    const currencyCounts = useSelector(currencyCountsSelector);
    const count = useSelector(countSelector);

    return register ? (
        <div>
            <RegisterFileNumber register={register} />
            <Gap size='s' />
            {!register.debtor && (
                <React.Fragment>
                    <RegisterErf id={register.id} />
                    <Gap size='s' />
                </React.Fragment>
            )}
            <RegisterStatus register={register} />
            <Gap size='xl' />
            <RegisterErrorList register={register} />
            <Space direction='horizontal' size={16} align='center'>
                <RegisterInfoSignSection />
                <DownloadRegistersButton
                    ids={[register.id]}
                    inaccessibleOptionKeys={inaccessibleOptionKeysForSingleRegister}
                />
                <RegisterDeleteToggleButton
                    id={register.id}
                    deleted={register.deleted}
                    partyTin={partyTin}
                />
            </Space>
            <Gap size='xl' />
            {Array.isArray(currencyCounts) && !register.debtor && (
                <RegisterCurrencyCounts currencyCounts={currencyCounts} count={count} />
            )}
            <RegisterInfoFilters />
            <Gap size='xl' />
            <RegisterInfoTable />
            <RegisterInfoSidePanel />
        </div>
    ) : null;
}
