import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Table } from '@alfalab/core-components/table';

import { selectedIdsSelector } from '#/src/store/supplies/supplies-list/selectors';
import { availableCellsSelector } from '#/src/store/supplies/table-cells-settings/selectors';
import { SupplyDto } from '#/src/types/supply';

import { SupplyDetailsSection } from '../supply-details-section';

import { SupplyRowCells } from './supply-row-cells';

type SupplyTableRowProps = {
    supply: SupplyDto;
};

export function SupplyTableRow({ supply }: SupplyTableRowProps) {
    const availableCells = useSelector(availableCellsSelector);
    const selected = useSelector(selectedIdsSelector);
    const [isExpanded, setExpanded] = useState(false);
    const handleExpandedToggle = useCallback(() => {
        setExpanded((prevState) => !prevState);
    }, []);

    return (
        <Table.TExpandableRow
            key={supply.id}
            selected={Boolean(supply.id && selected.includes(supply.id))}
            onToggle={handleExpandedToggle}
            expanded={isExpanded}
            renderContent={(expanded) => (
                <React.Fragment>
                    <Table.TCell />
                    <Table.TCell colSpan={availableCells.length + 1}>
                        {supply.id && supply.status && (
                            <SupplyDetailsSection
                                expanded={expanded}
                                supplyId={supply.id}
                                supplyStatus={supply.status}
                            />
                        )}
                    </Table.TCell>
                </React.Fragment>
            )}
        >
            <SupplyRowCells supply={supply} isExpanded={isExpanded} />
        </Table.TExpandableRow>
    );
}
