import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import { CountFilter, DashboardTabs } from '#/src/dictionaries/reports-dashboard';

import ChartAmount from '../../chart-amount';

import './high-charts-tooltip.css';

export type TooltipListItem = {
    name: string;
    value: number;
};

type HighChartsTooltipProps = {
    title: string;
    tab: DashboardTabs;
    list: TooltipListItem[];
    activeColumn: number;
    byCount: CountFilter;
};

const cn = createCn('high-charts-tooltip');

export default function HighChartsTooltip({
    tab,
    title,
    list,
    activeColumn,
    byCount,
}: HighChartsTooltipProps) {
    return (
        <div className={cn()}>
            <Typography.Text view='secondary-large' weight='bold' className={cn('title')}>
                {title}
                {tab === DashboardTabs.COMMISSIONS && ', с НДС'}
            </Typography.Text>
            <div>
                {list.map((item, index) => (
                    <Typography.Text
                        tag='div'
                        view='primary-medium'
                        color={index === activeColumn ? 'primary' : 'secondary'}
                        className={cn('list-item', {
                            color: String(tab === DashboardTabs.PAYMENTS ? index + 1 : index),
                            active: index === activeColumn,
                        })}
                    >
                        <ChartAmount amount={item.value} byCount={byCount} styled={true} />{' '}
                        <Typography.Text view='secondary-medium'>{item.name}</Typography.Text>
                    </Typography.Text>
                ))}
            </div>
        </div>
    );
}
