import {
    FiltersState,
    RegisterInfoFiltersState,
} from '#/src/store/registers/register-info-filters/reducer';
import createAction from '#/src/utils/create-action';

export const updateSelectedFilters = createAction(
    'REGISTERS / INFO / FILTERS / updateSelectedFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const updateAppliedFilters = createAction(
    'REGISTERS / INFO / FILTERS / updateAppliedFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const setFiltersErrors = createAction(
    'REGISTERS / INFO / FILTERS / updateFiltersErrors',
    (errors: RegisterInfoFiltersState['errors']) => ({ errors }),
);

export const resetAllFilters = createAction(
    'REGISTERS / INFO / FILTERS / resetAllFilters',
    () => ({}),
);
