import { fetchFactory } from '#/src/server/services/lib/fetch-factory';

export default function create(contextRoot = '', csrfCookieName: string) {
    const fetch = {
        sendCertificateData: fetchFactory(
            `${contextRoot}/secure/nodeapi/sendCertificateData`,
            'POST',
            { allowMultiRequest: true, csrfCookieName },
        ),
    };

    return {
        // фетчер для логирования взаимодействия с CryptoProp plugin
        sendCertificateData(params: unknown) {
            return fetch.sendCertificateData(params);
        },
    };
}
