import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alfalab/core-components/spinner';

import { companiesTypeByRegisterRoleFilter } from '#/src/dictionaries/companies-type-by-register-role-filter';
import { fetchCompaniesByType } from '#/src/store/companies/actions';
import {
    currentOrganizationIdSelector,
    currentOrganizationInfoSelector,
    currentOrganizationTypeSelector,
} from '#/src/store/organizations/selectors';
import { fetchRegistersByRole } from '#/src/store/registers/registers-list/actions';
import {
    entitiesIsErrorSelector,
    entitiesIsLoadingSelector,
    entitiesSelector,
    paginationSelector,
} from '#/src/store/registers/registers-list/selectors';
import { Permission } from '#/src/types/orgatiozations';

import { RegisterListEmpty } from './register-list-empty';
import { RegisterListErrorSection } from './register-list-error-section';
import { RegisterListTable } from './register-list-table';
import { RegistersListFilters } from './registers-list-filters';
import { SendRegister } from './send-register';

export function RegistersListPage() {
    const dispatch = useDispatch();
    const organizationInfo = useSelector(currentOrganizationInfoSelector);
    const organizationId = useSelector(currentOrganizationIdSelector);
    const organizationType = useSelector(currentOrganizationTypeSelector);
    const entities = useSelector(entitiesSelector);
    const entitiesIsLoading = useSelector(entitiesIsLoadingSelector);
    const entitiesIsError = useSelector(entitiesIsErrorSelector);
    const pagination = useSelector(paginationSelector);

    let content = <RegisterListEmpty />;

    if (entitiesIsLoading) {
        content = <Spinner size='s' visible={entitiesIsLoading} />;
    } else if (entitiesIsError) {
        content = <RegisterListErrorSection />;
    } else if (entities.length) {
        content = <RegisterListTable />;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pagination.page, pagination.pageSize]);

    useEffect(() => {
        let newRole: 'supplier' | 'debtor' = 'supplier';

        if (
            organizationInfo?.actions.includes(Permission.FACT_SHOW_DEBTOR_REGISTERS) &&
            organizationType === 'debtor'
        ) {
            newRole = 'debtor';
        }

        dispatch(fetchRegistersByRole(newRole));

        if (organizationInfo) {
            dispatch(
                fetchCompaniesByType({
                    tin: organizationInfo.tin,
                    type: companiesTypeByRegisterRoleFilter[newRole],
                }),
            );
        }
    }, [dispatch, organizationId, organizationType, organizationInfo]);

    return (
        <div>
            <SendRegister />
            <RegistersListFilters />
            {content}
        </div>
    );
}
