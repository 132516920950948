import React from 'react';
import { createCn } from 'bem-react-classname';

import { CollapseToggleSection } from '#/src/components/collapse-toggle-section';
import { CertificateInfoSignedSupply } from '#/src/types/certificates';

import { SupplyCertificatesItem } from './supply-certificates-item';

import './supply-certificates-list.css';

type SupplyCertificatesListProps = {
    certificates: CertificateInfoSignedSupply[];
};

const cn = createCn('supply-certificates-list');

export function SupplyCertificatesList({ certificates }: SupplyCertificatesListProps) {
    return (
        <div className={cn()}>
            <CollapseToggleSection
                linkTextExpanded='Информация о сертификатах'
                linkTextCollapsed='Информация о сертификатах'
                linkDataTestId='toggle-expended-state-certificates-link'
            >
                {certificates.map((certificate, index) => (
                    <SupplyCertificatesItem
                        key={certificate.id}
                        index={index + 1}
                        certificate={certificate}
                    />
                ))}
            </CollapseToggleSection>
        </div>
    );
}
