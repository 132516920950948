import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Alert } from '@alfalab/core-components/alert';
import { Amount } from '@alfalab/core-components/amount';
import { Button } from '@alfalab/core-components/button';
import { Divider } from '@alfalab/core-components/divider';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Modal } from '@alfalab/core-components/modal';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { SIGN_SUPPLIES_LIMIT_COUNT } from '#/src/constants/limits';
import { changeAdditionalModalState } from '#/src/store/supplies/sign-supplies/action-creators';
import { createSignSuppliesData } from '#/src/store/supplies/sign-supplies/actions';
import {
    activeErfSelector,
    additionalInfoModalIsOpenSelector,
    availableAmountSelector,
    suppliesIdsSelector,
} from '#/src/store/supplies/sign-supplies/selectors';

import { ItemOfActiveErfRow } from './item-of-active-erf-row';

export function AdditionalInfoModal() {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(additionalInfoModalIsOpenSelector);
    const activeErf = useSelector(activeErfSelector);
    const suppliesIds = useSelector(suppliesIdsSelector);
    const availableAmount = useSelector(availableAmountSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(changeAdditionalModalState(false));
    }, [dispatch]);

    const handleCreateSignSuppliesData = useCallback(() => {
        batch(() => {
            dispatch(changeAdditionalModalState(false));
            dispatch(createSignSuppliesData());
        });
    }, [dispatch]);

    return (
        <Modal open={modalIsOpen} hasCloser={true} onClose={handleCloseModal} size='l'>
            <Modal.Header hasCloser={true} />
            <Modal.Content>
                <Typography.Title view='medium' font='system' tag='div'>
                    Дополнительная информация
                </Typography.Title>
                <Gap size='2xl' />
                <Alert view='attention'>
                    <Typography.Text view='primary-small'>
                        Внимание! На финансирование будут отправлены только&nbsp;
                        {suppliesIds.length > SIGN_SUPPLIES_LIMIT_COUNT
                            ? `${SIGN_SUPPLIES_LIMIT_COUNT} поставок`
                            : 'поставки'}
                        &nbsp;в статусе &quot;Обработано&quot;, &quot;Подтверждено&quot;,
                        &quot;Ошибка&quot; с источником &quot;ЭДО&quot; или &quot;РПД&quot;.
                    </Typography.Text>
                    <Gap size='l' />
                    <Typography.Text view='primary-small'>
                        Поставки с источником &quot;Реестр&quot; или признаком&nbsp; &quot;Без
                        финансирования&quot; направляются с вкладки &quot;Реестры&quot;.
                    </Typography.Text>
                </Alert>
                {Boolean(activeErf.length) && (
                    <React.Fragment>
                        <Gap size='l' />
                        <Grid.Row justify='between'>
                            <Grid.Col width='5'>
                                <Typography.Text color='secondary'>Дебитор</Typography.Text>
                            </Grid.Col>
                            <Grid.Col width='available'>
                                <Typography.Text color='secondary'>Контракт</Typography.Text>
                            </Grid.Col>
                            <Grid.Col width='auto'>
                                <Typography.Text color='secondary'>ЭРФ</Typography.Text>
                            </Grid.Col>
                        </Grid.Row>
                        <Gap size='xs' />
                        <Divider />
                        {activeErf.map((erf) => (
                            <ItemOfActiveErfRow key={erf.id} item={erf} />
                        ))}
                    </React.Fragment>
                )}
                <Gap size='l' />
                <Typography.Text tag='p' view='primary-medium' weight='bold'>
                    Всего поставок: {suppliesIds.length}
                </Typography.Text>
                <Typography.Text tag='p' view='primary-medium' weight='bold'>
                    Общая сумма:
                    <Amount
                        view='withZeroMinorPart'
                        value={availableAmount * 100}
                        currency='RUR'
                        minority={100}
                        transparentMinor={true}
                    />
                </Typography.Text>
                <Gap size='3xl' />
                <Space size={12} direction='horizontal'>
                    <Button
                        disabled={Boolean(!suppliesIds.length)}
                        view='primary'
                        size='s'
                        onClick={handleCreateSignSuppliesData}
                    >
                        Продолжить
                    </Button>
                    <Button view='tertiary' size='s' onClick={handleCloseModal}>
                        Отменить
                    </Button>
                </Space>
            </Modal.Content>
        </Modal>
    );
}
