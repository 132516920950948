import React from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';
import Content from 'arui-private/core/content';
import CorporatePage from 'arui-private/core/corporate-page';
import ErrorPage from 'arui-private/core/error-page';
import Footer from 'arui-private/core/footer';
import Page from 'arui-private/core/page';

import { AppHeader } from '#/src/containers/app/app-header';
import NotificationManager from '#/src/containers/notification-manager';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { hasAvailableSegmentSelector } from '#/src/store/app/selectors';
import { hasAvailableOrganizationsByPageSelector } from '#/src/store/organizations/selectors';
import { pageTitleSelector, routeInfoSelector } from '#/src/store/router/selectors';
import { ViewPageType } from '#/src/types/pages';

import './app.css';

type AppProps = {
    viewPageType: ViewPageType;
    children: React.ReactNode;
};

const cn = createCn('app');

export default function App({ viewPageType, children }: AppProps) {
    const PageComponent = viewPageType === 'newCorporatePage' ? CorporatePage : Page;
    const pageTitle = useSelector(pageTitleSelector);
    const hasAvailableOrganizationsByPage = useSelector(hasAvailableOrganizationsByPageSelector);
    const hasAvailableSegment = useSelector(hasAvailableSegmentSelector);
    const routeInfo = useSelector(routeInfoSelector);
    const theme = viewPageType === 'newCorporatePage' ? undefined : 'alfa-on-white';

    if (
        !hasAvailableSegment ||
        (!hasAvailableOrganizationsByPage && !routeInfo?.availableWithoutOrganizations)
    ) {
        return (
            <ErrorPage
                data-test-id='error-page'
                errorCode={404}
                returnUrl={routesDictionary.authPage.path}
            />
        );
    }

    return (
        <PageComponent
            className={cn({ viewPageType })}
            header={<AppHeader viewPageType={viewPageType} />}
            footer={<Footer />}
        >
            {viewPageType === 'pageWithIframe' ? (
                <React.Fragment>{children}</React.Fragment>
            ) : (
                <Content
                    data-test-id='page-content'
                    theme={theme}
                    className={cn('content-container')}
                >
                    {pageTitle && (
                        <div className={cn('heading-page')}>
                            <Typography.Title
                                tag='div'
                                view='xlarge'
                                weight='bold'
                                defaultMargins={true}
                            >
                                {pageTitle}
                            </Typography.Title>
                        </div>
                    )}
                    {children}
                </Content>
            )}
            <NotificationManager />
        </PageComponent>
    );
}
