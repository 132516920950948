import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CalendarInput, CalendarInputProps } from '@alfalab/core-components/calendar-input';
import { CalendarRange, CalendarRangeProps } from '@alfalab/core-components/calendar-range';

import { updateReportFilters } from '#/src/store/reports-xls/report-filters/action-creators';
import { reportFiltersSelector } from '#/src/store/reports-xls/report-filters/selectors';
import { ReportNames } from '#/src/types/reports';

const useHandleChangeFilterDate = (key: 'dateFrom' | 'dateTo') => {
    const dispatch = useDispatch();

    return useCallback<
        NonNullable<CalendarInputProps['onChange'] | CalendarRangeProps['onDateFromChange']>
    >(
        (eventOrPayload, payload) => {
            dispatch(
                updateReportFilters({
                    [key]:
                        (
                            eventOrPayload as unknown as Parameters<
                                NonNullable<CalendarRangeProps['onDateFromChange']>
                            >[0]
                        )?.value ||
                        payload?.value ||
                        '',
                }),
            );
        },
        [dispatch, key],
    );
};

const inputDateToProps: CalendarInputProps = {
    label: 'Конец периода',
    preventFlip: true,
    popoverPosition: 'bottom-start',
};

type DatesFilterProps = {
    type: 'dateFrom' | 'dateTo' | 'dateFrom;dateTo';
    reportName: ReportNames;
};

const DatesFilter: FC<DatesFilterProps> = ({ type, reportName }) => {
    const { dateFrom, dateTo } = useSelector(reportFiltersSelector);
    const label = [
        ReportNames.DEBTOR_DEBT_DEBTOR_TO_SUPPLY,
        ReportNames.DEBTOR_TARIFFS_DEBTOR_FOR_SELLER,
    ].includes(reportName)
        ? 'На дату'
        : 'Дата поставки';

    const inputDateFromProps: CalendarInputProps = useMemo(
        () => ({
            label: type === 'dateFrom' ? label : 'Начало периода',
            preventFlip: true,
            popoverPosition: 'bottom-start',
        }),
        [label, type],
    );

    const handleChangeDateFrom = useHandleChangeFilterDate('dateFrom');
    const handleChangeDateTo = useHandleChangeFilterDate('dateTo');

    switch (type) {
        case 'dateFrom;dateTo':
            return (
                <CalendarRange
                    calendarPosition='popover'
                    valueFrom={dateFrom}
                    inputFromProps={inputDateFromProps}
                    valueTo={dateTo}
                    inputToProps={inputDateToProps}
                    onDateFromChange={
                        handleChangeDateFrom as CalendarRangeProps['onDateFromChange']
                    }
                    onDateToChange={handleChangeDateTo as CalendarRangeProps['onDateFromChange']}
                />
            );
        case 'dateFrom':
            return (
                <CalendarInput
                    block={true}
                    calendarPosition='popover'
                    value={dateFrom}
                    label={inputDateFromProps.label}
                    onChange={handleChangeDateFrom as CalendarInputProps['onChange']}
                />
            );
        case 'dateTo':
            return (
                <CalendarInput
                    block={true}
                    calendarPosition='popover'
                    value={dateTo}
                    label={inputDateToProps.label}
                    onChange={handleChangeDateTo as CalendarInputProps['onChange']}
                />
            );
    }

    return null;
};

export default DatesFilter;
