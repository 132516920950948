import * as actionCreators from './action-creators';
import { CellNames, TableCellsSettingsType } from './types';

export const defaultAvailableCells: CellNames[] = [
    'type',
    'amount',
    'date',
    'debtor',
    'contract',
    'supplySource',
    'status',
];

export type TableCellsSettings = {
    availableCells: CellNames[];
};

const initialState: TableCellsSettings = {
    availableCells: [...defaultAvailableCells],
};

export function tableCellsSettingsReducer(
    state: TableCellsSettings = initialState,
    action: TableCellsSettingsType,
): TableCellsSettings {
    switch (action.type) {
        case actionCreators.updateAvailableTableCells.type:
            return {
                ...state,
                availableCells: action.cells,
            };
        default:
            return state;
    }
}
