import { RegisterData } from '#/src/types/registers';

export function getSuccessModalText(
    result: Record<string, string> | null,
    registersIds: number[],
    register: RegisterData | undefined,
    isDebtor: boolean,
) {
    if (!result) return null;

    const success = Object.keys(result).filter((key) => result[key] === 'SUCCESS');

    if (register) {
        if (isDebtor) {
            return `Количество реестров, успешно отправленных на сверку: ${success.length}.
            Подробную информацию о неуспешно отправленных реестрах можно посмотреть в списке реестров`;
        }

        return `Количество реестров, успешно отправленных на финансирование: ${success.length}.
        Подробную информацию о неуспешно отправленных реестрах можно посмотреть в списке реестров.`;
    }
    if (success.length) {
        if (isDebtor) {
            return 'Реестр успешно отправлен на сверку';
        }

        return 'Реестр успешно отправлен на финансирование';
    }

    if (isDebtor) {
        return 'Реестр не отправлен на сверку. С причинами можно ознакомиться на странице реестра';
    }

    return 'Реестр не отправлен на финансирование. С причинами можно ознакомиться на странице реестра';
}
