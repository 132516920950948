import React, { useCallback, useState } from 'react';

import { Collapse } from '@alfalab/core-components/collapse';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownSIcon } from '@alfalab/icons-glyph/ChevronDownSIcon';
import { ChevronUpSIcon } from '@alfalab/icons-glyph/ChevronUpSIcon';

type CollapseToggleSectionProps = {
    linkTextCollapsed: React.ReactNode;
    linkTextExpanded: React.ReactNode;
    linkDataTestId?: string;
    children: React.ReactNode;
};

export function CollapseToggleSection({
    linkTextCollapsed,
    linkTextExpanded,
    linkDataTestId = 'collapse-toggle-section-link',
    children,
}: CollapseToggleSectionProps) {
    const [isExpanded, setExpanded] = useState(false);
    const handleExpandedToggle = useCallback((event?: React.MouseEvent) => {
        event?.preventDefault();
        setExpanded((prevState) => !prevState);
    }, []);

    return (
        <React.Fragment>
            <Typography.Text view='secondary-large' tag='div'>
                <Link
                    dataTestId={linkDataTestId}
                    onClick={handleExpandedToggle}
                    pseudo={true}
                    rightAddons={
                        isExpanded ? (
                            <ChevronUpSIcon width='16px' height='16px' />
                        ) : (
                            <ChevronDownSIcon width='16px' height='16px' />
                        )
                    }
                >
                    {isExpanded ? linkTextExpanded : linkTextCollapsed}
                </Link>
            </Typography.Text>
            <Collapse expanded={isExpanded}>{children}</Collapse>
        </React.Fragment>
    );
}
