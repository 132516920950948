import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import { statusByDebtorOptions, statusBySellerOptions } from '#/src/dictionaries/registers-status';
import { updateSelectedFilters } from '#/src/store/registers/registers-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';
import { RegisterStatusType } from '#/src/types/registers';

export function StatusFilter() {
    const dispatch = useDispatch();
    const { status, role } = useSelector(selectedFiltersSelector);
    const options = useMemo(() => {
        if (role === 'debtor') {
            return statusByDebtorOptions;
        }

        if (role === 'supplier') {
            return statusBySellerOptions;
        }

        return [];
    }, [role]);

    const handleChangeStatus = useCallback<NonNullable<SelectProps['onChange']>>(
        (payload) => {
            dispatch(
                updateSelectedFilters({
                    status: payload.selectedMultiple.map(({ key }) => key as RegisterStatusType),
                }),
            );
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='registers-filter-status-select'
            allowUnselect={true}
            multiple={true}
            block={true}
            placeholder='Статус'
            label='Статус'
            optionsListWidth='field'
            selected={status}
            options={options}
            onChange={handleChangeStatus}
        />
    );
}
