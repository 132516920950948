import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';
import { Typography } from '@alfalab/core-components/typography';

import {
    companiesIsErrorByTypeSelector,
    companiesIsLoadingByTypeSelector,
    companiesListByTypeSelector,
} from '#/src/store/companies/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { updateSelectedFilters } from '#/src/store/supplies/supplies-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/supplies/supplies-filters/selectors';

export function DebtorsTinFilter() {
    const dispatch = useDispatch();
    const { debtorsTin } = useSelector(selectedFiltersSelector);
    const debtors = useSelector((state: ApplicationState) =>
        companiesListByTypeSelector(state, 'debtors'),
    );
    const isLoading = useSelector((state: ApplicationState) =>
        companiesIsLoadingByTypeSelector(state, 'debtors'),
    );
    const isError = useSelector((state: ApplicationState) =>
        companiesIsErrorByTypeSelector(state, 'debtors'),
    );

    const options = useMemo(
        () =>
            debtors.map(({ tin, name }) => ({
                key: tin,
                value: name,
                content: (
                    <React.Fragment>
                        <Typography.Text view='secondary-large' tag='div'>
                            {name}
                        </Typography.Text>
                        <Typography.Text view='secondary-large' color='secondary' tag='div'>
                            ИНН {tin}
                        </Typography.Text>
                    </React.Fragment>
                ),
            })),
        [debtors],
    );

    const valueRenderer = useCallback<NonNullable<SelectProps['valueRenderer']>>(
        ({ selectedMultiple }) =>
            selectedMultiple.length
                ? selectedMultiple.map((opt, index) =>
                      index ? `, ${opt.value || opt.key}` : opt.value || opt.key,
                  )
                : null,
        [],
    );

    const handleChangeDebtorsTin = useCallback<NonNullable<SelectProps['onChange']>>(
        ({ selectedMultiple }) => {
            dispatch(updateSelectedFilters({ debtorsTin: selectedMultiple.map(({ key }) => key) }));
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='debtors-tin-select'
            allowUnselect={true}
            multiple={true}
            block={true}
            placeholder='Дебитор'
            label='Дебитор'
            optionsListWidth='field'
            selected={debtorsTin}
            valueRenderer={valueRenderer}
            options={options}
            disabled={isLoading || isError}
            onChange={handleChangeDebtorsTin}
        />
    );
}
