import { batch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { NotificationsActionsType } from '#/src/store/notifications/types';
import { ApplicationState } from '#/src/store/reducer';
import { resetAllFilters } from '#/src/store/registers/register-info-filters/action-creators';
import { RegisterInfoFiltersActionsType } from '#/src/store/registers/register-info-filters/types';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { setRegisterInfo } from './action-creators';
import { RegisterInfoActionsType } from './types';

export function fetchRegisterInfo(
    registerId: number,
    partyTin: string,
): ThunkAction<
    void,
    ApplicationState,
    void,
    RegisterInfoActionsType | RegisterInfoFiltersActionsType | NotificationsActionsType
> {
    return async (dispatch) => {
        dispatch(actionCreators.changeRegisterInfoLoadingState(LOADING_STATE.LOADING));

        try {
            const { currencyCounts, registerData } = await fetchers.fetchRegisterInfo({
                registerId,
                partyTin,
            });

            const { registerRows = [], ...registerInfo } = registerData || {};

            batch(() => {
                dispatch(resetAllFilters());
                dispatch(
                    setRegisterInfo({
                        id: registerId,
                        entities: registerRows,
                        info: 'id' in registerInfo ? registerInfo : null,
                        count: registerRows.length,
                        currencyCounts: currencyCounts || [],
                    }),
                );
            });
        } catch (e) {
            dispatch(
                dispatchErrorNotification(
                    `Реестр с id = ${registerId} не доступен для детального просмотра`,
                ),
            );
            dispatch(actionCreators.changeRegisterInfoLoadingState(LOADING_STATE.ERROR));
        }
    };
}

export function deleteRegister(
    registerId: number,
    partyTin: string,
    deleted: boolean,
): ThunkAction<
    void,
    ApplicationState,
    void,
    RegisterInfoActionsType | RegisterInfoFiltersActionsType | NotificationsActionsType
> {
    return async (dispatch) => {
        dispatch(actionCreators.changeRegisterInfoLoadingState(LOADING_STATE.LOADING));

        try {
            await fetchers.fetchDeleteRegister({ id: registerId, deleted });

            dispatch(fetchRegisterInfo(registerId, partyTin));
        } catch (e) {
            dispatch(dispatchErrorNotification('Не удалось удалить реестр'));
            dispatch(actionCreators.changeRegisterInfoLoadingState(LOADING_STATE.SUCCESS));
        }
    };
}
