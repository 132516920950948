import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '#/src/store/reducer';
import { fetchers } from '#/src/utils/client-api';

import * as actionCreators from './action-creators';
import { SupplyCorrectionHistoryActionsType } from './types';

export function fetchSupplyCorrectionHistory(
    supplyId: number,
): ThunkAction<void, ApplicationState, void, SupplyCorrectionHistoryActionsType> {
    return async (dispatch) => {
        dispatch(actionCreators.getSupplyCorrectionHistoryStart(supplyId));
        try {
            const response = await fetchers.fetchSupplyCorrectionHistory(supplyId);

            dispatch(actionCreators.getSupplyCorrectionHistorySuccess(supplyId, response));
        } catch (e) {
            dispatch(actionCreators.getSupplyCorrectionHistoryError(supplyId));
        }
    };
}
