import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CalendarRange, CalendarRangeProps } from '@alfalab/core-components/calendar-range';

import {
    inputDateFromProps,
    inputDateToProps,
} from '#/src/containers/pages/registers-pages/registers-list-page/registers-list-filters/dates-filter';
import { updateSelectedFilters } from '#/src/store/registers/register-info-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/register-info-filters/selectors';

const useHandleChangeFilterDate = (key: 'dateFrom' | 'dateTo') => {
    const dispatch = useDispatch();

    return useCallback<NonNullable<CalendarRangeProps['onDateFromChange']>>(
        (payload) => {
            dispatch(updateSelectedFilters({ [key]: payload?.value || '' }));
        },
        [dispatch, key],
    );
};

export function DatesFilter() {
    const { dateFrom, dateTo } = useSelector(selectedFiltersSelector);

    const handleChangeDateFrom = useHandleChangeFilterDate('dateFrom');
    const handleChangeDateTo = useHandleChangeFilterDate('dateTo');

    return (
        <CalendarRange
            calendarPosition='popover'
            valueFrom={dateFrom}
            inputFromProps={inputDateFromProps}
            valueTo={dateTo}
            inputToProps={inputDateToProps}
            onDateFromChange={handleChangeDateFrom as CalendarRangeProps['onDateFromChange']}
            onDateToChange={handleChangeDateTo as CalendarRangeProps['onDateFromChange']}
        />
    );
}
