import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Spinner } from '@alfalab/core-components/spinner';

import { ApplicationState } from '#/src/store/reducer';
import { fetchSupplyRepayments } from '#/src/store/supply/supply-repayments/actions';
import {
    supplyRepaymentsIsLoadingSelector,
    supplyRepaymentsSelector,
} from '#/src/store/supply/supply-repayments/selectors';

import SupplyRepaymentsList from './supply-repayments-list';

const cn = createCn('supply-certificates');

type SupplyPaymentsProps = {
    supplyId: number;
};

export function SupplyPayments({ supplyId }: SupplyPaymentsProps) {
    const dispatch = useDispatch();
    const payments = useSelector((state: ApplicationState) =>
        supplyRepaymentsSelector(state, supplyId),
    );
    const isLoading = useSelector((state: ApplicationState) =>
        supplyRepaymentsIsLoadingSelector(state, supplyId),
    );

    useEffect(() => {
        dispatch(fetchSupplyRepayments(supplyId));
    }, [dispatch, supplyId]);

    return (
        <div className={cn()}>
            {isLoading && <Spinner size='s' visible={true} />}
            {!isLoading && !!payments.length && <SupplyRepaymentsList repayments={payments} />}
        </div>
    );
}
