import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/store/reducer';

import { reportsXlsSelector } from '../selectors';

export const debtorsStateSelector = (state: ApplicationState) => reportsXlsSelector(state).debtors;

export const debtorsSelector = (state: ApplicationState) => debtorsStateSelector(state).data;

export const debtorOptionsSelector = createSelector(debtorsSelector, (debtors) =>
    debtors
        ? Object.keys(debtors).map((value) => ({ key: value, value, content: debtors[+value] }))
        : [],
);

export const debtorsLoadingStateSelector = createSelector(
    debtorsStateSelector,
    (state) => state.loadingState,
);
