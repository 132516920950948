import { ThunkAction } from 'redux-thunk';

import { authErrorDictionary, AuthErrorNames } from '#/src/dictionaries/auth-errors';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';
import { ApplicationState } from '#/src/store/reducer';
import { fetchActualSystemNotifications } from '#/src/store/system-notify/actions';
import { notifyModalIsOpenSelector } from '#/src/store/system-notify/selectors';
import { NavigationTab } from '#/src/types/navigations';
import { fetchers } from '#/src/utils/client-api';
import navigationsMap from '#/src/utils/navigations-map';
import { parseErrorMessage } from '#/src/utils/parse-error-message';

import * as actionCreators from './action-creators';
import { AuthActionsType } from './types';

export function logIn(
    login: string,
    password: string,
): ThunkAction<void, ApplicationState, void, AuthActionsType> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.authStart());

        try {
            const authResult = await fetchers.fetchLogin({ login, password });

            if (authResult === 'PASSWORD_EXPIRED') {
                dispatch(actionCreators.authSuccess());
                window.location.assign(routesDictionary.changePassword.path);
            }

            await dispatch(fetchActualSystemNotifications());

            const notifyModalIsOpen = notifyModalIsOpenSelector(getState());

            if (notifyModalIsOpen) {
                dispatch(actionCreators.authSuccess());

                return;
            }

            await dispatch(redirectUserAfterAuthFlow());
            dispatch(actionCreators.authSuccess());
        } catch (e) {
            const error = parseErrorMessage(e);

            dispatch(
                actionCreators.authError(
                    authErrorDictionary[error as AuthErrorNames] ||
                        authErrorDictionary.UNKNOWN_ERROR,
                ),
            );
        }
    };
}

export function redirectUserAfterAuthFlow(): ThunkAction<
    void,
    ApplicationState,
    void,
    AuthActionsType
> {
    return async (dispatch) => {
        try {
            const navigations: NavigationTab[] = navigationsMap(await fetchers.fetchNavigations());

            if (navigations.length) {
                window.location.assign(navigations[0].url);
            } else {
                dispatch(actionCreators.setUserWithoutPermissionsModalState(true));
            }
        } catch (e) {
            window.location.assign('/secure/int/frameset');
        }
    };
}

export function logOut(): ThunkAction<void, ApplicationState, void, AuthActionsType> {
    return async () => {
        try {
            await fetchers.fetchLogout();
        } finally {
            window.location.assign(routesDictionary.authPage.path);
        }
    };
}
