import React from 'react';

import { Badge } from '@alfalab/core-components/badge';
import { Plate } from '@alfalab/core-components/plate';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';
import { InformationCircleMIcon } from '@alfalab/icons-glyph/InformationCircleMIcon';

import { SystemNotification, SystemNotificationType } from '#/src/types/communication';

type SystemNotificationPlateProps = {
    notification: SystemNotification;
};

export default function SystemNotificationPlate({ notification }: SystemNotificationPlateProps) {
    const isJobNotification = notification.notifyType === SystemNotificationType.JOB_NOTIFICATION;
    const icon = isJobNotification ? (
        <Badge view='icon' iconColor='attention' content={<AlertCircleMIcon />} />
    ) : (
        <Badge view='icon' iconColor='secondary' content={<InformationCircleMIcon />} />
    );

    return (
        <Plate
            view={isJobNotification ? 'attention' : 'common'}
            title={notification.title}
            leftAddons={icon}
        >
            {notification.message}
        </Plate>
    );
}
