import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import { SuppliesListState } from '#/src/store/supplies/supplies-list/reducer';
import createAction from '#/src/utils/create-action';

export const changeSuppliesListLoadingState = createAction(
    'SUPPLIES / LIST / changeSuppliesListLoadingState',
    (loadingState: LoadingState) => ({ loadingState }),
);

type GetSuppliesParams = Partial<{
    page: number;
    pageSize: number;
    needMergeSelected: boolean;
    reloadPage: boolean;
}>;

export const getSupplies = createAction(
    'SUPPLIES / LIST / getSupplies',
    ({
        page = 1,
        pageSize = 20,
        reloadPage = false,
        needMergeSelected = false,
    }: GetSuppliesParams) => ({ page, pageSize, needMergeSelected, reloadPage }),
);

export const setSupplies = createAction(
    'SUPPLIES / LIST / setSupplies',
    (data: Partial<SuppliesListState>) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const getSuppliesByPage = createAction(
    'SUPPLIES / LIST / getSuppliesByPage',
    ({ page = 1, pageSize = 20 }: { page: number; pageSize: number }) => ({ page, pageSize }),
);

export const reloadSuppliesPage = createAction('SUPPLIES / LIST / reloadSuppliesPage', () => ({}));

export const clearSupplies = createAction('SUPPLIES / LIST / clearSupplies', () => ({}));

export const setSuppliesList = createAction(
    'SUPPLIES / LIST / setSuppliesList',
    (data: Partial<SuppliesListState>) => ({ data, loadingState: LOADING_STATE.SUCCESS }),
);

export const toggleSelectedSupplies = createAction(
    'SUPPLIES / LIST / toggleSelectedSupplies',
    () => ({}),
);

export const setSelectedSupplies = createAction(
    'SUPPLIES / LIST / setSelectedSupplies',
    (selectedIds: number[]) => ({ selectedIds }),
);

export const setSort = createAction(
    'SUPPLIES / LIST / setSort',
    (data: SuppliesListState['sort']) => ({ data }),
);

export const setNeedUpdate = createAction(
    'SUPPLIES / LIST / setNeedUpdate',
    (needUpdate: boolean) => ({ needUpdate }),
);

export const needUpdateToggle = createAction(
    'SUPPLIES / LIST / needUpdateToggle',
    (needUpdate: boolean) => ({ needUpdate }),
);
