import { RegisterData } from '#/src/types/registers';

export function getAdditionalInfoText(isDebtor: boolean, register?: RegisterData) {
    if (register) {
        if (isDebtor) {
            return 'Будут отправлены на сверку только поставки в статусе "Обработана" и "Ошибка".';
        }

        return 'На финансирование будут отправлены только поставки в статусе "Обработана" и "Ошибка".';
    }
    if (isDebtor) {
        return 'Будут отправлены только реестры в статусе "Обработан", "Частично обработан" и "Ошибка".';
    }

    return 'На финансирование будут отправлены только реестры в статусе "Обработан", "Частично обработан" и "Ошибка".';
}
