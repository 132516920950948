import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Switch } from '@alfalab/core-components/switch';

import {
    cellNamesDictionary,
    disabledCells,
} from '#/src/dictionaries/available-supplies-table-cells';
import { updateCellState } from '#/src/store/supplies/table-cells-settings/actions';
import { availableCellsSelector } from '#/src/store/supplies/table-cells-settings/selectors';
import { CellNames } from '#/src/store/supplies/table-cells-settings/types';

type SwitchColumnItemProps = {
    cellName: CellNames;
};
export function SwitchColumnItem({ cellName }: SwitchColumnItemProps) {
    const dispatch = useDispatch();
    const availableCells = useSelector(availableCellsSelector);
    const handleChangeCellState = useCallback(() => {
        dispatch(updateCellState(cellName));
    }, [dispatch, cellName]);

    return (
        <Switch
            reversed={true}
            checked={availableCells.includes(cellName)}
            disabled={disabledCells.includes(cellName)}
            label={cellNamesDictionary[cellName]}
            onChange={handleChangeCellState}
        />
    );
}
