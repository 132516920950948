import React, { useCallback } from 'react';
import { createCn } from 'bem-react-classname';

import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { ExternalPage } from '#/src/components/external-page';
import FormButton from '#/src/components/form-button';
import { routesDictionary } from '#/src/dictionaries/routes-dictionary';

import './restore-login-page.css';

const cn = createCn('restore-login-page');

export default function RestoreLoginPage() {
    const handleMoveToAuthPage = useCallback(() => {
        window.location.assign(routesDictionary.authPage.path);
    }, []);

    return (
        <ExternalPage title='Восстановление логина'>
            <div className={cn()}>
                <div className={cn('text')}>
                    <Typography.Text view='primary-medium' tag='p' dataTestId='restore-login-text'>
                        Вы можете восстановить ваш логин, позвонив в службу поддержки
                        <br />
                        по телефону <Link href='tel:74957555858'>+7 495 755 58 58</Link> или на
                        почту{' '}
                        <Link href='mailto:alfafinance@alfabank.ru'>alfafinance@alfabank.ru</Link>.
                    </Typography.Text>
                </div>
                <FormButton
                    form='restoreLogin'
                    text='Вернуться назад'
                    onClick={handleMoveToAuthPage}
                    view='tertiary'
                />
            </div>
        </ExternalPage>
    );
}
