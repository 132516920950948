import { LOADING_STATE } from '#/src/constants/loading-state';
import createAction from '#/src/utils/create-action';

export const authStart = createAction('AUTH / authStart', () => ({
    loadingState: LOADING_STATE.LOADING,
}));

export const authSuccess = createAction('AUTH / authSuccess', () => ({
    loadingState: LOADING_STATE.SUCCESS,
}));

export const authError = createAction('AUTH / authError', (error: string) => ({
    error,
    loadingState: LOADING_STATE.ERROR,
}));

export const setUserWithoutPermissionsModalState = createAction(
    'AUTH / setUserWithoutPermissionsModalState',
    (isOpen: boolean) => ({ isOpen }),
);
