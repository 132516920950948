import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RadioGroup, RadioGroupProps } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';

import {
    PeriodFilter as PeriodFilterKeys,
    periodFilterDictionary,
} from '#/src/dictionaries/reports-dashboard';
import { companiesIsLoadingByTypeSelector } from '#/src/store/companies/selectors';
import { dashboardChartsIsLoadingSelector } from '#/src/store/dashboard/dashboard-charts/selectors';
import { updateFilters } from '#/src/store/dashboard/dashboard-filters/action-creators';
import { filtersSelector } from '#/src/store/dashboard/dashboard-filters/selectors';
import { ApplicationState } from '#/src/store/reducer';
import getDateForDashboardByPeriod from '#/src/utils/get-date-for-dashboard-by-period';

export function PeriodFilter() {
    const dispatch = useDispatch();
    const { period } = useSelector(filtersSelector);
    const debtorsIsLoading = useSelector((state: ApplicationState) =>
        companiesIsLoadingByTypeSelector(state, 'debtors'),
    );
    const isLoading = useSelector(dashboardChartsIsLoadingSelector);

    const handleChangePeriod = useCallback<NonNullable<RadioGroupProps['onChange']>>(
        (event, { value }) => {
            const { dateFrom, dateTo } = getDateForDashboardByPeriod(value as PeriodFilterKeys);

            dispatch(
                updateFilters({ dateFrom, dateTo, period: value as PeriodFilterKeys, stepDate: 0 }),
            );
        },
        [dispatch],
    );

    return (
        <RadioGroup
            value={period}
            direction='horizontal'
            type='tag'
            label='Период'
            disabled={isLoading || debtorsIsLoading}
            onChange={handleChangePeriod}
        >
            <Tag value={PeriodFilterKeys.WEEK} size='xxs'>
                {periodFilterDictionary.WEEK}
            </Tag>
            <Tag value={PeriodFilterKeys.QUARTER} size='xxs'>
                {periodFilterDictionary.QUARTER}
            </Tag>
            <Tag value={PeriodFilterKeys.YEAR} size='xxs'>
                {periodFilterDictionary.YEAR}
            </Tag>
        </RadioGroup>
    );
}
