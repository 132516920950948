import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PickerButton, PickerButtonProps } from '@alfalab/core-components/picker-button';
import { ArrowDownBoldContainerMIcon } from '@alfalab/icons-glyph/ArrowDownBoldContainerMIcon';

import {
    DOWNLOAD_LIMIT_COUNT,
    DOWNLOAD_LIMIT_COUNT_FOR_REPORT,
    OptionKey,
    optionsForDownloadDocuments,
} from '#/src/constants/registers';
import {
    downloadDocumentsForRegisters,
    downloadReportByRegisters,
} from '#/src/store/documents/actions';
import { documentsIsLoadingSelector } from '#/src/store/documents/selectors';
import { FileType } from '#/src/types/documents';

import DownloadRegistersLimitModal from './download-registers-limit-modal';
import DownloadRegistersResultModal from './download-registers-result-modal';

type DownloadRegistersButtonProps = {
    ids: number[];
    inaccessibleOptionKeys?: OptionKey[];
};

export default function DownloadRegistersButton({
    ids,
    inaccessibleOptionKeys,
}: DownloadRegistersButtonProps) {
    const [limitModalIsOpen, setLimitModalState] = useState({
        isOpen: false,
        limit: DOWNLOAD_LIMIT_COUNT,
    });
    const dispatch = useDispatch();
    const isLoading = useSelector(documentsIsLoadingSelector);

    const handleClosLimitModal = useCallback(
        () => setLimitModalState({ isOpen: false, limit: DOWNLOAD_LIMIT_COUNT }),
        [],
    );

    const handleDownload = useCallback<NonNullable<PickerButtonProps['onChange']>>(
        (payload) => {
            if (payload.selected?.key === 'REPORT') {
                if (ids.length > DOWNLOAD_LIMIT_COUNT_FOR_REPORT) {
                    setLimitModalState({ isOpen: true, limit: DOWNLOAD_LIMIT_COUNT_FOR_REPORT });
                } else {
                    dispatch(downloadReportByRegisters(ids));
                }
            } else if (ids.length > DOWNLOAD_LIMIT_COUNT) {
                setLimitModalState({ isOpen: true, limit: DOWNLOAD_LIMIT_COUNT });
            } else {
                dispatch(
                    downloadDocumentsForRegisters(
                        ids,
                        payload.selected?.key === 'SOURCE_FILE'
                            ? [FileType.REGISTER_SOURCE_FILE]
                            : [],
                    ),
                );
            }
        },
        [dispatch, ids],
    );

    const options = useMemo(() => {
        if (inaccessibleOptionKeys) {
            return optionsForDownloadDocuments.filter(
                ({ key }) => !inaccessibleOptionKeys.includes(key),
            );
        }

        return optionsForDownloadDocuments;
    }, [inaccessibleOptionKeys]);

    return (
        <React.Fragment>
            <PickerButton
                dataTestId='download-reg-files-button'
                label='Скачать'
                leftAddons={<ArrowDownBoldContainerMIcon width='18px' height='18px' />}
                view='tertiary'
                size='xxs'
                options={options}
                disabled={isLoading}
                onChange={handleDownload}
            />
            <DownloadRegistersResultModal />
            <DownloadRegistersLimitModal
                open={limitModalIsOpen.isOpen}
                onClose={handleClosLimitModal}
                limit={limitModalIsOpen.limit}
            />
        </React.Fragment>
    );
}
