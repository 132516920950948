import { LOADING_STATE, LoadingState } from '#/src/constants/loading-state';
import { RegisterInfoState } from '#/src/store/registers/register-info/reducer';
import createAction from '#/src/utils/create-action';

export const changeRegisterInfoLoadingState = createAction(
    'REGISTERS / INFO / changeRegisterInfoLoadingState',
    (loadingState: LoadingState) => ({ loadingState }),
);

export const setRegisterInfo = createAction(
    'REGISTERS / INFO / setRegisterInfo',
    (data: Pick<RegisterInfoState, 'id' | 'entities' | 'info' | 'count' | 'currencyCounts'>) => ({
        data,
        loadingState: LOADING_STATE.SUCCESS,
    }),
);

export const clearRegisterInfo = createAction('REGISTERS / INFO / clearRegisterInfo', () => ({}));

export const setSort = createAction(
    'REGISTERS / INFO / setSort',
    (data: RegisterInfoState['sort']) => ({ data }),
);

export const setPagination = createAction(
    'REGISTERS / INFO / setPagination',
    (data: RegisterInfoState['pagination']) => ({ data }),
);

export const setSelectedRowId = createAction(
    'REGISTERS / INFO / setSelectedRowId',
    (id: number | null) => ({ id }),
);
