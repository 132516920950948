import createAction from '#/src/utils/create-action';

import { FiltersState, SuppliesFiltersState } from './reducer';

export const updateSelectedFilters = createAction(
    'SUPPLIES / FILTERS / updateSelectedFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const updateAppliedFilters = createAction(
    'SUPPLIES / FILTERS / updateAppliedFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const updateAllFilters = createAction(
    'SUPPLIES / FILTERS / updateAllFilters',
    (filters: Partial<FiltersState>) => ({ filters }),
);

export const updateErrorsFilters = createAction(
    'SUPPLIES / FILTERS / updateErrorsFilters',
    (errors: Partial<SuppliesFiltersState['errors']>) => ({ errors }),
);
