import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { AmountInput, AmountInputProps } from '@alfalab/core-components/amount-input';

import {
    setFiltersErrors,
    updateSelectedFilters,
} from '#/src/store/registers/register-info-filters/action-creators';
import {
    filtersErrorsSelector,
    selectedFiltersSelector,
} from '#/src/store/registers/register-info-filters/selectors';

import './amount-range-filter.css';

const cn = createCn('amount-range-filter');

export function AmountRangeFilter() {
    const dispatch = useDispatch();
    const { amountFrom, amountTo } = useSelector(selectedFiltersSelector);
    const errors = useSelector(filtersErrorsSelector);

    const handleAmountFromChange = useCallback<NonNullable<AmountInputProps['onChange']>>(
        (_, payload) => {
            const amount = payload.valueString;
            const amountFilter = { amountFrom: amount.replace(/\s+/g, '') };
            const amountError =
                String(amount).replace(/\s+/g, '') && amountTo
                    ? +String(amount).replace(/\s+/g, '').replace(/,/, '.') >
                      +String(amountTo || '')
                          .replace(/\s+/g, '')
                          .replace(/,/, '.')
                    : false;

            batch(() => {
                dispatch(updateSelectedFilters(amountFilter));
                dispatch(
                    setFiltersErrors({
                        ...errors,
                        amountError: amountError
                            ? '"Сумма от" должна быть меньше "Суммы до"'
                            : null,
                    }),
                );
            });
        },
        [dispatch, amountTo, errors],
    );

    const handleAmountToChange = useCallback<NonNullable<AmountInputProps['onChange']>>(
        (_, payload) => {
            const amount = payload.valueString;
            const amountFilter = { amountTo: amount.replace(/\s+/g, '') };
            const amountError =
                String(amount).replace(/\s+/g, '') && amountFrom
                    ? +String(amount).replace(/\s+/g, '').replace(/,/, '.') <
                      +String(amountFrom || '')
                          .replace(/\s+/g, '')
                          .replace(/,/, '.')
                    : false;

            batch(() => {
                dispatch(updateSelectedFilters(amountFilter));
                dispatch(
                    setFiltersErrors({
                        ...errors,
                        amountError: amountError
                            ? '"Сумма от" должна быть меньше "Суммы до"'
                            : null,
                    }),
                );
            });
        },
        [dispatch, amountFrom, errors],
    );

    return (
        <React.Fragment>
            <div className={cn()}>
                <div className={cn('item')}>
                    <AmountInput
                        block={true}
                        name='input_amountFrom'
                        integerLength={20}
                        integersOnly={true}
                        label='Сумма от'
                        suffix=''
                        bold={false}
                        minority={100}
                        value={+amountFrom * 100}
                        onChange={handleAmountFromChange}
                        className={cn('input')}
                        error={Boolean(errors.amountError)}
                    />
                </div>
                <span className={cn('divider')} />
                <div className={cn('item')}>
                    <AmountInput
                        block={true}
                        name='input_amountTo'
                        integerLength={20}
                        integersOnly={true}
                        label='Сумма до'
                        suffix=''
                        minority={100}
                        value={+amountTo * 100}
                        bold={false}
                        onChange={handleAmountToChange}
                        className={cn('input')}
                        error={Boolean(errors.amountError)}
                    />
                </div>
            </div>
            {!!errors.amountError && <div className={cn('error')}>{errors.amountError}</div>}
        </React.Fragment>
    );
}
