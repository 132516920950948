import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';

import { CalendarRange, CalendarRangeProps } from '@alfalab/core-components/calendar-range';
import { Typography } from '@alfalab/core-components/typography';
import { parse } from 'arui-feather/lib/date-utils';

import {
    updateErrorsFilters,
    updateSelectedFilters,
} from '#/src/store/supplies/supplies-filters/action-creators';
import {
    errorsFiltersSelector,
    selectedFiltersSelector,
} from '#/src/store/supplies/supplies-filters/selectors';
import { isValidDate } from '#/src/utils/date-converter';

const mainInputProps: CalendarRangeProps['inputToProps'] = {
    block: true,
    preventFlip: true,
    popoverPosition: 'bottom-start',
};

export function CalendarRangeFilter() {
    const dispatch = useDispatch();
    const { dateTo, dateFrom } = useSelector(selectedFiltersSelector);
    const errors = useSelector(errorsFiltersSelector);

    const handleChangeDateFrom = useCallback<NonNullable<CalendarRangeProps['onDateFromChange']>>(
        (payload) => {
            if (payload.value) {
                const parsedDate = parse(payload.value);

                if (isValidDate(parsedDate)) {
                    const parsedDateTo = parse(dateTo);

                    if (
                        isValidDate(parsedDateTo) &&
                        payload.value !== dateTo &&
                        !isBefore(parsedDate, parsedDateTo)
                    ) {
                        dispatch(
                            updateErrorsFilters({
                                dateFrom: "Дата 'Период с' не должна быть больше даты 'Период до'",
                            }),
                        );
                    } else if (errors.dateFrom || errors.dateTo) {
                        const updatedErrors = { dateFrom: null, dateTo: errors.dateTo };

                        if (errors.dateTo && dateTo && isValidDate(parsedDateTo)) {
                            updatedErrors.dateTo = null;
                        }
                        dispatch(updateErrorsFilters(updatedErrors));
                    }
                } else {
                    dispatch(updateErrorsFilters({ dateFrom: 'Неверный формат даты' }));
                }
            } else {
                dispatch(updateErrorsFilters({ dateFrom: 'Обязательно для заполнения' }));
            }

            dispatch(updateSelectedFilters({ dateFrom: payload.value }));
        },
        [dispatch, errors, dateTo],
    );

    const handleChangeDateTo = useCallback<NonNullable<CalendarRangeProps['onDateToChange']>>(
        (payload) => {
            if (payload.value) {
                const parsedDate = parse(payload.value);

                if (isValidDate(parsedDate)) {
                    const parsedDateFrom = parse(dateFrom);

                    if (
                        isValidDate(parsedDateFrom) &&
                        payload.value !== dateFrom &&
                        !isAfter(parsedDate, parsedDateFrom)
                    ) {
                        dispatch(
                            updateErrorsFilters({
                                dateFrom: "Дата 'Период до' не должна быть меньше даты 'Период с'",
                            }),
                        );
                    } else if (errors.dateFrom || errors.dateTo) {
                        const updatedErrors = { dateTo: null, dateFrom: errors.dateTo };

                        if (errors.dateFrom && dateFrom && isValidDate(parsedDateFrom)) {
                            updatedErrors.dateFrom = null;
                        }
                        dispatch(updateErrorsFilters(updatedErrors));
                    }
                } else {
                    dispatch(updateErrorsFilters({ dateFrom: 'Неверный формат даты' }));
                }
            } else {
                dispatch(updateErrorsFilters({ dateFrom: 'Обязательно для заполнения' }));
            }

            dispatch(updateSelectedFilters({ dateTo: payload.value }));
        },
        [dispatch, errors, dateFrom],
    );

    return (
        <CalendarRange
            dataTestId='calendar-range-filter'
            calendarPosition='popover'
            valueFrom={dateFrom}
            inputFromProps={{
                ...mainInputProps,
                dataTestId: 'date-from-filter',
                label: 'Период с',
                error: errors.dateFrom,
                hint: errors.dateFrom ? (
                    <Typography.Text color='accent' view='primary-small'>
                        {errors.dateFrom}
                    </Typography.Text>
                ) : undefined,
            }}
            valueTo={dateTo}
            inputToProps={{
                ...mainInputProps,
                dataTestId: 'date-to-filter',
                label: 'Период до',
                error: errors.dateTo,
                hint: errors.dateTo ? (
                    <Typography.Text color='accent' view='primary-small'>
                        {errors.dateTo}
                    </Typography.Text>
                ) : undefined,
            }}
            onDateFromChange={handleChangeDateFrom}
            onDateToChange={handleChangeDateTo}
        />
    );
}
