import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';
import { CategoryEducationMBlackIcon } from '@alfalab/icons-classic/CategoryEducationMBlackIcon';

import { DownloadFileLink } from '#/src/components/download-file-link';

import './external-page-footer-manual-link.css';

const cn = createCn('external-page-footer-manual-link');

export default function ExternalPageFooterManualLink() {
    return (
        <DownloadFileLink
            underline={false}
            dataTestId='user-manual-link'
            fileType='USER_MANUAL'
            fileName='Альфа-Финанс (Факторинг)_Руководство пользователя.pdf'
            spinnerSize='m'
        >
            <div className={cn()}>
                <div className={cn('icon')}>
                    <CategoryEducationMBlackIcon />
                </div>
                <div className={cn('text')}>
                    <Typography.Text tag='div' view='primary-medium'>
                        Документация
                    </Typography.Text>
                    <Typography.Text tag='div' view='primary-medium' color='secondary'>
                        Справочная информация
                    </Typography.Text>
                </div>
            </div>
        </DownloadFileLink>
    );
}
