import { batch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { DashboardTabs } from '#/src/dictionaries/reports-dashboard';
import { filtersSelector } from '#/src/store/dashboard/dashboard-filters/selectors';
import { dispatchErrorNotification } from '#/src/store/notifications/actions';
import { NotificationsActionsType } from '#/src/store/notifications/types';
import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { ApplicationState } from '#/src/store/reducer';
import { GetDashboardQuery } from '#/src/types/dashboard-reports';
import { fetchers } from '#/src/utils/client-api';
import { formatDateForCalendar } from '#/src/utils/date-converter';

import * as actionCreators from './action-creators';
import { selectedTabSelector } from './selectors';
import { DashboardChartsActionsType } from './types';

export function fetchDashboardCharts(): ThunkAction<
    void,
    ApplicationState,
    void,
    DashboardChartsActionsType | NotificationsActionsType
> {
    return async (dispatch, getState) => {
        dispatch(actionCreators.changeDashboardChartsLoadingState(LOADING_STATE.LOADING));

        try {
            const state = getState();
            const { period, dateFrom, debtorTin } = filtersSelector(state);
            const selectedTab = selectedTabSelector(state);
            const currentOrganizationId = currentOrganizationIdSelector(state) as string;

            const requestParams: GetDashboardQuery = {
                period,
                sellerTin: currentOrganizationId,
                dateFrom: formatDateForCalendar(new Date(dateFrom)),
                debtorTin,
            };

            if (selectedTab === DashboardTabs.RECEIVABLES) {
                const { receivables, ...info } = await fetchers.fetchReceivables(requestParams);

                dispatch(
                    actionCreators.setDashboardCharts({
                        receivables: {
                            list: receivables,
                            info,
                        },
                    }),
                );
            } else if (selectedTab === DashboardTabs.COMMISSIONS) {
                const { commissions, ...info } = await fetchers.fetchCommissions(requestParams);

                dispatch(
                    actionCreators.setDashboardCharts({
                        commissions: {
                            list: commissions,
                            info,
                        },
                    }),
                );
            } else if (selectedTab === DashboardTabs.PAYMENTS) {
                const { payments, ...info } = await fetchers.fetchPayments(requestParams);

                dispatch(
                    actionCreators.setDashboardCharts({
                        payments: {
                            list: payments,
                            info,
                        },
                    }),
                );
            } else if (selectedTab === DashboardTabs.REFUNDS) {
                const { refunds, ...info } = await fetchers.fetchRefunds(requestParams);

                dispatch(
                    actionCreators.setDashboardCharts({
                        refunds: {
                            list: refunds,
                            info,
                        },
                    }),
                );
            }
        } catch (error) {
            batch(() => {
                dispatch(dispatchErrorNotification(error));
                dispatch(actionCreators.changeDashboardChartsLoadingState(LOADING_STATE.ERROR));
                dispatch(actionCreators.clearCharts());
            });
        }
    };
}
