import { FormField, FormType } from './types';

export function getFormFields(form: FormType): FormField[] {
    switch (form) {
        case 'auth':
            return [
                { name: 'username', label: 'Логин' },
                { name: 'password', label: 'Пароль', hasHiddenValue: true },
            ];
        case 'restorePassword':
            return [
                { name: 'username', label: 'Логин' },
                { name: 'keyword', label: 'Кодовое слово', hasHiddenValue: true },
            ];
        case 'changePassword':
            return [
                { name: 'password', label: 'Старый пароль', hasHiddenValue: true },
                { name: 'newPassword', label: 'Новый пароль', hasHiddenValue: true },
                { name: 'newPasswordConfirm', label: 'Пароль повторно', hasHiddenValue: true },
            ];
        default:
            return [];
    }
}
