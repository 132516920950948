import React from 'react';

import { Pagination } from '#/src/components/pagination';
import { fetchRegistersByPage } from '#/src/store/registers/registers-list/actions';
import { countSelector, paginationSelector } from '#/src/store/registers/registers-list/selectors';

export function RegistersListPagination() {
    return (
        <Pagination
            paginationSelector={paginationSelector}
            onChangePageAction={fetchRegistersByPage}
            totalItemsSelector={countSelector}
        />
    );
}
