import { factoryFetch as factoryFetchCorp } from 'corporate-services/lib/fetch-factory';

import { routesDictionary } from '#/src/dictionaries/routes-dictionary';

export function fetchFactory<Req = unknown, Resp = unknown>(
    ...arg: Parameters<typeof factoryFetchCorp>
) {
    const fetch = factoryFetchCorp<Req, Resp>(...arg);

    return async (...argFetch: Parameters<typeof fetch>): Promise<Resp> => {
        try {
            const response = await fetch(...argFetch);

            return await new Promise<Resp>((resolve) => {
                resolve(response);
            });
        } catch (error) {
            if (Array.isArray(error) && error[0]?.status === 401) {
                window.location.assign(routesDictionary.authPage.path);
            }
            throw error;
        }
    };
}
