import { createSelector } from 'reselect';

import { LOADING_STATE } from '#/src/constants/loading-state';
import { ApplicationState } from '#/src/store/reducer';

import { registersModelsSelector } from '../selectors';

export const listStateSelector = (state: ApplicationState) =>
    registersModelsSelector(state).registersList;

export const entitiesSelector = (state: ApplicationState) => listStateSelector(state).entities;

export const idsSelector = (state: ApplicationState) => listStateSelector(state).ids;

export const selectedSelector = (state: ApplicationState) => listStateSelector(state).selected;

export const sortSelector = (state: ApplicationState) => listStateSelector(state).sort;

export const countSelector = (state: ApplicationState) => listStateSelector(state).count;

export const paginationSelector = (state: ApplicationState) => listStateSelector(state).pagination;

export const listLoadingStateSelector = (state: ApplicationState) =>
    listStateSelector(state).loadingState;

export const entitiesIsLoadingSelector = createSelector(
    listLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.LOADING,
);

export const entitiesIsErrorSelector = createSelector(
    listLoadingStateSelector,
    (loadingState) => loadingState === LOADING_STATE.ERROR,
);
