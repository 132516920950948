import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, SelectProps } from '@alfalab/core-components/select';

import {
    statusByDebtorOptions,
    statusBySellerOptions,
} from '#/src/dictionaries/register-row-status';
import { registerInfoSelector } from '#/src/store/registers/register-info/selectors';
import { updateSelectedFilters } from '#/src/store/registers/register-info-filters/action-creators';
import { selectedFiltersSelector } from '#/src/store/registers/register-info-filters/selectors';
import { RegisterRowStatusType } from '#/src/types/registers';

export function StatusFilter() {
    const dispatch = useDispatch();
    const { status } = useSelector(selectedFiltersSelector);
    const register = useSelector(registerInfoSelector);
    const options = useMemo(() => {
        if (register) {
            if (register.debtor) {
                return statusByDebtorOptions;
            }

            return statusBySellerOptions;
        }

        return [];
    }, [register]);

    const handleChangeStatus = useCallback<NonNullable<SelectProps['onChange']>>(
        (payload) => {
            dispatch(
                updateSelectedFilters({
                    status: payload.selectedMultiple.map(({ key }) => key as RegisterRowStatusType),
                }),
            );
        },
        [dispatch],
    );

    return (
        <Select
            dataTestId='registers-filter-status-select'
            allowUnselect={true}
            multiple={true}
            block={true}
            placeholder='Статус'
            label='Статус'
            optionsListWidth='field'
            selected={status}
            options={options}
            onChange={handleChangeStatus}
        />
    );
}
