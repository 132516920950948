import React, { useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Gap } from '@alfalab/core-components/gap';
import { RadioGroup, RadioGroupProps } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';

import { organizationsListSelector } from '#/src/store/organizations/selectors';
import {
    changeRegisterType,
    changeStateType,
} from '#/src/store/registers/send-register/action-creators';
import {
    fileSelector,
    partyTinSelector,
    sendRegisterIsLoadingSelector,
    typeSelector,
} from '#/src/store/registers/send-register/selectors';
import { Permission } from '#/src/types/orgatiozations';

export function OrganizationTypeField() {
    const dispatch = useDispatch();
    const partyTin = useSelector(partyTinSelector);
    const file = useSelector(fileSelector);
    const type = useSelector(typeSelector);
    const organizations = useSelector(organizationsListSelector);
    const isLoading = useSelector(sendRegisterIsLoadingSelector);
    const handleChange = useCallback<NonNullable<RadioGroupProps['onChange']>>(
        (event, payload) => {
            if (payload.value) {
                batch(() => {
                    dispatch(changeStateType(payload.value as 'supplier' | 'debtor'));
                    dispatch(changeRegisterType(true));
                });
            }
        },
        [dispatch],
    );

    const typeRadios = useMemo(() => {
        const organizationInfo = organizations.find((item) => partyTin === item.tin);
        const options = [];

        if (organizationInfo) {
            if (organizationInfo.actions.includes(Permission.FACT_SHOW_SELLER_REGISTERS)) {
                options.push(
                    <Tag key='supplier' value='supplier' size='xxs' disabled={!file || isLoading}>
                        Поставщик
                    </Tag>,
                );
            }

            if (organizationInfo.actions.includes(Permission.FACT_SHOW_DEBTOR_REGISTERS)) {
                options.push(
                    <Tag key='debtor' value='debtor' size='xxs' disabled={!file || isLoading}>
                        Дебитор
                    </Tag>,
                );
            }
        }

        return options;
    }, [organizations, partyTin, file, isLoading]);

    return typeRadios.length ? (
        <React.Fragment>
            <RadioGroup
                label='Роль'
                direction='horizontal'
                type='tag'
                disabled={!file || isLoading}
                onChange={handleChange}
                value={type || undefined}
            >
                {typeRadios}
            </RadioGroup>
            <Gap size='2xl' />
        </React.Fragment>
    ) : null;
}
